import React, { useRef, useState } from 'react';
import './PayStubModal.css';
import { useTranslation } from 'react-i18next';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Button, Typography, Row, Col, Table, Card, Descriptions, Space, Avatar } from 'antd';
import { DownloadOutlined, UserOutlined, BankOutlined, PhoneOutlined, IdcardOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const formatNumber = (number, locale) => {
  if (isNaN(number)) {
    return '0.00';
  }
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(number);
};

const formatDate = (dateString, locale) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString(locale, { month: 'short' });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};


// Modified component to work inside an Ant Design Modal
const PayStubModal = ({ payStubData }) => {
  const { t, i18n } = useTranslation();
  const componentRef = useRef(); // Ref for the content
  const [isButtonHidden, setIsButtonHidden] = useState(false); // State to manage button visibility
  const [logoError, setLogoError] = useState(false); // State to track logo loading errors

  // If no payStubData is provided, don't render anything
  if (!payStubData) {
    return null;
  }

  const formatPayPeriod = (periodString) => {
    // Check if period is in the expected format "2025-01-26_2025-02-25"
    if (periodString && periodString.includes('_')) {
      const [startDateStr, endDateStr] = periodString.split('_');
      
      // Parse the dates
      const startParts = startDateStr.split('-').map(Number);
      const endParts = endDateStr.split('-').map(Number);
      
      // Create date objects (year, month-1, day)
      const startDate = new Date(startParts[0], startParts[1] - 1, startParts[2]);
      const endDate = new Date(endParts[0], endParts[1] - 1, endParts[2]);
      
      // Format as "26 Jan - 25 Feb" (no year)
      const startFormatted = startDate.getDate() + ' ' + 
                            startDate.toLocaleString(i18n.language, { month: 'short' });
      
      const endFormatted = endDate.getDate() + ' ' + 
                          endDate.toLocaleString(i18n.language, { month: 'short' });
      
      return `${startFormatted} - ${endFormatted}`;
    }
    
    // Fallback if period is not in expected format
    return periodString;
  };

  const handleDownloadPDF = () => {
    setIsButtonHidden(true); // Hide the button before capturing
    const element = componentRef.current;

    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(imgData, 'PNG', 10, 10, 190, 0); // Adjust width and height to fit content
      pdf.save('paystub.pdf');
      setIsButtonHidden(false); // Show the button again after capturing
    });
  };

  const calculateSubtotal = (rate, hours) => rate * hours;
  const calculateWithholdingTax = (subtotal) => subtotal * 0.03;
  const calculateTotal = (subtotal, withholdingTax) => subtotal - withholdingTax;

  const calculatedClasses = payStubData.classes ? payStubData.classes.map(cls => {
    const subtotal = calculateSubtotal(cls.rate, cls.hours);
    const withholdingTax = calculateWithholdingTax(subtotal);
    const total = calculateTotal(subtotal, withholdingTax);

    return {
      ...cls,
      subtotal,
      withholdingTax,
      total
    };
  }) : [];

  const totalSubtotal = calculatedClasses.reduce((acc, cls) => acc + cls.subtotal, 0);
  const totalWithholdingTax = calculatedClasses.reduce((acc, cls) => acc + cls.withholdingTax, 0);
  const totalPay = calculatedClasses.reduce((acc, cls) => acc + cls.total, 0);

  // Define table columns
  const columns = [
    {
      title: t('class'),
      dataIndex: 'classId',
      key: 'classId',
    },
    {
      title: t('hourly_rate'),
      dataIndex: 'rate',
      key: 'rate',
      render: (rate) => formatNumber(rate, i18n.language),
      align: 'right',
    },
    {
      title: t('hours'),
      dataIndex: 'hours',
      key: 'hours',
      align: 'right',
    },
    {
      title: t('subtotal'),
      dataIndex: 'subtotal',
      key: 'subtotal',
      render: (subtotal) => formatNumber(subtotal, i18n.language),
      align: 'right',
    },
    {
      title: t('sumWithholdingTax'),
      dataIndex: 'withholdingTax',
      key: 'withholdingTax',
      render: (tax) => formatNumber(tax, i18n.language),
      align: 'right',
    },
    {
      title: t('nettotal'),
      dataIndex: 'total',
      key: 'total',
      render: (total) => formatNumber(total, i18n.language),
      align: 'right',
    },
  ];

  // Add summary row
  const summary = () => {
    return (
      <Table.Summary.Row style={{ fontWeight: 'bold', backgroundColor: '#f0f2f5' }}>
        <Table.Summary.Cell>{t('Total')}</Table.Summary.Cell>
        <Table.Summary.Cell></Table.Summary.Cell>
        <Table.Summary.Cell align="right">{payStubData.totalHours || '0'}</Table.Summary.Cell>
        <Table.Summary.Cell align="right">{formatNumber(totalSubtotal, i18n.language)}</Table.Summary.Cell>
        <Table.Summary.Cell align="right">{formatNumber(totalWithholdingTax, i18n.language)}</Table.Summary.Cell>
        <Table.Summary.Cell align="right">{formatNumber(totalPay, i18n.language)}</Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };

  return (
    <div className="paystub-content" ref={componentRef}>
      {/* Header Card */}
      <Card 
        className="paystub-header-card" 
        bordered={false}
        style={{ marginBottom: 24 }}
      >
        <Row gutter={[24, 16]} align="middle">
          <Col xs={24} sm={12} md={8} lg={6}>
            {/* Company Logo */}
            {payStubData.companyLogo && !logoError ? (
              <img
                src={payStubData.companyLogo}
                alt="Company Logo"
                className="company-logo"
                style={{ maxWidth: '120px', height: 'auto' }}
                onError={() => setLogoError(true)}
              />
            ) : (
              <Avatar shape="square" size={100} icon={<BankOutlined />} />
            )}
          </Col>
          
          <Col xs={24} sm={12} md={8} lg={10}>
            {/* Company Info */}
            <div>
              <Title level={5} style={{ margin: 0 }}>{payStubData.companyName || 'Company Name'}</Title>
              <Text>{payStubData.companyAddress || 'Company Address'}</Text>
              <br />
              <Text>{payStubData.companyWebsite || 'Company Website'}</Text>
              <br />
              <Text>{payStubData.companyTax || 'Company Tax'}</Text>
              <br />
              <Text>{payStubData.companyPhone || 'Company Phone'}</Text>
              <br />
              <Text>{payStubData.companyLine || 'Company Email'}</Text>
            </div>
          </Col>
          
          <Col xs={24} sm={24} md={8} lg={8}>
            <div className="paystub-info" style={{ textAlign: 'right' }}>
              <Descriptions column={1} size="small" style={{ textAlign: 'right' }}>
              <Descriptions.Item label={t('Period')} labelStyle={{ textAlign: 'right' }}>
                <Text strong>
                  {payStubData.payPeriod && payStubData.payPeriod.includes('_') 
                    ? formatPayPeriod(payStubData.payPeriod)
                    : payStubData.payPeriod || 'N/A'}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label={t('Pay Date')} labelStyle={{ textAlign: 'right' }}>
  <Text strong>{payStubData.payDate ? formatDate(payStubData.payDate, i18n.language) : 'N/A'}</Text>
</Descriptions.Item>
              </Descriptions>
            </div>
          </Col>
        </Row>
      </Card>

      {/* Employee Info */}
      <Card 
        title={<Space><UserOutlined /> {t('Employee Info')}</Space>}
        style={{ marginBottom: 24 }}
        size="small"
      >
        <Row gutter={[16, 8]}>
          <Col xs={24} sm={12}>
            <Space>
              <UserOutlined />
              <Text strong>{t('name')}:</Text>
              <Text>{payStubData.teacherName || 'N/A'}</Text>
            </Space>
          </Col>
          <Col xs={24} sm={12}>
            <Space>
              <IdcardOutlined />
              <Text strong>{t('nationalID')}:</Text>
              <Text>{payStubData.teacherNationalId || 'N/A'}</Text>
            </Space>
          </Col>
          <Col xs={24} sm={12}>
            <Space>
              <BankOutlined />
              <Text strong>{t('address')}:</Text>
              <Text>{payStubData.teacherAddress || 'N/A'}</Text>
            </Space>
          </Col>
          <Col xs={24} sm={12}>
            <Space>
              <PhoneOutlined />
              <Text strong>{t('phone')}:</Text>
              <Text>{payStubData.teacherPhone || 'N/A'}</Text>
            </Space>
          </Col>
        </Row>
      </Card>

      {/* Payment Details Table */}
      <Card 
        title={t('Payment Details')}
        style={{ marginBottom: 24 }}
        size="small"
      >
        <Table 
          columns={columns} 
          dataSource={calculatedClasses}
          pagination={false}
          rowKey="classId"
          size="small"
          summary={summary}
          locale={{ emptyText: t('No class data available') }}
          style={{ overflow: 'auto' }}
        />
      </Card>

      {/* Download Button */}
      {!isButtonHidden && (
        <div style={{ textAlign: 'center', marginTop: 24 }}>
          <Button 
            type="primary" 
            icon={<DownloadOutlined />} 
            onClick={handleDownloadPDF}
            size="large"
          >
            {t('Download as PDF')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default PayStubModal;