import React, { useState, useRef, useEffect } from 'react';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faTrash, 
  faUndo, 
  faPen, 
  faEraser, 
  faSave, 
  faPaintBrush, 
  faPalette, 
  faFont, 
  faMousePointer 
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { 
  Row, 
  Col, 
  Button, 
  Input, 
  Slider, 
  ColorPicker, 
  Typography, 
  Select, 
  Space,
  Tooltip,
  Divider,
  Card
} from 'antd';
import './DoodlePage.css';

const { TextArea } = Input;
const { Title } = Typography;
const { Option } = Select;

const DoodlePage = () => {
  const canvasRef = useRef(null);
  const wrapperRef = useRef(null);
  const containerRef = useRef(null);
  const { t } = useTranslation();
  
  // Canvas drawing states
  const [strokeColor, setStrokeColor] = useState("#000000");
  const [strokeWidth, setStrokeWidth] = useState(4);
  const [isErasing, setIsErasing] = useState(false);
  
  // Text states
  const [textInput, setTextInput] = useState('');
  const [textSize, setTextSize] = useState(20);
  const [textColor, setTextColor] = useState('#000000');
  const [textFont, setTextFont] = useState('Arial');
  
  // Mode and selection states
  const [mode, setMode] = useState('draw'); // 'draw', 'text', or 'select'
  const [texts, setTexts] = useState([]);
  const [textsHistory, setTextsHistory] = useState([]);
  const [selectedTextIndex, setSelectedTextIndex] = useState(null);
  
  // Drag and drop states
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartPos, setDragStartPos] = useState({ x: 0, y: 0 });
  
  // Canvas sizing
  const [canvasSize, setCanvasSize] = useState({ width: 1200, height: 650 });

  // Handle responsive canvas sizing
  useEffect(() => {
    const updateCanvasSize = () => {
      if (containerRef.current) {
        const container = containerRef.current;
        const containerWidth = container.clientWidth;
        // Set a maximum width for the canvas
        const maxWidth = Math.min(containerWidth - 20, 1200);
        // Maintain aspect ratio
        const aspectRatio = 650 / 1200;
        const height = maxWidth * aspectRatio;
        
        setCanvasSize({
          width: maxWidth,
          height: height
        });
      }
    };

    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);
    
    return () => {
      window.removeEventListener('resize', updateCanvasSize);
    };
  }, []);

  // Add drag event listeners
  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, selectedTextIndex, texts]);

  // Add new text with history tracking
  const addText = (position) => {
    if (textInput.trim()) {
      // Save current state to history
      setTextsHistory(prev => [...prev, [...texts]]);
      
      // Add new text
      setTexts([...texts, {
        id: Date.now(),
        content: textInput,
        position: position,
        color: textColor,
        size: textSize,
        font: textFont
      }]);
      
      // Reset text input
      setTextInput('');
    }
  };

  // Undo text placement
  const undoText = () => {
    if (textsHistory.length > 0) {
      // Restore previous state
      const lastState = textsHistory[textsHistory.length - 1];
      setTexts(lastState);
      
      // Remove the used history item
      setTextsHistory(prev => prev.slice(0, -1));
    }
  };

  // Update selected text
  const updateSelectedText = () => {
    if (selectedTextIndex !== null) {
      const updatedTexts = [...texts];
      updatedTexts[selectedTextIndex] = {
        ...updatedTexts[selectedTextIndex],
        content: textInput,
        color: textColor,
        size: textSize,
        font: textFont
      };
      setTexts(updatedTexts);
      setSelectedTextIndex(null);
      setTextInput('');
    }
  };

  // Delete selected text
  const deleteSelectedText = () => {
    if (selectedTextIndex !== null) {
      const updatedTexts = texts.filter((_, index) => index !== selectedTextIndex);
      setTexts(updatedTexts);
      setSelectedTextIndex(null);
      setTextInput('');
    }
  };

  // Handle wrapper click for text placement
  const handleWrapperClick = (e) => {
    // If in draw mode, don't do anything special
    if (mode === 'draw') {
      return;
    }
    
    e.preventDefault();
    e.stopPropagation();
    
    // Get canvas position
    const wrapper = wrapperRef.current;
    const rect = wrapper.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    
    if (mode === 'text') {
      addText({ x, y });
    } else if (mode === 'select') {
      // Check if any text is clicked
      const clickedTextIndex = texts.findIndex(text => {
        // Simple hit detection (can be improved for better accuracy)
        const textWidth = text.content.length * (text.size / 2);
        return (
          x >= text.position.x &&
          x <= text.position.x + textWidth &&
          y >= text.position.y - text.size &&
          y <= text.position.y
        );
      });
      
      if (clickedTextIndex === -1) {
        // If clicking empty space, deselect
        setSelectedTextIndex(null);
      }
    }
  };
  
  // Handle mouse down for dragging text
  const handleTextMouseDown = (e, index) => {
    if (mode === 'select') {
      e.stopPropagation();
      e.preventDefault();
      
      setSelectedTextIndex(index);
      setIsDragging(true);
      
      // Get the specific text element
      const text = texts[index];
      
      // Load the selected text's properties
      setTextInput(text.content);
      setTextColor(text.color);
      setTextSize(text.size);
      setTextFont(text.font);
      
      // Remember where the drag started relative to the text position
      const wrapper = wrapperRef.current;
      const rect = wrapper.getBoundingClientRect();
      const mouseX = e.clientX - rect.left;
      const mouseY = e.clientY - rect.top;
      
      setDragStartPos({
        x: mouseX - text.position.x,
        y: mouseY - text.position.y
      });
    }
  };

  // Handle mouse move for dragging text
  const handleMouseMove = (e) => {
    if (isDragging && selectedTextIndex !== null) {
      const wrapper = wrapperRef.current;
      if (!wrapper) return;
      
      const rect = wrapper.getBoundingClientRect();
      const mouseX = e.clientX - rect.left;
      const mouseY = e.clientY - rect.top;
      
      // Calculate new position
      const newX = mouseX - dragStartPos.x;
      const newY = mouseY - dragStartPos.y;
      
      // Update the text position
      const updatedTexts = [...texts];
      updatedTexts[selectedTextIndex] = {
        ...updatedTexts[selectedTextIndex],
        position: { x: newX, y: newY }
      };
      
      setTexts(updatedTexts);
    }
  };

  // Handle mouse up for dragging text
  const handleMouseUp = () => {
    if (isDragging) {
      setIsDragging(false);
    }
  };

  const saveImage = async () => {
    // Create a canvas to combine drawing and text
    const canvas = document.createElement('canvas');
    canvas.width = canvasSize.width;
    canvas.height = canvasSize.height;
    const ctx = canvas.getContext('2d');
    
    // Fill with white background first
    ctx.fillStyle = '#FFFFFF';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    
    // Get the sketch data
    const dataUrl = await canvasRef.current.exportImage("png");
    
    // Draw the sketch on our canvas
    const img = new Image();
    img.onload = () => {
      ctx.drawImage(img, 0, 0);
      
      // Draw all text elements
      texts.forEach(text => {
        ctx.font = `${text.size}px ${text.font}`;
        ctx.fillStyle = text.color;
        ctx.fillText(text.content, text.position.x, text.position.y);
      });
      
      // Convert to JPG data URL and download
      const finalDataUrl = canvas.toDataURL('image/jpeg', 0.9);
      const link = document.createElement("a");
      link.href = finalDataUrl;
      link.download = "sketch.jpg";
      link.click();
    };
    img.src = dataUrl;
  };

  const toggleEraser = () => {
    setMode('draw');
    if (isErasing) {
      setStrokeColor("#000000"); // Reset to black
    } else {
      setStrokeColor("#FFFFFF"); // Set to white (assuming the background is white)
    }
    setIsErasing(!isErasing);
  };

  // Switch to text mode
  const switchToTextMode = () => {
    setMode('text');
    setIsErasing(false);
    setSelectedTextIndex(null);
  };

  // Switch to draw mode
  const switchToDrawMode = () => {
    setMode('draw');
    setSelectedTextIndex(null);
    
    // Set eraser mode if needed
    if (canvasRef.current && isErasing) {
      canvasRef.current.eraseMode(true);
    }
  };

  // Switch to select mode
  const switchToSelectMode = () => {
    setMode('select');
    setIsErasing(false);
    setSelectedTextIndex(null);
  };

  // Render texts on canvas overlay
  const renderTexts = () => {
    return texts.map((text, index) => (
      <div
        key={text.id}
        style={{
          position: 'absolute',
          left: `${text.position.x}px`,
          top: `${text.position.y - text.size}px`,
          color: text.color,
          fontSize: `${text.size}px`,
          fontFamily: text.font,
          cursor: mode === 'select' ? 'move' : 'default',
          pointerEvents: mode === 'select' ? 'auto' : 'none',
          border: selectedTextIndex === index ? '1px dashed #777' : 'none',
          padding: selectedTextIndex === index ? '2px' : '0',
          userSelect: 'none',
        }}
        onMouseDown={(e) => handleTextMouseDown(e, index)}
      >
        {text.content}
      </div>
    ));
  };

  return (
    <div className="doodle-container">
      <Sidebar />
      <Header />
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} sm={24} md={24} lg={24}>
          <Title level={3} style={{ textAlign: 'center' }}>{t('Doodle Board')}</Title>
        </Col>

        <Col xs={24} sm={24} md={18} lg={18} ref={containerRef}>
          <Card className="canvas-container">
            <div 
              ref={wrapperRef}
              onClick={mode !== 'draw' ? handleWrapperClick : undefined}
              style={{ position: 'relative', width: '100%', height: '100%' }}
              className={`${mode === 'text' ? 'text-mode' : mode === 'select' ? 'select-mode' : 'draw-mode'}`}
            >
              <ReactSketchCanvas
                ref={canvasRef}
                style={{
                  border: '1px solid #9c9c9c',
                  borderRadius: '4px',
                  width: `${canvasSize.width}px`,
                  height: `${canvasSize.height}px`,
                  pointerEvents: mode === 'draw' ? 'auto' : 'none'
                }}
                width={`${canvasSize.width}px`}
                height={`${canvasSize.height}px`}
                strokeWidth={strokeWidth}
                strokeColor={strokeColor}
                backgroundColor="white"
                canvasColor="white"
                exportWithBackgroundImage={true}
              />
              <div 
                className="text-overlay" 
                style={{ 
                  position: 'absolute', 
                  top: 0, 
                  left: 0,
                  width: `${canvasSize.width}px`,
                  height: `${canvasSize.height}px`,
                  pointerEvents: mode !== 'draw' ? 'auto' : 'none'
                }}
              >
                {renderTexts()}
              </div>
            </div>
          </Card>
        </Col>

        <Col xs={24} sm={24} md={6} lg={6}>
          <Card title={t('Tools')} className="tools-card">
            <Space direction="vertical" style={{ width: '100%' }}>
              {/* Drawing Mode Selection */}
              <div className="mode-selector">
                <Space>
                  <Tooltip title={t('Draw Mode')}>
                    <Button 
                      type={mode === 'draw' ? 'primary' : 'default'}
                      shape="circle" 
                      icon={<FontAwesomeIcon icon={faPen} />} 
                      onClick={switchToDrawMode}
                    />
                  </Tooltip>
                  <Tooltip title={t('Text Mode')}>
                    <Button 
                      type={mode === 'text' ? 'primary' : 'default'}
                      shape="circle" 
                      icon={<FontAwesomeIcon icon={faFont} />} 
                      onClick={switchToTextMode}
                    />
                  </Tooltip>
                  <Tooltip title={t('Select Mode')}>
                    <Button 
                      type={mode === 'select' ? 'primary' : 'default'}
                      shape="circle" 
                      icon={<FontAwesomeIcon icon={faMousePointer} />} 
                      onClick={switchToSelectMode}
                    />
                  </Tooltip>
                </Space>
              </div>

              <Divider />

              {/* Drawing Tools */}
              {mode === 'draw' && (
                <>
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <Space>
                      <Button 
                        onClick={() => canvasRef.current.clearCanvas()} 
                        icon={<FontAwesomeIcon icon={faTrash} />}
                        danger
                      >
                        {t('Clear')}
                      </Button>
                      <Button 
                        onClick={() => canvasRef.current.undo()} 
                        icon={<FontAwesomeIcon icon={faUndo} />}
                      >
                        {t('Undo')}
                      </Button>
                    </Space>
                    
                    <Button 
                      onClick={toggleEraser} 
                      type={isErasing ? 'primary' : 'default'}
                      icon={<FontAwesomeIcon icon={isErasing ? faPen : faEraser} />}
                    >
                      {isErasing ? t('Pen') : t('Eraser')}
                    </Button>
                    
                    <div className="brush-size">
                      <Typography.Text strong>
                        <FontAwesomeIcon icon={faPaintBrush} /> {t('Pen Size')}
                      </Typography.Text>
                      <Slider
                        min={1}
                        max={15}
                        value={strokeWidth}
                        onChange={setStrokeWidth}
                      />
                    </div>
                    
                    <div className="color-picker">
                      <Typography.Text strong>
                        <FontAwesomeIcon icon={faPalette} /> {t('Pen Color')}
                      </Typography.Text>
                      <div>
                        <ColorPicker
                          value={strokeColor}
                          onChange={(color) => {
                            setStrokeColor(color.toHexString());
                            setIsErasing(false);
                          }}
                        />
                      </div>
                    </div>
                  </Space>
                </>
              )}

              {/* Text Tools */}
              {(mode === 'text' || mode === 'select') && (
                <>
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <TextArea
                      rows={3}
                      value={textInput}
                      onChange={(e) => setTextInput(e.target.value)}
                      placeholder={t('Enter text to add to canvas')}
                    />
                    
                    <div className="text-settings">
                      <div className="text-size">
                        <Typography.Text strong>{t('Text Size')}</Typography.Text>
                        <Slider
                          min={10}
                          max={50}
                          value={textSize}
                          onChange={setTextSize}
                        />
                      </div>
                      
                      <div className="text-color">
                        <Typography.Text strong>{t('Text Color')}</Typography.Text>
                        <div>
                          <ColorPicker
                            value={textColor}
                            onChange={(color) => setTextColor(color.toHexString())}
                          />
                        </div>
                      </div>
                      
                      <div className="text-font">
                        <Typography.Text strong>{t('Font Family')}</Typography.Text>
                        <Select
                          style={{ width: '100%' }}
                          value={textFont}
                          onChange={setTextFont}
                        >
                          <Option value="Arial">Arial</Option>
                          <Option value="Times New Roman">Times New Roman</Option>
                          <Option value="Courier New">Courier New</Option>
                          <Option value="Georgia">Georgia</Option>
                          <Option value="Verdana">Verdana</Option>
                        </Select>
                      </div>
                    </div>
                    
                    {mode === 'text' && (
                      <Typography.Text italic>
                        {t('Click on the canvas to place your text')}
                      </Typography.Text>
                    )}
                    
                    {mode === 'select' && (
                      <Space>
                        {selectedTextIndex !== null ? (
                          <>
                            <Button
                              type="primary"
                              onClick={updateSelectedText}
                            >
                              {t('Update Text')}
                            </Button>
                            <Button
                              danger
                              onClick={deleteSelectedText}
                            >
                              {t('Delete Text')}
                            </Button>
                          </>
                        ) : (
                          <Typography.Text italic>
                            {t('Click on text to select it')}
                          </Typography.Text>
                        )}
                      </Space>
                    )}
                  </Space>
                </>
              )}
              
              <Divider />
              
              {/* Undo Text Button */}
              {mode !== 'draw' && texts.length > 0 && (
                <Button 
                  onClick={undoText}
                  icon={<FontAwesomeIcon icon={faUndo} />}
                >
                  {t('Undo Text')}
                </Button>
              )}
              
              {/* Save Button */}
              <Button 
                type="primary" 
                icon={<FontAwesomeIcon icon={faSave} />} 
                onClick={saveImage}
                block
              >
                {t('Save Image')}
              </Button>
            </Space>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default withRoleAccess(DoodlePage, ['admin', 'superadmin', 'teacher', 'student']);