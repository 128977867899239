import React, { useState, useEffect } from 'react';
import { 
  Table, 
  Card, 
  Button, 
  Space, 
  Typography, 
  Tag, 
  message, 
  Tooltip, 
  Divider,
  Row,
  Col
} from 'antd';
import { 
  FileExcelOutlined, 
  FilterOutlined, 
  ClockCircleOutlined,
  ReloadOutlined,
  SearchOutlined
} from '@ant-design/icons';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import dayjs from 'dayjs';
import './InventoryPage.css';

const { Title, Text } = Typography;

const InventoryReport = () => {
  const { t } = useTranslation();
  const [reportData, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  
  const API_URL = process.env.REACT_APP_API_URL;

  // Format date and time
  const formatDateTime = (dateString) => {
    return moment(dateString).format('YYYY-MM-DD HH:mm:ss');
  };

  // Fetch report data
  useEffect(() => {
    const fetchReportData = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/inventory/report`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        
        const sortedData = response.data
          .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
          .map(item => ({
            ...item,
            key: `${item.product_id}-${item.timestamp}`,
            formattedDate: formatDateTime(item.timestamp)
          }));
          
        setReportData(sortedData);
        setFilteredData(sortedData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching inventory report:', err);
        setError('Failed to fetch inventory report.');
        message.error('Failed to fetch inventory report');
        setLoading(false);
      }
    };

    fetchReportData();
  }, [API_URL]);

  // Handle date range change
  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  // Apply filters
const applyFilters = () => {
  let filtered = reportData;

  // Apply date range filter
  if (dateRange[0] && dateRange[1]) {
    const startDate = dateRange[0].startOf('day').valueOf(); // Convert to milliseconds
    const endDate = dateRange[1].endOf('day').valueOf(); // Convert to milliseconds
    
    filtered = filtered.filter(item => {
      const timestamp = new Date(item.timestamp).getTime(); // Convert to milliseconds
      return timestamp >= startDate && timestamp <= endDate; // Simple numeric comparison
    });
  }

  setFilteredData(filtered);
};

  // Reset filters
  const resetFilters = () => {
    setDateRange([null, null]);
    setFilteredData(reportData);
  };

  // Refresh data
  const refreshData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/inventory/report`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      const sortedData = response.data
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
        .map(item => ({
          ...item,
          key: `${item.product_id}-${item.timestamp}`,
          formattedDate: formatDateTime(item.timestamp)
        }));
        
      setReportData(sortedData);
      setFilteredData(sortedData);
      message.success('Report data refreshed');
    } catch (err) {
      console.error('Error refreshing inventory report:', err);
      message.error('Failed to refresh inventory report');
    } finally {
      setLoading(false);
    }
  };

  // Get search input for table filtering
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    render: text =>
      searchedColumn === dataIndex ? (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ) : (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
  });

  // Handle search in table
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  // Reset search filters
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  // Get transaction type tag color
  const getTransactionTypeColor = (type) => {
    switch(type?.toLowerCase()) {
      case 'purchase':
        return 'green';
      case 'sale':
        return 'blue';
      case 'adjustment':
        return 'orange';
      case 'return':
        return 'purple';
      default:
        return 'default';
    }
  };

  // Define table columns
  const columns = [
    {
      title: t('productid'),
      dataIndex: 'product_id',
      key: 'product_id',
      width: 120,
      ...getColumnSearchProps('product_id'),
      sorter: (a, b) => a.product_id.localeCompare(b.product_id),
    },
    {
      title: t('itemname'),
      dataIndex: 'item_name',
      key: 'item_name',
      ...getColumnSearchProps('item_name'),
      sorter: (a, b) => a.item_name.localeCompare(b.item_name),
      render: (text) => <Text strong>{text}</Text>,
    },
    {
      title: t('description'),
      dataIndex: 'item_description',
      key: 'item_description',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: t('category'),
      dataIndex: 'category',
      key: 'category',
      filters: [...new Set(reportData.map(item => item.category))]
        .filter(Boolean)
        .map(category => ({ text: category, value: category })),
      onFilter: (value, record) => record.category === value,
      render: (category) => (
        <Tag color="blue">{category}</Tag>
      ),
    },
    {
      title: t('priceperunit'),
      dataIndex: 'price_per_unit',
      key: 'price_per_unit',
      sorter: (a, b) => a.price_per_unit - b.price_per_unit,
      render: (price) => (
        <Text>฿{parseFloat(price).toFixed(2)}</Text>
      ),
    },
    {
      title: t('type'),
      dataIndex: 'transaction_type',
      key: 'transaction_type',
      filters: [...new Set(reportData.map(item => item.transaction_type))]
        .filter(Boolean)
        .map(type => ({ text: type, value: type })),
      onFilter: (value, record) => record.transaction_type === value,
      render: (type) => (
        <Tag color={getTransactionTypeColor(type)}>
          {type}
        </Tag>
      ),
    },
    {
      title: t('unit'),
      dataIndex: 'quantity_change',
      key: 'quantity_change',
      sorter: (a, b) => a.quantity_change - b.quantity_change,
      render: (quantity) => {
        const isPositive = quantity > 0;
        return (
          <Text style={{ color: isPositive ? '#52c41a' : '#f5222d' }}>
            {isPositive ? '+' : ''}{quantity}
          </Text>
        );
      },
    },
    {
      title: t('changereason'),
      dataIndex: 'change_reason',
      key: 'change_reason',
      ...getColumnSearchProps('change_reason'),
    },
    {
      title: t('document'),
      dataIndex: 'document_no',
      key: 'document_no',
      render: (text, record) => {
        const documentNumber = text || record.invoiceno;
        return documentNumber ? (
          <Text copyable={{ text: documentNumber }}>
            {documentNumber}
          </Text>
        ) : (
          <Text type="secondary">-</Text>
        );
      },
    },
    {
      title: t('updated by'),
      dataIndex: 'updated_by',
      key: 'updated_by',
      width: 120,
      ...getColumnSearchProps('updated_by'),
      render: (text) => text ? (
        <Text>
          {text}
        </Text>
      ) : (
        <Text type="secondary">-</Text>
      ),
    },
    {
      title: t('date'),
      dataIndex: 'formattedDate',
      key: 'formattedDate',
      sorter: (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
      defaultSortOrder: 'descend',
      render: (text, record) => (
        <Tooltip title={moment(record.timestamp).format('YYYY-MM-DD HH:mm:ss')}>
          <Space>
            <ClockCircleOutlined style={{ color: '#8c8c8c' }} />
            <span>{text}</span>
          </Space>
        </Tooltip>
      ),
    },
  ];

  // Prepare CSV data
  const csvData = filteredData.map(item => ({
    product_id: item.product_id,
    item_name: item.item_name,
    item_description: item.item_description,
    category: item.category,
    price_per_unit: parseFloat(item.price_per_unit).toFixed(2),
    transaction_type: item.transaction_type,
    quantity_change: item.quantity_change,
    change_reason: item.change_reason,
    document_no: item.document_no || item.invoiceno || '',
    timestamp: formatDateTime(item.timestamp)
  }));

  // Prepare CSV filename
  const getCSVFilename = () => {
    let filename = 'inventory_report';
    
    if (dateRange[0] && dateRange[1]) {
      filename += `_${dateRange[0].format('YYYYMMDD')}_to_${dateRange[1].format('YYYYMMDD')}`;
    } else {
      filename += `_${moment().format('YYYYMMDD')}`;
    }
    
    return `${filename}.csv`;
  };

  // Get summary statistics
  const getSummary = (pageData) => {
    const totalItems = pageData.length;
    const totalPositiveChanges = pageData.reduce((sum, item) => 
      item.quantity_change > 0 ? sum + item.quantity_change : sum, 0);
    const totalNegativeChanges = pageData.reduce((sum, item) => 
      item.quantity_change < 0 ? sum + Math.abs(item.quantity_change) : sum, 0);
      
    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={6}>
            <Text strong>Total</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6}>
            <Text strong style={{ color: '#52c41a' }}>+{totalPositiveChanges}</Text> / 
            <Text strong style={{ color: '#f5222d' }}>-{totalNegativeChanges}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7} colSpan={3}>
            <Text strong>{totalItems} transactions</Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  return (
    <div>
      <Sidebar />
      <Header />
      <div className="inventory-report-container">
        <Card 
          title={<Title level={3}>{t('Inventory Report')}</Title>}
          extra={
            <Button 
              type="primary" 
              icon={<ReloadOutlined />} 
              onClick={refreshData}
              loading={loading}
            >
              Refresh
            </Button>
          }
          className="inventory-card"
        >
          {error && (
            <div className="error-message">
              <Text type="danger">{error}</Text>
            </div>
          )}
          
          <Row gutter={16} className="filter-section">
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
  <Space direction="vertical" style={{ width: '100%' }}>
    <Text strong><FilterOutlined /> Date Range</Text>
    <Space>
      <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <input
          type="date"
          value={dateRange?.[0]?.format('YYYY-MM-DD') || ''}
          onChange={(e) => {
            const startDate = e.target.value ? dayjs(e.target.value) : null;
            const endDate = dateRange?.[1] || null;
            handleDateRangeChange([startDate, endDate]);
          }}
          style={{ 
            padding: '8px', 
            borderRadius: '4px', 
            border: '1px solid #d9d9d9',
            height: '32px'
          }}
        />
        <span>to</span>
        <input
          type="date"
          value={dateRange?.[1]?.format('YYYY-MM-DD') || ''}
          onChange={(e) => {
            const startDate = dateRange?.[0] || null;
            const endDate = e.target.value ? dayjs(e.target.value) : null;
            handleDateRangeChange([startDate, endDate]);
          }}
          style={{ 
            padding: '8px', 
            borderRadius: '4px', 
            border: '1px solid #d9d9d9',
            height: '32px'
          }}
        />
      </div>
      <Button 
        type="primary" 
        onClick={applyFilters}
        icon={<FilterOutlined />}
      >
        Apply
      </Button>
      <Button onClick={resetFilters}>
        Reset
      </Button>
      <CSVLink
                data={csvData}
                filename={getCSVFilename()}
                className="ant-btn ant-btn-primary export-button"
                target="_blank"
              >
                <Button
                              type="primary"
                              icon={<FileExcelOutlined />}
                            >
                              {t("Export CSV")}
                            </Button>
              </CSVLink>
    </Space>
  </Space>
</Col>
            

          </Row>
          
          <Divider />

          <Table
            columns={columns}
            dataSource={filteredData}
            loading={loading}
            pagination={{
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100'],
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
            }}
            summary={getSummary}
            size="middle"
            scroll={{ x: 'max-content' }}
            className="inventory-report-table"
          />
        </Card>
      </div>
    </div>
  );
};

export default withRoleAccess(InventoryReport, ['superadmin', 'admin']);