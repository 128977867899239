import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';
import axios from 'axios';
import { Card, Typography, Button, Form, Input, Space } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPenToSquare, faMessage, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import LanguageSwitcher from '../LanguageSwitcher'; 
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

const Header = ({ onSignOut }) => {
  const [username, setUsername] = useState('');
  const [unreadCount, setUnreadCount] = useState(0);
  const [profile, setProfile] = useState(null);
  const [showProfile, setShowProfile] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const { t } = useTranslation();

  useEffect(() => {
    const fetchUserProfile = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${API_URL}/users/me`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUsername(response.data.firstname);
        setProfile(response.data);

        // Fetch unread count
        const unreadCountResponse = await axios.get(`${API_URL}/chat/unread-count/${response.data.userid}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUnreadCount(unreadCountResponse.data.unreadCount);
      } catch (error) {
        console.error('Error fetching user profile or unread count:', error);
      }
    };

    fetchUserProfile();
    const interval = setInterval(fetchUserProfile, 60000); // Refresh every minute

    return () => clearInterval(interval);
  }, [API_URL]);

  const handleChatClick = () => {
    navigate('/chat');
  };

  const handleSignOut = async () => {
    try {
      await axios.get(`${API_URL}/auth/signout`);
      localStorage.removeItem('token');
      if (typeof onSignOut === 'function') {
        onSignOut();
      }
      window.location.href = '/signin';
    } catch (error) {
      console.error('Error signing out:', error);
      alert('Failed to sign out. Please try again.');
    }
  };

  const toggleProfile = () => {
    setShowProfile(!showProfile);
  };

  const toggleChangePassword = () => {
    setShowChangePassword(!showChangePassword);
  };

  const handleDoodleClick = () => {
    navigate('/doodle');
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      alert("New passwords do not match");
      return;
    }
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(`${API_URL}/users/change-password`, {
        password,
        newPassword
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      alert(response.data.message);
      setShowChangePassword(false);
      handleSignOut(); // Force sign out after successful password change
    } catch (error) {
      console.error('Error changing password:', error);
      alert('Failed to change password. Please try again.');
    }
  };

  return (
    <div className="modern-header">
      <div className="header-container">
        <div className="logo-section">
          <img src='/images/advisorlogo.png' alt="Company Logo" className="modern-logo" />
        </div>
        
        <div className="header-actions">
          <div className="user-profile" onClick={toggleProfile}>
            <FontAwesomeIcon icon={faUser} className="header-icon user-icon" />
            <span className="username-text">{t('hello')} <strong>{username}</strong>!</span>
          </div>
          
          {(profile?.role === 'superadmin' || profile?.role === 'admin' || profile?.role === 'teacher') && (
            <button onClick={handleChatClick} className="modern-button chat-button">
              <FontAwesomeIcon icon={faMessage} className="header-icon" />
              {unreadCount > 0 && <span className="notification-indicator">{unreadCount}</span>}
            </button>
          )}
          
          <button onClick={handleDoodleClick} className="modern-button doodle-button">
            <FontAwesomeIcon icon={faPenToSquare} className="header-icon" />
          </button>
          
          <button onClick={handleSignOut} className="modern-button signout-button">
            <FontAwesomeIcon icon={faSignOutAlt} className="header-icon" />
            <span className="button-text">{t('sign out')}</span>
          </button>
          
          <div className="language-control">
            <LanguageSwitcher />
          </div>
        </div>
      </div>
      
      {showProfile && profile && (
          <div className={`profile-popup ${showProfile ? 'show' : ''}`} style={{ position: 'fixed', top: 40, right: 40, zIndex: 1000 }}>
            <Card
              style={{
                width: 320,
                borderRadius: 12,
                boxShadow: '0 2px 12px rgba(0,0,0,0.2)',
                padding: '0 0 16px 0',
              }}
              bodyStyle={{ paddingTop: 0 }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '16px 16px 8px',
                  borderBottom: '1px solid #f0f0f0',
                }}
              >
                <Title level={5} style={{ margin: 0 }}>
                  User Profile
                </Title>
                <Button
                  type="text"
                  icon={<CloseOutlined />}
                  onClick={toggleProfile}
                  style={{ marginRight: -8 }}
                />
              </div>

              <div style={{ textAlign: 'center', padding: '16px' }}>
                <Title level={4} style={{ marginBottom: 4 }}>
                  {profile.firstname} {profile.lastname}
                </Title>
                <Text type="secondary">{profile.role}</Text>

                <Button
                  type="link"
                  onClick={toggleChangePassword}
                  style={{ marginTop: 16, color: '#b01030', fontWeight: 600 }}
                >
                  Change My Password
                </Button>
              </div>

              {showChangePassword && (
                <div style={{ padding: '0 16px' }}>
                  <Form layout="vertical" onFinish={handleChangePassword}>
                    <Form.Item label="Current Password" required>
                      <Input.Password
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item label="New Password" required>
                      <Input.Password
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item label="Confirm New Password" required>
                      <Input.Password
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                      />
                    </Form.Item>
                    <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Button type="primary" htmlType="submit">
                        Change Password
                      </Button>
                      <Button onClick={toggleChangePassword}>Cancel</Button>
                    </Space>
                  </Form>
                </div>
              )}
            </Card>

          </div>
        )}
    </div>
  );
};

export default Header;