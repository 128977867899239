import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Form, Input, Button, Row, Col, Select, message, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { MailOutlined, UserOutlined, IdcardOutlined, BookOutlined, UploadOutlined } from '@ant-design/icons';
import withRoleAccess from '../../hoc/withRoleAccess';

const { Option } = Select;
const { TextArea } = Input;

const EditTeacherModal = ({ isOpen, onClose, onSave, teacherId }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [photoUrl, setPhotoUrl] = useState('');
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchTeacherData = async () => {
            if (!teacherId) return;
            setLoading(true);
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${API_URL}/teachers/${teacherId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                const data = response.data;
                setPhotoUrl(data.photourl || '');
                form.setFieldsValue({
                    ...data,
                    photourl: data.photourl || '',
                    language: Array.isArray(data.language) ? data.language : [],
                });
            } catch (error) {
                console.error('Error fetching teacher data:', error);
                message.error(t('fetchTeacherError'));
            } finally {
                setLoading(false);
            }
        };

        if (isOpen) {
            fetchTeacherData();
        }
    }, [teacherId, isOpen, API_URL, t, form]);

    const handleSubmit = async (values) => {
        try {
            const token = localStorage.getItem('token');
            await axios.put(`${API_URL}/teachers/${teacherId}`, {
                ...values,
                photourl: values.photourl || photoUrl
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            message.success(t('success'));
            onSave(values);
            onClose();
        } catch (error) {
            console.error('Error updating teacher:', error);
            message.error(t('updateTeacherError'));
        }
    };

    return (
        <Modal
            title={t('editTeacher')}
            visible={isOpen}
            onCancel={onClose}
            footer={null}
            destroyOnClose
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                scrollToFirstError
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="firstname" label={t('form.first_name')} rules={[{ required: true }]}>
                            <Input prefix={<UserOutlined />} placeholder={t('form.first_name')} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="lastname" label={t('form.last_name')} rules={[{ required: true }]}>
                            <Input placeholder={t('form.last_name')} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="nickname" label={t('form.nickname')} rules={[{ required: true }]}>
                            <Input placeholder={t('form.nickname')} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="email" label={t('form.email')} rules={[{ required: true, type: 'email' }]}>
                            <Input prefix={<MailOutlined />} placeholder={t('form.email')} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="phone" label={t('form.phone')} rules={[{ required: true }]}>
                            <Input placeholder={t('form.phone')} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="nationalid" label={t('form.national_id')}>
                            <Input prefix={<IdcardOutlined />} placeholder={t('form.national_id')} />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item name="photourl" label={t('Photo')}>
                    <Input 
                        placeholder={t('Photo (URL)')} 
                        addonAfter={<UploadOutlined />} 
                    />
                </Form.Item>

                <Form.Item name="address" label={t('form.address')} rules={[{ required: true }]}>
                    <TextArea rows={2} placeholder={t('form.address')} />
                </Form.Item>

                <h3>{t('form.academic_details')}</h3>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="bachelor_degree" label={t('form.bachelor_degree')} rules={[{ required: true }]}>
                            <Input prefix={<BookOutlined />} placeholder={t('form.bachelor_degree')} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="bachelor_school" label={t('form.bachelor_school')} rules={[{ required: true }]}>
                            <Input placeholder={t('form.bachelor_school')} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="master_degree" label={t('form.master_degree')}>
                            <Input placeholder={t('form.master_degree')} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="master_school" label={t('form.master_school')}>
                            <Input placeholder={t('form.master_school')} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="doctoral_degree" label={t('form.doctoral_degree')}>
                            <Input placeholder={t('form.doctoral_degree')} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="doctoral_school" label={t('form.doctoral_school')}>
                            <Input placeholder={t('form.doctoral_school')} />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item name="specialize_subjects" label={t('form.specialized_subjects')} rules={[{ required: true }]}>
                    <TextArea rows={2} placeholder={t('form.enter_specialized_subjects')} />
                </Form.Item>

                <Form.Item name="schedule" label={t('about')} rules={[{ required: true }]}>
                    <TextArea rows={2} placeholder={t('form.schedule_description')} />
                </Form.Item>

                <Form.Item name="language" label={t('Language')} rules={[{ required: true }]}>
                    <Select mode="multiple" placeholder={t('form.select_languages')}>
                        <Option value="Thai">Thai</Option>
                        <Option value="English">English</Option>
                    </Select>
                </Form.Item>

                <Form.Item>
                    <Row justify="end">
                        <Button onClick={onClose} style={{ marginRight: 8 }}>
                            {t('cancel')}
                        </Button>
                        <Button type="primary" htmlType="submit">
                            {t('save')}
                        </Button>
                    </Row>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default withRoleAccess(EditTeacherModal, ['admin', 'superadmin']);