import React, { useState, useEffect, useCallback } from "react";
import { Table, Input, Select, Button, Card, Space } from "antd";
import { CSVLink } from "react-csv";
import { EditOutlined } from '@ant-design/icons';
import axios from "axios";
import withRoleAccess from "../../hoc/withRoleAccess";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faFileExcel, 
  faSearch, 
  faCalendarAlt, 
  faChalkboardTeacher 
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import "./Classes.css";

const { Option } = Select;

const AllClassList = () => {
  const { t, i18n } = useTranslation();
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [editingTeacherId, setEditingTeacherId] = useState(null);
  const [editedTeacherId, setEditedTeacherId] = useState('');
  const [editingDateId, setEditingDateId] = useState(null);
  const [editedDate, setEditedDate] = useState('');
  const [classrooms, setClassrooms] = useState([]);
  const [editingClassroomId, setEditingClassroomId] = useState(null);
  const [editedClassroomId, setEditedClassroomId] = useState('');
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM")); 
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 100,
    total: 0
  });

  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };

  const formatScheduleHour = (scheduleHourString) => {
    if (!scheduleHourString) return "-";
    try {
      const [hours, minutes] = scheduleHourString.split(' ')
        .map((value, index) => (index === 0 ? parseInt(value) : parseInt(value)));
      if (isNaN(hours) || isNaN(minutes)) return scheduleHourString;
      return `${hours}:${minutes.toString().padStart(2, '0')}`;
    } catch (error) {
      console.error('Error formatting schedule hour:', error);
      return scheduleHourString;
    }
  };

  // Fetch classes
const fetchClasses = useCallback(async () => {
  setLoading(true);
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${API_URL}/classes`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setClasses(response.data);
  } catch (error) {
    console.error("Error fetching classes:", error);
  } finally {
    setLoading(false);
  }
}, [API_URL]); // Closing parentheses and dependency array added

// Fetch teachers
const fetchTeachers = useCallback(async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${API_URL}/teachers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setTeachers(response.data.filter((teacher) => teacher.status === "active"));
  } catch (error) {
    console.error("Error fetching teachers:", error);
  }
}, [API_URL]); // Closing parentheses and dependency array added

useEffect(() => {
  fetchClasses();
  fetchTeachers();
}, [fetchClasses, fetchTeachers]); // Dependencies included


  useEffect(() => {
    const fetchClassrooms = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/classrooms`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
  
        const classroomsData = response.data || [];
  
        // Filter classrooms with availability 'true'
        const availableClassrooms = classroomsData.filter(classroom => classroom.availability === true);
  
        // Sort by 'number' field in alphanumeric order
        const sortedClassrooms = availableClassrooms.sort((a, b) => {
          return a.number.localeCompare(b.number, undefined, { numeric: true, sensitivity: 'base' });
        });
  
        setClassrooms(sortedClassrooms);
      } catch (error) {
        console.error('Error fetching classrooms:', error);
      }
    };
  
    fetchClassrooms();
  }, [API_URL]);

  const handleEditDateClick = (classId, date) => {
    setEditingDateId(classId);
    setEditedDate(date); // Set initial date
  };

  const handleClassroomChangeDropdown = (value) => {
    setEditedClassroomId(value);
  };

  const handleTeacherChangeDropdown = (value) => {
    setEditedTeacherId(value);
  };

  const handleEditClassroomClick = (classId, classroomId) => {
    setEditingClassroomId(classId);
    setEditedClassroomId(classroomId); // Set initial classroom ID
  };

  const handleEditTeacherClick = (classId, teacherId) => {
    setEditingTeacherId(classId);
    setEditedTeacherId(teacherId); // Set initial teacher ID
  };

  const handleSaveClassroomClick = async (classId) => {
    try {
      const token = localStorage.getItem('token');
      const updatedClass = {
        classroom_id: editedClassroomId,
      };

      await axios.put(`${API_URL}/classes/${classId}`, updatedClass, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setEditingClassroomId(null);
      setEditedClassroomId('');
      fetchClasses(); // Refresh the class list
    } catch (error) {
      console.error('Error updating classroom:', error);
    }
  };

  const handleSaveTeacherClick = async (classId) => {
    try {
      const token = localStorage.getItem('token');
      const updatedClass = {
        teacher_id: editedTeacherId,
      };
  
      await axios.put(`${API_URL}/classes/${classId}`, updatedClass, { 
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      setEditingTeacherId(null);
      setEditedTeacherId('');
      fetchClasses(); // Refresh the class list
    } catch (error) {
      console.error('Error updating teacher:', error);
    }
  };

  const handleSaveDateClick = async (classId) => {
    try {
      const token = localStorage.getItem('token');
      const updatedClass = {
        date: editedDate,
      };
  
      await axios.put(`${API_URL}/classes/${classId}`, updatedClass, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      setEditingDateId(null);
      setEditedDate('');
      fetchClasses(); // Refresh the class list after update
    } catch (error) {
      console.error('Error updating date:', error);
    }
  };

  const filteredClasses = classes
  .filter((classItem) => {
    const isInMonthYear =
      moment(classItem.date).format("YYYY-MM") === selectedDate;
    const isSearchMatch =
      classItem.subject_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      classItem.class_code?.toLowerCase().includes(searchTerm.toLowerCase());
    const isTeacherMatch =
      !selectedTeacher || classItem.teacher_id === selectedTeacher;

    return isInMonthYear && isSearchMatch && isTeacherMatch;
  })
  .sort((a, b) => {
    // Sort by date and then by schedule_time
    const dateComparison = new Date(a.date) - new Date(b.date);
    if (dateComparison !== 0) {
      return dateComparison;
    }
    return moment(a.schedule_time, "HH:mm:ss").diff(
      moment(b.schedule_time, "HH:mm:ss")
    );
  });

  useEffect(() => {
    setPagination(prev => ({
      ...prev,
      total: filteredClasses.length
    }));
  }, [filteredClasses]);

  const handleTableChange = (newPagination, filters, sorter) => {
    setPagination(prev => ({
      ...prev,
      current: newPagination.current,
      pageSize: newPagination.pageSize
    }));
  };

  const getCurrentPageData = () => {
    const { current, pageSize } = pagination;
    const startIndex = (current - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredClasses.slice(startIndex, endIndex);
  };

  // CSV Data for Export
  const csvData = filteredClasses.map((classItem) => ({
    Date: formatDate(classItem.date),
    Day: new Date(classItem.date).toLocaleDateString("en-US", { weekday: "long" }),
    "Class Code": classItem.class_code,
    "Start Time": formatTime(classItem.schedule_time),
    "End Time": formatTime(classItem.end_time),
    Hours: formatScheduleHour(classItem.schedule_hour),
    Subject: classItem.subject_name,
    Tutor:
      teachers.find((teacher) => teacher.userid === classItem.teacher_id)?.nickname ||
      "N/A",
    Room: classItem.classroom_number,
    Type: classItem.isprivate ? "Private" : "Group",
    Status: classItem.status || "N/A",
  }));

  const columns = [
    {
      title: t("Date"),
      dataIndex: "date",
      key: "date",
      width: 160,
      render: (date, record) => {
        return editingDateId === record.classid ? (
          <Space direction="vertical" style={{ width: '100%' }}>
            <input
              type="date"
              value={editedDate}
              onChange={(e) => setEditedDate(e.target.value)}
              style={{
                width: '100%',
                padding: '4px 11px',
                border: '1px solid #d9d9d9',
                borderRadius: '2px',
                fontSize: '14px'
              }}
            />
            <Space>
              <Button
                type="primary"
                size="small"
                onClick={() => handleSaveDateClick(record.classid)}
              >
                {t("Save")}
              </Button>
              <Button
                size="small"
                onClick={() => setEditingDateId(null)}
              >
                {t("Cancel")}
              </Button>
            </Space>
          </Space>
        ) : (
          <Space>
            <span>{moment(date).format("DD MMM YYYY")}</span>
            <Button
              type="link"
              icon={<FontAwesomeIcon icon={faCalendarAlt} />}
              onClick={() => handleEditDateClick(record.classid, record.date)}
              style={{ padding: '0', height: 'auto' }}
            />
          </Space>
        );
      },
      fixed: "left",
    },
    {
      title: t("Day"),
      dataIndex: "day",
      key: "day",
      width: 100,
      render: (_, record) => (
        <span className="day-cell">
          {new Date(record.date).toLocaleDateString("en-US", { weekday: "long" })}
        </span>
      ),
    },
    {
      title: t("Time"),
      dataIndex: "time",
      key: "time",
      width: 120,
      render: (_, record) => (
        <span>
          {moment(record.schedule_time, "HH:mm:ss").format("HH:mm")} -{" "}
          {moment(record.end_time, "HH:mm:ss").format("HH:mm")}
        </span>
      ),
    },
    {
      title: t("Hours"),
      dataIndex: "schedule_hour",
      key: "schedule_hour",
      width: 80,
      align: "center",
      render: (scheduleHour) => {
        if (!scheduleHour) return "-";
        const formattedHour = formatScheduleHour(scheduleHour);
        return <span>{formattedHour} {t('hr')}</span>;
      },
    },
    {
      title: t("Type"),
      dataIndex: "isprivate",
      key: "isprivate",
      width: 90,
      align: "center",
      render: (isPrivate) => (
        <span
          style={{
            display: "inline-block",
            padding: "4px 8px",
            borderRadius: "4px",
            fontSize: "12px",
            lineHeight: 1,
            whiteSpace: "nowrap",
            background: isPrivate ? "#e6f4ff" : "#f6ffed",
            color: isPrivate ? "#1890ff" : "#52c41a",
            border: `1px solid ${isPrivate ? "#91caff" : "#b7eb8f"}`,
          }}
        >
          {isPrivate ? t("Private") : t("Group")}
        </span>
      ),
    },
    {
      title: t("Class Code"),
      dataIndex: "class_code",
      key: "class_code",
      width: 250,
      render: (text) => <div className="wrapped-cell">{text}</div>,
    },
    {
      title: t("Subject"),
      dataIndex: "subject_name",
      key: "subject_name",
      width: 250,
      render: (text) => <div className="wrapped-cell">{text}</div>,
    },
    {
      title: t("Tutor"),
      dataIndex: "teacher_id",
      key: "teacher_id",
      width: 180,
      render: (teacherId, record) => {
        return editingTeacherId === record.classid ? (
          <Space direction="vertical" style={{ width: '100%' }}>
            <Select
              value={editedTeacherId || undefined}
              onChange={handleTeacherChangeDropdown}
              style={{ width: '100%' }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {teachers.map((teacher) => (
                <Option key={teacher.userid} value={teacher.userid}>
                  {teacher.nickname} {teacher.firstname}
                </Option>
              ))}
            </Select>
            <Space>
              <Button
                type="primary"
                size="small"
                onClick={() => handleSaveTeacherClick(record.classid)}
              >
                {t("Save")}
              </Button>
              <Button
                size="small"
                onClick={() => setEditingTeacherId(null)}
              >
                {t("Cancel")}
              </Button>
            </Space>
          </Space>
        ) : (
          <Space>
            <span>
              {teachers.find((teacher) => teacher.userid === teacherId)?.nickname || t("N/A")}
            </span>
            <Button
              type="link"
              icon={<FontAwesomeIcon icon={faChalkboardTeacher} />}
              onClick={() => handleEditTeacherClick(record.classid, record.teacher_id)}
              style={{ padding: '0', height: 'auto' }}
            />
          </Space>
        );
      },
    },
    {
      title: t("Room"),
      dataIndex: "classroom_number",
      key: "classroom_number",
      width: 150,
      align: "center",
      render: (_, record) => {
        return editingClassroomId === record.classid ? (
          <Space direction="vertical">
            <Select
              value={editedClassroomId || undefined}
              onChange={handleClassroomChangeDropdown}
              style={{ width: "100%" }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {classrooms.map((classroom) => (
                <Option key={classroom.classroomid} value={classroom.classroomid}>
                  {classroom.number}
                </Option>
              ))}
            </Select>
            <Space>
              <Button
                type="primary"
                size="small"
                onClick={() => handleSaveClassroomClick(record.classid)}
              >
                {t("Save")}
              </Button>
              <Button
                size="small"
                onClick={() => setEditingClassroomId(null)}
              >
                {t("Cancel")}
              </Button>
            </Space>
          </Space>
        ) : (
          <Space>
            <span>{record.classroom_number || t("N/A")}</span>
            <Button
  type="link"
  onClick={() => handleEditClassroomClick(record.classid, record.classroom_id)}
  icon={<EditOutlined />}
/>
          </Space>
        );
      },
    },      
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      width: 100,
      align: "center",
      render: (status) => (
        <span
          style={{
            display: "inline-block",
            padding: "4px 8px",
            borderRadius: "4px",
            fontSize: "12px",
            lineHeight: 1,
            whiteSpace: "nowrap",
            background: getStatusBackground(status),
            color: getStatusColor(status),
            border: `1px solid ${getStatusBorderColor(status)}`,
          }}
        >
          {status}
        </span>
      ),
    },
  ];

  // Helper functions for status styling
  const getStatusBackground = (status) => {
    switch(status?.toLowerCase()) {
      case 'approved': return '#f6ffed';
      case 'pending': return '#fff7e6';
      case 'cancelled': return '#fff1f0';
      default: return '#f5f5f5';
    }
  };

  const getStatusColor = (status) => {
    switch(status?.toLowerCase()) {
      case 'approved': return '#52c41a';
      case 'pending': return '#fa8c16';
      case 'cancelled': return '#f5222d';
      default: return '#8c8c8c';
    }
  };

  const getStatusBorderColor = (status) => {
    switch(status?.toLowerCase()) {
      case 'approved': return '#b7eb8f';
      case 'pending': return '#ffd591';
      case 'cancelled': return '#ffa39e';
      default: return '#d9d9d9';
    }
  };

  return (
    <Card className="class-list-container">
      <div className="header-section">
      </div>

      <div className="cc-search-container">
        <Space size="middle" wrap>
          {/* Date Picker */}
          <input
            type="month"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            style={{
              padding: "8px",
              border: "1px solid #d9d9d9",
              borderRadius: "4px",
              fontSize: "14px",
            }}
          />

          <Input
            prefix={
              <FontAwesomeIcon icon={faSearch} style={{ color: "#bfbfbf" }} />
            }
            placeholder={t("Search by subject or class code")}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ width: 300 }}
            allowClear
          />

          <Select
            showSearch
            placeholder={t("Filter by teacher")}
            value={selectedTeacher}
            onChange={(value) => setSelectedTeacher(value)}
            style={{ width: 200 }}
            allowClear
            optionFilterProp="label"
            filterOption={(input, option) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          >
            <Option value="" label={t("All Teachers")}>{t("All Teachers")}</Option>
            {teachers.map((teacher) => (
              <Option key={teacher.userid} value={teacher.userid} label={`${teacher.nickname} ${teacher.firstname}`}>
                {teacher.nickname} {teacher.firstname}
              </Option>
            ))}
          </Select>

          <CSVLink
            data={csvData}
            filename={`class_list_${moment().format("YYYY-MM-DD")}.csv`}
          >
            <Button
              type="primary"
              icon={<FontAwesomeIcon icon={faFileExcel} />}
            >
              {t("Export CSV")}
            </Button>
          </CSVLink>
        </Space>
      </div>

      <Table
        dataSource={getCurrentPageData()}
        columns={columns}
        rowKey="classid"
        loading={loading}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
          showSizeChanger: true,
          pageSizeOptions: ["50", "100", "200"],
          showTotal: (total, range) => 
            `${range[0]}-${range[1]} of ${total} items`
        }}
        onChange={handleTableChange}
        scroll={{ x: '100%' }}
        size="middle"
      />
    </Card>
  );
};

export default withRoleAccess(AllClassList, ["admin", "superadmin"]);