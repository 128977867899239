import React from 'react';
import { Modal, Button, Table } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { sarabunFont } from '../../sarabunFont'; // Import your font data
import { useTranslation } from 'react-i18next';

const ScheduleModal = ({ isVisible, onClose, popupData }) => {
  const { t } = useTranslation();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) return 'Invalid Date'; // Handle invalid dates
  
    return new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: 'long', // 'long' ensures full month name (e.g., "January")
      year: 'numeric'
    }).format(date);
  };
  

  const formatTime = (timeString) => {
    if (!timeString) return '';
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };

  // Function to load Sarabun font into jsPDF
  const loadSarabunFont = (doc) => {
    doc.addFileToVFS('Sarabun.ttf', sarabunFont.src.split('base64,')[1]);
    doc.addFont('Sarabun.ttf', 'Sarabun', 'normal');
    doc.setFont('Sarabun');
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    loadSarabunFont(doc);
  
    const margin = 20;
    const keyColor = '#95101e';
  
    const headerImage = new Image();
    headerImage.src = '/images/advisorlogo.png';
  
    headerImage.onload = () => {
      // Calculate aspect ratio for logo
      const imgAspectRatio = headerImage.width / headerImage.height;
      const targetHeight = 20; // Made logo slightly bigger
      const targetWidth = targetHeight * imgAspectRatio;
  
      // Add logo maintaining aspect ratio
      doc.addImage(headerImage, 'PNG', margin, margin, targetWidth, targetHeight);
  
      // Large class code below logo with more space
      const pageWidth = doc.internal.pageSize.getWidth();
      const centerX = pageWidth / 2;
      
      const classCode = popupData[0]?.class_code || 'N/A';
        doc.setFontSize(28);
        doc.setTextColor(keyColor);
        doc.setFont('helvetica', 'bold');
        doc.text(classCode, centerX, margin + 40, { align: 'center' }); // Moved down
  
      // Get first and last dates from sorted data
      const sortedDates = popupData
        .map(item => new Date(item.date))
        .sort((a, b) => a - b);
      const startDate = formatDate(sortedDates[0]);
      const endDate = formatDate(sortedDates[sortedDates.length - 1]);
  
      // Display dates with larger font and bold
        doc.setFontSize(14);
        doc.setFont('helvetica', 'bold');
        doc.setTextColor(51, 51, 51);
        doc.text(`Start Date: ${startDate}`, margin, margin + 60); 
        doc.text(`End Date: ${endDate}`, centerX + 20, margin + 60);
  
      // Subject line closer to dates with key color
      const subjects = [...new Set(popupData.map(slot => slot.subject_name))].join(', ');
      doc.setFontSize(12);
      doc.setTextColor(keyColor);
      doc.text(`Subject(s): ${subjects}`, margin, margin + 75); // Moved closer to dates
  
      // Table starts after the header info
      const tableStartY = margin + 80;
  
      // Rest of your table code...
      const sortedPopupData = popupData.sort((a, b) => new Date(a.date) - new Date(b.date));
      const columns = [
        { header: t('day'), dataKey: 'schedule_day' },
        { header: t('date'), dataKey: 'date', render: formatDate },
        {
          header: t('time'),
          dataKey: 'schedule_time',
          render: (text, record) => `${formatTime(record.schedule_time)} - ${formatTime(record.end_time)}`
        },
        { header: t('subjectName'), dataKey: 'subject_name' },
        { header: t('teacherName'), dataKey: 'nickname' },
        { header: t('hours'), dataKey: 'schedule_hour' },
        { header: t('classroomNumber'), dataKey: 'classroom_number' }
      ];
  
      doc.autoTable({
        startY: tableStartY,
        head: [columns.map(col => col.header)],
        body: sortedPopupData.map(row => 
          columns.map(col => col.render ? col.render(row[col.dataKey], row) : row[col.dataKey])
        ),
        theme: 'grid',
        styles: {
          font: 'Sarabun',
          fontStyle: 'normal',
          textColor: [51, 51, 51],
          lineColor: [200, 200, 200],
        },
        headStyles: {
          fillColor: [149, 16, 30],
          textColor: [255, 255, 255],
          fontStyle: 'bold'
        },
        alternateRowStyles: {
          fillColor: [252, 252, 252]
        },
        margin: { top: 20 }
      });
  
      // Page numbers remain the same...
      const pageCount = doc.internal.getNumberOfPages();
      for(let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setTextColor(128, 128, 128);
        doc.setFontSize(8);
        doc.text(
          `Page ${i} of ${pageCount}`, 
          doc.internal.pageSize.getWidth() / 2, 
          doc.internal.pageSize.getHeight() - 10, 
          { align: 'center' }
        );
      }
  
      doc.save(`${classCode}.pdf`);
    };
  
    headerImage.onerror = () => {
      console.error('Failed to load the header image.');
    };
  };

  const columns = [
    { title: t('day'), dataIndex: 'schedule_day', key: 'schedule_day' },
    { title: t('date'), dataIndex: 'date', key: 'date', render: formatDate },
    {
      title: t('time'),
      key: 'time',
      render: (text, record) => `${formatTime(record.schedule_time)} - ${formatTime(record.end_time)}`
    },
    { title: t('subjectName'), dataIndex: 'subject_name', key: 'subject_name' },
    { title: t('teacherName'), dataIndex: 'nickname', key: 'nickname' },

    { title: t('hours'), dataIndex: 'schedule_hour', key: 'schedule_hour' },
    { title: t('classroomNumber'), dataIndex: 'classroom_number', key: 'classroom_number' }
  ];

  return (
    <Modal
      visible={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="download" onClick={handleDownloadPDF}>
          <FontAwesomeIcon icon={faDownload} /> {t('downloadPDF')}
        </Button>
      ]}
      title={popupData?.[0]?.class_code || t('scheduleDetails')}
      bodyStyle={{ maxHeight: '60vh', overflowY: 'auto' }} // Adjust the modal body to handle overflow
      width={800} // Adjust the width of the modal if needed
    >
      <Table
        dataSource={popupData}
        columns={columns}
        rowKey={(record) => record.id}
        pagination={false}
        scroll={{ x: 'max-content' }} // Enable horizontal scrolling if necessary
      />
    </Modal>
  );
};

export default ScheduleModal;
