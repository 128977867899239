import React, { useContext } from 'react';
import './Sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faCalendarAlt, faBullhorn, faSchool, faChalkboardTeacher, faChalkboard, faLock, faCog, faBookOpen, faChartColumn, faBank, faBookBookmark, faDollarSign, faMagnifyingGlassDollar, faStarHalfAlt, faCashRegister, faCartShopping, faLineChart, faTableCellsColumnLock, faTableCellsLarge } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../Auth/AuthContext';
import { useTranslation } from 'react-i18next';

const Sidebar = () => {
  const { permissions } = useContext(AuthContext);
  const { role } = permissions;

  const { t } = useTranslation();

  const menuItems = [
    { iconName: "dashboard", tooltip: t('dashboard'), path: "/dashboard", roles: ["admin", "teacher", "student", "superadmin"] },
    { iconName: "myperformance", tooltip: t('myperformance'), path: "/myperformance", roles: ["student"] },
    { iconName: "annoucement", tooltip: t('announcement'), path: "/announcement", roles: ["admin", "teacher", "student", "superadmin"] },
    { iconName: "calendar", tooltip: t('calendar'), path: "/calendar", roles: ["admin", "teacher", "superadmin"] },
    { iconName: "subject", tooltip: t('subjects'), path: "/subject", roles: ["admin", "student", "superadmin"] },
    { iconName: "teacher", tooltip: t('tutors'), path: "/teacher", roles: ["admin", "student", "superadmin"] },
    { iconName: "classroom", tooltip: t('classrooms'), path: "/classroom", roles: ["admin", "teacher", "superadmin"] },
    { iconName: "class", tooltip: t('courses'), path: "/currentclasses", roles: ["admin", "student", "superadmin"] },
    { iconName: "allclasses", tooltip: t('classes'), path: "/allclasses", roles: ["admin", "superadmin"] },
    { iconName: "register", tooltip: t('register'), path: "/registrations", roles: ["admin", "superadmin"] },
    { iconName: "sales", tooltip: t('sales'), path: "/sales", roles: ["admin", "superadmin"] },
    { iconName: "adminpanel", tooltip: t('admin'), path: "/adminpanel", roles: ["admin", "superadmin"] },
    { iconName: "accounting", tooltip: t('accounting'), path: "/accounting", roles: ["admin", "superadmin"] },
    { iconName: "registrationlist", tooltip: t('attendance'), path: "/registrationlist", roles: ["admin", "superadmin"] },
    { iconName: "report", tooltip: t('report'), path: "/report", roles: ["superadmin"] },
    { iconName: "teacherhoursadmin", tooltip: t('tutor_hours'), path: "/teacher-hours-admin", roles: ["superadmin"] },
    { iconName: "teacherhours", tooltip: t('my_hours'), path: "/teacher-hours", roles: ["teacher"] },
    { iconName: "lessonplan", tooltip: t('Lesson Plan'), path: "/lessonplan", roles: ["teacher"] },
    { iconName: "usercontrolpage", tooltip: t('users'), path: "/usercontrolpage", roles: ["superadmin"] },
  ];

  const iconMapping = {
    "dashboard": faTachometerAlt,
    "myperformance": faStarHalfAlt,
    "annoucement": faBullhorn,
    "calendar": faCalendarAlt,
    "subject": faSchool,
    "teacher": faChalkboardTeacher,
    "classroom": faChalkboard,
    "class": faBookBookmark,
    "allclasses": faBookOpen,
    "register": faCashRegister,
    "sales": faCartShopping,
    "adminpanel": faCog,
    "accounting": faBank,
    "report": faChartColumn,
    "teacherhoursadmin": faMagnifyingGlassDollar,
    "teacherhours": faDollarSign,
    "lessonplan": faTableCellsLarge,
    "registrationlist": faLineChart,
    "usercontrolpage": faLock,
  };

  const filteredMenuItems = menuItems.filter(item => item.roles.includes(role));

  return (
    <div className="modern-sidebar">
      <nav className="sidebar-nav">
        <ul className="sidebar-menu">
          {filteredMenuItems.map((item, index) => (
            <li key={index} className="sidebar-item">
              <NavLink 
                to={item.path} 
                className={({isActive}) => 
                  isActive ? "sidebar-link active" : "sidebar-link"
                }
              >
                <div className="sidebar-icon-container">
                  <FontAwesomeIcon 
                    icon={iconMapping[item.iconName]} 
                    className="sidebar-icon" 
                  />
                </div>
                <span className="sidebar-label">{item.tooltip}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;