import React, { useState, useEffect } from 'react';
import { Layout, Card, Row, Col, Typography, Spin, Alert, Space, Divider } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { useTranslation } from 'react-i18next';

const { Content } = Layout;
const { Title, Paragraph, Text } = Typography;

const AnnouncementPage = () => {
  const { i18n } = useTranslation();
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: '2-digit' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  };

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/announcements`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const latestAnnouncements = response.data.slice(0, 13);
        setAnnouncements(latestAnnouncements);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching announcements:', error);
        setError('Failed to fetch announcements. Please try again later.');
        setLoading(false);
      }
    };

    fetchAnnouncements();
  }, [API_URL]);

  // Image loading error handler
  const handleImageError = (e) => {
    e.target.src = '/placeholder-image.png'; // Add a default placeholder image
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return <Alert message={error} type="error" />;
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar />
      <Layout>
        <Header />
        <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280 }}>
          <div style={{ maxWidth: 1200, margin: '0 auto' }}>
            {/* Header Section */}
            <Title level={1} style={{ textAlign: 'center', marginTop: 65, marginBottom: 40 }}>
              SCHOOL NEWSLETTER
            </Title>

            {/* About Us Section */}
           <Card className="about-section" style={{ marginBottom: 24 }}>
              <Title level={2}>About Us</Title>
              <Paragraph>
              The Advisor Academy is a leading International Exam Tutorial Center in Thailand that has been operating for over 10 years. 
              We have helped more than 3,000 students aged 14-20 gain confidence before their exams and achieve high scores. 
              We specialize in tutoring international curriculum exams, particularly GED, IGCSE, IELTS, SAT, and BMAT. </Paragraph>
            </Card> 

            {/* Announcements Section */}
            <Row gutter={[24, 24]}>
              {announcements.map((announcement) => (
                <Col xs={24} sm={24} md={12} lg={8} key={announcement.id}>
                  <Card
                    hoverable
                    bodyStyle={{ padding: '16px' }}
                    style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                  >
                    <div style={{ position: 'relative', paddingTop: '56.25%', overflow: 'hidden', marginBottom: 16 }}>
                      {announcement.image_url ? (
                        <img
                          alt={announcement.title}
                          src={announcement.image_url}
                          onError={handleImageError}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            backgroundColor: '#f0f2f5',
                            padding: '8px'
                          }}
                        />
                      ) : (
                        <div style={{ 
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          backgroundColor: '#f0f2f5',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                          No Image Available
                        </div>
                      )}
                    </div>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                      <Title level={4} style={{ marginTop: 0 }}>{announcement.title}</Title>
                      <Paragraph style={{ flex: 1 }}>{announcement.message}</Paragraph>
                      <Space direction="vertical" size="small" style={{ width: '100%' }}>
                        <Space>
                          <CalendarOutlined />
                          <Text type="secondary">{formatDate(announcement.date)}</Text>
                        </Space>
                        {announcement.link && (
                          <a 
                            href={announcement.link} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            style={{ color: '#1890ff' }}
                          >
                            More Info →
                          </a>
                        )}
                      </Space>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>

            {/* Reminders Section */}
            <Divider />
            <Card className="reminders-section" style={{ marginTop: 24 }}>
              <Title level={2}>Reminders</Title>
              <Paragraph>
                Homework and Assignments: Please continue to check the online platform/classroom bulletin board 
                for daily homework and ongoing assignments. Consistent review and completion of assignments 
                greatly contribute to your child's academic success.
              </Paragraph>
            </Card>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default withRoleAccess(AnnouncementPage, ['admin', 'superadmin', 'teacher', 'student']);