import React, { useState, useEffect } from 'react';
import { Table, Card, Spin, message, Statistic, Row, Col, Empty, Button, Modal } from 'antd';
import { EyeOutlined, DollarOutlined, ClockCircleOutlined, CalendarOutlined } from '@ant-design/icons';
import axios from 'axios';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import PayStubModal from './PayStubModal';
import withRoleAccess from '../../hoc/withRoleAccess';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './Teacher.css';


const TeacherPayout = () => {
    const { t } = useTranslation();
    const [user, setUser] = useState(null);
    const [payoutData, setPayoutData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedPayStub, setSelectedPayStub] = useState(null);
    const [loading, setLoading] = useState(true);
    const [periods, setPeriods] = useState([]);
    const [stats, setStats] = useState({
        totalEarnings: 0,
        totalHours: 0,
        latestPayout: null,
        periodCount: 0
    });
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        fetchUserInfo();
        fetchPayoutData();
    }, []);

    useEffect(() => {
        if (payoutData.length > 0) {
            // Extract unique periods
            const uniquePeriods = [...new Set(payoutData.map(payout => payout.period))]
                .filter(Boolean)
                .sort((a, b) => new Date(b.split('_')[0]) - new Date(a.split('_')[0])); // Sort by start date, newest first
            setPeriods(uniquePeriods);
            
            // Calculate statistics
            calculateStats(payoutData);
            
            // Initialize filtered data with all data
            setFilteredData(payoutData);
        }
    }, [payoutData]);

    const fetchPayoutData = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            const response = await axios.get(`${API_URL}/teacher-hours/teacher-payout`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setPayoutData(response.data);
        } catch (error) {
            console.error('Error fetching payout data:', error);
            if (error.response?.status === 404) {
                // No payouts found is not an error, just an empty state
                setPayoutData([]);
            } else {
                message.error(t('failedToFetchPayoutData'));
            }
        } finally {
            setLoading(false);
        }
    };

    const fetchUserInfo = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${API_URL}/users/me`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          
          console.log("User info fetched:", response.data);
          setUser(response.data);
        } catch (error) {
          console.error('Error fetching user info:', error);
        }
      };

    const calculateStats = (data) => {
        if (!data || data.length === 0) {
            setStats({
                totalEarnings: 0,
                totalHours: 0,
                latestPayout: null,
                periodCount: 0
            });
            return;
        }

        const totalEarnings = data.reduce((sum, item) => sum + parseFloat(item.total_amount || 0), 0);
        const totalHours = data.reduce((sum, item) => sum + parseFloat(item.total_hours || 0), 0);
        
        // Sort by approval date to find the latest
        const sortedData = [...data].sort((a, b) => new Date(b.approval_date) - new Date(a.approval_date));
        const latestPayout = sortedData[0];
        
        setStats({
            totalEarnings,
            totalHours,
            latestPayout,
            periodCount: data.length
        });
    };



    const formatPeriod = (periodString) => {
        if (periodString && periodString.includes('_')) {
            const [startDateStr, endDateStr] = periodString.split('_');
            
            const startParts = startDateStr.split('-').map(Number);
            const endParts = endDateStr.split('-').map(Number);
            
            const startDate = new Date(startParts[0], startParts[1] - 1, startParts[2]);
            const endDate = new Date(endParts[0], endParts[1] - 1, endParts[2]);
            
            const startFormatted = startDate.getDate() + ' ' + 
                                  startDate.toLocaleString('default', { month: 'short' }) + ' ' + 
                                  startDate.getFullYear().toString();
            
            const endFormatted = endDate.getDate() + ' ' + 
                                endDate.toLocaleString('default', { month: 'short' }) + ' ' + 
                                endDate.getFullYear().toString();
            
            return `${startFormatted} - ${endFormatted}`;
        }
        
        return periodString;
    };

    const formatCurrency = (amount) => {
        const value = parseFloat(amount || 0);
        return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };

    const formatDateToDMY = (dateString) => {
        if (!dateString) return '';
        return moment(dateString).format('DD MMM YYYY');
    };

    const handleViewPayStub = async (payout) => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${API_URL}/teacher-hours/teacher-payment-report/${payout.batch_id}/details`, {
            headers: { Authorization: `Bearer ${token}` }
          });
      
          const classDetails = response.data.map(cls => ({
            classId: cls.class_id,
            rate: cls.hourly_rate,
            hours: cls.hours_worked,
            total: cls.total_amount
          }));
      
          // Include user details in payStubData
          const payStubData = {
            companyLogo: "/images/logosquare.png", 
            companyName: "บริษัท วาย เลิร์นนิ่ง (ไทยแลนด์) จำกัด",
            companyAddress: "เลขที่ 98/38 ซอยลาดพร้าว 35 แขวงจันทรเกษม",
            companyTax: "เลขประจำตัวผู้เสียภาษี 0105564038397",
            companyWebsite: "เขตจตุจักร กรุงเทพมหานคร 10900",
            companyPhone: "Tel: 082-656-2287",
            companyLine: "theadvisor.service@gmail.com",
            teacherName: user ? `${user.firstname || ''} ${user.lastname || ''}`.trim() : "Current Teacher",
            teacherAddress: user?.address || '',
            teacherPhone: user?.phone || '',
            teacherEmail: user?.email || '',
            teacherNationalId: user?.nationalid || '',
            payPeriod: payout.period,
            payDate: payout.approval_date,
            classes: classDetails,
            totalHours: payout.total_hours,
            totalPay: payout.total_amount,
            withholdingTax: payout.sum_withholdingtax,
          };
          
          setSelectedPayStub(payStubData);
        } catch (error) {
          console.error(t('errorFetchingPayStubDetails'), error);
          message.error(t('errorFetchingPayStubDetails'));
        }
      };

    const columns = [
        {
            title: t('Payment Date'),
            dataIndex: 'approval_date',
            key: 'approval_date',
            render: date => formatDateToDMY(date),
            sorter: (a, b) => new Date(a.approval_date) - new Date(b.approval_date),
        },
        {
            title: t('Pay Period'),
            dataIndex: 'period',
            key: 'period',
            render: period => formatPeriod(period),
        },
        {
            title: t('Total Hours'),
            dataIndex: 'total_hours',
            key: 'total_hours',
            align: 'right',
            sorter: (a, b) => a.total_hours - b.total_hours,
        },
        {
            title: t('Sub Total'),
            dataIndex: 'sub_amount',
            key: 'sub_amount',
            align: 'right',
            render: amount => `฿ ${formatCurrency(amount)}`,
        },
        {
            title: t('w/h tax'),
            dataIndex: 'sum_withholdingtax',
            key: 'sum_withholdingtax',
            align: 'right',
            render: amount => `฿ ${formatCurrency(amount)}`,
        },
        {
            title: t('Net Amount'),
            dataIndex: 'total_amount',
            key: 'total_amount',
            align: 'right',
            render: amount => `฿ ${formatCurrency(amount)}`,
            sorter: (a, b) => parseFloat(a.total_amount) - parseFloat(b.total_amount),
        },
        {
            title: t('details'),
            key: 'actions',
            render: (_, record) => (
                <Button 
                    type="primary" 
                    icon={<EyeOutlined />} 
                    size="small"
                    onClick={() => handleViewPayStub(record)}
                >
                    {t('viewDetails')}
                </Button>
            ),
        },
    ];

    const isMobile = window.innerWidth < 480;

    return (
        <div style={{ display: 'flex' }}>
            <Sidebar />
            <div style={{ 
                flexGrow: 1, 
                padding: isMobile ? '12px' : '24px', 
                marginLeft: isMobile ? '0' : '0',
            }}>
                <Header />
                
                {loading ? (
                    <div style={{ textAlign: 'center', padding: '40px' }}>
                        <Spin size="large" />
                    </div>
                ) : (
                    <>
                        {/* Stats Cards */}
                        <Row gutter={[16, 16]} style={{ marginBottom: '24px' }}>
                            <Col xs={24} sm={12} md={6}>
                                <Card>
                                    <Statistic
                                        title={t('Total Earnings')}
                                        value={stats.totalEarnings}
                                        precision={2}
                                        valueStyle={{ color: '#3f8600' }}
                                        prefix="฿"
                                        suffix=""
                                        icon={<DollarOutlined />}
                                    />
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={6}>
                                <Card>
                                    <Statistic
                                        title={t('totalHours')}
                                        value={stats.totalHours}
                                        precision={1}
                                        valueStyle={{ color: '#1890ff' }}
                                        suffix={t('hours')}
                                        icon={<ClockCircleOutlined />}
                                    />
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={6}>
                                <Card>
                                    <Statistic
                                        title={t('Latest Payment')}
                                        value={stats.latestPayout ? formatDateToDMY(stats.latestPayout.approval_date) : '-'}
                                        valueStyle={{ color: '#722ed1' }}
                                        icon={<CalendarOutlined />}
                                    />
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={6}>
                                <Card>
                                    <Statistic
                                        title={t('Total Payments')}
                                        value={stats.periodCount}
                                        valueStyle={{ color: '#cf1322' }}
                                    />
                                </Card>
                            </Col>
                        </Row>

                        {/* Payment History Table */}
                        <Card title={t('Payment History')} style={{ marginBottom: '24px' }}>
                            {filteredData.length > 0 ? (
                                <Table
                                    columns={columns}
                                    dataSource={filteredData}
                                    rowKey="batch_id"
                                    pagination={{ pageSize: 10 }}
                                    size={isMobile ? 'small' : 'middle'}
                                    scroll={{ x: isMobile ? true : false }}
                                />
                            ) : (
                                <Empty 
                                    description={t('noPaymentsFound')} 
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                />
                            )}
                        </Card>

                        {/* Pay Stub Modal */}
<Modal
  title={t('payStub')}
  visible={!!selectedPayStub}
  onCancel={() => setSelectedPayStub(null)}
  footer={null}
  width={800}
  centered
>
  {selectedPayStub && (
    <PayStubModal 
      payStubData={selectedPayStub} 
      onClose={() => setSelectedPayStub(null)} 
    />
  )}
</Modal>
                    </>
                )}
            </div>
        </div>
    );
};

export default withRoleAccess(TeacherPayout, ['teacher']);