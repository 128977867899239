import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Input, Button, Typography, Space, Alert } from 'antd';
import { LockOutlined } from '@ant-design/icons';

const { Text, Title } = Typography;

const PasswordModal = ({ isOpen, onClose, onConfirm, errorMessage }) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (errorMessage) {
      setPassword(''); // Clear password if there's an error message
    }
  }, [errorMessage]);

  const handleConfirm = () => {
    onConfirm(password);
  };

  const handleCancel = () => {
    setPassword(''); // Clear password on cancel
    onClose();
  };

  return (
    <Modal
      title={<Title level={4}>{t('confirmDeletion')}</Title>}
      open={isOpen}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          {t('cancel')}
        </Button>,
        <Button 
          key="confirm" 
          type="primary" 
          onClick={handleConfirm}
          danger
        >
          {t('confirm')}
        </Button>
      ]}
      maskClosable={false}
      centered
    >
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Text>{t('enterPasswordToDelete')}</Text>
        
        <Input.Password
          prefix={<LockOutlined />}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder={t('password')}
          autoFocus
          onPressEnter={handleConfirm}
        />

        {errorMessage && (
          <Alert
            message={t(errorMessage)}
            type="error"
            showIcon
          />
        )}
      </Space>
    </Modal>
  );
};

export default PasswordModal;