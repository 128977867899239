import React, { useState, useEffect } from 'react';
import { Row, Col, Tabs, Card, Typography, Tag, Space, Divider, Alert, Progress } from 'antd';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { ClockCircleOutlined, CalendarOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCalendar, faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons';
import getUserIdFromToken from '../../Utils/authUtils';
import axios from 'axios';
import './MyPerformance.css';


const { Title, Text } = Typography;
const API_URL = process.env.REACT_APP_API_URL;

const MyPerformance = () => {
  const { t, i18n } = useTranslation();
  const userId = getUserIdFromToken();
  const [registrations, setRegistrations] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    const fetchRegistrations = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/registrations/user`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setRegistrations(response.data.registrations);
        setActiveTab(response.data.registrations[0]?.groupid || null);
      } catch (error) {
        console.error('Error fetching registrations:', error);
      }
    };

    const fetchAttendance = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/attendance/student/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAttendance(response.data);
      } catch (error) {
        console.error('Error fetching attendance logs:', error);
      }
    };

    fetchRegistrations();
    fetchAttendance();
  }, [userId]);

  const getAttendanceForClass = (classId) => {
    return attendance.find((log) => log.classid === classId);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours}:${minutes}`;
  };

  const calculateEndTime = (startTime, duration) => {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [durationHours, durationMinutes] = duration.split(':').map(Number);
    let endHours = startHours + durationHours;
    let endMinutes = startMinutes + durationMinutes;
    if (endMinutes >= 60) {
      endHours += Math.floor(endMinutes / 60);
      endMinutes %= 60;
    }
    return `${endHours.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}`;
  };

  const getClassProgress = (registration) => {
    if (registration.package_id) {
      // If it's a package-based registration
      const totalClasses = registration.total_classes;
      const remainingClasses = registration.classes_remaining;
      const completedClasses = totalClasses - remainingClasses;
      const progressPercent = Math.round((completedClasses / totalClasses) * 100);

      return {
        total: totalClasses,
        completed: completedClasses,
        percent: progressPercent
      };
    } else {
      // For non-package registrations, use the schedule length
      const totalClasses = registration.schedule.length;
      const completedClasses = registration.schedule.filter(cls => {
        const attendanceLog = getAttendanceForClass(cls.classid);
        return attendanceLog?.status === 'Present' || attendanceLog?.status === 'Late';
      }).length;
      const progressPercent = Math.round((completedClasses / totalClasses) * 100);

      return {
        total: totalClasses,
        completed: completedClasses,
        percent: progressPercent
      };
    }
  };


  const getStatusConfig = (status) => {
    const configs = {
      'Present': {
        color: '#52c41a',
        backgroundColor: '#f6ffed',
        text: t('finished'),
        tagBg: '#52c41a',
        tagText: 'white',
        textColor: '#135200',
        iconColor: '#389e0d'
      },
      'Late': {
        color: '#faad14',
        backgroundColor: '#fff7e6',
        text: t('late'),
        tagBg: '#faad14',
        tagText: 'white',
        textColor: '#874d00',
        iconColor: '#d48806'
      },
      'Absent': {
        color: '#ff4d4f',
        backgroundColor: '#fff1f0',
        text: t('absent'),
        tagBg: '#ff4d4f',
        tagText: 'white',
        textColor: '#820014',
        iconColor: '#cf1322'
      },
      'Rescheduled': {
        color: '#1890ff',
        backgroundColor: '#e6f7ff',
        text: t('rescheduled'),
        tagBg: '#1890ff',
        tagText: 'white',
        textColor: '#003a8c',
        iconColor: '#096dd9'
      },
      'No Attendance': {
        color: '#8c8c8c',
        backgroundColor: '#fafafa',
        text: t('scheduled'),
        tagBg: '#8c8c8c',
        tagText: 'white',
        textColor: '#262626',
        iconColor: '#595959'
      }
    };
    return configs[status] || configs['No Attendance'];
  };

  return (
    <Row style={{ minHeight: '100vh', margin: 0 }}>

    <Sidebar />
    <Header />
    <div className="performance-content">
    <Tabs
      activeKey={activeTab}
      onChange={setActiveTab} // Trigger tab change
      tabPosition="top" // Tabs aligned horizontally at the top
      type="card" // Card-style tabs
      style={{ marginBottom: '32px' }}
    >
      {registrations.map((registration) => {
        const progress = getClassProgress(registration);

        return (
          <Tabs.TabPane
            tab={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '8px 16px',
                  cursor: 'pointer',
                }}
              >
                <Title level={5} style={{ margin: 0 }}>
                  {registration.class_code}
                </Title>
                <Text type="secondary" style={{ fontSize: '12px' }}>
                  {registration.subjects?.map(subject => subject.title).join(', ')}
                </Text>
              </div>
            }
            key={registration.groupid} // Unique key for each tab
          >
            <Card
              bordered={false}
              style={{
                background: '#fafafa',
                borderRadius: '8px',
                height: '100%',
                boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                padding: '16px',
              }}
            >
              <div style={{ marginBottom: '24px', display: 'flex', justifyContent: 'center' }}>
                {(() => {
                  const totalClasses = registration.schedule.length;
                  const completedClasses = registration.schedule.filter(cls => {
                    const attendanceLog = getAttendanceForClass(cls.classid);
                    return attendanceLog?.status === 'Present' || attendanceLog?.status === 'Late';
                  }).length;
                  const progressPercent = Math.round((completedClasses / totalClasses) * 100);

                  return (
                    <Progress
                      percent={progressPercent}
                      format={() => (
                        <div style={{ textAlign: 'center', whiteSpace: 'pre' }}>
                          <div style={{ fontSize: '20px', fontWeight: 'bold' }}>
                            {completedClasses}/{totalClasses}
                          </div>
                          <div style={{ fontSize: '12px' }}>{t('classes')}</div>
                        </div>
                      )}
                      type="circle"
                      size={120}
                      strokeColor={{
                        '0%': '#108ee9',
                        '100%': '#87d068',
                      }}
                    />
                  );
                })()}
              </div>

              <Row justify="space-between" style={{ color: '#8c8c8c' }}>
                <Col>
                  <Space>
                    <ClockCircleOutlined />
                    <Text>{registration.schedule.length} {t('classes')}</Text>
                  </Space>
                </Col>
                <Col>
                  <Space>
                    <CalendarOutlined />
                    <Text>{registration.total_hours} {t('Hours')}</Text>
                  </Space>
                </Col>
              </Row>
            </Card>
          </Tabs.TabPane>
        );
      })}
    </Tabs>
  

    <div className="class-details-section">
  {registrations.map((registration) => (
    registration.groupid === activeTab && (
      <Row
        key={registration.groupid}
        justify="center"
        gutter={[24, 24]}
        style={{
          width: '100%',
          margin: 0,
          padding: '0 16px',
        }}
      >
        {registration.schedule
          .sort((a, b) => new Date(a.date) - new Date(b.date))
          .map((cls, index) => {
            const attendanceLog = getAttendanceForClass(cls.classid);
            const status = attendanceLog ? attendanceLog.status : 'No Attendance';
            const statusConfig = getStatusConfig(status);

            return (
              <Col
                key={index}
                xs={24}
                sm={12}
                md={8}
                style={{
                  display: 'flex',
                  padding: '12px',
                }}
              >
               <Card
  bordered={false}
  style={{
    backgroundColor: statusConfig.backgroundColor,
    borderRadius: '12px',
    width: '100%',
    height: '100%',
    boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
  }}
  bodyStyle={{
    padding: '24px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  }}
>
  <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
    <Col flex="auto">
      <Title
        level={4}
        style={{
          color: statusConfig.textColor,
          margin: 0,
          fontSize: '24px',
          fontWeight: 600,
        }}
      >
        {cls.subject}
      </Title>
    </Col>
    <Col>
      <Tag
        style={{
          backgroundColor: statusConfig.tagBg,
          color: statusConfig.tagText,
          borderRadius: '20px',
          padding: '4px 12px',
          fontSize: '12px',
          fontWeight: '500',
          border: 'none',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        }}
      >
        {statusConfig.text}
      </Tag>
    </Col>
  </Row>

  <Divider
    style={{
      borderColor: statusConfig.iconColor + '20', // Using icon color with 20% opacity
      margin: '12px 0',
    }}
  />

  <Space
    direction="vertical"
    size="middle"
    style={{
      color: statusConfig.textColor,
      flex: 1,
    }}
  >
    <Space align="center">
      <FontAwesomeIcon
        icon={faCalendar}
        style={{
          width: '16px',
          color: statusConfig.iconColor,
        }}
      />
      <span style={{ fontSize: '14px', color: statusConfig.textColor }}>
        {formatDate(cls.date)}
      </span>
    </Space>

    <Space align="center">
      <FontAwesomeIcon
        icon={faClock}
        style={{
          width: '16px',
          color: statusConfig.iconColor,
        }}
      />
      <span style={{ fontSize: '14px', color: statusConfig.textColor }}>
        {formatTime(cls.time)} - {calculateEndTime(cls.time, cls.hours)}
      </span>
    </Space>

    <Space align="center">
      <FontAwesomeIcon
        icon={faChalkboardTeacher}
        style={{ width: '16px', color: statusConfig.iconColor }}
      />
      <span style={{ fontSize: '14px', color: statusConfig.textColor }}>
        {cls.teacher || t('No Teacher')}
      </span>
    </Space>

    {attendanceLog?.comment && (
      <Alert
        message={attendanceLog.comment}
        type={
          status === 'Present'
            ? 'success'
            : status === 'Late'
            ? 'warning'
            : status === 'Absent'
            ? 'error'
            : 'info'
        }
        showIcon
        style={{
          marginTop: 'auto',
          border: 'none',
          backgroundColor: statusConfig.backgroundColor + '80',
        }}
      />
    )}
  </Space>
</Card>

              </Col>
            );
          })}
      </Row>
    )
  ))}
</div>

    </div>
  </Row>
);
};

export default withRoleAccess(MyPerformance, ['student']);
