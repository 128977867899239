import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Input, Modal, Tooltip, Spin, Avatar} from 'antd';
import { EditOutlined, DeleteOutlined, UserOutlined, BgColorsOutlined, SaveOutlined } from '@ant-design/icons';
import { SketchPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import withRoleAccess from '../../hoc/withRoleAccess';
import PasswordModal from './PasswordModal';
import EditTeacherModal from './EditTeacherModal';
import { confirmAlert } from 'react-confirm-alert';


function EditDeleteTeacher({ onCreateNewTeacher }) {
  const { t } = useTranslation();
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [teacherIdToDelete, setTeacherIdToDelete] = useState(null);
  const [teacherIdToEdit, setTeacherIdToEdit] = useState(null);
  const [passwordError, setPasswordError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [colorPickerVisible, setColorPickerVisible] = useState(null);
  const [selectedColor, setSelectedColor] = useState('#000000');
  const itemsPerPage = 50;
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;


    const fetchTeachers = useCallback(async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setError(t('noToken'));
          setLoading(false);
          return;
        }
        const response = await axios.get(`${API_URL}/teachers`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
  
        // Sort teachers by userid before setting the state
        const sortedTeachers = response.data.sort((a, b) => a.userid - b.userid);
        setTeachers(sortedTeachers);  // Update the state with sorted teachers
      } catch (error) {
        console.error('Error fetching teachers:', error);
        setError(t('fetchTeachersError'));
      } finally {
        setLoading(false);
      }
    }, [API_URL, t]);
  
    useEffect(() => {
      fetchTeachers();
    }, [fetchTeachers]); // Now fetchTeachers is included in the dependency array
  
    const saveTeacher = async (updatedTeacher) => {
      try {
        const token = localStorage.getItem('token');
        await axios.put(`${API_URL}/teachers/${updatedTeacher.userid}`, updatedTeacher, {
          headers: { Authorization: `Bearer ${token}` }
        });
  
        confirmAlert({
          title: t('success'),
          message: t('teacherUpdated'),
          buttons: [
            {
              label: 'OK',
              onClick: () => {},
            }
          ]
        });
  
        // Fetch the updated teachers list after saving
        await fetchTeachers();
        
        setIsEditModalOpen(false);
      } catch (error) {
        console.error('Error updating teacher:', error);
        setError(t('updateTeacherError'));
      }
    };
  
    const handleEdit = (teacherId) => {
      setTeacherIdToEdit(teacherId);
      setIsEditModalOpen(true);
    };
  
    const handleDelete = (teacherId) => {
      setTeacherIdToDelete(teacherId);
      setPasswordError(''); // Clear any previous error message
      setIsPasswordModalOpen(true);
    };
  
    const confirmDelete = async (password) => {
      setIsPasswordModalOpen(false);
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setError(t('noToken'));
          return;
        }
        await axios.delete(`${API_URL}/teachers/${teacherIdToDelete}`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          data: { password }
        });
        setTeachers(prev => prev.filter(teacher => teacher.userid !== teacherIdToDelete)); // Update local state
  
        confirmAlert({
          title: t('success'),
          message: t('teacherDeleted'),
          buttons: [
            {
              label: 'OK',
              onClick: () => {},
            }
          ]
        });
      } catch (error) {
        console.error('Error deleting teacher:', error);
        if (error.response && error.response.status === 401) {
          setPasswordError(t('invalidPassword'));
          setIsPasswordModalOpen(true); // Reopen the modal with an error message
        } else {
          setError(t('deleteTeacherError'));
        }
      }
    };
  
    const handleColorChange = (color) => {
      setSelectedColor(color.hex);
    };
  
    const handleSaveColor = async (teacherId) => {
      try {
        const token = localStorage.getItem('token');
        await axios.put(`${API_URL}/teachers/${teacherId}/color`, { color: selectedColor }, {
          headers: { Authorization: `Bearer ${token}` },
        });
        confirmAlert({
          title: t('success'),
          message: t('colorUpdated'),
          buttons: [
            {
              label: 'OK',
              onClick: () => {},
            }
          ],
        });
        const updatedTeachers = teachers.map(teacher =>
          teacher.userid === teacherId ? { ...teacher, color: selectedColor } : teacher
        );
        setTeachers(updatedTeachers);
        setColorPickerVisible(null); // Close the color picker popup after saving
      } catch (error) {
        console.error('Error updating color:', error);
        confirmAlert({
          title: t('error'),
          message: t('errorUpdatingColor'),
          buttons: [
            {
              label: 'OK',
              onClick: () => {},
            }
          ],
        });
      }
    };
  
    const toggleColorPicker = (teacherId) => {
      setColorPickerVisible(teacherId === colorPickerVisible ? null : teacherId);
    };
  

  
    // Filter and paginate teachers
    const filteredTeachers = teachers.filter(teacher =>
      teacher.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      teacher.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      teacher.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

  
    const columns = [
      { title: t('id'), dataIndex: 'userid', key: 'userid' },
      {
        title: '',
        key: 'dashboard',
        render: (_, teacher) => (
          <Tooltip title={t('viewDashboard')}>
            <Button
              shape="circle"
              icon={<UserOutlined />}
              onClick={() => navigate(`/teacher-dashboard-admin/${teacher.userid}`)}
            />
          </Tooltip>
        ),
      },
      {
        title: t('photo'),
        dataIndex: 'photourl',
        key: 'photo',
        render: (url, teacher) => (
          <Avatar src={url || 'default-path/defaultImg.jpg'} alt={`${teacher.firstname} ${teacher.lastname}`} />
        ),
      },
      { title: t('name'), dataIndex: 'firstname', key: 'name', render: (text, teacher) => `${teacher.firstname} ${teacher.lastname}` },
      {
        title: t('color'),
        key: 'color',
        render: (_, teacher) => (
          <div>
            <div
              style={{
                backgroundColor: teacher.color || '#ffffff',
                width: '30px',
                height: '30px',
                borderRadius: '4px',
                border: '1px solid #ddd',
                cursor: 'pointer',
              }}
              onClick={() => setColorPickerVisible(teacher.userid)}
            />
            {colorPickerVisible === teacher.userid && (
              <Modal
                visible={colorPickerVisible === teacher.userid}
                footer={null}
                onCancel={() => setColorPickerVisible(null)}
              >
                <SketchPicker color={selectedColor} onChange={handleColorChange} />
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={() => handleSaveColor(teacher.userid)}
                >
                  {t('save')}
                </Button>
              </Modal>
            )}
          </div>
        ),
      },
      { title: t('nickname'), dataIndex: 'nickname', key: 'nickname' },
      { title: t('subjects'), dataIndex: 'specialize_subjects', key: 'subjects' },
      {
        title: t('about'),
        dataIndex: 'schedule',
        key: 'about',
        render: (text) => (
          <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
            {text}
          </div>
        ),
      },
      
      {
        title: t('actions'),
        key: 'actions',
        render: (_, teacher) => (
          <div>
            <Tooltip title={t('edit')}>
              <Button type="primary"
              shape="circle" 
              icon={<EditOutlined />} 
              onClick={() => handleEdit(teacher.userid)} />
            </Tooltip>
            <Tooltip title={t('delete')}>
              <Button type="primary"
              shape="circle" 
              icon={<DeleteOutlined />} 
              onClick={() => handleDelete(teacher.userid)} />
            </Tooltip>
            <Tooltip title={t('changeColor')}>
              <Button
              type="primary"
                shape="circle"
                icon={<BgColorsOutlined />}
                onClick={() => toggleColorPicker(teacher.userid)}
              />
            </Tooltip>
          </div>
        ),
      },
    ];
  
  
    if (loading) return <Spin tip="Loading..." fullscreen />;
  
    return (
      <div>
        <div >
          <Button type="primary" onClick={onCreateNewTeacher}>
            <UserOutlined /> {t('createNewTeacher')}
          </Button>
          <Input
            placeholder={t('searchPlaceholder')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ margin: '16px 0' }}
          />
          <Table
            columns={columns}
            dataSource={filteredTeachers}
            rowKey="userid"
            pagination={{
              current: currentPage,
              pageSize: itemsPerPage,
              total: filteredTeachers.length,
              onChange: setCurrentPage,
            }}
          />
        </div>
        <PasswordModal
          isOpen={isPasswordModalOpen}
          onClose={() => setIsPasswordModalOpen(false)}
          onConfirm={confirmDelete}
          errorMessage={passwordError}
        />
        <EditTeacherModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onSave={saveTeacher}
          teacherId={teacherIdToEdit}
        />
      </div>
    );
  } 
  
  export default withRoleAccess(EditDeleteTeacher, ['admin', 'superadmin']);