import React, { useRef, useEffect } from 'react';
import './Accounting.css';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const formatNumber = (number, locale) => {
  // Ensure the number is a properly parsed float
  const parsedNumber = parseFloat(number);
  if (isNaN(parsedNumber)) {
    console.log("Warning: formatNumber received NaN value:", number);
    return '0.00';
  }
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(parsedNumber);
};

const formatDate = (dateString, locale) => {
  const date = new Date(dateString);
  return moment(date).format('DD/MM/YYYY');
};

const InvoiceModal = ({ visible, onClose, registration }) => {
  const { t, i18n } = useTranslation();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  if (!visible || !registration) return null;

  // Generate timestamp for invoice number if not provided
  const timestamp = moment().format('YYYYMMDDHHmm');
  const invoiceNo = registration.invoiceno || `INV-${timestamp}`;

  // Ensure all values are properly parsed as numbers
  const classPrice = parseFloat(registration.price || 0);
  const inventoryTotal = parseFloat(registration.inventory_price || 0);
  const totalBeforeDiscount = classPrice + inventoryTotal;
  const discountAmount = parseFloat(registration.discount || 0);
  const totalAfterDiscount = totalBeforeDiscount - discountAmount;
  const vatAmount = parseFloat(registration.vat || 0);
  const grandTotal = parseFloat(registration.final_price || 0) || (totalAfterDiscount + vatAmount);
  const remainingBalance = parseFloat(registration.remaining_balance || 0);


  return (
    <div className="invoice-modal-overlay" style={{ 
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000
    }}>
      <div className="invoice-modal" ref={componentRef} style={{ 
        backgroundColor: 'white',
        padding: '20px',
        width: '210mm',
        maxHeight: '90vh',
        overflowY: 'auto',
        position: 'relative',
        fontFamily: '"Sarabun", Arial, sans-serif',
      }}>
        <span className="modal-close-button" onClick={onClose} style={{
          position: 'absolute',
          top: '10px',
          right: '15px',
          fontSize: '24px',
          cursor: 'pointer',
          zIndex: 1
        }}>&times;</span>
        
        {/* Header section with logo and company info */}
        <div className="modal-header" style={{ 
          display: 'flex', 
          marginBottom: '20px',
          alignItems: 'flex-start'
 
        }}>
          {/* Left side - Logo */}
          <div className="company-logo" style={{ 
            width: '80px', 
            height: '80px',
            marginRight: '20px'
          }}>
            <img src="/images/logosquare.png" alt="THE ADVISOR" style={{ 
              width: '130%',
              height: 'auto'
            }} />
          </div>
          
          {/* Middle - Company info */}
          <div style={{ flex: 1, paddingTop: '5px' }}>
            <h3 style={{ 
              fontSize: '16px', 
              fontWeight: 'bold', 
              marginBottom: '5px',
              marginTop: 0,
              color: '#000000',
              textAlign: 'left',
              marginLeft: '30px'
            }}>Y Learning (Thailand) Co., Ltd.</h3>
            <p style={{ 
              fontSize: '13px', 
              margin: '2px 0',
              lineHeight: '1.3',
              color: '#000000',
              textAlign: 'left',
              marginLeft: '30px'
            }}>บริษัท วาย เลิร์นนิ่ง (ไทยแลนด์) จำกัด</p>
            <p style={{ 
              fontSize: '13px', 
              margin: '2px 0',
              lineHeight: '1.3',
              color: '#000000',
              textAlign: 'left',
              marginLeft: '30px'
            }}>เลขที่ 98/38 ซอยลาดพร้าว 35 แขวงจันทรเกษม</p>
            <p style={{ 
              fontSize: '13px', 
              margin: '2px 0',
              lineHeight: '1.3',
              color: '#000000',
              textAlign: 'left',
              marginLeft: '30px'
            }}>เขตจตุจักร กรุงเทพมหานคร 10900</p>
            <p style={{ 
              fontSize: '13px', 
              margin: '2px 0',
              lineHeight: '1.3',
              color: '#000000',
              textAlign: 'left',
              marginLeft: '30px'
            }}>เลขภาษี 0105564038397</p>
            <p style={{ 
              fontSize: '13px', 
              margin: '2px 0',
              lineHeight: '1.3',
              color: '#000000',
              textAlign: 'left',
              marginLeft: '30px'
            }}>Tel: 082-656-2287</p>
            <p style={{ 
              fontSize: '13px', 
              margin: '2px 0',
              lineHeight: '1.3',
              color: '#000000',
              textAlign: 'left',
              marginLeft: '30px'
            }}>Email: theadvisor.service@gmail.com</p>
          </div>
          
          {/* Right side - Invoice box */}
          <div className="invoice-box" style={{ 
            backgroundColor: 'rgb(240, 245, 255)', 
            padding: '15px', 
            color: '#000',            
            borderRadius: '5px',
            width: '200px'
          }}>
            <h2 style={{ 
              color: 'rgb(70, 70, 255)', 
              fontSize: '24px', 
              margin: '0 0 15px 0',
              fontWeight: 'bold',
              textAlign: 'center'
            }}>
              {registration.is_refunded ? t('creditNote').toUpperCase() : t('invoice').toUpperCase()}
            </h2>
            <div style={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              fontSize: '13px', 
              margin: '5px 0' 
            }}>
              <span style={{ fontWeight: 'normal' }}>{t('No.')}:</span>
              <span>{invoiceNo}</span>
            </div>
            <div style={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              fontSize: '13px', 
              margin: '5px 0' 
            }}>
              <span style={{ fontWeight: 'normal' }}>{t('date')}:</span>
              <span>{formatDate(registration.date, i18n.language)}</span>
            </div>
          </div>
        </div>
        
        <hr style={{ 
          border: 'none', 
          height: '1px', 
          backgroundColor: '#ddd', 
          margin: '0 0 15px 0' 
        }} />
        
        {/* Customer Information */}
        <div className="customer-info" style={{ marginBottom: '15px' }}>
          <h3 style={{ 
            fontSize: '14px', 
            fontWeight: 'bold', 
            marginBottom: '20px',
            color: '#000000',
              paddingTop: '15px'

          }}>{t('Customer Information')}</h3>
          <p style={{ fontSize: '13px', margin: '10px 0', color: '#000000', }}><strong>{t('name')}:</strong> {registration.student_name}</p>
          <p style={{ fontSize: '13px', margin: '10px 0', color: '#000000', }}><strong>{t('address')}:</strong> {registration.address || t('na')}</p>
          <p style={{ fontSize: '13px', margin: '10px 0', color: '#000000', }}><strong>{t('phone')}:</strong> {registration.phone || t('na')}</p>
        </div>
        
        <hr style={{ 
          border: 'none', 
          height: '1px', 
          backgroundColor: 'rgb(220, 220, 240)', 
          margin: '15px 0'
        }} />
        
        {/* Invoice Table */}
        <div className="invoice-table" style={{ marginTop: '20px' }}>
          <table style={{ 
            width: '100%', 
            borderCollapse: 'collapse',
            fontSize: '12px'
          }}>
            <thead>
              <tr style={{ 
                backgroundColor: 'rgb(240, 240, 250)', 
                color: 'rgb(50, 50, 50)'
              }}>
                <th style={{ border: '1px solid #ddd', padding: '8px', width: '5%', textAlign: 'center' }}>#</th>
                <th style={{ border: '1px solid #ddd', padding: '8px', width: '45%' }}>{t('description')}</th>
                <th style={{ border: '1px solid #ddd', padding: '8px', width: '10%', textAlign: 'center' }}>{t('quantity')}</th>
                <th style={{ border: '1px solid #ddd', padding: '8px', width: '10%', textAlign: 'center' }}>{t('unit')}</th>
                <th style={{ border: '1px solid #ddd', padding: '8px', width: '15%', textAlign: 'right' }}>{t('unitPrice')}</th>
                <th style={{ border: '1px solid #ddd', padding: '8px', width: '15%', textAlign: 'right' }}>{t('total')}</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ backgroundColor: 'rgb(248, 248, 255)' }}>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>1</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{registration.class_code}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>1</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>Course</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>{formatNumber(classPrice, i18n.language)}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>{formatNumber(classPrice, i18n.language)}</td>
              </tr>
              
              {/* Show inventory items if present */}
              {registration.inventory_items && registration.inventory_items.map((item, index) => {
                // Parse the item if it's a string (from JSON_AGG in PostgreSQL)
                let parsedItem;
                try {
                  parsedItem = typeof item === 'string' ? JSON.parse(item) : item;
                } catch (e) {
                  console.error("Error parsing inventory item:", e);
                  parsedItem = { label: "Error parsing item", price_per_unit: 0 };
                }
                
                return (
                  <tr key={index} style={{ backgroundColor: index % 2 === 0 ? 'white' : 'rgb(248, 248, 255)' }}>
                    <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{index + 2}</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>{parsedItem.label}</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>1</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>Book</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>{formatNumber(parsedItem.price_per_unit, i18n.language)}</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>{formatNumber(parsedItem.price_per_unit, i18n.language)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        
        {/* Summary Information */}
        <div className="invoice-summary" style={{ 
          marginLeft: 'auto', 
          width: '250px', 
          marginTop: '20px',
          fontSize: '13px'
        }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '6px 0', fontSize: '15px' }}>
            <span>{t('total')}:</span>
            <span>{formatNumber(totalBeforeDiscount, i18n.language)}</span>
          </div>
          
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '6px 0', color: 'red' }}>
            <span>{t('discount')}:</span>
            <span>- {formatNumber(discountAmount, i18n.language)}</span>
          </div>
          
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '6px 0' }}>
          <span>{t('Total After Discount')}:</span>
            <span>{formatNumber(totalAfterDiscount, i18n.language)}</span>
          </div>
          
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '6px 0' }}>
            <span>{t('VAT 7%', { percentage: 7 })}:</span>
            <span>{formatNumber(vatAmount, i18n.language)}</span>
          </div>
          
          <div style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            margin: '6px 0',
            fontWeight: 'bold',
            fontSize: '16px'
          }}>
            <span>{t('grandTotal')}:</span>
            <span>{formatNumber(grandTotal, i18n.language)}</span>
          </div>
          
          {/* Only show remaining balance if needed */}
          {remainingBalance > 0 && (
            <div style={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              margin: '6px 0',
              color: 'red',
              fontWeight: 'bold',
            fontSize: '14px'
            }}>
              <span>{t('Remaining Balance')}:</span>
              <span>({formatNumber(remainingBalance, i18n.language)})</span>
            </div>
          )}
        </div>
        
        {/* Payment Information */}
        <div className="payment-info" style={{ marginTop: '40px' }}>
          <h3 style={{ 
            fontSize: '14px', 
            fontWeight: 'bold', 
            marginBottom: '10px',
            marginTop: '100px'
         }}>{t('Payment Information')}</h3>
          
          <div className="bank-info" style={{ display: 'flex', alignItems: 'flex-start' }}>
            <img 
              src="/images/kbank.png" 
              alt="Kasikorn Bank" 
              style={{ width: '30px', height: '30px', marginRight: '10px', marginTop: '2px' }} 
            />
            <div className="bank-details" style={{ fontSize: '10px' }}>
              <p style={{ margin: '2px 0',  color: '#000000' }}>ธนาคารกสิกรไทย [Kasikorn Bank]</p>
              <p style={{ margin: '2px 0',  color: '#000000' }}>บจก. วาย เลิร์นนิ่ง (ไทยแลนด์) [Y Learning (Thailand) Co., Ltd.]</p>
              <p style={{ margin: '2px 0',  color: '#000000' }}>เลขที่บัญชีออมทรัพย์ 110-1-19906-8</p>
            </div>
          </div>
          
        </div>
        
        {/* Signature Section */}
        <div className="signature-section" style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          marginTop: '100px',
          marginBottom: '10px',
          padding: '0 50px',
          fontSize: '9px'
        }}>
          <div className="signature-box" style={{ textAlign: 'center', width: '200px' }}>
            <div className="signature-line" style={{ 
              borderTop: '1px solid #000', 
              marginBottom: '5px',
              width: '100%',
              color: '#000000'
            }}></div>
            <p style={{ color: '#000000' }}>{t('Authorized Signature')}</p>
          </div>
          
          <div className="signature-box" style={{ textAlign: 'center', width: '200px' }}>
            <div className="signature-line" style={{ 
              borderTop: '1px solid #000', 
              marginBottom: '5px',
              width: '100%',
              color: '#000000'
            }}></div>
            <p style={{ color: '#000000' }}>{t('companyStamp')}</p>
          </div>
        </div>
        
        {/* Note */}
        <div className="invoice-note" style={{ 
          textAlign: 'center', 
          marginTop: '20px',
          marginBottom: '15px'
        }}>
          <p style={{ fontSize: '9px' }}>{t('Note: We appreciate your business. Please note that all transactions are final, and refunds cannot be processed.')}</p>
        </div>
        
        {/* Print Button - Only visible when displaying the modal, not when printing */}
        <div className="invoice-actions" 
            style={{ 
              display: 'flex', 
              justifyContent: 'center',
              marginTop: '15px',
              '@media print': {
                display: 'none'
              }
            }}>
          <button 
            onClick={handlePrint}
            style={{
              padding: '8px 16px',
              backgroundColor: '#4646ff',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            {t('printInvoice')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvoiceModal;