import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import withRoleAccess from '../../hoc/withRoleAccess';
import {
  Table,
  Input,
  Card,
  Typography,
  Tag,
  Tooltip,
  Spin,
  Space,
  Divider,
  message,
  Badge,
  Button
} from 'antd';
import {
  CalendarOutlined,
  ClockCircleOutlined,
  UserOutlined,
  BookOutlined,
  FileTextOutlined,
  SyncOutlined,
  CloseCircleOutlined,
    PauseCircleOutlined,
    ExclamationCircleOutlined,
    ReconciliationOutlined,
    QuestionCircleOutlined,
    FileExcelOutlined
} from '@ant-design/icons';

const { Text } = Typography;
const { Search } = Input;

const ClassChange = () => {
  const { i18n, t } = useTranslation();
  const [classChangeLogs, setClassChangeLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
  });
  
  const API_URL = process.env.REACT_APP_API_URL;

  const formatTime = (timeString) => {
    try {
      const [hours, minutes] = timeString.split(':');
      return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    } catch (error) {
      console.error('Invalid time value:', timeString);
      return t('invalidTime');
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date);
  };

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const options = { 
      day: '2-digit', 
      month: 'short', 
      year: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit' 
    };
    return new Intl.DateTimeFormat(i18n.language, options).format(dateTime);
  };

  const calculateDuration = (startTime, endTime) => {
    try {
      const start = new Date(`1970-01-01T${startTime}Z`);
      const end = new Date(`1970-01-01T${endTime}Z`);
      const diff = (end - start) / (1000 * 60); // difference in minutes

      const hours = Math.floor(diff / 60);
      const minutes = diff % 60;

      return `${hours}:${minutes.toString().padStart(2, '0')} ${t('hr')}`;
    } catch (error) {
      console.error('Error calculating duration:', error);
      return t('invalidDuration');
    }
  };

  const getChangeReasonTag = (reason) => {
    const reasonMap = {
      'admin_adjust': { color: 'blue', icon: <SyncOutlined />, text: 'Admin' },
      'cancelled_by_student': { color: 'red', icon: <CloseCircleOutlined />, text: 'CL by S.' },
      'cancelled_by_teacher': { color: 'orange', icon: <CloseCircleOutlined />, text: 'CL by T.' },
      'class_on_hold_by_student': { color: 'geekblue', icon: <PauseCircleOutlined />, text: 'On hold by S.' },
      'class_on_hold_by_school': { color: 'green', icon: <PauseCircleOutlined />, text: 'On hold by P.' },
      'cancel_same_day': { color: 'volcano', icon: <ExclamationCircleOutlined />, text: 'Cancel Same Day' },
      'make_up': { color: 'purple', icon: <ReconciliationOutlined />, text: 'Make Up' },
      'others': { color: 'default', icon: <QuestionCircleOutlined />, text: 'Others' }
    };

    const config = reasonMap[reason] || { color: 'default', text: reason };
    
    return (
      <Tag color={config.color} icon={config.icon}>
        {config.text}
      </Tag>
    );
  };

  const fetchClassChangeLogs = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/class-change/class-change-logs`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setClassChangeLogs(response.data);
      setFilteredLogs(response.data);
      setPagination(prev => ({
        ...prev,
        total: response.data.length
      }));
    } catch (error) {
      console.error('Error fetching class change logs:', error);
      message.error('Failed to load class change logs');
    } finally {
      setLoading(false);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchClassChangeLogs();
  }, [fetchClassChangeLogs]);

  const handleSearch = (value) => {
    const term = value.toLowerCase();
    setSearchTerm(term);
    const filtered = classChangeLogs.filter(
      (log) =>
        log.classid?.toString().includes(term) || 
        log.subject_name?.toLowerCase().includes(term) ||
        log.class_code?.toLowerCase().includes(term) ||
        log.nickname?.toLowerCase().includes(term)
    );
    setFilteredLogs(filtered);
    setPagination(prev => ({
      ...prev,
      current: 1,
      total: filtered.length
    }));
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const prepareCSVData = () => {
    return filteredLogs.map(log => ({
      'Class ID': log.classid,
      'Class Code': log.class_code,
      'Subject': log.subject_name,
      'Teacher': log.nickname,
      'Original Date': formatDate(log.originaldate),
      'Original Time': `${formatTime(log.originalstarttime)} - ${formatTime(log.originalendtime)}`,
      'Original Hours': calculateDuration(log.originalstarttime, log.originalendtime),
      'New Date': formatDate(log.newdate),
      'New Time': `${formatTime(log.newstarttime)} - ${formatTime(log.newendtime)}`,
      'New Hours': calculateDuration(log.newstarttime, log.newendtime),
      'Change Reason': log.changereason,
      'Note': log.note || '',
      'Updated By': log.updateby,
      'Updated At': formatDateTime(log.updated_at)
    }));
  };

  const columns = [
    {
      title: t('classcode'),
      dataIndex: 'class_code',
      key: 'class_code',
      width: 120,
      fixed: 'left',
      render: code => (
        <Badge status="processing" text={code} />
      )
    },
    {
      title: t('id'),
      dataIndex: 'classid',
      key: 'classid',
      width: 80,
    },
    {
      title: t('subject'),
      dataIndex: 'subject_name',
      key: 'subject_name',
      width: 150,
      render: text => (
        <Tooltip title={text}>
          <Space>
            <BookOutlined />
            <Text ellipsis style={{ maxWidth: 120 }}>{text}</Text>
          </Space>
        </Tooltip>
      )
    },
    {
      title: t('teacher'),
      dataIndex: 'nickname',
      key: 'nickname',
      width: 120,
      render: name => (
        <Space>
          <UserOutlined />
          <Text>{name}</Text>
        </Space>
      )
    },
    {
      title: t('originaldate'),
      dataIndex: 'originaldate',
      key: 'originaldate',
      width: 120,
      render: date => (
        <Space>
          <CalendarOutlined />
          <Text>{formatDate(date)}</Text>
        </Space>
      )
    },
    {
      title: t('originaltime'),
      key: 'originaltime',
      width: 150,
      render: record => (
        <Space>
          <ClockCircleOutlined />
          <Text>{formatTime(record.originalstarttime)} - {formatTime(record.originalendtime)}</Text>
        </Space>
      )
    },
    {
      title: t('originalhour'),
      key: 'originalhour',
      width: 100,
      render: record => calculateDuration(record.originalstarttime, record.originalendtime)
    },
    {
      title: t('newdate'),
      dataIndex: 'newdate',
      key: 'newdate',
      width: 120,
      render: date => (
        <Space>
          <CalendarOutlined />
          <Text>{formatDate(date)}</Text>
        </Space>
      )
    },
    {
      title: t('newtime'),
      key: 'newtime',
      width: 150,
      render: record => (
        <Space>
          <ClockCircleOutlined />
          <Text>{formatTime(record.newstarttime)} - {formatTime(record.newendtime)}</Text>
        </Space>
      )
    },
    {
      title: t('newhour'),
      key: 'newhour',
      width: 100,
      render: record => calculateDuration(record.newstarttime, record.newendtime)
    },
    {
      title: t('changereason'),
      dataIndex: 'changereason',
      key: 'changereason',
      width: 130,
      render: reason => getChangeReasonTag(reason)
    },
    {
      title: t('note'),
      dataIndex: 'note',
      key: 'note',
      width: 200,
      render: note => (
        <Tooltip title={note} placement="topLeft">
          <Text ellipsis style={{ maxWidth: 180 }}>
            <FileTextOutlined style={{ marginRight: 8 }} />
            {note || '-'}
          </Text>
        </Tooltip>
      )
    },
    {
      title: t('admin'),
      dataIndex: 'updateby',
      key: 'updateby',
      width: 120,
    },
    {
      title: t('updateat'),
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 160,
      render: date => formatDateTime(date)
    },
  ];

  return (
    <div className="class-change-container">      
      <Card className="search-card">
  <Space direction="vertical" style={{ width: '100%' }}>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Search
        placeholder={t('Search by Class ID, Code, Subject or Teacher')}
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        onSearch={handleSearch}
        enterButton
        allowClear
        style={{ width: 'calc(100% - 140px)' }}
      />
      <CSVLink
        data={prepareCSVData()}
        filename={`class-changes-${new Date().toISOString().slice(0, 10)}.csv`}
        className="ant-btn ant-btn-primary"
        style={{ marginLeft: '10px' }}
      >
        <Button type="primary" icon={<FileExcelOutlined />}>
              {t("Export CSV")}
            </Button>
      </CSVLink>
    </div>
    <Text type="secondary">
      {filteredLogs.length} {t('records found')}
    </Text>
  </Space>
</Card>
      
      <Divider />
      
      <Spin spinning={loading} tip={t('Loading logs...')}>
        <Table
          columns={columns}
          dataSource={filteredLogs}
          rowKey="id"
          pagination={pagination}
          onChange={handleTableChange}
          scroll={{ x: 'max-content', y: 'calc(100vh - 300px)' }}
          size="middle"
          bordered
        />
      </Spin>
    </div>
  );
};

export default withRoleAccess(ClassChange, ['admin', 'superadmin']);