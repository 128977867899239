import React, { useState, useEffect, useCallback } from 'react';
import { 
  Table, 
  Card, 
  Button, 
  Space, 
  Modal, 
  Form, 
  Input, 
  Select, 
  InputNumber, 
  Tag, 
  Typography, 
  Tooltip, 
  message, 
  Divider,
  Badge
} from 'antd';
import { 
  EditOutlined, 
  PlusOutlined, 
  MinusOutlined, 
  ExclamationCircleOutlined,
  SearchOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { useTranslation } from 'react-i18next';
import './InventoryPage.css';

const { Title, Text } = Typography;
const { Option } = Select;
const { confirm } = Modal;

const CurrentInventory = () => {
  const { t } = useTranslation();
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [adjustQuantity, setAdjustQuantity] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isAdjustModalOpen, setIsAdjustModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchCategory, setSearchCategory] = useState('');
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  
  const API_URL = process.env.REACT_APP_API_URL;

  const fetchInventory = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/inventory`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      const currentQuantityResponse = await axios.get(`${API_URL}/inventory/current-quantity`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const inventoryWithQuantities = response.data.map((item) => {
        const quantityData = currentQuantityResponse.data.find(q => q.inventory_id === item.inventory_id);
        return {
          ...item,
          current_quantity: quantityData ? quantityData.current_quantity : 0,
          key: item.inventory_id
        };
      });

      setInventory(inventoryWithQuantities);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch inventory data.');
      message.error('Failed to fetch inventory data');
      setLoading(false);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchInventory();
  }, [fetchInventory]);

  const showAdjustModal = (record) => {
    setSelectedItem(record);
    setAdjustQuantity(0);
    setIsAdjustModalOpen(true);
  };

  const handleAdjustCancel = () => {
    setIsAdjustModalOpen(false);
    setSelectedItem(null);
    setAdjustQuantity(0);
  };

  const handleAdjustSubmit = async () => {
    if (adjustQuantity === 0) {
      message.warning('Please enter a non-zero quantity to adjust');
      return;
    }
    
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}/inventory/log`, {
        inventory_id: selectedItem.inventory_id,
        quantity_change: adjustQuantity,
        change_reason: 'Admin Adjustment'
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      setIsAdjustModalOpen(false);
      message.success('Inventory quantity updated successfully!');
      fetchInventory();
    } catch (error) {
      console.error('Error adjusting quantity:', error);
      message.error('Failed to update inventory quantity');
    }
  };

  const showEditModal = (record) => {
    setSelectedItem(record);
    editForm.setFieldsValue({
      product_id: record.product_id,
      item_name: record.item_name,
      item_description: record.item_description,
      category: record.category,
      price_per_unit: record.price_per_unit
    });
    setIsEditModalOpen(true);
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
    setSelectedItem(null);
    editForm.resetFields();
  };

  const handleEditSubmit = async () => {
    try {
      const values = await editForm.validateFields();
      const token = localStorage.getItem('token');
      
      await axios.put(`${API_URL}/inventory/${selectedItem.inventory_id}`, values, {
        headers: { 
          Authorization: `Bearer ${token}` 
        }
      });
      
      setIsEditModalOpen(false);
      message.success('Inventory item updated successfully!');
      fetchInventory();
    } catch (error) {
      if (error.errorFields) {
        message.error('Please check form fields');
      } else {
        console.error('Error updating inventory:', error);
        message.error('Failed to update inventory item');
      }
    }
  };

  const handleStatusChange = (record, newStatus) => {
    confirm({
      title: 'Are you sure you want to change the status?',
      icon: <ExclamationCircleOutlined />,
      content: `Change status to ${newStatus}?`,
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'No',
      onOk: async () => {
        try {
          const token = localStorage.getItem('token');
          await axios.put(`${API_URL}/inventory/${record.inventory_id}/status`, 
            { status: newStatus }, 
            { headers: { Authorization: `Bearer ${token}` }}
          );
          message.success(`Status changed to ${newStatus}`);
          fetchInventory();
        } catch (error) {
          console.error('Error changing status:', error);
          message.error('Failed to change status');
        }
      }
    });
  };

  // Get all unique categories
  const categories = [...new Set(inventory.map(item => item.category))].filter(Boolean);

  // Filter inventory data
  const filteredInventory = inventory.filter(item => {
    const matchesSearch = !searchText || 
      item.product_id?.toLowerCase().includes(searchText.toLowerCase()) ||
      item.item_name?.toLowerCase().includes(searchText.toLowerCase()) ||
      item.item_description?.toLowerCase().includes(searchText.toLowerCase());
    
    const matchesCategory = !searchCategory || item.category === searchCategory;
    
    return matchesSearch && matchesCategory;
  });

  const columns = [
    {
      title: t('productid'),
      dataIndex: 'product_id',
      key: 'product_id',
      sorter: (a, b) => (a.product_id || '').localeCompare(b.product_id || ''),
      width: 120,
    },
    {
      title: t('itemname'),
      dataIndex: 'item_name',
      key: 'item_name',
      sorter: (a, b) => (a.item_name || '').localeCompare(b.item_name || ''),
      render: (text) => <Text strong>{text}</Text>,
    },
    {
      title: t('description'),
      dataIndex: 'item_description',
      key: 'item_description',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: t('category'),
      dataIndex: 'category',
      key: 'category',
      filters: categories.map(category => ({ text: category, value: category })),
      onFilter: (value, record) => record.category === value,
      render: (category) => (
        <Tag color="blue">{category}</Tag>
      ),
    },
    {
      title: t('priceperunit'),
      dataIndex: 'price_per_unit',
      key: 'price_per_unit',
      sorter: (a, b) => a.price_per_unit - b.price_per_unit,
      render: (price) => (
        <Text type="secondary">
          ฿{parseFloat(price).toFixed(2)}
        </Text>
      ),
    },
    {
      title: t('currentquantity'),
      dataIndex: 'current_quantity',
      key: 'current_quantity',
      sorter: (a, b) => a.current_quantity - b.current_quantity,
      render: (quantity) => {
        let color = 'success';
        if (quantity <= 5) color = 'error';
        else if (quantity <= 10) color = 'warning';
        
        return (
          <Badge 
            count={quantity} 
            showZero 
            style={{ backgroundColor: color === 'success' ? '#52c41a' : 
                                     color === 'warning' ? '#faad14' : '#f5222d' }}
          />
        );
      },
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Active', value: 'active' },
        { text: 'Inactive', value: 'inactive' },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status, record) => (
        <Select
          value={status || 'inactive'}
          onChange={(value) => handleStatusChange(record, value)}
          style={{ width: 110 }}
        >
          <Option value="active">
            <Badge status="success" text="Active" />
          </Option>
          <Option value="inactive">
            <Badge status="error" text="Inactive" />
          </Option>
        </Select>
      ),
    },
    {
      title: t('actions'),
      key: 'actions',
      width: 200,
      render: (_, record) => (
        <Space size="small">
          <Button 
            type="primary" 
            onClick={() => showAdjustModal(record)}
            icon={<PlusOutlined />}
          >
            Adjust
          </Button>
          <Button 
            onClick={() => showEditModal(record)}
            icon={<EditOutlined />}
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  const resetFilters = () => {
    setSearchText('');
    setSearchCategory('');
  };

  return (
    <div>
      <Sidebar />
      <Header />
      <div className="inventory-container">
        <Card 
          title={<Title level={3}>{t('Current Inventory')}</Title>}
          extra={
            <Button 
              type="primary" 
              icon={<ReloadOutlined />} 
              onClick={fetchInventory}
              loading={loading}
            >
              Refresh
            </Button>
          }
          className="inventory-card"
        >
          {error && (
            <div className="error-message">
              <Text type="danger">{error}</Text>
            </div>
          )}
          
          <div className="filter-section">
            <Space wrap>
              <Input
                placeholder="Search by product ID, name or description"
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                style={{ width: 300 }}
                prefix={<SearchOutlined />}
                allowClear
              />
              
              <Select
                placeholder="Filter by category"
                style={{ width: 200 }}
                onChange={value => setSearchCategory(value)}
                value={searchCategory}
                allowClear
              >
                {categories.map(category => (
                  <Option key={category} value={category}>{category}</Option>
                ))}
              </Select>
              
              <Button onClick={resetFilters}>Reset</Button>
            </Space>
          </div>
          
          <Divider />
          
          <Table
            columns={columns}
            dataSource={filteredInventory}
            rowKey="inventory_id"
            loading={loading}
            pagination={{
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100'],
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
            }}
            rowClassName={record => record.current_quantity <= 5 ? 'low-stock-row' : ''}
            size="middle"
          />
        </Card>
      </div>

      {/* Adjust Quantity Modal */}
      <Modal
        title={
          <div>
            <div>Adjust Inventory Quantity</div>
            <Text type="secondary">
              {selectedItem?.item_name} (Current: {selectedItem?.current_quantity})
            </Text>
          </div>
        }
        open={isAdjustModalOpen}
        onCancel={handleAdjustCancel}
        footer={[
          <Button key="back" onClick={handleAdjustCancel}>
            Cancel
          </Button>,
          <Button 
            key="submit" 
            type="primary" 
            onClick={handleAdjustSubmit}
            disabled={adjustQuantity === 0}
          >
            Update Quantity
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form}>
          <Form.Item label="Quantity to Add/Remove">
            <div className="quantity-adjuster">
              <Button 
                icon={<MinusOutlined />} 
                onClick={() => setAdjustQuantity(prev => prev - 1)}
              />
              <InputNumber
                value={adjustQuantity}
                onChange={setAdjustQuantity}
                style={{ width: 200 }}
              />
              <Button 
                icon={<PlusOutlined />} 
                onClick={() => setAdjustQuantity(prev => prev + 1)}
              />
            </div>
            <div style={{ marginTop: 8 }}>
              <Text type="secondary">
                {adjustQuantity > 0 
                  ? `Adding ${adjustQuantity} item(s). New total will be ${selectedItem?.current_quantity + adjustQuantity}`
                  : adjustQuantity < 0 
                    ? `Removing ${Math.abs(adjustQuantity)} item(s). New total will be ${selectedItem?.current_quantity + adjustQuantity}`
                    : 'Enter a positive number to add or negative to remove items'}
              </Text>
            </div>
          </Form.Item>
        </Form>
      </Modal>

      {/* Edit Inventory Modal */}
      <Modal
        title="Edit Inventory Item"
        open={isEditModalOpen}
        onCancel={handleEditCancel}
        footer={[
          <Button key="back" onClick={handleEditCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleEditSubmit}>
            Save Changes
          </Button>,
        ]}
      >
        <Form 
          form={editForm} 
          layout="vertical"
          initialValues={{
            product_id: selectedItem?.product_id,
            item_name: selectedItem?.item_name,
            item_description: selectedItem?.item_description,
            category: selectedItem?.category,
            price_per_unit: selectedItem?.price_per_unit
          }}
        >
          <Form.Item 
            name="product_id" 
            label="Product ID"
            rules={[{ required: true, message: 'Please enter product ID' }]}
          >
            <Input />
          </Form.Item>
          
          <Form.Item 
            name="item_name" 
            label="Item Name"
            rules={[{ required: true, message: 'Please enter item name' }]}
          >
            <Input />
          </Form.Item>
          
          <Form.Item 
            name="item_description" 
            label="Description"
          >
            <Input.TextArea rows={3} />
          </Form.Item>
          
          <Form.Item 
            name="category" 
            label="Category"
            rules={[{ required: true, message: 'Please select a category' }]}
          >
            <Select>
              {categories.map(category => (
                <Option key={category} value={category}>{category}</Option>
              ))}
              <Option value="other">Other</Option>
            </Select>
          </Form.Item>
          
          <Form.Item 
            name="price_per_unit" 
            label="Price per Unit"
            rules={[{ required: true, message: 'Please enter price' }]}
          >
            <InputNumber
              disabled
              style={{ width: '100%' }}
              formatter={value => `฿ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/฿\s?|(,*)/g, '')}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default withRoleAccess(CurrentInventory, ['superadmin', 'admin']);