import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { 
  Table, Input, Button, DatePicker, Select, 
  Tabs, Form, Drawer, Space, message, Layout, Card,
  Typography, Popconfirm, Tag, Tooltip, Row, Col
} from 'antd';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPlus, faPencilAlt, faTrash,
  faSave, faEye, faCheckCircle, faTimes
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import LessonPlanView from './LessonPlanView'; 

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;
const { Content } = Layout;

const LessonPlanPage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const API_URL = process.env.REACT_APP_API_URL;
  const [user, setUser] = useState(null);
  const [lessonPlans, setLessonPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false); // New state for view modal
  const [editMode, setEditMode] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [viewPlan, setViewPlan] = useState(null); // New state for viewing a plan
  const [classes, setClasses] = useState([]);
  const [processedClasses, setProcessedClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [dateRange, setDateRange] = useState(null);
  const [classFilter, setClassFilter] = useState(null);
  const [activeTab, setActiveTab] = useState('upcoming');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const formatDate = (date) => {
    try {
      if (!date) return '-';
      
      const momentDate = moment(date);
      if (!momentDate.isValid()) return '-';
      
      return momentDate.format('DD MMM YYYY').replace(/[a-z]/g, letter => letter.toUpperCase());
    } catch (error) {
      console.error("Error formatting date:", error);
      return '-';
    }
  };

  useEffect(() => {
    fetchClasses();
    fetchLessonPlans();
    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (classes.length > 0 && Array.isArray(lessonPlans)) {
  
      const processed = classes.map(cls => {
        const classId = cls.classid; 
        const hasLessonPlan = checkForExistingLessonPlan(classId, cls.date);
  
        const displayName = `${cls.class_code || 'Class'} - ${cls.subject_name || ''} - ${cls.date ? formatDate(cls.date) : ''}`;
  
        return {
          ...cls,
          hasLessonPlan,
          displayName
        };
      });
  
      const excludedClasses = processed.filter(cls => cls.hasLessonPlan);
      
      setProcessedClasses(processed);
    }
  }, [classes, lessonPlans]);
  
  const checkForExistingLessonPlan = (classId, date) => {
    if (!classId || !date) {
      console.warn(`🛑 Missing data in check: classId=${classId}, date=${date}`);
      return false;
    }
  
    const formattedDate = moment(date).format('YYYY-MM-DD');
  
    const match = lessonPlans.some(plan => {
      const planClassId = String(plan.classid);
      const planDate = moment(plan.lesson_date).format('YYYY-MM-DD');
  
      const sameClass = planClassId === String(classId);
      const sameDate = planDate === formattedDate;

      return sameClass && sameDate;
    });
  
    return match;
  };
  
  const fetchClasses = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/classes/teacher`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setClasses(response.data);
    } catch (error) {
      console.error('Error fetching classes:', error);
      message.error(t('errorFetchingClasses'));
    }
  };


  const fetchLessonPlans = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      
      const response = await axios.get(`${API_URL}/lessonplan`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      setLessonPlans(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching lesson plans:', error.response || error);
      
      if (error.response) {
        message.error(`Error ${error.response.status}: ${error.response.data.message || 'Unknown error'}`);
      } else {
        message.error(t('errorFetchingLessonPlans'));
      }
      
      setLoading(false);
    }
  };

  const fetchUserInfo = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const handleClassChange = (classId) => {
    
    const selected = processedClasses.find(cls => cls.classid === classId);
    
    if (selected) {
      setSelectedClass(selected);
      
      const classDate = selected.date ? moment(selected.date) : null;
      const startTime = selected.schedule_time ? moment(selected.schedule_time, 'HH:mm:ss') : null;
      
      let endTime = null;
      if (startTime && selected.schedule_hour) {
        const durationMatch = selected.schedule_hour.match(/(\d+)h\s*(\d*)m?/);
        if (durationMatch) {
          const hours = parseInt(durationMatch[1], 10) || 0;
          const minutes = parseInt(durationMatch[2], 10) || 0;
          endTime = moment(startTime).add(hours, 'hours').add(minutes, 'minutes');
        }
      }
      
      const formattedDateForTitle = classDate 
        ? moment(classDate).format('DD MMM YYYY').toUpperCase() 
        : moment().format('DD MMM YYYY').toUpperCase();
      
      const lessonTitle = selected.subject_name 
        ? `${selected.subject_name} - ${formattedDateForTitle}` 
        : form.getFieldValue('title');
      
      form.setFieldsValue({
        classid: selected.classid,
        class_id: selected.groupid, // Make sure this is set for the backend
        lesson_date: classDate || moment(),
        start_time: startTime || moment('09:00', 'HH:mm'),
        end_time: endTime || moment('10:00', 'HH:mm'),
        title: lessonTitle || 'New Lesson'
      });
    } else {
      setSelectedClass(null);
      form.setFieldsValue({
        classid: null,
        class_id: null
      });
    }
  };

  const filteredLessonPlans = useMemo(() => {
    if (!lessonPlans || !Array.isArray(lessonPlans)) return [];

    return lessonPlans.filter((plan) => {
      try {
        let matchesSearch = true;
        let matchesClass = true;
        let matchesDate = true;

        if (searchTerm) {
          matchesSearch = 
            (plan.title?.toLowerCase().includes(searchTerm.toLowerCase())) || 
            (plan.description?.toLowerCase().includes(searchTerm.toLowerCase()));
        }

        if (classFilter) {
          matchesClass = String(plan.classid) === String(classFilter);
        }

        if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
          const planDate = moment(plan.lesson_date);
          matchesDate = 
            planDate.isSameOrAfter(dateRange[0].startOf('day')) && 
            planDate.isSameOrBefore(dateRange[1].endOf('day'));
        }

        return matchesSearch && matchesClass && matchesDate;
      } catch (error) {
        console.error("Error filtering lesson plan:", error, plan);
        return false;
      }
    });
  }, [lessonPlans, searchTerm, classFilter, dateRange]);

  const saveLessonPlan = async (values) => {
    try {
      const token = localStorage.getItem('token');
      
      if (!values.classid) {
        message.error(t('errorMissingClassId'));
        return;
      }
      
      if (!values.lesson_date) {
        message.error(t('pleaseSelectDate'));
        return;
      }
      
      const data = {
        ...values,
        lesson_date: values.lesson_date ? values.lesson_date.format('YYYY-MM-DD') : null,
        start_time: values.start_time ? values.start_time.format('HH:mm:ss') : null,
        end_time: values.end_time ? values.end_time.format('HH:mm:ss') : null
      };
      
      if (editMode && currentPlan) {
        axios.put(
          `${API_URL}/lessonplan/${currentPlan.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        message.success(t('lessonPlanUpdated'));
      } else {
        axios.post(
          `${API_URL}/lessonplan`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        message.success(t('lessonPlanCreated'));
      }

      closeDrawer();
      fetchLessonPlans();
    } catch (error) {
      console.error('Error saving lesson plan:', error);
      
      if (error.response?.status === 409) {
        message.error(t('lessonPlanAlreadyExists'));
      } else if (error.response?.status === 400) {
        const errorMessage = error.response?.data?.message || 'Bad request';
        message.error(`${t('errorSavingLessonPlan')}: ${errorMessage}`);
      } else {
        message.error(`${t('errorSavingLessonPlan')}: ${error.response?.data?.message || error.message}`);
      }
    }
  };

  const updateLessonPlanStatus = async (id, status) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(
        `${API_URL}/lessonplan/${id}/status`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      message.success(t('statusUpdated'));
      fetchLessonPlans();
    } catch (error) {
      console.error('Error updating lesson plan status:', error);
      message.error(t('errorUpdatingStatus'));
    }
  };

  const deleteLessonPlan = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${API_URL}/lessonplan/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      message.success(t('lessonPlanDeleted'));
      fetchLessonPlans();
    } catch (error) {
      console.error('Error deleting lesson plan:', error);
      message.error(t('errorDeletingLessonPlan'));
    }
  };

  const openCreateDrawer = () => {
    setCurrentPlan(null);
    setEditMode(false);
    setSelectedClass(null);
    form.resetFields();
    form.setFieldsValue({
      lesson_date: moment(),
      start_time: moment('09:00', 'HH:mm'),
      end_time: moment('10:00', 'HH:mm')
    });
    setDrawerVisible(true);
  };

  const openEditDrawer = (plan) => {
    setCurrentPlan(plan);
    setEditMode(true);
  
    
    const relatedClass = processedClasses.find(cls => cls.classid === plan.classid);
    setSelectedClass(relatedClass || null);
    
    const lessonDate = plan.lesson_date ? moment(plan.lesson_date) : moment();
    const startTime = plan.start_time ? moment(plan.start_time, 'HH:mm:ss') : moment('09:00', 'HH:mm');
    const endTime = plan.end_time ? moment(plan.end_time, 'HH:mm:ss') : moment('10:00', 'HH:mm');
    
    form.setFieldsValue({
      title: plan.title || '',
      classid: plan.classid || null,
      class_id: plan.class_id || null,
      lesson_date: lessonDate,
      start_time: startTime,
      end_time: endTime,
      description: plan.description || '',
      learning_objectives: plan.learning_objectives || '',
      activities: plan.activities || '',
      assessment: plan.assessment || '',
      homework: plan.homework || '',
      materials: plan.materials || '',
      status: plan.status || 'planned'
    });
    
    setDrawerVisible(true);
  };

  const handleRefresh = async () => {
    try {
      setLoading(true);
      await Promise.all([
        fetchLessonPlans(),
        fetchClasses()
      ]);
      message.success(t('refreshSuccess') || 'Data refreshed successfully');
    } catch (error) {
      console.error('Error refreshing data:', error);
      message.error(t('refreshError') || 'Failed to refresh data');
    } finally {
      setLoading(false);
    }
  };

  // New function to open the view modal
  const openViewModal = (plan) => {
    const relatedClass = processedClasses.find(cls => cls.classid === plan.classid);
    setViewPlan(plan);
    setSelectedClass(relatedClass || null);
    setViewModalVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setCurrentPlan(null);
    setEditMode(false);
    setSelectedClass(null);
    form.resetFields();
  };

  const closeViewModal = () => {
    setViewModalVisible(false);
    setViewPlan(null);
  };

  const filterByDate = (plans, isUpcoming) => {
    try {
      if (!plans || !Array.isArray(plans)) return [];
      
      return plans.filter(plan => {
        if (!plan.lesson_date) return false;
        
        try {
          const planDate = moment(plan.lesson_date);
          const today = moment().startOf('day');
          
          if (isUpcoming) {
            return planDate.isSameOrAfter(today);
          } else {
            return planDate.isBefore(today);
          }
        } catch (err) {
          console.error("Date filtering error:", err);
          return false;
        }
      });
    } catch (err) {
      console.error("Error in filterByDate:", err);
      return [];
    }
  };


const getResponsiveColumns = () => {
  // Check if we're on mobile
  const isMobile = window.innerWidth < 480;
  
  if (isMobile) {
    // Mobile optimized columns - fewer columns, more compact
    return [
      {
        title: t('Details'),
        key: 'details',
        render: (_, record) => (
          <div style={{ padding: '4px 0' }}>
            <div style={{ fontWeight: 'bold' }}>{record.title || 'Untitled'}</div>
            <div>
              <Tag color="blue">{record.class_code || '-'}</Tag>
              {' '}
              <Text type="secondary">{formatDate(record.lesson_date)}</Text>
            </div>
            <div style={{ marginTop: '4px' }}>
              {(record.status)}
            </div>
          </div>
        ),
      },
      {
        title: t('actions'),
        key: 'actions',
        width: 110,
        render: (_, record) => (
          <Space size="small" wrap>
            <Button 
              icon={<FontAwesomeIcon icon={faPencilAlt} />} 
              onClick={() => openEditDrawer(record)} 
              size="small"
            />
            <Button 
              icon={<FontAwesomeIcon icon={faEye} />} 
              onClick={() => openViewModal(record)} 
              size="small"
            />
            <Popconfirm
              title={t('confirmDeleteLessonPlan')}
              onConfirm={() => deleteLessonPlan(record.id)}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <Button
                icon={<FontAwesomeIcon icon={faTrash} />}
                danger
                size="small"
              />
            </Popconfirm>
          </Space>
        ),
      },
    ];
  }
  
  // Desktop columns - full set of columns
  return [
    {
      title: t('classcode'),
      dataIndex: 'class_code',
      key: 'class_code',
      render: (text) => text || '-',
    },
    {
      title: t('Class ID'),
      dataIndex: 'classid',
      key: 'classid',
      render: (text) => text ? (
        <Tag color="blue">
          {text}
        </Tag>
      ) : '-',
    },
    {
      title: t('class'),
      dataIndex: 'title',
      key: 'title',
      render: (text) => text || 'Untitled Lesson',
    },
    {
      title: t('date'),
      dataIndex: 'lesson_date',
      key: 'lesson_date',
      render: (date) => formatDate(date),
      sorter: (a, b) => {
        try {
          return moment(a.lesson_date).unix() - moment(b.lesson_date).unix();
        } catch (error) {
          return 0;
        }
      },
    },
    {
      title: t('time'),
      key: 'time',
      render: (_, record) => {
        try {
          if (record.start_time && record.end_time) {
            return `${moment(record.start_time, 'HH:mm:ss').format('HH:mm')} - ${moment(record.end_time, 'HH:mm:ss').format('HH:mm')}`;
          }
          return record.start_time ? moment(record.start_time, 'HH:mm:ss').format('HH:mm') : '-';
        } catch (error) {
          console.error("Error formatting time:", error, record);
          return '-';
        }
      },
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        try {
          if (status === 'completed') {
            return <Tag color="green">{t('completed')}</Tag>;
          } else if (status === 'cancelled') {
            return <Tag color="red">{t('cancelled')}</Tag>;
          }
          return <Tag color="blue">{status || t('planned')}</Tag>;
        } catch (error) {
          return <Tag color="blue">{t('planned')}</Tag>;
        }
      },
    },
    {
      title: t('actions'),
      key: 'actions',
      render: (_, record) => (
        <Space size="small">
          <Tooltip title={t('edit')}>
            <Button 
              icon={<FontAwesomeIcon icon={faPencilAlt} />} 
              onClick={() => openEditDrawer(record)} 
              size="small"
            />
          </Tooltip>
          <Tooltip title={t('view')}>
            <Button 
              icon={<FontAwesomeIcon icon={faEye} />} 
              onClick={() => openViewModal(record)} 
              size="small"
            />
          </Tooltip>
          
          {(!record.status || record.status === 'planned') && (
            <Tooltip title={t('markCompleted')}>
              <Button 
                icon={<FontAwesomeIcon icon={faCheckCircle} />} 
                onClick={() => updateLessonPlanStatus(record.id, 'completed')} 
                size="small"
                style={{ color: 'green' }}
              />
            </Tooltip>
          )}
          
          {(!record.status || record.status === 'planned') && (
            <Tooltip title={t('markCancelled')}>
              <Button 
                icon={<FontAwesomeIcon icon={faTimes} />} 
                onClick={() => updateLessonPlanStatus(record.id, 'cancelled')} 
                size="small"
                style={{ color: 'red' }}
              />
            </Tooltip>
          )}
          
          <Popconfirm
            title={t('confirmDeleteLessonPlan')}
            onConfirm={() => deleteLessonPlan(record.id)}
            okText={t('yes')}
            cancelText={t('no')}
          >
            <Tooltip title={t('delete')}>
              <Button
                icon={<FontAwesomeIcon icon={faTrash} />}
                danger
                size="small"
              />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];
};

  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Header />
        <Content style={{ 
      padding: '24px', 
      marginTop: '60px',
      marginLeft: isMobile ? '0' : '70px'
    }}>
      <Card title={t('My Lesson Plans')}>

    <div className="filters" style={{ marginBottom: 16 }}>
  <Row gutter={[8, 8]} align="middle">
    <Col xs={24} sm={6} md={5} lg={4}>
      <Button 
        type="primary" 
        icon={<FontAwesomeIcon icon={faPlus} />}
        onClick={openCreateDrawer}
        style={{ width: '100%' }}
      >
        {t('Create')}
      </Button>
    </Col>
    <Col xs={24} sm={6} md={6} lg={5}>
      <Input.Search
        placeholder={t('Search')}
        allowClear
        onSearch={(value) => setSearchTerm(value)}
        style={{ width: '100%' }}
      />
    </Col>
    <Col xs={16} sm={8} md={10} lg={12}>
      <Select 
        placeholder={t('Filter By Class')}
        allowClear
        style={{ width: '100%' }}
        onChange={(value) => setClassFilter(value)}
      >
        {processedClasses.map((cls) => (
          <Option key={cls.classid} value={cls.classid}>
            {cls.displayName}
          </Option>
        ))}
      </Select>
    </Col>
    <Col xs={8} sm={4} md={3} lg={3}>
      <Button 
        onClick={handleRefresh}
        loading={loading}
        style={{ width: '100%' }}
      >
        {t('Refresh')}
      </Button>
    </Col>
  </Row>
</div>

      {/* Mobile-friendly Tabs */}
    <Tabs 
      activeKey={activeTab} 
      onChange={(key) => setActiveTab(key)}
      size={window.innerWidth < 768 ? "small" : "default"}
    >
      <TabPane tab={t('Upcoming')} key="upcoming">
        <div className="table-responsive">
          <Table
            columns={getResponsiveColumns()}
            dataSource={filterByDate(filteredLessonPlans, true)}
            rowKey="id"
            loading={loading}
            pagination={{ pageSize: 10 }}
            locale={{ emptyText: t('noUpcomingLessonPlans') }}
            scroll={{ x: '100%' }}
            size={window.innerWidth < 768 ? "small" : "default"}
          />
        </div>
      </TabPane>
      <TabPane tab={t('Past')} key="past">
        <div className="table-responsive">
          <Table
            columns={getResponsiveColumns()}
            dataSource={filterByDate(filteredLessonPlans, false)}
            rowKey="id"
            loading={loading}
            pagination={{ pageSize: 10 }}
            locale={{ emptyText: t('noPastLessonPlans') }}
            scroll={{ x: '100%' }}
            size={window.innerWidth < 768 ? "small" : "default"}
          />
        </div>
      </TabPane>
      <TabPane tab={t('All')} key="all">
        <div className="table-responsive">
          <Table
            columns={getResponsiveColumns()}
            dataSource={filteredLessonPlans || []}
            rowKey="id"
            loading={loading}
            pagination={{ pageSize: 10 }}
            locale={{ emptyText: t('noLessonPlans') }}
            scroll={{ x: '100%' }}
            size={window.innerWidth < 768 ? "small" : "default"}
          />
        </div>
      </TabPane>
    </Tabs>


      {/* Edit/Create Drawer */}
      <Drawer
      title={editMode ? t('Edit Lesson Plan') : t('Create Lesson Plan')}
      width={window.innerWidth < 768 ? '100%' : 720}
      onClose={closeDrawer}
      visible={drawerVisible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button onClick={closeDrawer} style={{ marginRight: 8 }}>
            {t('cancel')}
          </Button>
          <Button 
            type="primary" 
            icon={<FontAwesomeIcon icon={faSave} />}
            onClick={() => form.submit()}
          >
            {t('save')}
          </Button>
        </div>
      }
    >
        <Form
          form={form}
          layout="vertical"
          onFinish={saveLessonPlan}
        >
          <Form.Item
            name="classid"
            label={t('class')}
            rules={[{ required: true, message: t('pleaseSelectClass') }]}
          >
            <Select 
              placeholder={t('Select Class')}
              onChange={handleClassChange}
              showSearch
              disabled={editMode}
              filterOption={(input, option) => {
                const text = option.children || '';
                return text.toLowerCase().includes(input.toLowerCase().trim());
              }}
            >
              {processedClasses
                .filter(cls => {
                  if (editMode && currentPlan && cls.classid === currentPlan.classid) {
                    return true;
                  }
                  return !cls.hasLessonPlan;
                })
                .map(cls => (
                  <Option key={cls.classid} value={cls.classid}>
                    {cls.displayName}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          {/* Hidden field for class_id (UUID) required by the database */}
          <Form.Item name="class_id" hidden>
            <Input />
          </Form.Item>

          <Form.Item
            name="title"
            label={t('subject')}
            rules={[{ required: true}]}
          >
            <Input placeholder={t('')} />
          </Form.Item>

          <Space style={{ display: 'flex', marginBottom: 8 }}>
            <Form.Item
              name="lesson_date"
              label={t('lessonDate')}
              rules={[{ required: true, message: t('pleaseSelectDate') }]}
            >
              <DatePicker format="MM/DD/YYYY" disabled={true} />
            </Form.Item>

            <Form.Item
              name="start_time"
              label={t('startTime')}
            >
              <DatePicker.TimePicker format="HH:mm" disabled={true} />
            </Form.Item>

            <Form.Item
              name="end_time"
              label={t('endTime')}
            >
              <DatePicker.TimePicker format="HH:mm" disabled={true} />
            </Form.Item>
          </Space>

          <Form.Item
            name="description"
            label={t('Lesson Brief')}
          >
            <TextArea 
              rows={3} 
              placeholder={t('Enter Brief Description')}
            />
          </Form.Item>

          <Form.Item
            name="learning_objectives"
            label={t('Learning Objective')}
          >
            <TextArea 
              rows={4} 
              placeholder={t('What Students Will Learn')}
            />
          </Form.Item>

          <Form.Item
            name="activities"
            label={t('Activities')}
          >
            <TextArea 
              rows={4} 
              placeholder={t('Describe Activities')}
            />
          </Form.Item>

          <Form.Item
            name="assessment"
            label={t('Assessment')}
          >
            <TextArea 
              rows={4} 
              placeholder={t('How Will Teacher Assess Learning')}
            />
          </Form.Item>

          <Form.Item
            name="homework"
            label={t('Homework')}
          >
            <TextArea 
              rows={4} 
              placeholder={t('Assignments For Students')}
            />
          </Form.Item>

          <Form.Item
            name="materials"
            label={t('Materials')}
          >
            <TextArea 
              rows={3}
              placeholder={t('List Required Materials')}
            />
          </Form.Item>

          {editMode && (
            <Form.Item
              name="status"
              label={t('status')}
            >
              <Select>
                <Option value="planned">{t('Planned')}</Option>
                <Option value="completed">{t('Completed')}</Option>
                <Option value="cancelled">{t('Cancelled')}</Option>
              </Select>
            </Form.Item>
          )}
        </Form>
      </Drawer>

      {/* View Modal */}
      <LessonPlanView 
        visible={viewModalVisible}
        onClose={closeViewModal}
        lessonPlan={viewPlan}
        classInfo={selectedClass}
        user={user} 
      />
      </Card>
      </Content>
      </Layout>
    </Layout>
  );
};

export default withRoleAccess(LessonPlanPage, ['teacher']);