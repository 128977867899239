import React, { useState, useEffect, useCallback  } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { Table, Input, DatePicker, Button, Modal, message, Tag } from 'antd';
import { PaperClipOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import ReceiptModal from './ReceiptModal';

const { Search } = Input;
const { MonthPicker } = DatePicker;

// Payment method mapping with color codes
const PAYMENT_METHODS = {
  'credit_card': { label: 'Credit Card', color: 'blue' },
  'cash': { label: 'Cash', color: 'green' },
  'bankTransfer': { label: 'Bank Transfer', color: 'purple' }
};

const PaymentDetails = () => {
  const { t } = useTranslation();
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedMonthYear, setSelectedMonthYear] = useState(null);
  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  // Helper function to format payment method
  const formatPaymentMethod = (method) => {
    return PAYMENT_METHODS[method]?.label || method;
  };

  // Helper function to get payment method color
  const getPaymentMethodColor = (method) => {
    return PAYMENT_METHODS[method]?.color || 'default';
  };

  const fetchPaymentDetails = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/payment/allpayments`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      // Format payment methods in the response data
      const formattedData = response.data.map(payment => ({
        ...payment,
        // Keep the original payment method for the Tag rendering
        formatted_payment_method: formatPaymentMethod(payment.payment_method)
      }));
      
      setPaymentDetails(formattedData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching payment details:', error);
      setLoading(false);
    }
  }, [API_URL]); 

  useEffect(() => {
    fetchPaymentDetails(); 
  }, [fetchPaymentDetails]); 

  const handleDeletePayment = async (id) => {
    Modal.confirm({
      title: t('confirmDeletePayment'),
      content: t('deletePaymentWarning'),
      okText: t('yes'),
      cancelText: t('no'),
      onOk: async () => {
        try {
          const token = localStorage.getItem('token');
          await axios.delete(`${API_URL}/payment/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          message.success(t('paymentDeleted'));
          fetchPaymentDetails(); // Refresh data after deletion
        } catch (error) {
          console.error('Error deleting payment:', error);
          message.error(t('deleteError'));
        }
      },
    });
  };

  const handleSearch = (value) => {
    setSearchTerm(value.toLowerCase());
  };

  const handleMonthYearChange = (date) => {
    setSelectedMonthYear(date ? date.format('YYYY-MM') : null);
  };

  const openReceiptModal = (record) => {
    setSelectedReceipt(record);
    setIsReceiptModalOpen(true);
  };

  const closeReceiptModal = () => {
    setIsReceiptModalOpen(false);
    setSelectedReceipt(null);
  };

  const filteredPaymentDetails = paymentDetails.filter((payment) => {
    const matchesSearch =
      payment.firstname.toLowerCase().includes(searchTerm) ||
      payment.lastname.toLowerCase().includes(searchTerm);

    const matchesMonthYear =
      !selectedMonthYear ||
      moment(payment.timestamp).format('YYYY-MM') === selectedMonthYear;

    return matchesSearch && matchesMonthYear;
  });

  const columns = [
    {
      title: t('invoiceNo'),
      dataIndex: 'invoice_no',
      key: 'invoice_no',
    },
    {
      title: t('firstName'),
      dataIndex: 'firstname',
      key: 'firstname',
    },
    {
      title: t('lastName'),
      dataIndex: 'lastname',
      key: 'lastname',
    },
    {
      title: t('course'),
      dataIndex: 'class_code',
      key: 'class_code',
    },
    {
      title: t('paymentMethod'),
      dataIndex: 'payment_method',
      key: 'payment_method',
      render: (method) => (
        <Tag color={getPaymentMethodColor(method)}>
          {formatPaymentMethod(method)}
        </Tag>
      ),
    },
    {
      title: t('subtotal'),
      dataIndex: 'final_price',
      key: 'final_price',
      render: (finalPrice) => (finalPrice != null ? finalPrice.toLocaleString() : '0'),
    },
    {
      title: t('nettotal'),
      dataIndex: 'pay_amount',
      key: 'pay_amount',
      render: (amount) => (
        <span style={{ color: 'green' }}>
          {amount != null ? amount.toLocaleString() : '0'}
        </span>
      ),
    },
    {
      title: t('remaining'),
      dataIndex: 'remaining_balance',
      key: 'remaining_balance',
      render: (balance) => (
        <span style={{ color: balance === 0 ? 'green' : 'red' }}>
          {balance != null ? balance.toLocaleString() : '0'}
        </span>
      ),
    },
    {
      title: t('at'),
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp) => (timestamp ? new Date(timestamp).toLocaleString() : 'N/A'),
    },
    {
      title: t('memo'),
      dataIndex: 'memo',
      key: 'memo',
    },
    {
      title: t('receipt'),
      key: 'receipt',
      render: (_, record) => (
        <>
          <Button 
            type="primary"
            icon={<PaperClipOutlined />}
            onClick={() => openReceiptModal(record)}
            style={{ marginRight: '8px' }}
          />
        </>
      ),
    },
    {
      title: t('actions'),
      key: 'actions',
      render: (_, record) => (
        <>
          <Button 
            type="primary"
            icon={<DeleteOutlined />}
            danger 
            onClick={() => handleDeletePayment(record.id)}
          />
        </>
      ),
    },
  ];

  const csvData = filteredPaymentDetails.map((payment) => ({
    invoiceNo: payment.invoice_no,
    firstName: payment.firstname,
    lastName: payment.lastname,
    subject: payment.class_code,
    paymentMethod: formatPaymentMethod(payment.payment_method),
    subtotal: payment.final_price ? payment.final_price.toLocaleString() : '0',
    nettotal: payment.pay_amount ? payment.pay_amount.toLocaleString() : '0',
    remaining: payment.remaining_balance ? payment.remaining_balance.toLocaleString() : '0',
    memo: payment.memo || '',
    timestamp: new Date(payment.timestamp).toLocaleString(),
  }));

  return (
    <div className="payment-details-page">
      <div style={{ marginBottom: '16px' }}>
        <Search placeholder={t('searchByName')} onSearch={handleSearch} style={{ width: 200, marginRight: '16px' }} />
        <MonthPicker placeholder={t('selectMonthYear')} onChange={handleMonthYearChange} style={{ marginRight: '16px' }} />
        <Button onClick={fetchPaymentDetails}>{t('refresh')}</Button>
        <CSVLink data={csvData} filename="payment_details.csv" target="_blank">
          <Button style={{ marginLeft: '16px' }} icon={<FontAwesomeIcon icon={faFileExcel} />}>
            {t('exportCSV')}
          </Button>
        </CSVLink>
      </div>

      {loading ? (
        <p>{t('loading')}</p>
      ) : (
        <Table dataSource={filteredPaymentDetails} columns={columns} rowKey="id" pagination={{ pageSize: 50 }} />
      )}

      {isReceiptModalOpen && <ReceiptModal show={isReceiptModalOpen} onClose={closeReceiptModal} registration={selectedReceipt} />}
    </div>
  );
};

export default withRoleAccess(PaymentDetails, ['superadmin']);