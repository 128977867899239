import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Spin, message, Row, Col, Select, Space, Card } from 'antd';
import { FilePdfOutlined, EyeOutlined, FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import PayStubModal from './PayStubModal';
import withRoleAccess from '../../hoc/withRoleAccess';
import { PDFDocument } from 'pdf-lib'; 
import { useTranslation } from 'react-i18next';
import './Teacher.css';

const { Option } = Select;

const TeacherPaymentReport = () => {
    const { t } = useTranslation();
    const [paymentReports, setPaymentReports] = useState([]);
    const [filteredReports, setFilteredReports] = useState([]);
    const [selectedPayStub, setSelectedPayStub] = useState(null);
    const [loading, setLoading] = useState(true);
    const [teachers, setTeachers] = useState([]);
    const [periods, setPeriods] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        fetchPaymentReports();
    }, [API_URL, t]);

    useEffect(() => {
        // Extract unique teachers and periods from payment reports
        if (paymentReports.length > 0) {
            // Get unique teachers
            const uniqueTeachers = [...new Set(paymentReports.map(report => report.teacher_name))]
                .filter(Boolean)
                .sort();
            setTeachers(uniqueTeachers);

            // Get unique periods
            const uniquePeriods = [...new Set(paymentReports.map(report => report.period))]
                .filter(Boolean)
                .sort((a, b) => new Date(b.split('_')[0]) - new Date(a.split('_')[0])); // Sort by start date, newest first
            setPeriods(uniquePeriods);
        }
    }, [paymentReports]);

    useEffect(() => {
        // Apply filters when selected values change
        filterReports();
    }, [selectedTeacher, selectedPeriod, paymentReports]);

    const fetchPaymentReports = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            const response = await axios.get(`${API_URL}/teacher-hours/teacher-payment-report`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setPaymentReports(response.data);
            setFilteredReports(response.data); // Initialize filtered reports with all reports
        } catch (error) {
            console.error(t('errorFetchingPaymentReports'), error);
            message.error(t('failedToFetchTeachers'));
        } finally {
            setLoading(false);
        }
    };

    const filterReports = () => {
        let filtered = [...paymentReports];

        // Filter by teacher if selected
        if (selectedTeacher) {
            filtered = filtered.filter(report => report.teacher_name === selectedTeacher);
        }

        // Filter by period if selected
        if (selectedPeriod) {
            filtered = filtered.filter(report => report.period === selectedPeriod);
        }

        setFilteredReports(filtered);
    };

    const handleReset = () => {
        setSelectedTeacher(null);
        setSelectedPeriod(null);
    };

    const formatTotalAmount = (amount) => {
        return typeof amount === 'string' && !isNaN(parseFloat(amount))
            ? parseFloat(amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            : '0.00';
    };

    const formatDateToDMY = (dateString) => {
        if (!dateString) return '';
        const [year, month, day] = dateString.split('-');
        return `${day}-${month}-${year}`;
    };

    const formatPeriod = (periodString) => {
        // Check if period is in the expected format like "2025-01-26_2025-02-25"
        if (periodString && periodString.includes('_')) {
            const [startDateStr, endDateStr] = periodString.split('_');
            
            // Parse the dates
            const startParts = startDateStr.split('-').map(Number);
            const endParts = endDateStr.split('-').map(Number);
            
            // Create date objects (year, month-1, day)
            const startDate = new Date(startParts[0], startParts[1] - 1, startParts[2]);
            const endDate = new Date(endParts[0], endParts[1] - 1, endParts[2]);
            
            // Get last 2 digits of year
            const startYear = startDate.getFullYear().toString().slice(-2);
            const endYear = endDate.getFullYear().toString().slice(-2);
            
            // Format as "26 Jan 25 - 25 Feb 25"
            const startFormatted = startDate.getDate() + ' ' + 
                                  startDate.toLocaleString('default', { month: 'short' }) + ' ' + 
                                  startYear;
            
            const endFormatted = endDate.getDate() + ' ' + 
                                endDate.toLocaleString('default', { month: 'short' }) + ' ' + 
                                endYear;
            
            return `${startFormatted} - ${endFormatted}`;
        }
        
        // Fallback if period is not in expected format
        return periodString;
    };

    const numberToWords = (num) => {
        const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
        const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
        const teens = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    
        if (num === 0) return "Zero";
    
        let words = "";
    
        if (Math.floor(num / 1000) > 0) {
            words += numberToWords(Math.floor(num / 1000)) + " Thousand ";
            num %= 1000;
        }
    
        if (Math.floor(num / 100) > 0) {
            words += numberToWords(Math.floor(num / 100)) + " Hundred ";
            num %= 100;
        }
    
        if (num >= 10 && num < 20) {
            words += teens[num - 10] + " ";
        } else if (num >= 20) {
            words += tens[Math.floor(num / 10)] + " ";
            num %= 10;
        }
    
        if (num > 0) {
            words += ones[num] + " ";
        }
    
        return words.trim();
    };
    
    const numberToWordsWithBahtAndSatang = (amount) => {
        // Ensure the amount is a number
        const numericAmount = parseFloat(amount);
        
        if (isNaN(numericAmount)) {
            throw new Error("Invalid amount: amount must be a number");
        }
    
        const [bahtPart, satangPart] = numericAmount.toFixed(2).split('.').map(Number);
        
        let words = numberToWords(bahtPart) + " Baht";
        
        if (satangPart > 0) {
            words += " and " + numberToWords(satangPart) + " Satang";
        }
    
        return words;
    };
    
    function formatNationalId(id) {
        if (!id) return '';
        
        // Remove any existing spaces or non-numeric characters
        const cleanId = id.replace(/\D/g, '');
        
        // Check if we have a valid ID length (assuming 13 digits for Thai national ID)
        if (cleanId.length !== 13) {
          return cleanId; // Return as is if not the expected length
        }
        
        // Format with spaces: X XXXX XXXXX XX X
        return `${cleanId.substring(0, 1)} ${cleanId.substring(1, 5)} ${cleanId.substring(5, 10)} ${cleanId.substring(10, 12)} ${cleanId.substring(12, 13)}`;
    }

    const generateTawi50PDF = async (report) => {
        try {
            const existingPdfBytes = await fetch('/images/tawi50.pdf').then(res =>
                res.arrayBuffer()
            );
    
            const pdfDoc = await PDFDocument.load(existingPdfBytes);
            const form = pdfDoc.getForm();
    
            // Print all field names (for reference)
            const fields = form.getFields();
            fields.forEach(field => {
            });
    
            // Ensure that each value is a string or use an empty string as a fallback
            form.getTextField('name1').setText('Y Learning (Thailand) Co., Ltd.');  // Fixed Company name in English
            form.getTextField('add1').setText('98/38 LadPhrao 35 Chan Kasem, Chatuchak, Bangkok 10900');  // Fixed Company address in English
            form.getTextField('id1').setText('0 1055 64038 39 7');  // Fixed Company tax ID
    
            form.getTextField('name2').setText(report.teacher_name || '');  // Teacher's name
            form.getTextField('add2').setText(report.address || '');  // Teacher's address
            form.getTextField('id1_2').setText(formatNationalId(report.nationalid));
    
            // Check if report.classes exists and is an array before iterating
            if (Array.isArray(report.classes)) {
                report.classes.forEach((cls, index) => {
                    form.getTextField('date1').setText(formatDateToDMY(report.approval_date) || '');
                    form.getTextField(`total${index + 1}`).setText(formatTotalAmount(report.sub_amount || 0)); 
                    form.getTextField(`spec${index + 1}`).setText(formatTotalAmount(report.sum_withholdingtax || 0));
                });
            } else {
                console.error('report.classes is not an array or is undefined');
            }
    
            // Calculate totals (adjust field names as needed)
            const totalSubtotal = report.sub_amount || 0;
            const totalWithholdingTax = report.sum_withholdingtax || 0;
            form.getTextField('date1').setText(report.approval_date || '');
            form.getTextField('pay1.0').setText(formatTotalAmount(totalSubtotal));  
            form.getTextField('tax1.0').setText(formatTotalAmount(totalWithholdingTax));

            form.getTextField('total').setText(numberToWordsWithBahtAndSatang(totalWithholdingTax));

    
            // Check the chk1 and chk8 checkboxes
            form.getCheckBox('chk1').check();
            form.getCheckBox('chk8').check();
    
            const pdfBytes = await pdfDoc.save();
    
            // Download the PDF
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `Tawi50_${report.teacher_name}_${report.period}.pdf`;
            link.click();
        } catch (error) {
            console.error('Error generating Tawi50 PDF:', error);
        }
    };
    
    
    const handlePayStubClick = async (report) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${API_URL}/teacher-hours/teacher-payment-report/${report.batch_id}/details`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const classDetails = response.data.map(cls => ({
                classId: cls.class_id,
                rate: cls.hourly_rate,
                hours: cls.hours_worked,
                total: cls.total_amount
            }));

            const payStubData = {
                companyLogo: "/images/logosquare.png",
                companyName: "บริษัท วาย เลิร์นนิ่ง (ไทยแลนด์) จำกัด",
                companyAddress: "เลขที่ 98/38 ซอยลาดพร้าว 35 แขวงจันทรเกษม",
                companyTax: "เลขประจำตัวผู้เสียภาษี 0105564038397",
                companyWebsite: "เขตจตุจักร กรุงเทพมหานคร 10900",
                companyPhone: "Tel: 082-656-2287",
                companyLine: "theadvisor.service@gmail.com",
                teacherName: report.teacher_name,
                teacherAddress: report.address,
                teacherPhone: report.phone,
                teacherNationalId: report.nationalid,
                payPeriod: report.period,
                payDate: report.approval_date,
                classes: classDetails,
                totalHours: report.total_hours,
                totalPay: report.total_amount,
                withholdingTax: report.sum_withholdingtax,
            };
            setSelectedPayStub(payStubData);
        } catch (error) {
            console.error(t('errorFetchingClassDetails'), error);
            message.error(t('errorFetchingClassDetails'));
        }
    };

    const columns = [
        {
            title: t('approvalDate'),
            dataIndex: 'approval_date',
            key: 'approval_date',
            render: (date) => formatDateToDMY(date),
            sorter: (a, b) => new Date(a.approval_date) - new Date(b.approval_date),
        },
        {
            title: t('period'),
            dataIndex: 'period',
            key: 'period',
            render: (periodString) => formatPeriod(periodString)
        },
        {
            title: t('teacherName'),
            dataIndex: 'teacher_name',
            key: 'teacher_name',
        },
        {
            title: t('nationalID'),
            dataIndex: 'nationalid',
            key: 'nationalid',
            render: (id) => formatNationalId(id),
        },
        {
            title: t('totalHours'),
            dataIndex: 'total_hours',
            key: 'total_hours',
            sorter: (a, b) => a.total_hours - b.total_hours,
        },
        {
            title: t('subAmount'),
            dataIndex: 'sub_amount',
            key: 'sub_amount',
            render: (amount) => formatTotalAmount(amount),
        },
        {
            title: t('sumWithholdingTax'),
            dataIndex: 'sum_withholdingtax',
            key: 'sum_withholdingtax',
            render: (amount) => formatTotalAmount(amount),
        },
        {
            title: t('totalPay'),
            dataIndex: 'total_amount',
            key: 'total_amount',
            render: (amount) => formatTotalAmount(amount),
        },
        {
            title: t('approveby'),
            dataIndex: 'approval_userid',
            key: 'approval_userid',
        },
        {
            title: t('paystub'),
            key: 'paystub',
            render: (_, report) => (
                <Button type="link" icon={<EyeOutlined />} onClick={() => handlePayStubClick(report)}>
                    {t('viewpaystub')}
                </Button>
            ),
        },
        {
            title: t('tawi50'),
            key: 'tawi50',
            render: (_, report) => (
                <Button type="link" icon={<FilePdfOutlined />} onClick={() => generateTawi50PDF(report)}>
                    {t('Generate Tawi50')}
                </Button>
            ),
        },
    ];

    return (
        <div style={{ display: 'flex' }}>
            <Sidebar />
            <div style={{ flexGrow: 1, padding: '16px' }}>
                <Header />
                <Card title={t('teacherPaymentReport')}>
                {/* Filter Section */}
                <div style={{ marginBottom: '20px', background: '#f5f5f5', padding: '15px', borderRadius: '5px' }}>
                    <Row gutter={[16, 16]} align="bottom">
                        <Col xs={24} sm={8} md={6} lg={5}>
                            <div>
                                <label style={{ display: 'block', marginBottom: '8px' }}>{t('Teacher')}</label>
                                <Select
                                    placeholder={t('selectTeacher')}
                                    style={{ width: '100%' }}
                                    allowClear
                                    value={selectedTeacher}
                                    onChange={setSelectedTeacher}
                                >
                                    {teachers.map(teacher => (
                                        <Option key={teacher} value={teacher}>{teacher}</Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col xs={24} sm={10} md={8} lg={7}>
                            <div>
                                <label style={{ display: 'block', marginBottom: '8px' }}>{t('Period')}</label>
                                <Select
                                    placeholder={t('selectPeriod')}
                                    style={{ width: '100%' }}
                                    allowClear
                                    value={selectedPeriod}
                                    onChange={setSelectedPeriod}
                                >
                                    {periods.map(period => (
                                        <Option key={period} value={period}>{formatPeriod(period)}</Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col xs={24} sm={6} md={4} lg={3}>
                            <Space>
                                <Button 
                                    icon={<ReloadOutlined />} 
                                    onClick={handleReset}
                                    disabled={!selectedTeacher && !selectedPeriod}
                                >
                                    {t('Reset')}
                                </Button>
                                <Button 
                                    type="primary"
                                    icon={<FilterOutlined />}
                                    onClick={filterReports}
                                >
                                    {t('Filter')}
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </div>
                
                {loading ? (
                    <Spin size="large" />
                ) : (
                    <Table 
                        columns={columns} 
                        dataSource={filteredReports} 
                        rowKey="batch_id"
                        pagination={{ pageSize: 10 }}
                        bordered
                    />
                )}

                <Modal
                    title={t('viewpaystub')}
                    open={selectedPayStub !== null}
                    onCancel={() => setSelectedPayStub(null)}
                    footer={null}
                    width={800}
                >
                    <PayStubModal payStubData={selectedPayStub} onClose={() => setSelectedPayStub(null)} />
                </Modal>
                </Card>
            </div>
        </div>
    );
};

export default withRoleAccess(TeacherPaymentReport, ['superadmin']);