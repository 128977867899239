import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { Table, Select, Button, Modal, Form, Input, notification, Tabs } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import InvoiceModal from './InvoiceModal';
import PaymentDetails from './PaymentDetails';
import AddExpense from './AddExpense';
import ExpenseReport from './ExpenseReport';
import { useTranslation } from 'react-i18next';
import './Accounting.css';
import moment from 'moment';

const { Option } = Select;

const formatDate = (dateString) => {
  return moment(dateString).format('DD MMM YYYY');
};

const formatNumber = (number) => {
  if (isNaN(number)) return '0.00';
  return new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(number);
};

const Accounting = () => {
  const { t, i18n } = useTranslation();
  const [registrations, setRegistrations] = useState([]);
  const [filteredRegistrations, setFilteredRegistrations] = useState([]);
  const [filters, setFilters] = useState({
    fromDate: null,
    toDate: null,
    paymentMethod: '',
  });
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedRegistration, setSelectedRegistration] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [form] = Form.useForm(); 

  const API_URL = process.env.REACT_APP_API_URL;

  const fetchRegistrations = useCallback(async () => {
    try {
        const token = localStorage.getItem('token');

        // Fetch all registrations (with remaining_balance included)
        const response = await axios.get(`${API_URL}/registrations`, {
            headers: { Authorization: `Bearer ${token}` },
        });

        // Directly use the API response
        setRegistrations(response.data);
        setFilteredRegistrations(response.data);

        // Extract payment methods for filtering
        const methods = [...new Set(response.data.map((reg) => reg.payment_method))];
        setPaymentMethods(methods);

    } catch (error) {
        console.error('Error fetching registrations:', error);
    }
}, [API_URL]);

// Fetch registrations when the component mounts
useEffect(() => {
    fetchRegistrations();
}, [fetchRegistrations]);


const handleDelete = async (id) => {
  Modal.confirm({
    title: t('confirmDelete'),
    content: t('areYouSureDelete'),
    okText: t('yes'),
    okType: 'danger',
    cancelText: t('cancel'),
    onOk: async () => {
      try {
        const token = localStorage.getItem('token');

        await axios.delete(`${API_URL}/registrations/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        notification.success({
          message: t('deletedSuccess'),
          description: t('recordDeleted'),
        });

        fetchRegistrations();
      } catch (error) {
        console.error('Error deleting registration:', error);
        if (error.response?.data?.message === 'Cannot delete: Payments exist for this registration.') {
          notification.error({
            message: t('deleteError'),
            description: t('cannotDeleteDueToPayments'),
          });
        } else {
          notification.error({
            message: t('deleteError'),
            description: t('couldNotDelete'),
          });
        }
      }
    },
  });
};



  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const applyFilters = () => {
    let filtered = registrations;

    if (filters.fromDate) {
      filtered = filtered.filter(registration => new Date(registration.date) >= new Date(filters.fromDate));
    }

    if (filters.toDate) {
      filtered = filtered.filter(registration => new Date(registration.date) <= new Date(filters.toDate));
    }

    if (filters.paymentMethod) {
      filtered = filtered.filter(registration => registration.payment_method === filters.paymentMethod);
    }

    setFilteredRegistrations(filtered);
  };

  const handleModalOk = async () => {
    try {
      const values = form.getFieldsValue();
  
      const paymentAmount = parseFloat(values.payment_amount);
  
      const remainingAmount = selectedRegistration.final_price - paymentAmount;
  
      const paymentData = {
        invoiceno: selectedRegistration.invoiceno,
        student_id: selectedRegistration.student_id,
        pay_amount: paymentAmount,
        remaining_balance: Math.round(remainingAmount), // Ensure it's an integer
        payment_method: values.payment_method,
        memo: values.memo,
      };
      
  
      const token = localStorage.getItem('token');
  
      await axios.post(`${API_URL}/payment`, paymentData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      notification.success({
        message: t('paymentConfirmed'),
        description: t('Payment has been confirmed!'),
      });
  
      setIsPaymentModalOpen(false);
      form.resetFields();
      fetchRegistrations();
    } catch (error) {
      console.error('Error in handleModalOk:', error);
  
      if (error.response) {
        // Log detailed API error response
        console.error('API Error Response:', error.response.data);
      }
  
      notification.error({
        message: t('error'),
        description: t('payment_log_error'),
      });
    }
  };
  

  const openPaymentModal = (registration) => {
    setSelectedRegistration(registration);
    setIsPaymentModalOpen(true);
  };
  
  const openInvoiceModal = (transaction) => {
    setSelectedTransaction(transaction);
    setIsInvoiceModalOpen(true);
  };
  
  const handleInvoiceModalClose = () => {
    setIsInvoiceModalOpen(false); // Close the modal
    setSelectedTransaction(null); // Clear the selected transaction data
  };
  
  const calculateTotals = () => {
    return filteredRegistrations.reduce(
      (totals, registration) => {
        totals.price += parseFloat(registration.price) || 0;
        totals.discount += parseFloat(registration.discount) || 0;
        totals.vat += parseFloat(registration.vat) || 0;
        totals.finalPrice += parseFloat(registration.final_price) || 0;
        return totals;
      },
      { price: 0, discount: 0, vat: 0, finalPrice: 0 }
    );
  };

  const totals = calculateTotals();
  const csvData = [
    ...filteredRegistrations.map(reg => ({
      'INVOICE NO.': reg.invoiceno.replace('INV', ''),
      'DATE': moment(reg.date).format('DD MMM YYYY'),
      'STUDENT': reg.student_name,
      'COURSE': reg.class_code,
      'COURSE PRICE': reg.price,
      'INVENTORY PRICE': reg.inventory_price || 0,
      'DISCOUNT': reg.discount || 0,
      'VAT': reg.vat || 0,
      'TOTAL': reg.final_price,
      'REMAINING': reg.remaining_balance > 0 ? `(${reg.remaining_balance})` : 0
    })),
    {
      'INVOICE NO.': 'TOTAL',
      'DATE': '',
      'STUDENT': '',
      'COURSE': '',
      'COURSE PRICE': totals.price,
      'INVENTORY PRICE': filteredRegistrations.reduce((sum, reg) => sum + (parseFloat(reg.inventory_price) || 0), 0),
      'DISCOUNT': totals.discount,
      'VAT': totals.vat,
      'TOTAL': totals.finalPrice,
      'REMAINING': filteredRegistrations.reduce((sum, reg) => sum + (parseFloat(reg.remaining_balance) || 0), 0)
    }
  ];


  const columns = [
    {
      title: t('invoiceNo'),
      dataIndex: 'invoiceno',
      key: 'invoiceno',
      render: (text, record) => (
        <Button type="link" onClick={() => openInvoiceModal(record)}>
          {text.replace('INV', '')}
        </Button>
      ),
    },
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      render: formatDate,
    },
    {
      title: t('student'),
      dataIndex: 'student_name',
      key: 'student_name',
    },
    {
      title: t('course'),
      dataIndex: 'class_code',
      key: 'class_code',
    },
    {
      title: t('course price'),
      dataIndex: 'price',
      key: 'price',
      render: formatNumber,
    },
    {
      title: t('inventory price'),
      dataIndex: 'inventory_price',
      key: 'inventory_price',
      render: (text, record) => formatNumber(record.inventory_price, i18n.language),
    },
    {
      title: t('discount'),
      dataIndex: 'discount',
      key: 'discount',
      render: formatNumber,
    },
    {
      title: t('VAT'),
      dataIndex: 'vat',
      key: 'vat',
      render: formatNumber,
    },
    {
      title: t('total'),
      dataIndex: 'final_price',
      key: 'final_price',
      align: 'right',
      render: (value) => (
        <span style={{ fontWeight: 'bold', color: '#1890ff' }}>
          {formatNumber(value)}
        </span>
      ),
    },
    {
      title: t('remaining'),
      dataIndex: 'remaining_balance',
      key: 'remaining_balance',
      render: (balance) =>
        balance > 0 ? (
          <span style={{ fontWeight: 'bold', color: 'red' }}>({formatNumber(Math.abs(balance))})</span> // Wrap the balance in parentheses
        ) : (
          '0'
        ),
    },
    {
      title: t('pay'),
      key: 'pay',
      render: (_, record) => {
        const isDisabled = record.remaining_balance <= 0;
        return (
          <Button 
            onClick={() => openPaymentModal(record)} 
            icon={<FontAwesomeIcon icon={faDollarSign} />}
            disabled={isDisabled}
            style={{ opacity: isDisabled ? 0.5 : 1 }}
          />
        );
      },
    },
    {
      title: t('actions'),
      key: 'actions',
      render: (_, record) => (
        <Button 
          type="primary" 
          danger  
          icon={<DeleteOutlined />} 
          onClick={() => handleDelete(record.id)} 
        />
      ),
    }     
  ];

  return (
    <div className="accounting-container">
      <Sidebar />
      <Header />
      <h1>{t('accounting')}</h1>

      {/* Ant Design Tabs with `items` prop */}
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            key: "1",
            label: t('invoice'),
            children: (
              <>
                <div className="filter-container">
                  <label>
                    {t('fromDate')}:
                    <input
                      type="date"
                      name="fromDate"
                      value={filters.fromDate || ""}
                      onChange={handleFilterChange}
                    />
                  </label>
                  <label>
                    {t('toDate')}:
                    <input
                      type="date"
                      name="toDate"
                      value={filters.toDate || ""}
                      onChange={handleFilterChange}
                    />
                  </label>
                  <label>
                    {t('paymentMethod')}:
                    <select
                      name="paymentMethod"
                      value={filters.paymentMethod || ""}
                      onChange={handleFilterChange}
                    >
                      <option value="">{t('all')}</option>
                      {paymentMethods.map((method) => (
                        <option key={method} value={method}>
                          {method}
                        </option>
                      ))}
                    </select>
                  </label>
                  <button onClick={applyFilters} className="filter-button">
                    {t('applyFilters')}
                  </button>
                  <CSVLink
                    data={csvData}
                    filename={"registrations.csv"}
                    target="_blank"
                    headers={[
                      { label: 'INVOICE NO.', key: 'INVOICE NO.' },
                      { label: 'DATE', key: 'DATE' },
                      { label: 'STUDENT', key: 'STUDENT' },
                      { label: 'COURSE', key: 'COURSE' },
                      { label: 'COURSE PRICE', key: 'COURSE PRICE' },
                      { label: 'INVENTORY PRICE', key: 'INVENTORY PRICE' },
                      { label: 'DISCOUNT', key: 'DISCOUNT' },
                      { label: 'VAT', key: 'VAT' },
                      { label: 'TOTAL', key: 'TOTAL' },
                      { label: 'REMAINING', key: 'REMAINING' }
                    ]}
                  >
                    <Button style={{ marginLeft: '16px' }} icon={<FontAwesomeIcon icon={faFileExcel} />}>
                      {t('exportCSV')}
                    </Button>
                  </CSVLink>
                </div>

                <Table
                  dataSource={filteredRegistrations}
                  columns={columns}
                  scroll={{ x: true }}
                  pagination={{
                    pageSize: 50,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                  }}
                  rowKey="invoiceno"
                />
              </>
            ),
          },
          {
            key: "2",
            label: t('billingHistory'),
            children: <PaymentDetails />,
          },
          {
            key: "3",
            label: t('Add Expense'),
            children: <AddExpense />,
          },
          {
            key: "4",
            label: t('Expense Report'),
            children: <ExpenseReport />,
          },
        ]}
      />

      {/* Invoice Modal */}
      {selectedTransaction && (
        <InvoiceModal
          visible={isInvoiceModalOpen}
          onClose={handleInvoiceModalClose}
          registration={selectedTransaction}
        />
      )}

      {/* Payment Modal */}
      <Modal
        title={t('makePayment')}
        open={isPaymentModalOpen}
        onOk={handleModalOk}
        onCancel={() => setIsPaymentModalOpen(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="payment_amount" label={t('paymentAmount')} rules={[{ required: true }]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item name="payment_method" label={t('paymentMethod')} rules={[{ required: true }]}>
            <Select>
              <Option value="cash">{t('Cash')}</Option>
              <Option value="credit_card">{t('Credit Card')}</Option>
              <Option value="bank_transfer">{t('Bank Transfer')}</Option>
            </Select>
          </Form.Item>
          <Form.Item name="memo" label={t('memo')}>
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};


export default withRoleAccess(Accounting, ['superadmin']);
