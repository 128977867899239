import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import { 
  Layout, Card, Button, Checkbox, Select, Tabs, Input, Space, 
  Divider, Typography, Row, Col, Menu, message, Spin, Badge
} from 'antd';
import { 
  DeleteOutlined, ShoppingCartOutlined, 
  UserOutlined, DollarOutlined
} from '@ant-design/icons';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import CurrentInventory from '../Inventory/CurrentInventory';
import InventoryPage from '../Inventory/InventoryPage';
import InventoryReport from '../Inventory/InventoryReport';
import './Sales.css';

const { Option } = Select;
const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Sider, Content } = Layout;

const SalesReport = lazy(() => import('./SalesReport'));

const ModernPOS = () => {
  const [activeTab, setActiveTab] = useState('pos');
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [items, setItems] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [vatChecked, setVatChecked] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [categories] = useState([
    'All', 'TEST', 'IELTS Book', 'TOEFL Book', 'GED Book', 
    'IGCSE Book', 'Other Book', 'Equipment', 'Others'
  ]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const formatPrice = (price) => Number(price).toFixed(2);

  // API Calls
  const fetchStudents = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.get(`${API_URL}/students`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setStudents(data.students || []);
    } catch (error) {
      message.error('Failed to fetch students');
    } finally {
      setLoading(false);
    }
  }, [API_URL]);

  const fetchItems = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.get(`${API_URL}/inventory`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setItems(data.filter(item => item.status === 'active'));
    } catch (error) {
      message.error('Failed to fetch items');
    } finally {
      setLoading(false);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchStudents();
    fetchItems();
  }, [fetchStudents, fetchItems]);

  // Cart Management
  const addToCart = (item) => {
    const existingItem = cartItems.find(i => i.inventory_id === item.inventory_id);
    if (existingItem) {
      setCartItems(cartItems.map(i => 
        i.inventory_id === item.inventory_id 
          ? { ...i, quantity: i.quantity + 1 }
          : i
      ));
    } else {
      setCartItems([...cartItems, { ...item, quantity: 1 }]);
    }
    message.success(`${item.item_name} added to cart`);
  };

  const removeFromCart = (id) => {
    setCartItems(cartItems.filter(item => item.inventory_id !== id));
  };

  const updateQuantity = (id, quantity) => {
    setCartItems(cartItems.map(item => 
      item.inventory_id === id ? { ...item, quantity } : item
    ));
  };

  // Calculations
  const subtotal = cartItems.reduce((sum, item) => 
    sum + (item.price_per_unit * item.quantity), 0);
  const vat = vatChecked ? (subtotal - discount) * 0.07 : 0;
  const total = subtotal - discount + vat;

  // Purchase Handler
  const handlePurchase = async () => {
    if (!selectedStudent) return message.warning('Please select a student');
    if (!cartItems.length) return message.warning('Cart is empty');
    if (!paymentMethod) return message.warning('Please select payment method');

    const saleData = {
      student_id: selectedStudent,
      items: cartItems,
      discount,
      vat,
      payment_method: paymentMethod,
    };

    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}/sales/log-sale`, saleData, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      confirmAlert({
        title: 'Success',
        message: 'Purchase completed successfully!',
        buttons: [{ label: 'OK', onClick: () => resetCart() }]
      });
    } catch (error) {
      message.error('Purchase failed');
    } finally {
      setLoading(false);
    }
  };

  const resetCart = () => {
    setSelectedStudent(null);
    setCartItems([]);
    setDiscount(0);
    setVatChecked(false);
    setPaymentMethod(null);
  };

  // Render Components
  const POSLayout = () => (
    <Layout className="pos-container">
      <Sider width={200} className="category-sider">
        <Menu
          mode="inline"
          selectedKeys={[selectedCategory]}
          onClick={({ key }) => setSelectedCategory(key)}
          items={categories.map(cat => ({
            key: cat,
            label: cat,
          }))}
        />
      </Sider>
      
      <Content className="items-content">
        <Row gutter={[16, 16]}>
          {items.filter(item => selectedCategory === 'All' || item.category === selectedCategory)
            .map(item => (
              <Col xs={24} sm={12} md={8} lg={6} key={item.inventory_id}>
                  <Card
                    hoverable
                    className="item-card"
                    onClick={() => addToCart(item)}
                    cover={
                      <div className="item-image-placeholder">
                        <ShoppingCartOutlined style={{ fontSize: 24, color: '#bfbfbf' }} />
                      </div>
                    }
                  >
                    <div className="item-content">

                      <Text strong className="item-name" ellipsis={{ tooltip: item.item_name }}>
                        {item.item_name}
                      </Text>
                      <div>
                      <Text className="item-description" ellipsis={{ tooltip: item.item_description }}>
                        {item.item_description}
                      </Text></div>
                      <div className="item-footer">
                        <Text type="success" strong>{formatPrice(item.price_per_unit)} THB</Text>
                        <Badge
                        count={item.category}
                        style={{
                          backgroundColor: '#95101e',
                          color: '#fff',
                          fontSize: 12,
                          top: 10,
                        }}
                      />
                      </div>
                    </div>
                  </Card>
                </Col>
            ))}
        </Row>
      </Content>

      <Sider width={350} className="cart-sider">
        <Card className="cart-card">
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Select
              showSearch
              placeholder="Select Customer"
              value={selectedStudent}
              onChange={setSelectedStudent}
              suffixIcon={<UserOutlined />}
              style={{ width: '100%' }}
            >
              {students.map(student => (
                <Option key={student.userid} value={student.userid}>
                  {student.nickname} {student.firstname} {student.lastname}
                </Option>
              ))}
            </Select>

            <div className="cart-items">
              {cartItems.map(item => (
                <div key={item.inventory_id} className="cart-item">
                  <Space>
                    <Text>{item.item_name}</Text>
                    <Input
                      type="number"
                      min={1}
                      value={item.quantity}
                      onChange={e => updateQuantity(item.inventory_id, parseInt(e.target.value))}
                      style={{ width: 60 }}
                    />
                    <Text>{formatPrice(item.price_per_unit * item.quantity)} THB</Text>
                    <Button
                      type="text"
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => removeFromCart(item.inventory_id)}
                    />
                  </Space>
                </div>
              ))}
            </div>

            <Divider />

            <Space direction="vertical" style={{ width: '100%' }}>
              <div className="summary-line">
                <Text>Subtotal</Text>
                <Text>{formatPrice(subtotal)} THB</Text>
              </div>
              <div className="summary-line">
                <Text>Discount</Text>
                <Input
                  value={discount}
                  onChange={e => setDiscount(parseFloat(e.target.value) || 0)}
                  style={{ width: 100 }}
                  suffix="THB"
                />
              </div>
              <div className="summary-line">
                <Checkbox checked={vatChecked} onChange={e => setVatChecked(e.target.checked)}>
                  VAT (7%)
                </Checkbox>
                <Text>{formatPrice(vat)} THB</Text>
              </div>
              <div className="summary-line total">
                <Title level={4}>Total</Title>
                <Title level={4}>{formatPrice(total)} THB</Title>
              </div>

              <Select
                placeholder="Payment Method"
                value={paymentMethod}
                onChange={setPaymentMethod}
                style={{ width: '100%' }}
              >
                <Option value="cash">Cash</Option>
                <Option value="QR">QR Code</Option>
                <Option value="credit_card">Credit Card</Option>
                <Option value="debit_card">Debit Card</Option>
              </Select>

              <Button
                type="primary"
                size="large"
                block
                icon={<DollarOutlined />}
                onClick={handlePurchase}
                disabled={!cartItems.length || !selectedStudent || !paymentMethod}
                loading={loading}
              >
                Process Payment
              </Button>
            </Space>
          </Space>
        </Card>
      </Sider>
    </Layout>
  );

  return (
    <Layout className="pos-system">
      <Sidebar />
      <Layout>
        <Header />
        <Content>
          <Tabs activeKey={activeTab} onChange={setActiveTab}>
            <TabPane tab={<><ShoppingCartOutlined /> POS</>} key="pos">
              {loading ? <Spin size="large" /> : <POSLayout />}
            </TabPane>
            <TabPane tab="Sales Report" key="salesReport">
              <Suspense fallback={<Spin size="large" />}>
                <SalesReport />
              </Suspense>
            </TabPane>
            <TabPane 
          tab="Current Inventory" 
          key="currentInventory"
        >
          {activeTab === 'currentInventory' && <CurrentInventory />}
        </TabPane>

        <TabPane 
          tab="Inventory Report" 
          key="report"
        >
          {activeTab === 'report' && <InventoryReport />}
        </TabPane>
        <TabPane 
          tab="Add Inventory" 
          key="add"
        >
          {activeTab === 'add' && <InventoryPage />}
        </TabPane>
          </Tabs>
        </Content>
      </Layout>
    </Layout>
  );
};

export default withRoleAccess(ModernPOS, ['superadmin', 'admin']);