import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { 
  Button, 
  Input, 
  Table, 
  Modal, 
  message, 
  Spin, 
  Tag, 
  Space, 
  Form, 
  Select, 
  InputNumber,
  Divider,
  Card,
  Tooltip
} from 'antd';
import { 
  DeleteOutlined, 
  EditOutlined, 
  ExclamationCircleOutlined,
  ScheduleOutlined,
  BookOutlined,
  UserOutlined,
  DollarOutlined,
  TagOutlined
} from '@ant-design/icons';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import ScheduleModal from './ScheduleModal';
import SubjectGroupModal from './SubjectGroupModal'; // Import the new component
import './Classes.css';

const { confirm } = Modal;
const { Option } = Select;

const FilterTaskTable = () => {
  const { t } = useTranslation();
  const [classGroups, setClassGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [popupData, setPopupData] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editData, setEditData] = useState(null);
  const [registrationsCount, setRegistrationsCount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [subjectGroups, setSubjectGroups] = useState([]);
  const [form] = Form.useForm();
  
  // New state for the subject group modal
  const [subjectGroupModalVisible, setSubjectGroupModalVisible] = useState(false);
  const [currentClassGroup, setCurrentClassGroup] = useState(null);
  
  const itemsPerPage = 150;
  const API_URL = process.env.REACT_APP_API_URL;

  // Your existing utility functions...
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) return 'Invalid Date';
  
    return new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    }).format(date);
  };
  
  const formatMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, '0')} ${t('hrs')}`;
  };

  const processClassGroups = (data) => {
    const groupedData = data.reduce((acc, item) => {
      const existing = acc.find((entry) => entry.groupid === item.groupid);
  
      if (existing) {
        existing.price = existing.price
          ? `${existing.price}, ${item.price || ''}`.trim()
          : item.price;
        existing.classgroup_ids = existing.classgroup_ids
          ? `${existing.classgroup_ids}, ${item.classgroup_id}`
          : item.classgroup_id;
      } else {
        acc.push({
          ...item,
          classgroup_ids: item.classgroup_id,
        });
      }
  
      return acc;
    }, []);
  
    return groupedData;
  };
  
  // Fetch data functions
  const fetchSubjectGroups = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/subject-group/classgroup`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      const validGroups = response.data.filter(group => group.id !== null && group.id !== undefined);
      setSubjectGroups(validGroups);
    } catch (error) {
      console.error('Error fetching subject groups:', error);
      setError(t('fetchSubjectGroupsError'));
    }
  }, [API_URL, t]);
  
  const fetchClassGroups = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
  
      const response = await axios.get(`${API_URL}/class-groups`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      const processedData = processClassGroups(response.data);
      setClassGroups(processedData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching class groups:', error);
      setError(t('fetchClassGroupsError'));
      setLoading(false);
    }
  }, [API_URL, t]);
  
  const fetchRegistrationsCount = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/registrations/registrations-count`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRegistrationsCount(response.data);
    } catch (error) {
      console.error('Error fetching registrations count:', error);
      setError(t('fetchRegistrationsCountError'));
    }
  }, [API_URL, t]);

  useEffect(() => {
    fetchClassGroups();
    fetchRegistrationsCount();
    fetchSubjectGroups();
  }, [fetchClassGroups, fetchRegistrationsCount, fetchSubjectGroups]);

  // Action handlers
  const confirmDeleteClassGroup = (groupID) => {
    confirm({
      title: t('confirmDeleteClassGroup'),
      icon: <ExclamationCircleOutlined />,
      content: t('deleteClassGroupWarning'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk() {
        handleDeleteClassGroup(groupID);
      },
      onCancel() {
      },
    });
  };

  const handleDeleteClassGroup = async (groupID) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${API_URL}/class-groups/${groupID}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setClassGroups((prevClasses) => prevClasses.filter((cls) => cls.groupid !== groupID));
      message.success(t('classGroupDeleted'));
    } catch (error) {
      console.error('Error deleting class group:', error);
      message.error(t('deleteClassGroupError'));
    }
  };

  // New function to open the subject group modal
  const openSubjectGroupModal = (classGroup) => {
    setCurrentClassGroup(classGroup);
    setSubjectGroupModalVisible(true);
  };

  // New function to handle the success of updating the subject group
  const handleSubjectGroupUpdateSuccess = () => {
    fetchClassGroups(); // Refresh the class groups to reflect the updated subject group
  };

  const handleEditClick = (classGroup) => {
    
    const formData = {
      groupID: classGroup.groupid,
      class_code: classGroup.class_code,
      book_details_materials: classGroup.book_details_materials,
      isprivate: classGroup.isprivate.toString(),
      register_capacity: classGroup.register_capacity,
      price: classGroup.price,
      // No longer include subject_group here since we're handling it separately
    };
    
    
    form.setFieldsValue(formData);
    setEditData(formData);
    setEditModalVisible(true);
  };

  const handleSaveEdit = async () => {
    try {
      const values = await form.validateFields();
      const token = localStorage.getItem('token');
      
      const dataToSubmit = {
        ...values,
        isprivate: values.isprivate === 'true',
        groupID: editData.groupID,
      };
      
      await axios.put(`${API_URL}/class-groups/${editData.groupID}`, dataToSubmit, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      setEditModalVisible(false);
      fetchClassGroups(); // Refresh the class list
      message.success(t('classGroupUpdated'));
    } catch (error) {
      console.error('Error updating class group:', error);
      message.error(t('updateClassGroupError'));
    }
  };
  
  // Function to get the number of registered students for a class group
  const getRegisteredStudents = (groupId) => {
    const group = registrationsCount.find((rc) => rc.groupid === groupId);
    return group ? group.registered_students : 0;
  };

  const formatNumber = (number) => {
    if (!number) return "0.00";
    return parseFloat(number).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  // Add a new action specifically for subject group
  const handleSubjectGroupClick = (record) => {
    openSubjectGroupModal(record);
  };

  const columns = [
    {
      title: t('classCode'),
      dataIndex: 'class_code',
      key: 'class_code',
      render: (text, record) => (
        <Link to={`/classdetails/${record.groupid}`} style={{ fontWeight: 'bold' }}>
          {text}
        </Link>
      ),
    },
    {
      title: t('schedule'),
      key: 'schedule',
      render: (_, record) => (
        <Button 
          icon={<ScheduleOutlined />}
          onClick={() => setPopupData(record.classes)}
        >
          {t('schedule')}
        </Button>
      ),
    },
    { title: t('startDate'), dataIndex: 'start_date', key: 'start_date', render: formatDate },
    { title: t('endDate'), dataIndex: 'end_date', key: 'end_date', render: formatDate },
    { title: t('totalHours'), dataIndex: 'total_hours', key: 'total_hours', render: formatMinutesToHours },
    { title: t('note'), dataIndex: 'book_details_materials', key: 'book_details_materials' },
    {
      title: t('subjectGroup'),
      dataIndex: 'group_name',
      key: 'group_name',
      render: (groupName, record) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {groupName ? (
            <Tag color="cyan" icon={<TagOutlined />}>
              {groupName}
            </Tag>
          ) : (
            <span style={{ color: '#bfbfbf', fontStyle: 'italic' }}>
              {t('noSubjectGroup')}
            </span>
          )}
            <Button 
                type="primary"
                icon={<EditOutlined />} 
                onClick={() => handleSubjectGroupClick(record)}
                loading={loading}
              >
              </Button>
        </div>
      ),
    },
    {
      title: t('private'),
      dataIndex: 'isprivate',
      key: 'isprivate',
      render: (isPrivate) => (
        <Tag color={isPrivate ? 'blue' : 'green'}>
          {isPrivate ? t('private') : t('group')}
        </Tag>
      ),
    },
    { title: t('capacity'), dataIndex: 'register_capacity', key: 'register_capacity' },
    {
      title: t('Reg'),
      key: 'totalRegis',
      render: (_, record) => getRegisteredStudents(record.groupid),
    },
    {
      title: t('price'),
      key: 'price',
      render: (_, record) => {
        const hasBasePrice = record.price && record.price !== "0.00";
        const hasPackages = record.package_pricing && record.package_pricing.length > 0;
    
        return (
          <div>
            {/* Show base price if it exists and is not zero */}
            {hasBasePrice && (
              <div style={{ marginBottom: hasPackages ? '12px' : '0' }}>
                <span style={{ 
                  color: '#1890ff', // Blue color for base price
                  fontWeight: 500,
                  fontSize: '15px'
                }}>
                  {formatNumber(record.price)} {t('THB')}
                </span>
              </div>
            )}
            
            {/* Divider if both prices exist */}
            {hasBasePrice && hasPackages && (
              <div style={{
                margin: '8px 0',
                borderBottom: '1px dashed #d9d9d9',
                width: '100%'
              }} />
            )}
            
            {/* Show package pricing if available */}
            {hasPackages && (
              <ul style={{ 
                padding: 0, 
                margin: 0, 
                listStyleType: 'none',
                color: '#52c41a' // Green color for package prices
              }}>
                {record.package_pricing.map((pkg, index) => (
                  <li 
                    key={`${record.groupid}-${index}`} 
                    style={{ 
                      marginBottom: '4px',
                      fontSize: '14px'
                    }}
                  >
                    <strong style={{ color: '#262626' }}>{pkg.number_of_classes} {t('classes')}:</strong>{' '}
                    <span style={{ fontWeight: 500 }}>{formatNumber(pkg.price_per_package)} {t('THB')}</span>{' '}
                    <span style={{ color: '#8c8c8c' }}>({pkg.validity_months} {t('months')})</span>
                  </li>
                ))}
              </ul>
            )}
            
            {/* Show message if no price information is available */}
            {(!hasBasePrice && !hasPackages) && (
              <span style={{ color: '#bfbfbf', fontStyle: 'italic' }}>
                {t('noPriceAvailable')}
              </span>
            )}
          </div>
        );
      },
    },    
    {
      title: t('actions'),
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Tooltip title={t('edit')}>
            <Button 
              type="primary"
                shape="circle"
              icon={<EditOutlined />} 
              onClick={() => handleEditClick(record)} 
              ghost
            />
          </Tooltip>
          <Tooltip title={t('delete')}>
            <Button 
              danger
              type="primary"
                shape="circle"
              icon={<DeleteOutlined />} 
              onClick={() => confirmDeleteClassGroup(record.groupid)} 
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  
  return (
    <div>
      <Sidebar />
      <Header />
      <div className="manage-course-container">
        <Card style={{ marginBottom: '20px' }}>
          <Input
            placeholder={t('searchByClassCode')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ maxWidth: '400px' }}
            prefix={<BookOutlined style={{ color: '#bfbfbf' }} />}
            allowClear
          />
        </Card>

        <Spin spinning={loading}>
        <Table
            columns={columns}
            dataSource={classGroups}
            rowKey="groupid"
            pagination={{
              current: currentPage,
              pageSize: itemsPerPage,
              total: classGroups.length,
              onChange: (page) => setCurrentPage(page),
              showSizeChanger: false,
            }}
            scroll={{ x: 'max-content' }}
            bordered
          />
        </Spin>

        {/* Schedule Modal */}
        <ScheduleModal isVisible={!!popupData} onClose={() => setPopupData(null)} popupData={popupData} />

        {/* Subject Group Modal - Separated */}
        <SubjectGroupModal
          visible={subjectGroupModalVisible}
          onClose={() => setSubjectGroupModalVisible(false)}
          classGroupData={currentClassGroup}
          subjectGroups={subjectGroups}
          onSuccess={handleSubjectGroupUpdateSuccess}
          t={t}
          API_URL={API_URL}
        />

        {/* Edit Modal - Now without subject group field */}
        <Modal
          title={<><EditOutlined /> {t('editClassGroup')}</>}
          visible={editModalVisible}
          onCancel={() => setEditModalVisible(false)}
          footer={[
            <Button key="cancel" onClick={() => setEditModalVisible(false)}>
              {t('cancel')}
            </Button>,
            <Button key="submit" type="primary" onClick={handleSaveEdit}>
              {t('save')}
            </Button>
          ]}
          destroyOnClose
          maskClosable={false}
          width={600}
          centered
        >
          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
          >
            <Form.Item
              name="class_code"
              label={<Space><BookOutlined />{t('classCode')}</Space>}
              rules={[
                { required: true, message: t('pleaseEnterClassCode') }
              ]}
            >
              <Input placeholder={t('enterClassCode')} />
            </Form.Item>
            
            <Form.Item
              name="book_details_materials"
              label={<Space><BookOutlined />{t('note')}</Space>}
            >
              <Input.TextArea 
                rows={3}
                placeholder={t('Note')} 
              />
            </Form.Item>
            
            <Divider />
            
            {/* Subject Group field removed from main Edit form */}
            
            <Form.Item
              name="isprivate"
              label={<Space><UserOutlined />{t('classType')}</Space>}
              rules={[
                { required: true, message: t('pleaseSelectClassType') }
              ]}
            >
              <Select placeholder={t('selectClassType')}>
                <Option value="true">{t('private')}</Option>
                <Option value="false">{t('group')}</Option>
              </Select>
            </Form.Item>
            
            <Form.Item
              name="register_capacity"
              label={<Space><UserOutlined />{t('registerCapacity')}</Space>}
              rules={[
                { required: true, message: t('pleaseEnterCapacity') }
              ]}
            >
              <InputNumber 
                min={1} 
                placeholder={t('enterCapacity')}
                style={{ width: '100%' }}
              />
            </Form.Item>
            
            <Divider />
            
            <Form.Item
              name="price"
              label={<Space><DollarOutlined />{t('price')}</Space>}
              rules={[
                { required: true, message: t('pleaseEnterPrice') }
              ]}
            >
              <InputNumber
                min={0}
                step={0.01}
                precision={2}
                prefix="฿"
                style={{ width: '100%' }}
                placeholder={t('enterPrice')}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>

            <Form.Item hidden name="groupID">
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default withRoleAccess(FilterTaskTable, ['admin', 'superadmin']);