import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Table, Input, Select, Button, Modal, notification, Spin, Tag, Grid, Tooltip } from 'antd';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { ExclamationCircleOutlined, UserOutlined, InfoCircleOutlined } from '@ant-design/icons';
import '../Dashboard.css';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { confirm } = Modal;
const { useBreakpoint } = Grid;

// Role styling configuration
const roleStyles = {
  superadmin: {
    color: '#003366', // dark blue
    textColor: '#ffffff'
  },
  admin: {
    color: '#ff8c00', // orange
    textColor: '#ffffff'
  },
  teacher: {
    color: '#006400', // dark green
    textColor: '#ffffff'
  },
  student: {
    color: '#8b0000', // dark red
    textColor: '#ffffff'
  }
};

const UserControlPage = () => {
  const { t, i18n } = useTranslation();
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [roleFilter, setRoleFilter] = useState('');
  const [activeCounts, setActiveCounts] = useState({
    superadmin: { total: 0, active: 0 },
    admin: { total: 0, active: 0 },
    teacher: { total: 0, active: 0 },
    student: { total: 0, active: 0 },
    total: { total: 0, active: 0 },
  });
  const API_URL = process.env.REACT_APP_API_URL;
  const MAX_ACTIVE_USERS = {
    superadmin: 4,
    admin: 5,
    teacher: 45,
    student: 150,
  };
  const ITEMS_PER_PAGE = 50;
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return t('neversignin');
    const date = new Date(dateTimeString);
    if (isNaN(date.getTime())) return t('neversignin');
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat(i18n.language || 'en-UK', options).format(date).toUpperCase();
    const today = new Date();
    const diffTime = Math.abs(today - date);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return `${formattedDate} (${diffDays} ${t('daysAgo')})`;
};


  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('token');
      const [usersResponse, lastSigninResponse] = await Promise.all([
        axios.get(`${API_URL}/users`, { headers: { Authorization: `Bearer ${token}` } }),
        axios.get(`${API_URL}/users/last-signin`, { headers: { Authorization: `Bearer ${token}` } }),
      ]);
      const lastSigninMap = lastSigninResponse.data.reduce((acc, curr) => {
        acc[curr.user_id] = curr.last_signin;
        return acc;
      }, {});
      const usersWithLastSignin = usersResponse.data.map(user => ({
        ...user,
        lastSignin: lastSigninMap[user.userid] || t('neversignin'),
      }));
      const sortedUsers = usersWithLastSignin.sort((a, b) => a.userid - b.userid);
      setUsers(sortedUsers);
      setFilteredUsers(sortedUsers);
      calculateCounts(sortedUsers);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      handleTokenError(error);
      setLoading(false);
    }
  };

  const calculateCounts = (users) => {
    const counts = {
      superadmin: { total: 0, active: 0 },
      admin: { total: 0, active: 0 },
      teacher: { total: 0, active: 0 },
      student: { total: 0, active: 0 },
      total: { total: 0, active: 0 },
    };
    users.forEach(user => {
      if (counts.hasOwnProperty(user.role)) {
        counts[user.role].total += 1;
        if (user.status === 'active') counts[user.role].active += 1;
      }
      counts.total.total += 1;
      if (user.status === 'active') counts.total.active += 1;
    });
    setActiveCounts(counts);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const results = users.filter(user => {
      const matchesSearchTerm = `${user.firstname} ${user.lastname}`.toLowerCase().includes(searchTerm.toLowerCase())
        || user.email.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesRole = roleFilter ? user.role === roleFilter : true;
      return matchesSearchTerm && matchesRole;
    });
    setFilteredUsers(results);
    setCurrentPage(1);
  }, [searchTerm, roleFilter, users]);

  const updateUserStatus = (userId, status, role) => {
    if (status === 'active' && activeCounts[role].active >= MAX_ACTIVE_USERS[role]) {
      notification.warning({
        message: t('alert'),
        description: t('maxLimitReached', { role: t(role), limit: MAX_ACTIVE_USERS[role] }),
      });
      return;
    }

    confirm({
      title: t('confirmStatusChange'),
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          const token = localStorage.getItem('token');
          await axios.put(`${API_URL}/users/${userId}/status`, { status }, {
            headers: { Authorization: `Bearer ${token}` },
          });
          fetchUsers();
        } catch (error) {
          console.error('Error updating user status:', error);
          handleTokenError(error);
        }
      },
      onCancel() {},
    });
  };

  const resetPassword = (userId) => {
    confirm({
      title: t('confirmResetPassword'),
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.put(`${API_URL}/users/${userId}/reset-password`, {}, {
            headers: { Authorization: `Bearer ${token}` },
          });
          notification.success({
            message: t('temporaryPasswordTitle'),
            description: t('temporaryPassword', { userId, tempPassword: response.data.tempPassword }),
          });
        } catch (error) {
          console.error('Error resetting password:', error);
          handleTokenError(error);
        }
      },
      onCancel() {},
    });
  };

  const handleTokenError = (error) => {
    if (error.response && error.response.status === 401) {
      refreshToken().then(() => fetchUsers()).catch(err => console.error('Token refresh failed:', err));
    }
  };

  const refreshToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    const response = await axios.post(`${API_URL}/refresh-token`, { refreshToken });
    localStorage.setItem('token', response.data.accessToken);
  };

  const screens = useBreakpoint();

  // Responsive role count box styles
  const getRoleBoxStyle = (role) => ({
    padding: screens.xs ? '10px' : '15px',
    borderRadius: '8px',
    backgroundColor: roleStyles[role]?.color,
    color: roleStyles[role]?.textColor,
    marginRight: screens.xs ? '8px' : '15px',
    marginBottom: screens.xs ? '8px' : '15px',
    minWidth: screens.xs ? '160px' : '200px',
    width: screens.xs ? '100%' : 'auto',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    fontSize: screens.xs ? '14px' : '16px'
  });

  // Responsive table columns
  const getResponsiveColumns = () => {
    const baseColumns = [
      {
        title: t('userId'),
        dataIndex: 'userid',
        key: 'userid',
        width: screens.xs ? 70 : 'auto'
      },
      {
        title: screens.xs ? '' : t('username'),
        dataIndex: 'firstname',
        key: 'firstname',
        render: (text, user) => (
          <Tooltip 
            title={
              <div>
                <p><strong>{t('address')}:</strong> {user.address}</p>
                <p><strong>{t('phone')}:</strong> {user.phone}</p>
              </div>
            }
            placement="topLeft"
            overlayStyle={{ 
              backgroundColor: 'rgba(255, 255, 255, 0.8)', 
              color: 'rgba(0, 0, 0, 0.85)'
            }}
            overlayInnerStyle={{
              backgroundColor: 'rgba(255, 255, 255, 0.8)'
            }}
          >
            <span>
              {screens.xs && <UserOutlined style={{ marginRight: 5 }} />}
              {`${user.firstname} ${user.lastname}`}
            </span>
          </Tooltip>
        )
      },
      {
        title: t('userRole'),
        dataIndex: 'role',
        key: 'role',
        render: (role) => (
          <Tag
            color={roleStyles[role]?.color}
            style={{
              color: roleStyles[role]?.textColor,
              padding: screens.xs ? '2px 4px' : '4px 8px',
              fontSize: screens.xs ? '12px' : '14px',
              border: 'none'
            }}
          >
            {t(role)}
          </Tag>
        )
      },
      {
        title: t('status'),
        dataIndex: 'status',
        key: 'status',
        width: screens.xs ? 100 : 'auto',
        render: (status, user) => (
          <Select
            value={status}
            onChange={(value) => updateUserStatus(user.userid, value, user.role)}
            className={status === 'active' ? 'select-active' : 'select-inactive'}
            style={{ width: screens.xs ? '90px' : '120px' }}
            size={screens.xs ? 'small' : 'middle'}
          >
            <Option value="active">{t('active')}</Option>
            <Option value="inactive">{t('inactive')}</Option>
          </Select>
        )
      }
    ];
  
    // Add additional columns for larger screens
    if (!screens.xs) {
      baseColumns.splice(2, 0, 
        {
          title: t('nickname'),
          dataIndex: 'nickname',
          key: 'nickname'
        },
        {
          title: t('email'),
          dataIndex: 'email',
          key: 'email'
        },
        {
          title: t('lastSignin'),
          dataIndex: 'lastSignin',
          key: 'lastSignin',
          render: (value) => formatDateTime(value)
        }
      );
      
      // Add info icon with tooltip for mobile-hidden information
      baseColumns.push({
        title: t('info'),
        key: 'info',
        render: (text, user) => (
          <Tooltip 
            title={
              <div>
                <p><strong>{t('address')}:</strong> {user.address}</p>
                <p><strong>{t('phone')}:</strong> {user.phone}</p>
              </div>
            }
            overlayStyle={{ 
              backgroundColor: 'rgba(255, 255, 255, 0.8)', 
              color: 'rgba(0, 0, 0, 0.85)'
            }}
            overlayInnerStyle={{
              backgroundColor: 'rgba(255, 255, 255, 0.8)'
            }}
          >
            <InfoCircleOutlined style={{ color: '#1890ff', cursor: 'pointer' }} />
          </Tooltip>
        )
      });
      
      baseColumns.push({
        title: t('resetPassword'),
        key: 'resetPassword',
        render: (text, user) => (
          user.userid !== 1 && (
            <Button 
                onClick={() => resetPassword(user.userid)}
                size={screens.sm ? 'middle' : 'small'}
                danger
                type="primary"
                style={{
                    background: '#ff4d4f',  // Ant Design's default danger color
                    borderColor: '#ff4d4f',
                    color: 'white'
                }}
                >
                {t('resetPassword')}
                </Button>
          )
        )
      });
    }
  
    return baseColumns;
  };

  // Responsive role counts section
  const renderRoleCounts = () => (
    <Row gutter={[8, 8]} style={{ marginBottom: '24px' }}>
      {Object.keys(MAX_ACTIVE_USERS).map((role) => {
        const remainingUsers = MAX_ACTIVE_USERS[role] - activeCounts[role].active; // Calculate remaining users
        const getTagColor = (remaining) => {
          if (remaining <= 0) return 'red'; // No remaining users
          if (remaining <= MAX_ACTIVE_USERS[role] * 0.2) return 'orange'; // Less than 20% remaining
          return 'green'; // Sufficient remaining users
        };
  
        return (
          <Col xs={24} sm={12} md={6} key={role}>
            <div style={getRoleBoxStyle(role)}>
              <strong>{t(role)}</strong>
              <div style={{ marginTop: '8px' }}>
                {t('maxUser')}: {MAX_ACTIVE_USERS[role]}
              </div>
              <div>
                {t('activeUser')}: {activeCounts[role].active}
              </div>
              <div>
                {t('inactiveUser')}: {activeCounts[role].total - activeCounts[role].active}
              </div>
              {/* Add remaining user tag at the bottom */}
              <div
                style={{
                  marginTop: '12px',
                  paddingTop: '12px',
                  borderTop: '1px solid rgba(0,0,0,0.06)',
                  textAlign: 'center',
                }}
              >
                <Tag
                  color={getTagColor(remainingUsers)} // Use dynamic color based on remaining users
                  style={{
                    padding: '4px 8px',
                    fontSize: '14px',
                  }}
                >
                  {t('Remaining Users')}: {remainingUsers}
                </Tag>
              </div>
            </div>
          </Col>
        );
      })}
    </Row>
  );
  

  if (loading) return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Spin tip={t('loading')} />
    </div>
  );

  return (
    <div>
      <Sidebar />
      <Header />
      <div className="dashboard-content" style={{ padding: screens.xs ? '10px' : '24px' }}>
        <h1 style={{ fontSize: screens.xs ? '24px' : '32px', textAlign: screens.xs ? 'center' : 'left' }}>
          {t('userControlPage')}
        </h1>
        
        {renderRoleCounts()}
        
        <Row gutter={[16, 16]} style={{ marginBottom: '24px' }}>
          <Col xs={24} sm={12}>
            <Input
              placeholder={t('searchByName')}
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={24} sm={12}>
            <Select
              value={roleFilter}
              onChange={setRoleFilter}
              style={{ width: '100%' }}
              placeholder={t('ALL ROLES')}
            >
              <Option value="">{t('ALL ROLES')}</Option>
              {Object.keys(roleStyles).map(role => (
                <Option key={role} value={role}>
                  <Tag color={roleStyles[role].color} style={{ color: roleStyles[role].textColor }}>
                    {t(role)}
                  </Tag>
                </Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Table
          columns={getResponsiveColumns()}
          dataSource={filteredUsers}
          rowKey="userid"
          pagination={{
            current: currentPage,
            pageSize: ITEMS_PER_PAGE,
            total: filteredUsers.length,
            onChange: setCurrentPage,
            size: screens.xs ? 'small' : 'default',
            simple: screens.xs
          }}
          scroll={{ x: true }}
          size={screens.xs ? 'small' : 'middle'}
        />
      </div>
    </div>
  );
};

export default withRoleAccess(UserControlPage, ['superadmin', 'admin']);
