import React, { useState } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import moment from 'moment';
import { 
  Form, 
  Input, 
  Button,  
  message, 
  Row, 
  Col, 
  Card,
  Space
} from 'antd';
import { 
  MailOutlined, 
  LockOutlined,
  UserOutlined,
  IdcardOutlined,
  HomeOutlined,
  BookOutlined,
  PhoneOutlined,
  TeamOutlined,
  BankOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import SuccessModal from './SuccessModal';
import { useTranslation } from 'react-i18next';
import './CreateUserForm.css';

const { TextArea } = Input;

const initialFormData = {
  firstname: '',
  lastname: '',
  nickname: '',
  nationalid: '',
  date_of_birth: '',
  email: '',
  password: '',
  role: 'student',
  phone: '',
  address: '',
  currentEducationLevel: '',
  guardianContact: '',
  guardianname: '',
  schoolName: ''
};

function CreateStudentForm({ onClose }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState(initialFormData);
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const generateRandomPassword = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let password = '';
    for (let i = 0; i < 8; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    form.setFieldsValue({ password });
  };

  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "date_of_birth" ? new Date(value) : value, 
    }));
  };
  
  const handleSubmit = async (values) => {
    try {
      const token = localStorage.getItem('token');
      const formattedDate = values.date_of_birth || null;
      
      const formData = new FormData();
      // Add all text fields
      Object.entries(values).forEach(([key, value]) => {
        if (key === 'date_of_birth') {
          formData.append(key, formattedDate);
        } else {
          formData.append(key, value);
        }
      });
      
      // Add role field
      formData.append('role', 'student');

      await axios.post(`${API_URL}/users/create`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      message.success(t('form.student_created_success'));
      form.resetFields();
      setShowConfirmModal(false);
    } catch (error) {
      if (error.response && error.response.data.message === "Email address is already in use") {
        message.error(t('form.email_already_in_use'));
      } else {
        message.error(t('form.create_user_error'));
      }
    }
  };

  const showConfirmationModal = (values) => {
    setFormData({
      ...values,
      date_of_birth: values.date_of_birth || ''
    });
    setShowConfirmModal(true);
  };

  return (
    <div className="form-page">
      <Sidebar />
      <Header />
      <div className="form-content">
        <Button type="button" onClick={() => onClose()} style={{ marginBottom: '16px' }}>
          <ArrowLeftOutlined /> {t('back')}
        </Button>

        <Card title={t('form.add_new_student')} bordered={false}>
          <Form 
            form={form}
            layout="vertical" 
            initialValues={initialFormData}
            onFinish={showConfirmationModal}
            scrollToFirstError
          >
            
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item 
                  name="firstname"
                  label={t('form.first_name')} 
                  rules={[{ required: true, message: t('form.required_field') }]}
                >
                  <Input placeholder={t('form.first_name')} prefix={<UserOutlined />} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item 
                  name="lastname"
                  label={t('form.last_name')} 
                  rules={[{ required: true, message: t('form.required_field') }]}
                >
                  <Input placeholder={t('form.last_name')} prefix={<UserOutlined />} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item 
                  name="nickname"
                  label={t('form.nickname')} 
                  rules={[{ required: true, message: t('form.required_field') }]}
                >
                  <Input placeholder={t('form.nickname')} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item 
                  name="nationalid"
                  label={t('form.national_id')}
                  rules={[{ required: true, message: t('form.required_field') }]}
                >
                  <Input prefix={<IdcardOutlined />} placeholder={t('form.national_id')} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item 
                  name="date_of_birth"
                  label={t('form.date_of_birth')}
                >
                  <input
                    type="date"
                    value={formData.date_of_birth ? moment(formData.date_of_birth).format("YYYY-MM-DD") : ""}
                    onChange={(e) => handleChange("date_of_birth", e.target.value)}
                    style={{
                      width: "100%",
                      padding: "8px",
                      border: "1px solid #d9d9d9",
                      borderRadius: "4px",
                      fontSize: "14px",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item 
                  name="email"
                  label={t('form.email')}
                  rules={[
                    { required: true, message: t('form.required_field') },
                    { type: 'email', message: t('form.invalid_email') }
                  ]}
                >
                  <Input prefix={<MailOutlined />} placeholder={t('form.email')} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item 
                  name="password"
                  label={t('form.temporary_password')}
                  rules={[{ required: true, message: t('form.required_field') }]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    visibilityToggle={{
                      visible: showPassword,
                      onVisibleChange: setShowPassword,
                    }}
                    placeholder={t('form.temporary_password')}
                  />
                </Form.Item>
                <Button onClick={generateRandomPassword} type="link">
                  {t('form.generate_password')}
                </Button>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item 
                  name="phone"
                  label={t('form.phone')}
                  rules={[{ required: true, message: t('form.required_field') }]}
                >
                  <Input prefix={<PhoneOutlined />} placeholder={t('form.phone')} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item 
              name="address"
              label={<Space><HomeOutlined /> {t('form.address')}</Space>}
              rules={[{ required: true, message: t('form.required_field') }]}
            >
              <TextArea rows={3} placeholder={t('form.address')} />
            </Form.Item>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item 
                  name="currentEducationLevel"
                  label={<Space><BookOutlined /> {t('form.current_education_level')}</Space>}
                  rules={[{ required: false, message: t('form.required_field') }]}
                >
                  <Input placeholder={t('form.education_level')} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item 
                  name="schoolName"
                  label={<Space><BankOutlined /> {t('form.school_name')}</Space>}
                  rules={[{ required: false, message: t('form.required_field') }]}
                >
                  <Input placeholder={t('school_name')} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item 
                  name="guardianName"
                  label={<Space><TeamOutlined /> {t('parent name')}</Space>}
                  rules={[{ required: false, message: t('form.required_field') }]}
                >
                  <Input placeholder={t('form.enter_guardian_name')} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item 
                  name="guardianContact"
                  label={<Space><PhoneOutlined /> {t('form.parent_phone')}</Space>}
                  rules={[{ required: false, message: t('form.required_field') }]}
                >
                  <Input placeholder={t('form.parent_phone')} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item style={{ marginTop: 24 }}>
              <Button type="primary" htmlType="submit" size="large">
                {t('form.create_student')}
              </Button>
            </Form.Item>
          </Form>
        </Card>

        {showConfirmModal && (
          <SuccessModal 
            details={formData} 
            onClose={() => setShowConfirmModal(false)} 
            onConfirm={() => {
              handleSubmit(formData);
              setShowConfirmModal(false);
            }} 
          />
        )}
      </div>
    </div>
  );
}

export default withRoleAccess(CreateStudentForm, ['admin', 'superadmin']);