import React, { useState, useEffect } from 'react';
import { 
  Table, message, Layout, Button, Select, Modal, Form, Input, 
  InputNumber, Space, Popconfirm, Card, Row, Col, Statistic, 
  Tag, Typography, DatePicker 
} from 'antd';
import axios from 'axios';
import { 
  EditOutlined, DeleteOutlined, DownloadOutlined, 
  ReloadOutlined, DollarOutlined, CalendarOutlined, 
  FileTextOutlined, TagOutlined, FileExcelOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';
import withRoleAccess from '../../hoc/withRoleAccess';
import 'dayjs/locale/th';

const { Content } = Layout;
const { Option } = Select;
const { Item: FormItem } = Form;
const { Title, Text } = Typography;

const API_URL = process.env.REACT_APP_API_URL;

// Define category colors for consistent tag display
const CATEGORY_COLORS = {
  postoffice: 'blue',
  transport: 'green',
  gas: 'orange',
  laundry: 'purple',
  utilities: 'cyan',
  return: 'red',
  facebook: '#3b5998',
  tiktok: '#000000',
  others: 'default',
  commission: 'gold',
  maintenance: 'lime',
  renovation: 'volcano',
  'blanket return': 'red',
  'key return': 'red',
  refund: 'red',
  'travel expense': 'purple',
  'staff meals and drinks': 'geekblue',
  'stationery supplies': 'cyan',
  'stationery equipment': 'cyan',
  'teaching materials and equipment': 'blue',
  'it equipment': 'magenta',
  'learning materials and equipment': 'blue'
};

const ExpenseReport = () => {
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredExpenses, setFilteredExpenses] = useState([]);
  const [selectedMonthYear, setSelectedMonthYear] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingExpense, setEditingExpense] = useState(null);
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);
  const [summaryStats, setSummaryStats] = useState({
    totalAmount: 0,
    expenseCount: 0,
    topCategory: '',
    latestExpense: ''
  });

  const { t } = useTranslation();

  // Format date in standard format, no localization needed
  const formatDate = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    });
  };

  const getCurrentMonthYear = () => {
    return dayjs().format('MM-YYYY');
  };

  // Fetch expenses
  useEffect(() => {
    fetchExpenses();
  }, []);

  const fetchExpenses = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/expense`);
      setExpenses(response.data);
      setFilteredExpenses(response.data);
      message.success(t('Data loaded successfully'));
    } catch (error) {
      message.error(t('load_error'));
    } finally {
      setLoading(false);
    }
  };

  const formatMonthYear = (monthYear) => {
    if (!monthYear) return '';
    return dayjs(monthYear + '-01').format('MM-YYYY');
  };
  
  // Update summary statistics whenever filtered expenses change
  useEffect(() => {
    if (!filteredExpenses.length) {
      setSummaryStats({
        totalAmount: 0,
        expenseCount: 0,
        topCategory: '-',
        latestExpense: '-'
      });
      return;
    }
    
    // Calculate total amount
    const totalAmount = filteredExpenses.reduce((sum, expense) => sum + Number(expense.amount || 0), 0);
    
    // Get expense count
    const expenseCount = filteredExpenses.length;
    
    // Find top category
    const categoryCount = {};
    filteredExpenses.forEach(expense => {
      const category = expense.category_name || 'unknown';
      categoryCount[category] = (categoryCount[category] || 0) + 1;
    });
    
    const topCategory = Object.entries(categoryCount)
      .sort((a, b) => b[1] - a[1])
      .map(entry => entry[0])[0] || '-';
    
    // Get latest expense date
    const latestDate = filteredExpenses
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .map(expense => formatDate(expense.date))[0] || '-';
    
    setSummaryStats({
      totalAmount,
      expenseCount,
      topCategory,
      latestExpense: latestDate
    });
  }, [filteredExpenses]);
  
  const applyFilters = () => {
    let filtered = expenses;
  
    if (selectedMonthYear) {
      filtered = filtered.filter((expense) =>
        formatMonthYear(expense.month_year) === selectedMonthYear
      );
    }
  
    if (selectedCategory) {
      filtered = filtered.filter((expense) => 
        expense.category_name === selectedCategory
      );
    }
  
    setFilteredExpenses(filtered);
  };
  
  const handleMonthYearChange = (value) => {
    setSelectedMonthYear(value);
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const handleCurrentMonthClick = () => {
    setSelectedMonthYear(getCurrentMonthYear());
  };

  const handleRefresh = () => {
    fetchExpenses();
  };

  useEffect(() => {
    applyFilters();
  }, [selectedMonthYear, selectedCategory, expenses]);

  useEffect(() => {
    fetchCategories();
  }, []);
  
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_URL}/expense/expense-categories`);
      setCategories(response.data);
    } catch (error) {
      message.error(t('Error fetching categories'));
    }
  };
  
  const handleUpdateExpense = async (values) => {
    if (!editingExpense) return;
  
    try {
      const token = localStorage.getItem('token');
  
      // Find the category_id based on the selected category_name
      const selectedCategory = categories.find(
        (category) => category.category_name === values.category_name
      );
  
      if (!selectedCategory) {
        message.error(t('Invalid category selected'));
        return;
      }
  
      // Automatically set month_year from the date value
      const date = dayjs(values.date);
      const month_year = date.format('YYYY-MM');
  
      const formattedValues = {
        ...values,
        category_id: selectedCategory.category_id,
        date: date.format('YYYY-MM-DD'),
        month_year: month_year,
      };
  
      await axios.put(
        `${API_URL}/expense/${editingExpense.expense_id}`,
        formattedValues,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      message.success(t('Expense updated successfully'));
      fetchExpenses();
      closeEditModal();
  
    } catch (error) {
      console.error('Error updating expense:', error);
      message.error(t('Error updating expense'));
    }
  };

  const getToken = () => {
    return localStorage.getItem('token');
  };

  const handleDeleteExpense = async (expenseId) => {
    try {
      const token = getToken();
      const response = await axios.delete(`${API_URL}/expense/${expenseId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      if (response.status === 200) {
        message.success(t('Delete Successfully'));
        fetchExpenses();
      }
    } catch (error) {
      console.error('Error deleting expense:', error);
      message.error(t('Error deleting expense'));
    }
  };

  const openEditModal = (expense) => {
    setEditingExpense(expense);
    form.setFieldsValue({
      ...expense,
      date: dayjs(expense.date),
    });
    setIsEditModalVisible(true);
  };
  
  const closeEditModal = () => {
    setIsEditModalVisible(false);
    setEditingExpense(null);
    form.resetFields();
  };
  
  // Get the color for a category tag
  const getCategoryColor = (category) => {
    const categoryLower = category?.toLowerCase();
    return CATEGORY_COLORS[categoryLower] || 'default';
  };

  const csvHeaders = [
    { label: t('month_year'), key: 'month_year' },
    { label: t('date'), key: 'date' },
    { label: t('category_label'), key: 'category_name' },
    { label: t('details'), key: 'expense_type' },
    { label: t('amount'), key: 'amount' },
    { label: t('memo'), key: 'description' },
    { label: t('created_by'), key: 'nickname' },
  ];

  const columns = [
    {
      title: t('month_year'),
      dataIndex: 'month_year',
      key: 'month_year',
      render: (monthYear) => formatMonthYear(monthYear),
    },
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      render: (dateString) => formatDate(dateString),
    },
    {
      title: t('category_label'),
      dataIndex: 'category_name',
      key: 'category_name',
      render: (category) => (
        <Tag color={getCategoryColor(category)}>
          {category}
        </Tag>
      )
    },
    { 
      title: t('details'), 
      dataIndex: 'expense_type', 
      key: 'expense_type' 
    },
    {
      title: t('amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'THB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount),
    },
    { 
      title: t('memo'), 
      dataIndex: 'description', 
      key: 'description',
      ellipsis: true
    },
    {
      title: t('receipt'),
      dataIndex: 'receipt_path',
      key: 'receipt',
      render: (receipt_path) => {
        const handleFileDownload = async () => {
          try {
            if (!receipt_path) {
              message.error(t('no_receipt'));
              return;
            }
  
            const filename = receipt_path.split('/').pop();
            if (!filename) {
              message.error(t('invalid_receipt_path'));
              return;
            }
  
            const downloadUrl = `${API_URL}/expense/download/expenses/${encodeURIComponent(filename)}`;
            const response = await fetch(downloadUrl);
            
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
  
            window.open(downloadUrl, '_blank', 'noopener,noreferrer');
          } catch (error) {
            console.error('Error downloading file:', error);
            message.error(t('error_downloading_receipt'));
          }
        };
  
        if (!receipt_path) {
          return <Text type="secondary">{t('no_receipt')}</Text>;
        }
  
        return (
          <Button
            type="link"
            icon={<DownloadOutlined />}
            onClick={handleFileDownload}
          >
            {t('download')}
          </Button>
        );
      },
    },
    { 
      title: t('created_by'), 
      dataIndex: 'nickname', 
      key: 'nickname' 
    },
    {
      title: t('actions'),
      key: 'actions',
      width: 120,
      render: (_, record) => (
        <Space size="small">
          <Button 
            type="primary"
            onClick={() => openEditModal(record)}
            icon={<EditOutlined />}
            size="small"
          />
          <Popconfirm
            title={t('Confirm Delete')}
            description={t('Are you sure you want to delete this expense?')}
            onConfirm={() => handleDeleteExpense(record.expense_id)}
            okText={t('yes')}
            cancelText={t('no')}
            okButtonProps={{ danger: true }}
            placement="topRight"
          >
            <Button 
              danger 
              icon={<DeleteOutlined />}
              size="small"
            />
          </Popconfirm>
        </Space>
      ),
    }
  ];

  // Unique month/year values for dropdown
  const uniqueMonthYears = Array.from(
    new Set(expenses.map((expense) => formatMonthYear(expense.month_year)))
  ).filter(Boolean).sort((a, b) => b.localeCompare(a)); // Sort descending

  // Unique category names for dropdown
  const uniqueCategories = Array.from(
    new Set(expenses.map((expense) => expense.category_name))
  ).filter(Boolean).sort();

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout>
        <Content style={{ padding: '24px', background: '#fff', minHeight: 280 }}>
          {/* Summary Section */}
          <Card className="summary-card" style={{ marginBottom: 24 }}>
            <Title level={4}>
              <FileTextOutlined /> {t('Expense Summary')}
            </Title>
            
            <Row gutter={24}>
              <Col xs={24} sm={12} md={6}>
                <Statistic 
                  title={t('Total Amount')}
                  value={summaryStats.totalAmount}
                  precision={2}
                  prefix={<DollarOutlined />} 
                  suffix="THB"
                  valueStyle={{ color: '#1890ff' }}
                />
              </Col>
              
              <Col xs={24} sm={12} md={6}>
                <Statistic 
                  title={t('Number of Expenses')}
                  value={summaryStats.expenseCount}
                  prefix={<TagOutlined />}
                />
              </Col>
              
              <Col xs={24} sm={12} md={6}>
                <Statistic 
                  title={t('Most Common Category')}
                  value={summaryStats.topCategory}
                  valueStyle={{ fontSize: '1rem' }}
                />
              </Col>
              
              <Col xs={24} sm={12} md={6}>
                <Statistic 
                  title={t('Latest Expense Date')}
                  value={summaryStats.latestExpense}
                  prefix={<CalendarOutlined />}
                  valueStyle={{ fontSize: '1rem' }}
                />
              </Col>
            </Row>
          </Card>
          
          {/* Filter Section */}
          <Card style={{ marginBottom: 24 }}>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '16px' }}>
              <Button 
                type="primary"
                onClick={handleCurrentMonthClick}
                icon={<CalendarOutlined />}
              >
                {t('current_month')}
              </Button>
              
              <Select
                placeholder={t('select_month_year')}
                style={{ width: 200 }}
                onChange={handleMonthYearChange}
                value={selectedMonthYear}
                allowClear
              >
                {uniqueMonthYears.map((monthYear) => (
                  <Option key={`month-${monthYear}`} value={monthYear}>
                    {monthYear}
                  </Option>
                ))}
              </Select>
              
              <Select
                placeholder={t('select_category')}
                style={{ width: 200 }}
                onChange={handleCategoryChange}
                value={selectedCategory}
                allowClear
                showSearch
                optionFilterProp="children"
              >
                {uniqueCategories.map((category) => (
                  <Option key={`category-${category}`} value={category}>
                    <Tag color={getCategoryColor(category)} style={{ marginRight: 8 }}>
                      {category}
                    </Tag>
                  </Option>
                ))}
              </Select>
              
              <Button 
                icon={<ReloadOutlined />} 
                onClick={handleRefresh}
                loading={loading}
              >
                {t('refresh')}
              </Button>
              
              <CSVLink
                data={filteredExpenses.map(expense => ({
                  ...expense,
                  month_year: formatMonthYear(expense.month_year),
                  date: formatDate(expense.date),
                  amount: new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(expense.amount),
                }))}
                headers={csvHeaders}
                className="ant-btn ant-btn-default"
                filename={`expenses_${selectedMonthYear || 'all'}.csv`}
              >
                <Button
              type="primary"
              icon={<FileExcelOutlined />}
            >
              {t("Export CSV")}
            </Button>
              </CSVLink>
            </div>
          </Card>
          
          {/* Data Table */}
          <Table
            columns={columns}
            dataSource={filteredExpenses}
            rowKey="expense_id"
            loading={loading}
            pagination={{ 
              pageSize: 15,
              showSizeChanger: true,
              pageSizeOptions: ['15', '30', '50', '100'],
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
            }}
            bordered
            size="middle"
            scroll={{ x: 'max-content' }}
          />
        </Content>
        
        {/* Edit Modal */}
        <Modal
          title={t('Edit Expense')}
          visible={isEditModalVisible}
          onCancel={closeEditModal}
          onOk={() => {
            form.validateFields().then(handleUpdateExpense);
          }}
          width={600}
        >
          <Form form={form} layout="vertical">
            <FormItem
              label={t('date')}
              name="date"
              rules={[{ required: true, message: t('Please select the date') }]}
            >
              <DatePicker
                format="DD-MM-YYYY"
                style={{ width: '100%' }}
              />
            </FormItem>
            
            <FormItem
              label={t('category_label')}
              name="category_name"
              rules={[{ required: true, message: t('Please select category') }]}
            >
              <Select showSearch optionFilterProp="children">
                {categories.map((category) => (
                  <Option key={category.category_id} value={category.category_name}>
                    <Tag color={getCategoryColor(category.category_name)} style={{ marginRight: 8 }}>
                      {category.category_name}
                    </Tag>
                  </Option>
                ))}
              </Select>
            </FormItem>

            <FormItem
              label={t('details')}
              name="expense_type"
              rules={[{ required: true, message: t('Please enter expense type') }]}
            >
              <Input />
            </FormItem>

            <FormItem
              label={t('amount')}
              name="amount"
              rules={[{ required: true, message: t('Please enter amount') }]}
            >
              <InputNumber 
                min={0} 
                precision={2} 
                style={{ width: '100%' }} 
                formatter={value => `฿ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/฿\s?|(,*)/g, '')}
              />
            </FormItem>

            <FormItem label={t('memo')} name="description">
              <Input.TextArea rows={3} />
            </FormItem>
          </Form>
        </Modal>
      </Layout>
    </Layout>
  );
};

export default withRoleAccess(ExpenseReport, ['superadmin']);