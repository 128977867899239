import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Button,
  Table,
  Space,
  Typography,
  Modal,
  message,
  Tooltip,
  Spin
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  LinkOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  SaveOutlined,
  ReloadOutlined,
  FileImageOutlined
} from '@ant-design/icons';
import moment from 'moment';

const { Text, Paragraph } = Typography;
const { TextArea } = Input;
const { confirm } = Modal;

const AnnouncementAdmin = () => {
  const [form] = Form.useForm();
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [editId, setEditId] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const fetchAnnouncements = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/announcements`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setAnnouncements(response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching announcements:', error);
      message.error('Failed to fetch announcements. Please try again later.');
      setLoading(false);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchAnnouncements();
  }, [fetchAnnouncements]);

  const handleSaveAnnouncement = async (values) => {
    setSubmitting(true);
    const token = localStorage.getItem('token');
    
    try {
      // Format the date correctly - using the regular date input value
      const formattedData = {
        ...values,
        date: values.date // The date string is already in YYYY-MM-DD format from input type="date"
      };
      
      let response;
      if (editId) {
        response = await axios.put(`${API_URL}/announcements/${editId}`, formattedData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAnnouncements(prev => 
          prev.map(announcement => 
            announcement.id === response.data.id ? response.data : announcement
          ).sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        );
        message.success('Announcement updated successfully');
      } else {
        response = await axios.post(`${API_URL}/announcements`, formattedData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAnnouncements(prev => 
          [response.data, ...prev].sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        );
        message.success('Announcement created successfully');
      }
      
      resetForm();
    } catch (error) {
      console.error('Error saving announcement:', error);
      message.error('Failed to save announcement. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  const resetForm = () => {
    form.resetFields();
    setEditId(null);
  };

  const handleEdit = (announcement) => {
    setEditId(announcement.id);
    // Format the date to YYYY-MM-DD for the HTML date input
    const formattedDate = moment(announcement.date).format('YYYY-MM-DD');
    
    form.setFieldsValue({
      title: announcement.title,
      message: announcement.message,
      link: announcement.link || '',
      by: announcement.by,
      date: formattedDate,
      image_url: announcement.image_url || ''
    });
  };

  const showDeleteConfirm = (id, title) => {
    confirm({
      title: 'Are you sure you want to delete this announcement?',
      content: `Announcement: "${title}"`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes, Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        handleDelete(id);
      }
    });
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`${API_URL}/announcements/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAnnouncements(prev => prev.filter(announcement => announcement.id !== id));
      message.success('Announcement deleted successfully');
    } catch (error) {
      console.error('Error deleting announcement:', error);
      message.error('Failed to delete announcement');
    }
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text}>
          <Text strong>{text}</Text>
        </Tooltip>
      )
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text}>
          <Paragraph ellipsis={{ rows: 2 }}>{text}</Paragraph>
        </Tooltip>
      )
    },
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link',
      render: (text) => text ? (
        <Tooltip title={text}>
          <a href={text} target="_blank" rel="noopener noreferrer">
            <LinkOutlined style={{ fontSize: '16px' }} />
          </a>
        </Tooltip>
      ) : null
    },
    {
      title: 'Image',
      dataIndex: 'image_url',
      key: 'image_url',
      render: (text) => text ? (
        <Tooltip title="View Image">
          <a href={text} target="_blank" rel="noopener noreferrer">
            <FileImageOutlined style={{ fontSize: '16px' }} />
          </a>
        </Tooltip>
      ) : null
    },
    {
      title: 'By',
      dataIndex: 'by',
      key: 'by',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text) => moment(text).format('DD MMMM YYYY')
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <Button 
              type="primary" 
              shape="circle" 
              icon={<EditOutlined />} 
              onClick={() => handleEdit(record)}
              ghost
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button 
              type="primary" 
              shape="circle" 
              danger 
              icon={<DeleteOutlined />} 
              onClick={() => showDeleteConfirm(record.id, record.title)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  // Get current date in YYYY-MM-DD format for default value
  const today = new Date().toISOString().split('T')[0];

  return (
    <div style={{ padding: '20px' }}>
      <Row gutter={[24, 24]}>
        <Col xs={24} lg={8}>
          <Card 
            title={
              <Space>
                {editId ? <EditOutlined /> : <PlusOutlined />}
                <span>{editId ? 'Edit Announcement' : 'Create Announcement'}</span>
              </Space>
            }
            bordered={true}
            style={{ marginBottom: 20 }}
          >
            <Spin spinning={submitting}>
              <Form
                form={form}
                layout="vertical"
                initialValues={{
                  by: 'Admin',
                  date: today
                }}
                onFinish={handleSaveAnnouncement}
              >
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[{ required: true, message: 'Please enter announcement title' }]}
                >
                  <Input placeholder="Enter announcement title" />
                </Form.Item>
                
                <Form.Item
                  name="message"
                  label="Message"
                  rules={[{ required: true, message: 'Please enter announcement message' }]}
                >
                  <TextArea rows={4} placeholder="Enter announcement message" />
                </Form.Item>
                
                <Form.Item
                  name="link"
                  label="Link (Optional)"
                >
                  <Input placeholder="Enter URL (if applicable)" prefix={<LinkOutlined />} />
                </Form.Item>
                
                <Form.Item
                  name="image_url"
                  label="Image URL (Optional)"
                >
                  <Input placeholder="Enter image URL (if applicable)" prefix={<FileImageOutlined />} />
                </Form.Item>
                
                <Form.Item
                  name="by"
                  label="Posted By"
                  rules={[{ required: true, message: 'Please enter author name' }]}
                >
                  <Input placeholder="Enter author name" />
                </Form.Item>
                
                <Form.Item
                  name="date"
                  label="Date"
                  rules={[{ required: true, message: 'Please select date' }]}
                >
                  <Input 
                    type="date" 
                    style={{ width: '100%' }} 
                  />
                </Form.Item>
                
                <Form.Item>
                  <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Button 
                      onClick={resetForm} 
                      icon={<ReloadOutlined />}
                      disabled={submitting}
                    >
                      Reset
                    </Button>
                    <Button 
                      type="primary" 
                      htmlType="submit" 
                      loading={submitting}
                      icon={<SaveOutlined />}
                    >
                      {editId ? 'Update' : 'Create'} Announcement
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Spin>
          </Card>
        </Col>
        
        <Col xs={24} lg={16}>
          <Card title="Announcements List" bordered={true}>
          <div style={{ width: '100%', overflowX: 'auto' }}>
            <Table
              columns={columns}
              dataSource={announcements}
              rowKey="id"
              loading={loading}
              pagination={{ 
                pageSize: 7, 
                showSizeChanger: false,
                showTotal: (total) => `Total ${total} announcements`
              }}
              scroll={{ x: true }}
            />
          </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default withRoleAccess(AnnouncementAdmin, ['admin', 'superadmin']);