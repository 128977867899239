import React, { useState, useEffect, useCallback } from 'react';
import { Form, Select, InputNumber, Button, Modal, Input, message, Table, Card, Tabs, Space, Divider } from 'antd';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
const { TabPane } = Tabs;

const CreateScheme = () => {
  // States
  const [subjectGroups, setSubjectGroups] = useState([]);
  const [subjectIds, setSubjectIds] = useState([]);
  const [schemes, setSchemes] = useState([]);
  const [platforms] = useState(['online', 'onsite']);
  
  // Modal visibility states
  const [subjectGroupModalVisible, setSubjectGroupModalVisible] = useState(false);
  const [schemeModalVisible, setSchemeModalVisible] = useState(false);
  const [subjectIdModalVisible, setSubjectIdModalVisible] = useState(false);
  
  // Forms
  const [subjectGroupForm] = Form.useForm();
  const [schemeForm] = Form.useForm();
  const [subjectIdForm] = Form.useForm();
  
  // Loading and error states
  const [loading, setLoading] = useState(true);
  const [loadingSubjectIds, setLoadingSubjectIds] = useState(false);
  const [error, setError] = useState(null);

  const [editingSchemeId, setEditingSchemeId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('token');
  
  // Fetch subject groups
  const fetchSubjectGroups = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/subject-group/subject-group-no`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      setSubjectGroups(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching subject groups:', error);
      message.error('Failed to fetch subject groups');
      setError('Error fetching subject groups');
      setLoading(false);
    }
  }, [API_URL, token]);



  // Fetch subject IDs from the new subject_id table
  const fetchSubjectIds = useCallback(async () => {
    try {
      setLoadingSubjectIds(true);
      const response = await axios.get(`${API_URL}/subjects/subject-id/all`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      if (Array.isArray(response.data)) {
        setSubjectIds(response.data);
      } else {
        setSubjectIds([]);
      }
      setLoadingSubjectIds(false);
    } catch (error) {
      console.error('Error fetching subject IDs:', error);
      message.error('Failed to fetch subject IDs');
      setLoadingSubjectIds(false);
    }
  }, [API_URL, token]);

  // Fetch existing schemes
  const fetchSchemes = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/subject-group/schemes`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      setSchemes(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching schemes:', error);
      message.error('Failed to fetch schemes');
      setError('Error fetching schemes');
      setLoading(false);
    }
  }, [API_URL, token]);

  // Create a new subject group
  const createSubjectGroup = useCallback(async (values) => {
    try {
      const { groupName, subjectGroupNo } = values;
      
      if (!groupName || !subjectGroupNo) {
        message.error('Group name and subject group number are required');
        return;
      }
  
      const dataToSubmit = {
        groupName,
        subjectGroup: subjectGroupNo
      };
  
      await axios.post(`${API_URL}/subject-group/subject-group-no`, dataToSubmit, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      message.success('Subject group created successfully');
      setSubjectGroupModalVisible(false);
      subjectGroupForm.resetFields();
      
      // Refresh subject groups after creating a new one
      fetchSubjectGroups();
    } catch (error) {
      console.error('Error creating subject group:', error);
      message.error('Failed to create subject group');
    }
  }, [API_URL, token, subjectGroupForm, fetchSubjectGroups]);

  // Create a new subject ID
  const createSubjectId = useCallback(async (values) => {
    try {
      const { name } = values;
      
      if (!name) {
        message.error('Subject ID name is required');
        return;
      }
  
      await axios.post(`${API_URL}/subjects/subject-id`, { name }, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      message.success('Subject created successfully');
      setSubjectIdModalVisible(false);
      subjectIdForm.resetFields();
      
      // Refresh subject IDs after creating a new one
      fetchSubjectIds();
    } catch (error) {
      console.error('Error creating subject ID:', error);
      message.error('Failed to create subject ID');
    }
  }, [API_URL, token, subjectIdForm, fetchSubjectIds]);

  // Update an existing subject group
  const updateSubjectGroup = useCallback(async (id, values) => {
    try {
      const { groupName, subjectGroupNo } = values;

      if (!groupName || !subjectGroupNo) {
        message.error('Group name and subject group number are required');
        return;
      }

      const dataToSubmit = {
        groupName,
        subjectGroupNo
      };

      await axios.put(`${API_URL}/subject-group/subject-group-no/${id}`, dataToSubmit, {
        headers: { Authorization: `Bearer ${token}` },
      });

      message.success('Subject group updated successfully');
      setSubjectGroupModalVisible(false);
      subjectGroupForm.resetFields();
      
      // Refresh subject groups after updating
      fetchSubjectGroups();
    } catch (error) {
      console.error('Error updating subject group:', error);
      message.error('Failed to update subject group');
    }
  }, [API_URL, token, subjectGroupForm, fetchSubjectGroups]);
  const fetchSchemeById = useCallback(async (id) => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/subject-group/scheme/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      const scheme = response.data;
      
      // Format the data for the form
      schemeForm.setFieldsValue({
        subjectGroup: scheme.subject_group,
        platform: scheme.platform,
        subjectId: scheme.subject_id,
        flatRate: scheme.flat_rate,
        additionalPayouts: {
          oneStudent: scheme.additional_payout_1,
          twoStudents: scheme.additional_payout_2,
          threeToFive: scheme.additional_payout_3,
          sixToTen: scheme.additional_payout_4,
          tenPlus: scheme.additional_payout_5,
        }
      });
      
      setEditingSchemeId(id);
      setIsEditing(true);
      setSchemeModalVisible(true);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching scheme:', error);
      message.error('Failed to fetch scheme');
      setLoading(false);
    }
  }, [API_URL, token, schemeForm]);

  const updateScheme = useCallback(async (id, values) => {
    try {
      const { subjectGroup, platform, subjectId, flatRate, additionalPayouts } = values;
      
      // Ensure subjectId is an integer
      const parsedSubjectId = parseInt(subjectId, 10);
      
      if (isNaN(parsedSubjectId)) {
        message.error('Invalid subject ID');
        return;
      }
      
      const dataToSubmit = {
        subjectGroup,
        platform,
        subjectId: parsedSubjectId,
        flatRate,
        additionalPayouts
      };
  
      await axios.put(`${API_URL}/subject-group/scheme/${id}`, dataToSubmit, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      message.success('Scheme updated successfully');
      setSchemeModalVisible(false);
      schemeForm.resetFields();
      setEditingSchemeId(null);
      setIsEditing(false);
      
      // Refresh schemes after updating
      fetchSchemes();
    } catch (error) {
      console.error('Error updating scheme:', error);
      message.error(`Failed to update scheme: ${error.response?.data?.message || error.message}`);
    }
  }, [API_URL, token, schemeForm, fetchSchemes]);
  
  // 2. Modify the handleSchemeModalSubmit function
  const handleSchemeModalSubmit = (values) => {
    if (isEditing && editingSchemeId) {
      // Update existing scheme
      updateScheme(editingSchemeId, values);
    } else {
      // Create new scheme
      createScheme(values);
    }
  };

// Delete a subject group with confirmation
const deleteSubjectGroup = useCallback(async (id) => {
    Modal.confirm({
      title: 'Delete Subject Group',
      content: 'Are you sure you want to delete this subject group? This action cannot be undone and may affect related schemes.',
      okText: 'Yes, Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          await axios.delete(`${API_URL}/subject-group/subject-group-no/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
      
          message.success('Subject group deleted successfully');
          
          // Refresh subject groups after deletion
          fetchSubjectGroups();
        } catch (error) {
          console.error('Error deleting subject group:', error);
          message.error('Failed to delete subject group');
        }
      },
    });
  }, [API_URL, token, fetchSubjectGroups]);



  // Create a new payout scheme
  const createScheme = useCallback(async (values) => {
    try {
      const { subjectGroup, platform, subjectId, flatRate, additionalPayouts } = values;
      
      // Ensure subjectId is an integer
      const parsedSubjectId = parseInt(subjectId, 10);
      
      if (isNaN(parsedSubjectId)) {
        message.error('Invalid subject ID');
        return;
      }
      
      const dataToSubmit = {
        subjectGroup,
        platform,
        subjectId: parsedSubjectId, // Make sure we pass as integer
        flatRate,
        additionalPayouts
      };

      await axios.post(`${API_URL}/subject-group/create-scheme`, dataToSubmit, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      message.success('Scheme created successfully');
      setSchemeModalVisible(false);
      schemeForm.resetFields();
      
      // Refresh schemes after creating a new one
      fetchSchemes();
    } catch (error) {
      console.error('Error creating scheme:', error);
      message.error(`Failed to create scheme: ${error.response?.data?.message || error.message}`);
    }
  }, [API_URL, token, schemeForm, fetchSchemes]);

  // Delete a scheme with confirmation
const deleteScheme = useCallback(async (id) => {
    Modal.confirm({
      title: 'Delete Scheme',
      content: 'Are you sure you want to delete this scheme? This action cannot be undone.',
      okText: 'Yes, Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          await axios.delete(`${API_URL}/subject-group/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
      
          message.success('Scheme deleted successfully');
          
          // Refresh schemes after deletion
          fetchSchemes();
        } catch (error) {
          console.error('Error deleting scheme:', error);
          message.error('Failed to delete scheme');
        }
      },
    });
  }, [API_URL, token, fetchSchemes]);

  // Load data when component mounts
  useEffect(() => {
    fetchSubjectGroups();
    fetchSchemes();
    fetchSubjectIds(); // Add this to fetch the subject IDs
  }, [fetchSubjectGroups, fetchSchemes, fetchSubjectIds]);

  const handleSubjectGroupModalSubmit = (values) => {
    if (subjectGroupForm.getFieldValue('id')) {
      // If ID exists, it's an update operation
      updateSubjectGroup(subjectGroupForm.getFieldValue('id'), values);
    } else {
      // Otherwise, it's a create operation
      createSubjectGroup(values);
    }
  };

  // Subject Group Table Columns
  const subjectGroupColumns = [
    {
      title: 'Group Name',
      dataIndex: 'group_name',
      key: 'group_name',
    },
    {
      title: 'Subject Group No',
      dataIndex: 'subject_group',
      key: 'subject_group',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space>
          <Button 
            type="link" 
            icon={<EditOutlined />} 
            onClick={() => {
              subjectGroupForm.setFieldsValue({
                id: record.id,
                groupName: record.group_name,
                subjectGroupNo: record.subject_group
              });
              setSubjectGroupModalVisible(true);
            }}
            title="Edit"
          />
          <Button 
            type="link" 
            icon={<DeleteOutlined />} 
            danger
            onClick={() => deleteSubjectGroup(record.id)}
            title="Delete"
          />
        </Space>
      ),
    },
  ];

  // Subject ID Table Columns
  const subjectIdColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '20%',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
  ];

  // Scheme Table Columns
  const schemeColumns = [
    {
      title: 'Subject Group',
      dataIndex: 'subject_group_id',
      key: 'subject_group_id',
      render: (text, record) => {
        const group = subjectGroups.find(g => g.id === record.subject_group_id);
        return group ? `${group.group_name} (${group.subject_group})` : record.subject_group_id;
      }
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
      render: text => text.charAt(0).toUpperCase() + text.slice(1)
    },
    {
      title: 'Subject',
      dataIndex: 'subject_id',
      key: 'subject_id',
      render: (text, record) => {
        const subject = subjectIds.find(s => s.id === record.subject_id);
        return subject ? subject.name : record.subject_id;
      }
    },
    {
      title: 'Flat Rate (₿)',
      dataIndex: 'flat_rate',
      key: 'flat_rate',
    },
    {
      title: 'Additional Payouts',
      children: [
        {
          title: '1 Student',
          dataIndex: 'one_student',
          key: 'one_student',
        },
        {
          title: '2 Students',
          dataIndex: 'two_students',
          key: 'two_students',
        },
        {
          title: '3-5 Students',
          dataIndex: 'three_to_five',
          key: 'three_to_five',
        },
        {
          title: '6-10 Students',
          dataIndex: 'six_to_ten',
          key: 'six_to_ten',
        },
        {
          title: '10+ Students',
          dataIndex: 'ten_plus',
          key: 'ten_plus',
        },
      ],
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space>
          <Button 
            type="link" 
            icon={<EditOutlined />} 
            onClick={() => fetchSchemeById(record.id)}
            title="Edit"
          />
          <Button 
            type="link" 
            icon={<DeleteOutlined />} 
            danger
            onClick={() => deleteScheme(record.id)}
            title="Delete"
          />
        </Space>
      ),
    }
  ];

  return (
    <div>

      <Tabs defaultActiveKey="1">
      <TabPane tab="Teacher Payout Schemes" key="1">
          <Card 
            title="Teacher Payout Schemes" 
            extra={
              <Button 
                type="primary" 
                onClick={() => {
                  schemeForm.resetFields();
                  setSchemeModalVisible(true);
                }}
              >
                + Create New Scheme
              </Button>
            }
          >
            <Table
              dataSource={schemes}
              columns={schemeColumns}
              rowKey="id"
              loading={loading}
              pagination={{ pageSize: 10 }}
              bordered
            />
          </Card>
        </TabPane>
        <TabPane tab="Subjects" key="2">
          <Card 
            title="Subjects" 
            extra={
              <Button 
                type="primary" 
                onClick={() => {
                  subjectIdForm.resetFields();
                  setSubjectIdModalVisible(true);
                }}
              >
                + Create New Subject
              </Button>
            }
          >
            <Table
              dataSource={subjectIds}
              columns={subjectIdColumns}
              rowKey="id"
              loading={loadingSubjectIds}
              pagination={{ pageSize: 10 }}
            />
          </Card>
        </TabPane>
        <TabPane tab="Subject Groups" key="3">
          <Card 
            title="Subject Groups" 
            extra={
              <Button 
                type="primary" 
                onClick={() => {
                  subjectGroupForm.resetFields();
                  setSubjectGroupModalVisible(true);
                }}
              >
                + Create New Subject Group
              </Button>
            }
          >
            <Table
              dataSource={subjectGroups}
              columns={subjectGroupColumns}
              rowKey="id"
              loading={loading}
              pagination={{ pageSize: 10 }}
            />
          </Card>
        </TabPane>
        
      </Tabs>

      {/* Modal for Creating/Updating Subject Group */}
      <Modal
        title={subjectGroupForm.getFieldValue('id') ? "Update Subject Group" : "Create New Subject Group"}
        visible={subjectGroupModalVisible}
        onCancel={() => setSubjectGroupModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setSubjectGroupModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => subjectGroupForm.submit()}>
            {subjectGroupForm.getFieldValue('id') ? "Update" : "Create"} Subject Group
          </Button>,
        ]}
      >
        <Form form={subjectGroupForm} onFinish={handleSubjectGroupModalSubmit} layout="vertical">
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="groupName"
            label="Group Name"
            rules={[{ required: true, message: 'Please enter group name!' }]}
          >
            <Input placeholder="Enter group name (e.g., Group 1)" />
          </Form.Item>

          <Form.Item
            name="subjectGroupNo"
            label="Subject Group Number"
            rules={[{ required: true, message: 'Please enter subject group number!' }]}
          >
            <InputNumber placeholder="Enter subject group number" style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Modal>

      {/* Modal for Creating Subject ID */}
      <Modal
        title="Create New Subject"
        visible={subjectIdModalVisible}
        onCancel={() => setSubjectIdModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setSubjectIdModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => subjectIdForm.submit()}>
            Create Subject
          </Button>,
        ]}
      >
        <Form form={subjectIdForm} onFinish={createSubjectId} layout="vertical">
          <Form.Item
            name="name"
            label="Subject Name"
            rules={[{ required: true, message: 'Please enter subject name!' }]}
          >
            <Input placeholder="Enter subject name (e.g., GED, IGCSE, SAT, IELTS, A-Level)" />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
  title={isEditing ? "Edit Teacher Payout Scheme" : "Create New Teacher Payout Scheme"}
  visible={schemeModalVisible}
  onCancel={() => {
    setSchemeModalVisible(false);
    schemeForm.resetFields();
    setEditingSchemeId(null);
    setIsEditing(false);
  }}
  width={800}
  footer={[
    <Button key="cancel" onClick={() => {
      setSchemeModalVisible(false);
      schemeForm.resetFields();
      setEditingSchemeId(null);
      setIsEditing(false);
    }}>
      Cancel
    </Button>,
    <Button key="submit" type="primary" onClick={() => schemeForm.submit()}>
      {isEditing ? "Update" : "Create"} Scheme
    </Button>,
  ]}
>
  <Form form={schemeForm} onFinish={handleSchemeModalSubmit} layout="vertical">
    {/* Subject Group */}
    <Form.Item name="subjectGroup" label="Subject Group" rules={[{ required: true }]}>
      <Select placeholder="Select Subject Group" loading={loading}>
        {subjectGroups.map(group => (
          <Select.Option key={group.id} value={group.id}>
            {group.group_name} ({group.subject_group})
          </Select.Option>
        ))}
      </Select>
    </Form.Item>

    {/* Platform */}
    <Form.Item name="platform" label="Platform" rules={[{ required: true }]}>
      <Select placeholder="Select Platform">
        {platforms.map((platform, idx) => (
          <Select.Option key={idx} value={platform}>
            {platform.charAt(0).toUpperCase() + platform.slice(1)}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>

    {/* Subject from subject_id table */}
    <Form.Item name="subjectId" label="Subject" rules={[{ required: true }]}>
      <Select 
        placeholder="Select Subject" 
        loading={loadingSubjectIds}
        dropdownRender={menu => (
          <div>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            <div style={{ padding: '4px 8px' }}>
              <Button 
                type="link" 
                onClick={() => {
                  subjectIdForm.resetFields();
                  setSubjectIdModalVisible(true);
                }}
                style={{ width: '100%', textAlign: 'left' }}
              >
                + Add New Subject
              </Button>
            </div>
          </div>
        )}
      >
        {subjectIds.map(subject => (
          <Select.Option key={subject.id} value={subject.id}>
            {subject.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>

    {/* Flat Rate */}
    <Form.Item name="flatRate" label="Flat Rate (₿)" rules={[{ required: true }]}>
      <InputNumber min={0} style={{ width: '100%' }} />
    </Form.Item>

    <Divider>Additional Payouts</Divider>

    <Form.Item label="Additional Payouts Based on Student Count">
      <Space style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Form.Item name={['additionalPayouts', 'oneStudent']} noStyle>
          <InputNumber placeholder="1 Student" style={{ width: 120, marginRight: 16 }} />
        </Form.Item>
        
        <Form.Item name={['additionalPayouts', 'twoStudents']} noStyle>
          <InputNumber placeholder="2 Students" style={{ width: 120, marginRight: 16 }} />
        </Form.Item>
        
        <Form.Item name={['additionalPayouts', 'threeToFive']} noStyle>
          <InputNumber placeholder="3-5 Students" style={{ width: 120, marginRight: 16 }} />
        </Form.Item>
        
        <Form.Item name={['additionalPayouts', 'sixToTen']} noStyle>
          <InputNumber placeholder="6-10 Students" style={{ width: 120, marginRight: 16 }} />
        </Form.Item>
        
        <Form.Item name={['additionalPayouts', 'tenPlus']} noStyle>
          <InputNumber placeholder="10+ Students" style={{ width: 120 }} />
        </Form.Item>
      </Space>
    </Form.Item>
  </Form>
</Modal>
    </div>
  );
};

export default withRoleAccess(CreateScheme, ['superadmin']);