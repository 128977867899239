import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { Form, Input, Button, Typography, Alert } from 'antd';
import PropTypes from 'prop-types';
import '../common.css';

const { Title } = Typography;

function SignIn({ setUserData }) {
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loading, setLoading] = useState(false);

  const { signIn } = useAuth(); 
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const handleSignIn = async (values) => {
    // Function logic remains the same
    setEmailError('');
    setPasswordError('');
    setLoading(true);

    try {
      const { email, password } = values;
      const deviceName = navigator.userAgent;

      const response = await fetch(`${API_URL}/auth/signin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          email, 
          password, 
          deviceName 
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        const { accessToken, refreshToken, userId, role, isTempPassword } = responseData;

        localStorage.setItem('token', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('userId', userId);
        localStorage.setItem('role', role);
        signIn(accessToken);
        setUserData({ userId, role });

        if (isTempPassword) {
          navigate('/change-password');
        } else {
          navigate('/dashboard');
        }
      } else {
        const errorResponse = await response.json();
        switch (errorResponse.error) {
          case 'UserNotFound':
            setEmailError('The email address is not registered.');
            break;
          case 'InvalidPassword':
            setPasswordError('The password is incorrect.');
            break;
          default:
            setEmailError('Email or password is incorrect.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setEmailError('Email or password is incorrect.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="modern-auth-container">
      {/* Left side - Logo Area (now white) */}
      <div className="logo-area">
        <div className="logo-content">
          <div className="logo-wrapper">
            <div className="logo-container"></div>
          </div>
          <div className="divider-line"></div>
        </div>
      </div>

      {/* Right side - Sign In Form (now red) */}
      <div className="form-area">
        <div style={{
          width: '100%',
          maxWidth: '400px',
          padding: '2rem',
        }}>
          <div style={{ marginBottom: '2.5rem' }}>
            <Title 
              level={2} 
              style={{ 
                color: '#000',
                fontSize: '28px',
                fontWeight: '600',
                marginBottom: '0.5rem',
              }}
            >
              Sign In
            </Title>
          </div>

          <Form
            layout="vertical"
            onFinish={handleSignIn}
            requiredMark="optional"
          >
            <Form.Item
              name="email"
              label={<span style={{ color: '#fff', fontWeight: '500' }}>Email</span>}
              style={{ marginBottom: '1.5rem' }}
              rules={[
                { required: true, message: 'Please input your email!' },
                { type: 'email', message: 'Please enter a valid email!' },
              ]}
            >
              <Input 
                size="large"
                style={{
                  height: '50px',
                  borderRadius: '6px',
                  borderColor: '#ddd',
                }}
              />
            </Form.Item>

            <Form.Item
              name="password"
              label={<span style={{ color: '#fff', fontWeight: '500' }}>Password</span>}
              style={{ marginBottom: '1.5rem' }}
              rules={[
                { required: true, message: 'Please input your password!' }
              ]}
            >
              <Input.Password 
                size="large"
                style={{
                  height: '50px',
                  borderRadius: '6px',
                  borderColor: '#ddd',
                }}
              />
            </Form.Item>

            {emailError && (
              <Alert
                message={emailError}
                type="error"
                showIcon
                style={{ marginBottom: '1.5rem' }}
              />
            )}
            {passwordError && (
              <Alert
                message={passwordError}
                type="error"
                showIcon
                style={{ marginBottom: '1.5rem' }}
              />
            )}

            <div style={{ textAlign: 'right', marginBottom: '1.5rem', color: '#999' }}>
            Please contact the school admin if you have forgotten your login email or password.
            </div>

            <Form.Item>
              <Button 
                type="primary" 
                htmlType="submit" 
                block
                loading={loading}
                style={{
                  height: '50px',
                  borderRadius: '6px',
                  fontSize: '16px',
                  fontWeight: '500',
                  backgroundColor: '#95101e',
                  border: 'none',
                  boxShadow: '0 4px 6px rgba(149, 16, 30, 0.2)',
                }}
              >
                Sign In
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

SignIn.propTypes = {
  setUserData: PropTypes.func.isRequired,
};

export default SignIn;