import React, { useState } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { useTranslation } from 'react-i18next';
import './InventoryPage.css';
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Card,
  Typography,
  message,
  Layout,
  Space
} from 'antd';
import {
  ShoppingOutlined,
  SaveOutlined,
  ClearOutlined
} from '@ant-design/icons';

const { Content } = Layout;
const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const InventoryPage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const categories = [
    { value: 'Test', label: t('TEST') },
    { value: 'IELTS Book', label: t('IELTS Book') },
    { value: 'TOEFL Book', label: t('TOEFL Book') },
    { value: 'GED Book', label: t('GED Book') },
    { value: 'IGCSE Book', label: t('IGCSE Book') },
    { value: 'Other Book', label: t('Other Book') },
    { value: 'Equipment', label: t('Equipment') },
    { value: 'Others', label: t('Others') }
  ];

  const handleAddItem = async (values) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}/inventory`, values, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      message.success(t('Item added successfully'));
      form.resetFields();

    } catch (error) {
      console.error('Error adding inventory item:', error);
      message.error(t('Failed to add item. Please try again.'));
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    form.resetFields();
  };

  return (
    <Layout className="site-layout">
      <Sidebar />
      <Layout>
        <Header />
        <Content className="site-content">
          <div className="inventory-container">
            <Card 
              className="inventory-card"
              title={
                <Space>
                  <ShoppingOutlined />
                  <Title level={2}>{t('Inventory Management')}</Title>
                </Space>
              }
            >
              <Form
                form={form}
                layout="vertical"
                onFinish={handleAddItem}
                className="inventory-form"
                initialValues={{
                  price_per_unit: 0.0
                }}
              >
                <Form.Item
                  name="product_id"
                  label={t('Product ID')}
                  rules={[
                    { required: true, message: t('Please enter the Product ID') }
                  ]}
                >
                  <Input 
                    placeholder={t('Enter Product ID')}
                    allowClear
                  />
                </Form.Item>

                <Form.Item
                  name="item_name"
                  label={t('Item Name')}
                  rules={[
                    { required: true, message: t('Please enter the Item Name') }
                  ]}
                >
                  <Input 
                    placeholder={t('Enter Item Name')}
                    allowClear
                  />
                </Form.Item>

                <Form.Item
                  name="item_description"
                  label={t('Item Description')}
                >
                  <TextArea
                    placeholder={t('Enter Item Description')}
                    rows={4}
                    allowClear
                  />
                </Form.Item>

                <Form.Item
                  name="category"
                  label={t('Category')}
                  rules={[
                    { required: true, message: t('Please select a category') }
                  ]}
                >
                  <Select placeholder={t('Select a category')}>
                    {categories.map(category => (
                      <Option key={category.value} value={category.value}>
                        {category.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="price_per_unit"
                  label={t('Price per Unit')}
                  rules={[
                    { required: true, message: t('Please enter the Price per Unit') },
                    { type: 'number', min: 0, message: t('Price must be greater than or equal to 0') }
                  ]}
                >
                  <InputNumber
                    placeholder={t('Enter Price per Unit')}
                    style={{ width: '100%' }}
                    step="0.01"
                    precision={2}
                    prefix="฿"
                  />
                </Form.Item>

                <Form.Item className="form-actions">
                  <Space size="middle">
                    <Button
                      type="primary"
                      htmlType="submit"
                      icon={<SaveOutlined />}
                      loading={loading}
                    >
                      {t('Add Item')}
                    </Button>
                    <Button 
                      onClick={handleReset}
                      icon={<ClearOutlined />}
                    >
                      {t('Reset')}
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default withRoleAccess(InventoryPage, ['superadmin', 'admin']);