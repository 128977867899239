import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import withRoleAccess from '../../hoc/withRoleAccess';
import axios from 'axios';
import getUserIdFromToken from '../../Utils/authUtils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Layout, Table, Select, Button, Card, Tag, Space, notification, Tabs } from 'antd';
import { CheckCircleOutlined, TagOutlined } from '@ant-design/icons';
import MonthlyHoursSummary from './MonthlyHoursSummary'; 
import TeacherPayout from './TeacherPayout'; 
import './Teacher.css';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

const { Content } = Layout;
const { Option } = Select;
const { TabPane } = Tabs;

const generateMonthYearOptions = () => {
  const options = [];
  const startYear = 2025;
  const startMonth = 1;
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // 1-based month

  for (let year = startYear; year <= currentYear + 1; year++) {
    for (let month = 1; month <= 12; month++) {
      // Skip months before the start date
      if (year === startYear && month < startMonth) continue;
      
      // Calculate previous month for the period start (26th)
      const prevMonth = month === 1 ? 12 : month - 1;
      const prevYear = month === 1 ? year - 1 : year;
      
      // Format the display label: "26 Feb - 25 Mar 2025"
      const startMonthName = new Date(prevYear, prevMonth - 1).toLocaleString('default', { month: 'short' });
      const endMonthName = new Date(year, month - 1).toLocaleString('default', { month: 'short' });
      const label = `26 ${startMonthName} - 25 ${endMonthName} ${year}`;
      
      // Store both current and previous month info for filtering
      const value = `${year}-${month}-${prevYear}-${prevMonth}`;
      
      options.push({ value, label });
      
      // Stop adding future months beyond current month
      if (year === currentYear + 1 && month >= currentMonth) break;

    }
  }

  return options;
};

const getDefaultPeriod = () => {
  const today = new Date();
  const currentMonth = today.getMonth() + 1; // 1-based month
  const currentYear = today.getFullYear();
  
  // If current date is between 1-25, we're in the previous period
  if (today.getDate() <= 25) {
    const prevMonth = currentMonth === 1 ? 12 : currentMonth - 1;
    const prevYear = currentMonth === 1 ? currentYear - 1 : currentYear;
    const startMonth = prevMonth === 1 ? 12 : prevMonth - 1;
    const startYear = prevMonth === 1 ? prevYear - 1 : prevYear;
    
    return `${prevYear}-${prevMonth}-${startYear}-${startMonth}`;
  } else {
    // We're in the current period (26th-end of month)
    const prevMonth = currentMonth;
    const prevYear = currentYear;
    const startMonth = prevMonth === 1 ? 12 : prevMonth - 1;
    const startYear = prevMonth === 1 ? prevYear - 1 : prevYear;
    
    return `${prevYear}-${prevMonth}-${startYear}-${startMonth}`;
  }
};
const getChangeReasonDisplay = (reason) => {
  switch (reason) { 
    case 'admin_adjust':
      return { text: 'Admin', color: '#95101e' };
    case 'cancelled_by_student':
      return { text: 'CL by S.', color: 'red' };
    case 'cancelled_by_teacher':
      return { text: 'CL by T.', color: 'orange' };
    case 'class_on_hold_by_student':
      return { text: 'On hold by S.', color: 'blue' };
    case 'class_on_hold_by_school':
      return { text: 'On hold by P.', color: 'green' };
    case 'cancel_same_day':
      return { text: 'Cancel Same Day', color: '#FF4500' }; // Added Cancel Same Day
    case 'make_up':
      return { text: 'Make Up', color: '#8A2BE2' }; // Added Make Up
    case 'others':
      return { text: 'Others', color: 'darkgrey' };
    default:
      return { text: reason || '', color: 'black' };
  }
};


const TeacherHours = () => {
  const userId = getUserIdFromToken();
  const { t } = useTranslation();
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [classChangeLogs, setClassChangeLogs] = useState([]);
  const [registrationCounts, setRegistrationCounts] = useState({});
  const [selectedMonthYear, setSelectedMonthYear] = useState(getDefaultPeriod());
  const [payoutFilter, setPayoutFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const API_URL = process.env.REACT_APP_API_URL;
  const [shownToasts, setShownToasts] = useState(new Set());

  const [isMobile, setIsMobile] = useState(window.innerWidth < 480);
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 480);
      };
      
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

  const formatDate = (dateString) => moment(dateString).format('DD MMM YYYY');

  const formatScheduleHour = (scheduleHourString) => {
    const [hours, minutes] = scheduleHourString.split(' ').map((value) => parseInt(value));
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  };

  const calculateTimeRange = (scheduleTime, scheduleHour) => {
    const startTime = moment(scheduleTime, 'HH:mm');
    const [hours, minutes] = scheduleHour.split(' ').map(value => parseInt(value));
    const endTime = startTime.clone().add(hours, 'hours').add(minutes, 'minutes');
    return `${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}`;
  };

  const calculateTotalHours = (classes) => {
    return classes.reduce((total, cls) => {
      const [hours, minutes] = cls.schedule_hour.split(' ').map(value => parseInt(value));
      return total + (hours * 60 + minutes);
    }, 0);
  };

  const formatTotalHours = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes}m`;
  };

  
  useEffect(() => {
    const fetchClassesWithStatus = async () => {
      const token = localStorage.getItem('token');
      try {
        const [classesRes, statusRes] = await Promise.all([
          axios.get(`${API_URL}/classes/teacher/${userId}`, { headers: { Authorization: `Bearer ${token}` } }),
          axios.get(`${API_URL}/classes/${userId}/classes-status`, { headers: { Authorization: `Bearer ${token}` } }),
        ]);

        const classesWithStatus = classesRes.data.map(cls => {
          const statusClass = statusRes.data.find(statusCls => statusCls.classid === cls.classid);
          return {
            ...cls,
            status: statusClass ? statusClass.status : 'pending',
            payout: statusClass ? statusClass.payout : 'pending',
          };
        });
        setClasses(classesWithStatus);
        setFilteredClasses(classesWithStatus);
      } catch (error) {
        console.error(t('errorFetchingClasses'), error);
      }
    };
    fetchClassesWithStatus();
  }, [userId, API_URL, t]);

  useEffect(() => {
    const fetchClassChangeLogs = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${API_URL}/class-change/class-change-logs`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setClassChangeLogs(response.data);
      } catch (error) {
        console.error('Error fetching class change logs:', error);
      }
    };
    fetchClassChangeLogs();
  }, [API_URL]);

  useEffect(() => {
    const fetchRegistrationCounts = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/registrations/registrations-count`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        // Convert array to an object with groupid as keys for easier lookup
        const countsObject = {};
        if (Array.isArray(response.data)) {
          response.data.forEach(item => {
            countsObject[item.groupid] = item.registered_students;
          });
        }

        setRegistrationCounts(countsObject);
      } catch (error) {
        console.error('Error fetching registration counts:', error);
      }
    };

    fetchRegistrationCounts(); // Call the function directly in useEffect

  }, [API_URL]);


  useEffect(() => {
    classChangeLogs.forEach(log => {
      if (log.changereason === 'cancelled_by_student' && !shownToasts.has(log.id)) {
        toast.error(`Class ${log.class_code} was cancelled by the student.`, {
          position: "top-center",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setShownToasts(prev => new Set(prev).add(log.id));
      }
    });
  }, [classChangeLogs, shownToasts]);

  useEffect(() => {
    const applyFilters = () => {
      // Parse the period parts from the selectedMonthYear format
      const [endYear, endMonth, startYear, startMonth] = selectedMonthYear.split('-').map(Number);
      
      // Create date objects for start and end of period
      const startDate = new Date(startYear, startMonth - 1, 26); // 26th of start month
      const endDate = new Date(endYear, endMonth - 1, 25); // 25th of end month
      
      let filtered = classes.filter(cls => {
        const classDate = new Date(cls.date);
        return classDate >= startDate && classDate <= endDate;
      });
  
      if (payoutFilter) {
        filtered = filtered.filter(cls => cls.payout === payoutFilter);
      }
  
      setFilteredClasses(filtered);
      setCurrentPage(1);
    };
    
    applyFilters();
  }, [selectedMonthYear, classes, payoutFilter]);
  

  const handleApprove = (classId, classDate, scheduleTime, scheduleHour) => {
    const now = moment().tz('Asia/Bangkok');
    
    // Combine date and time
    const classDateTime = moment.tz(classDate, 'Asia/Bangkok')
      .hours(parseInt(scheduleTime.split(':')[0]))
      .minutes(parseInt(scheduleTime.split(':')[1]));
      
    const [hours, minutes] = scheduleHour.split(' ').map(value => parseInt(value));
    const endTime = classDateTime.clone().add(hours, 'hours').add(minutes, 'minutes');
  
    if (now.isBefore(endTime)) {
      return notification.error({
        message: t('Cannot Approve Yet'),
        description: t('Approval is only allowed after the class session has ended.'),
      });
    }
  
    confirmAlert({
      title: t('Confirm Approval'),
      message: t('Are you sure you want to approve this class?'),
      buttons: [
        { label: t('Yes'), onClick: () => handleSubmitApproval(classId) },
        { label: t('No'), onClick: () => {} },
      ],
    });
  };
  
  

  const handleSubmitApproval = async (classId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${API_URL}/classes/${classId}/approve`, {}, { headers: { Authorization: `Bearer ${token}` } });
      notification.success({ message: t('Class approved successfully!') });
      setClasses(prevClasses => prevClasses.map(cls => cls.classid === classId ? { ...cls, status: 'approved' } : cls));
    } catch (error) {
      notification.error({ message: t('Failed to approve class. Please try again.') });
    }
  };

  const TableSummary = ({ classes }) => {
    const totalMinutes = calculateTotalHours(classes);
    const formattedTotal = formatTotalHours(totalMinutes);
    
    // Parse the period parts
    const [endYear, endMonth, startYear, startMonth] = selectedMonthYear.split('-').map(Number);
    
    // Format the display label
    const startMonthName = new Date(startYear, startMonth - 1).toLocaleString('default', { month: 'short' });
    const endMonthName = new Date(endYear, endMonth - 1).toLocaleString('default', { month: 'short' });
    const periodDisplay = `26 ${startMonthName} - 25 ${endMonthName} ${endYear}`;
    
    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={5} className="text-right font-medium">
            {t('Total Hours for')} {periodDisplay}:
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} colSpan={4}>
            <span className="font-bold text-blue-800">{formattedTotal}</span>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };
  

  const columns = [
    { title: t('classcode'), dataIndex: 'class_code', key: 'class_code', ellipsis: true },
    { title: t('classid'), dataIndex: 'classid', key: 'classid', ellipsis: true },
    {
      title: t('subjectGroup'),
      key: 'subject_group',
      width: 120,
      render: (_, record) => (
        record.subject_group_name ? (
          <Tag color="#95101e">
            {record.subject_group_name}
          </Tag>
        ) : (
          <Tag color="default">
            {record.subjectgroup || t('noGroup')}
          </Tag>
        )
      ),
    },
    { title: t('subject'), dataIndex: 'subject_name', key: 'subject_name', ellipsis: true },
    { title: t('date'), dataIndex: 'date', key: 'date', render: formatDate },
    {
      title: t('time'),
      key: 'time_range',
      render: (_, record) => calculateTimeRange(record.schedule_time, record.schedule_hour),
    },
    { title: t('hours'), dataIndex: 'schedule_hour', key: 'schedule_hour', render: formatScheduleHour },
    {
      title: t('subjectGroup'),
      dataIndex: 'group_name',
      key: 'group_name',
      render: (groupName, record) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {groupName ? (
            <Tag color="cyan" icon={<TagOutlined />}>
              {groupName}
            </Tag>
          ) : (
            <span style={{ color: '#bfbfbf', fontStyle: 'italic' }}>
              {t('noSubjectGroup')}
            </span>
          )}
        </div>
      ),
    },
     {
          title: t('type'),
          key: 'type',
          width: 100,
          render: (_, record) => (
            <Tag color={record.isprivate ? 'blue' : 'green'}>
              {record.isprivate ? t('private') : t('group')}
            </Tag>
          ),
        },
        {
              title: t('no. students'),
              key: 'registered_students',
              width: 120,
              render: (_, record) => {
                const count = registrationCounts[record.groupid] || 0;
        
                let color = 'default';
                if (count > 0) color = 'blue';
                if (count >= 5) color = 'green';
                if (count >= 10) color = 'purple';
                
                return (
                  <Tag color={color}>
                    {count}
                  </Tag>
                );
              },
            },
            
    {
      title: t('approve'),
      key: 'approve',
      render: (_, record) => (
        record.status === 'approved' ? (
          <Tag icon={<CheckCircleOutlined />} color="success">{t('HOURSAPPROVED')}</Tag>
        ) : (
          <Button
            type="primary"
            size="small"
            onClick={() => handleApprove(record.classid, record.date, record.schedule_time, record.schedule_hour)}
          >
            {t('confirmteach')}
          </Button>
        )
      ),
    },    
    {
      title: t('payment'),
      key: 'payment',
      render: (_, record) => (
        <Tag color={record.payout === 'pending' ? 'orange' : 'green'}>
          {record.payout === 'pending' ? t('Pending') : t('Paid')}
        </Tag>
      ),
    },
    {
      title: t('classchange'),
      key: 'classchange',
      render: (_, record) => {
        const changeLog = classChangeLogs.find(log => log.classid === record.classid);
        const changeReason = changeLog ? changeLog.changereason : '';
        const { text, color } = getChangeReasonDisplay(changeReason);
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: t('note'),
      dataIndex: 'note',
      key: 'note',
      ellipsis: true,
      width: 200, // Optional: add fixed width
      render: (_, record) => {
        const changeLog = classChangeLogs.find(log => log.classid === record.classid);
        return changeLog?.note ? (
          <span className="text-gray-600">{changeLog.note}</span>
        ) : (
          <span className="text-gray-400">-</span>
        );
      }
    }
  ];

 return (
    <Layout>
      <Sidebar />
      <Layout>
        <Header />
        <Content style={{ 
      padding: '24px', 
      marginTop: '60px',
      marginLeft: isMobile ? '0' : '70px'
    }}>
          <Card title={t('my_hours')}>
            <Tabs defaultActiveKey="classes">
            <TabPane tab={t('Classes')} key="classes">
              <Space style={{ marginBottom: 16 }}>
                <Select
                  value={selectedMonthYear}
                  onChange={value => setSelectedMonthYear(value)}
                  style={{ width: 250 }}
                  options={generateMonthYearOptions()}
                />
                <Select
                  value={payoutFilter}
                  onChange={value => setPayoutFilter(value)}
                  style={{ width: 150 }}
                >
                  <Option value="">{t('All Payouts')}</Option>
                  <Option value="pending">{t('Pending')}</Option>
                  <Option value="paid">{t('Paid')}</Option>
                </Select>
              </Space>
              <Table
                columns={columns}
                dataSource={filteredClasses}
                rowKey="classid"
                pagination={{
                  current: currentPage,
                  pageSize: itemsPerPage,
                  total: filteredClasses.length,
                  onChange: setCurrentPage,
                  showSizeChanger: false,
                }}
                scroll={{ x: 'max-content' }}
                style={{ width: '100%' }}
                summary={() => <TableSummary classes={filteredClasses} />}
              />
            </TabPane>
              
              <TabPane tab={t('Hours Summary')} key="summary">
                <MonthlyHoursSummary teacherId={userId} />
              </TabPane>
              <TabPane tab={t('Payout')} key="payout">
                <TeacherPayout teacherId={userId} />
              </TabPane>
            </Tabs>
          </Card>
        </Content>
      </Layout>
    </Layout>
  );
};

export default withRoleAccess(TeacherHours, ['teacher']);