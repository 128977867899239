import React, { useRef, useEffect, useState } from 'react';
import withRoleAccess from '../../hoc/withRoleAccess';
import { Modal, Typography, Descriptions, Divider, Button, Space, Tag, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';

const { Title, Text, Paragraph } = Typography;

const LessonPlanView = ({ visible, onClose, lessonPlan, classInfo, user }) => {
  const { t } = useTranslation();
  const printRef = useRef();
  const [isMobile, setIsMobile] = useState(false);
  
  // Handle responsive layout
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);
  
  const formatDate = (date) => {
    try {
      if (!date) return '-';
      
      const momentDate = moment(date);
      if (!momentDate.isValid()) return '-';
      
      return momentDate.format('DD MMM YYYY').replace(/[a-z]/g, letter => letter.toUpperCase());
    } catch (error) {
      console.error("Error formatting date:", error);
      return '-';
    }
  };

  const formatTime = (time) => {
    try {
      if (!time) return '-';
      return moment(time, 'HH:mm:ss').format('hh:mm A');
    } catch (error) {
      console.error("Error formatting time:", error);
      return '-';
    }
  };

  const getStatusTag = (status) => {
    if (status === 'completed') {
      return <Tag color="green">{t('completed')}</Tag>;
    } else if (status === 'cancelled') {
      return <Tag color="red">{t('cancelled')}</Tag>;
    }
    return <Tag color="blue">{status || t('planned')}</Tag>;
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Lesson_Plan_${lessonPlan?.id || 'Detail'}`,
    onAfterPrint: () => console.log('Print successful'),
    pageStyle: `
      @page {
        size: A4;
        margin: 1cm;
      }
      @media print {
        body {
          -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
        }
      }
    `
  });

  if (!lessonPlan) return null;

  const teacherName = user ? ` ${user.nickname || ''} ${user.firstname || ''} ${user.lastname || ''}`.trim() : '';

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      width={isMobile ? '100%' : 800}
      style={{ top: isMobile ? 0 : 20 }}
      bodyStyle={isMobile ? { padding: '12px', maxHeight: '85vh', overflowY: 'auto' } : {}}
      footer={[
        <Button key="close" onClick={onClose}>
          {t('close')}
        </Button>,
        <Button 
          key="print" 
          type="primary" 
          icon={<FontAwesomeIcon icon={faPrint} />}
          onClick={handlePrint}
        >
          {t('print')}
        </Button>
      ]}
    >
      <div ref={printRef} style={{ padding: isMobile ? '12px' : '20px' }}>
        {/* Top section with current date and teacher name */}
        <Row style={{ marginBottom: '10px' }}>
          {/* Current date (top left) */}
          <Col xs={12} style={{ textAlign: 'left' }}>
            <Text strong>{t('Date')}:</Text> {moment().format('DD MMM YYYY')}
          </Col>
          
          {/* Teacher Name (top right) */}
          <Col xs={12} style={{ textAlign: 'right' }}>
            <Text strong>{t('Teacher')}:</Text> {teacherName}
          </Col>
        </Row>

        <div className="lesson-plan-header" style={{ textAlign: 'center', marginBottom: '20px' }}>
          <Title level={isMobile ? 4 : 3}>{lessonPlan.title}</Title>
          <Space>
            {getStatusTag(lessonPlan.status)}
            <Text type="secondary">{`ID: ${lessonPlan.id}`}</Text>
          </Space>
        </div>

        <Descriptions
          bordered
          column={isMobile ? 1 : 2}
          size={isMobile ? "small" : "default"}
          title={<Title level={5}>{t('Lesson Information')}</Title>}
          layout={isMobile ? "vertical" : "horizontal"}
        >
          <Descriptions.Item label={t('classcode')}>{classInfo?.class_code || '-'}</Descriptions.Item>
          <Descriptions.Item label={t('subject')}>{classInfo?.subject_name || '-'}</Descriptions.Item>
          <Descriptions.Item label={t('date')}>{formatDate(lessonPlan.lesson_date)}</Descriptions.Item>
          <Descriptions.Item label={t('time')}>
            {formatTime(lessonPlan.start_time)} - {formatTime(lessonPlan.end_time)}
          </Descriptions.Item>
          <Descriptions.Item label={t('room')}>{classInfo?.classroom_number || '-'}</Descriptions.Item>
          <Descriptions.Item label={t('day')}>{classInfo?.schedule_day || '-'}</Descriptions.Item>
        </Descriptions>

        <Divider />

        <div className="lesson-plan-content">
          <div className="section">
            <Title level={5}>{t('Lesson Brief')}</Title>
            <Paragraph>{lessonPlan.description || t('noDescriptionProvided')}</Paragraph>
          </div>

          <Divider />

          <div className="section">
            <Title level={5}>{t('Learning Objectives')}</Title>
            <Paragraph>{lessonPlan.learning_objectives || t('noObjectivesProvided')}</Paragraph>
          </div>

          <Divider />

          <div className="section">
            <Title level={5}>{t('Activities')}</Title>
            <Paragraph>{lessonPlan.activities || t('noActivitiesProvided')}</Paragraph>
          </div>

          <Divider />

          <div className="section">
            <Title level={5}>{t('Assessment')}</Title>
            <Paragraph>{lessonPlan.assessment || t('noAssessmentProvided')}</Paragraph>
          </div>

          <Divider />

          <div className="section">
            <Title level={5}>{t('Homework')}</Title>
            <Paragraph>{lessonPlan.homework || t('noHomeworkProvided')}</Paragraph>
          </div>

          <Divider />

          <div className="section">
            <Title level={5}>{t('Materials')}</Title>
            <Paragraph>{lessonPlan.materials || t('noMaterialsProvided')}</Paragraph>
          </div>

          {lessonPlan.notes && (
            <>
              <Divider />
              <div className="section">
                <Title level={5}>{t('notes')}</Title>
                <Paragraph>{lessonPlan.notes}</Paragraph>
              </div>
            </>
          )}
        </div>

        <div className="print-footer" style={{ marginTop: '30px', textAlign: 'center', display: 'none' }}>
          <Text type="secondary">{`${t('printed')}: ${moment().format('DD MMM YYYY, HH:mm')}`}</Text>
        </div>
      </div>
    </Modal>
  );
};

export default withRoleAccess(LessonPlanView, ['teacher']);