import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Table, Button, Modal, message, Spin, Space, Form, Input, Tabs } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import SubjectModal from './SubjectModal';

const { confirm } = Modal;
const { TabPane } = Tabs;

function EditDeleteSubject() {
  const { t } = useTranslation();
  // Subject states
  const [subjects, setSubjects] = useState([]);
  const [showSubjectModal, setShowSubjectModal] = useState(false);
  const [currentSubject, setCurrentSubject] = useState(null);
  
  // Subject ID states
  const [subjectIds, setSubjectIds] = useState([]);
  const [subjectIdForm] = Form.useForm();
  const [subjectIdModalVisible, setSubjectIdModalVisible] = useState(false);
  const [editingSubjectId, setEditingSubjectId] = useState(null);
  
  // Shared states
  const [loading, setLoading] = useState(true);
  const [loadingSubjectIds, setLoadingSubjectIds] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  
  const API_URL = process.env.REACT_APP_API_URL;

  // Fetch regular subjects
  const fetchSubjects = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/subjects`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const sortedSubjects = response.data.sort((a, b) => a.subjectid - b.subjectid);
      setSubjects(sortedSubjects);
    } catch (error) {
      console.error('Error fetching subjects:', error);
      message.error(t('fetchSubjectsError'));
    } finally {
      setLoading(false);
    }
  }, [API_URL, t]);

  // Fetch subject IDs from the new table
  const fetchSubjectIds = useCallback(async () => {
    try {
      setLoadingSubjectIds(true);
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/subjects/subject-id/all`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      if (Array.isArray(response.data)) {
        setSubjectIds(response.data);
      } else {
        setSubjectIds([]);
      }
    } catch (error) {
      console.error('Error fetching subject IDs:', error);
      message.error(t('fetchSubjectIdsError'));
    } finally {
      setLoadingSubjectIds(false);
    }
  }, [API_URL, t]);

  useEffect(() => {
    fetchSubjects();
    fetchSubjectIds();
  }, [fetchSubjects, fetchSubjectIds]);

  // Subject management functions
  const handleEdit = (subject) => {
    setCurrentSubject(subject);
    setShowSubjectModal(true);
  };

  const handleDelete = (subjectId) => {
    confirm({
      title: t('confirmDelete'),
      icon: <ExclamationCircleOutlined />,
      content: t('areYouSureToDelete'),
      onOk: async () => {
        try {
          const token = localStorage.getItem('token');
          await axios.delete(`${API_URL}/subjects/${subjectId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setSubjects((prev) => prev.filter((subject) => subject.subjectid !== subjectId));
          message.success(t('subjectDeleted'));
        } catch (error) {
          console.error('Error deleting subject:', error);
          message.error(t('deleteSubjectError'));
        }
      },
    });
  };

  const handleSaveSubject = async (subjectData) => {
    try {
      const token = localStorage.getItem('token');
      let response;
      if (currentSubject) {
        response = await axios.put(`${API_URL}/subjects/${currentSubject.subjectid}`, subjectData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSubjects((prev) =>
          prev.map((subject) => (subject.subjectid === response.data.subjectid ? response.data : subject))
        );
        message.success(t('subjectUpdated'));
      } else {
        response = await axios.post(`${API_URL}/subjects`, subjectData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSubjects((prev) => [...prev, response.data]);
        message.success(t('subjectCreated'));
      }
      setShowSubjectModal(false);
    } catch (error) {
      console.error('Error saving subject:', error);
      message.error(t('saveSubjectError'));
    }
  };

  // Subject ID management functions
  const handleAddEditSubjectId = (record = null) => {
    if (record) {
      setEditingSubjectId(record.id);
      subjectIdForm.setFieldsValue({
        name: record.name
      });
    } else {
      setEditingSubjectId(null);
      subjectIdForm.resetFields();
    }
    setSubjectIdModalVisible(true);
  };

  const handleDeleteSubjectId = (id) => {
    confirm({
      title: t('confirmDelete'),
      icon: <ExclamationCircleOutlined />,
      content: t('areYouSureToDeleteSubjectId'),
      onOk: async () => {
        try {
          const token = localStorage.getItem('token');
          await axios.delete(`${API_URL}/subjects/subject-id/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          message.success(t('subjectIdDeleted'));
          fetchSubjectIds();
        } catch (error) {
          console.error('Error deleting subject ID:', error);
          message.error(t('deleteSubjectIdError'));
        }
      },
    });
  };

  const handleSaveSubjectId = async (values) => {
    try {
      const token = localStorage.getItem('token');
      
      if (editingSubjectId) {
        // Update existing subject ID
        await axios.put(`${API_URL}/subjects/subject-id/${editingSubjectId}`, values, {
          headers: { Authorization: `Bearer ${token}` },
        });
        message.success(t('subjectIdUpdated'));
      } else {
        // Create new subject ID
        await axios.post(`${API_URL}/subjects/subject-id`, values, {
          headers: { Authorization: `Bearer ${token}` },
        });
        message.success(t('subjectIdCreated'));
      }
      
      setSubjectIdModalVisible(false);
      subjectIdForm.resetFields();
      fetchSubjectIds();
    } catch (error) {
      console.error('Error saving subject ID:', error);
      message.error(t('saveSubjectIdError'));
    }
  };

  // Table column definitions
 // Replace the existing subjectColumns array with this updated one
const subjectColumns = [
  { title: t('code'), dataIndex: 'code', key: 'code' },
  { 
    title: t('group'), 
    key: 'group',
    render: (text, record) => (
      <span>
        {record.subject_group_name || record.subjectgroup}
      </span>
    )
  },
  { title: t('subjecttitle'), dataIndex: 'title', key: 'title' },
  { title: t('level'), dataIndex: 'level', key: 'level' },
  { title: t('description'), dataIndex: 'description', key: 'description' },
  {
    title: t('actions'),
    key: 'actions',
    render: (text, record) => (
      <div className="action-buttons">
        <Button
        type="primary"
            shape="circle"
          icon={<EditOutlined />}
          onClick={() => handleEdit(record)}
        />
        <Button
        type="primary"
            shape="circle"
          icon={<DeleteOutlined />}
          danger
          onClick={() => handleDelete(record.subjectid)}
        />
      </div>
    ),
  }
];

  const subjectIdColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '20%',
    },
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('actions'),
      key: 'actions',
      width: '20%',
      render: (_, record) => (
        <Space>
          <Button
          type="primary"
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => handleAddEditSubjectId(record)}
            
          />
          <Button
          type="primary"
            shape="circle"
            icon={<DeleteOutlined />}
            danger
            onClick={() => handleDeleteSubjectId(record.id)}
            
          />
        </Space>
      ),
    },
  ];

  if (loading && activeTab === '1') return <Spin tip={t('loading')} />;
  if (loadingSubjectIds && activeTab === '2') return <Spin tip={t('loading')} />;

  return (
    <div className="subject-table-container">
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <TabPane tab={t('subjects')} key="1">
          <div style={{ marginBottom: '16px' }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setCurrentSubject(null);
                setShowSubjectModal(true);
              }}
            >
              {t('createSubject')}
            </Button>
          </div>
          
          <Table
            dataSource={subjects}
            columns={subjectColumns}
            rowKey="subjectid"
            pagination={{ pageSize: 100 }}
            scroll={{ x: 'max-content' }}
            loading={loading}
          />
        </TabPane>
        
        <TabPane tab={t('Group')} key="2">
          <div style={{ marginBottom: '16px' }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => handleAddEditSubjectId()}
            >
              {t('Create Group')}
            </Button>
          </div>
          
          <Table
            dataSource={subjectIds}
            columns={subjectIdColumns}
            rowKey="id"
            pagination={{ pageSize: 10 }}
            loading={loadingSubjectIds}
          />
        </TabPane>
      </Tabs>

      {/* Subject Modal */}
      <SubjectModal
        visible={showSubjectModal}
        onClose={() => setShowSubjectModal(false)}
        onSave={handleSaveSubject}
        subjectData={currentSubject}
        subjectGroups={subjectIds}
      />

      {/* Subject ID Modal */}
      <Modal
        title={editingSubjectId ? t('editSubjectGroup') : t('createSubjectGroup')}
        visible={subjectIdModalVisible}
        onCancel={() => setSubjectIdModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setSubjectIdModalVisible(false)}>
            {t('cancel')}
          </Button>,
          <Button key="submit" type="primary" onClick={() => subjectIdForm.submit()}>
            {editingSubjectId ? t('update') : t('create')}
          </Button>,
        ]}
      >
        <Form form={subjectIdForm} layout="vertical" onFinish={handleSaveSubjectId}>
          <Form.Item
            name="name"
            label={t('subjectGroupName')}
            rules={[{ required: true, message: t('fieldRequired') }]}
          >
            <Input placeholder={t('enterSubjectGroupName')} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default withRoleAccess(EditDeleteSubject, ['admin', 'superadmin']);