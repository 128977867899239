import React, { useEffect, useState } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Card,
  Select,
  Button,
  Space,
  Typography,
  Tag,
  message,
  Tooltip,
  Popconfirm,
  Row,
  Col,
  Statistic
} from 'antd';
import {
  FileExcelOutlined,
  CalendarOutlined,
  UserOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  BookOutlined,
  CheckOutlined
} from '@ant-design/icons';
import moment from 'moment';

const { Text } = Typography;
const { Option } = Select;

const generateMonthYearOptions = () => {
  const options = [];
  const startYear = 2024;
  const startMonth = 7; // August (0-indexed, so January is 0, August is 7)
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  for (let year = startYear; year <= currentYear + 5; year++) { // Loop up to the next 5 years
    for (let month = 0; month < 12; month++) {
      // Ensure we only start from August 2024 onwards
      if (year === startYear && month < startMonth) continue;

      // Construct the month-year label
      const monthYear = new Date(year, month).toLocaleString('default', {
        month: 'short',
        year: 'numeric'
      }).toUpperCase();

      options.push({ value: `${year}-${month + 1}`, label: monthYear });

      // Stop if we go past the current month in the current year
      if (year === currentYear && month >= currentMonth) {
        continue;
      }
    }
  }

  return options;
};

const TutorClassLog = () => {
  const { t } = useTranslation();
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    showTotal: (total) => `${t('Total')}: ${total} ${t('classes')}`,
  });

  // Get the current month and year in 'YYYY-M' format
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // Months are 0-indexed, so add 1
  const defaultMonthYear = `${currentYear}-${currentMonth}`;

  const [selectedMonthYear, setSelectedMonthYear] = useState(defaultMonthYear);
  const [statusFilter, setStatusFilter] = useState(null);
  const [adminapproveFilter, setAdminapproveFilter] = useState(null);
  const [tutorFilter, setTutorFilter] = useState(null);
  const [summaryStats, setSummaryStats] = useState({
    totalClasses: 0,
    confirmedClasses: 0,
    pendingClasses: 0,
    adminConfirmed: 0
  });

  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchClasses = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/classes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setClasses(response.data);
      setFilteredClasses(response.data);
      setLoading(false);
      calculateSummaryStats(response.data);
    } catch (error) {
      console.error('Error fetching classes:', error);
      message.error(t('Failed to fetch classes'));
      setLoading(false);
    }
  };

  const calculateSummaryStats = (data) => {
    const stats = {
      totalClasses: data.length,
      confirmedClasses: data.filter(cls => cls.status === 'approved').length,
      pendingClasses: data.filter(cls => cls.status !== 'approved').length,
      adminConfirmed: data.filter(cls => cls.adminapprove === 'adminconfirm').length
    };
    setSummaryStats(stats);
  };

  useEffect(() => {
    const applyFilters = () => {
      const filtered = classes.filter((cls) => {
        const classDate = new Date(cls.date);
        const classMonthYear = `${classDate.getFullYear()}-${classDate.getMonth() + 1}`;
        
        const isMonthYearMatch = selectedMonthYear ? classMonthYear === selectedMonthYear : true;
        const isStatusMatch = statusFilter ? cls.status === statusFilter : true;
        const isAdminapproveMatch = adminapproveFilter ? cls.adminapprove === adminapproveFilter : true;
        const isTutorMatch = tutorFilter
          ? cls.nickname === tutorFilter
          : true;
          
        return isMonthYearMatch && isStatusMatch && isAdminapproveMatch && isTutorMatch;
      }).sort((a, b) => {
        // Sort by date first
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        if (dateA !== dateB) return dateA - dateB;
  
        // If the date is the same, sort by schedule_time
        const timeA = new Date(`1970-01-01T${a.schedule_time}`).getTime();
        const timeB = new Date(`1970-01-01T${b.schedule_time}`).getTime();
        return timeA - timeB;
      });
      
      setFilteredClasses(filtered);
      setPagination(prev => ({
        ...prev,
        current: 1,
        total: filtered.length
      }));
      
      calculateSummaryStats(filtered);
    };
    
    applyFilters();
  }, [selectedMonthYear, statusFilter, adminapproveFilter, tutorFilter, classes]);

  const getUniqueTutors = () => {
    const tutors = classes.map(cls => cls.nickname);
    return [...new Set(tutors)].sort();
  };

  const handleAdminApprove = async (classId, status) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${process.env.REACT_APP_API_URL}/classes/${classId}/adminapprove`, {
        adminapprove: status
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      // Update the class state with the new approval status
      setClasses(prevClasses => prevClasses.map(cls => 
        cls.classid === classId ? { ...cls, adminapprove: status } : cls
      ));
      
      message.success(t('Class approved successfully'));
    } catch (error) {
      console.error('Error confirming hours:', error);
      message.error(t('Failed to approve class'));
    }
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const formatTime = (timeString) => {
    return moment(`1970-01-01T${timeString}`).format('HH:mm');
  };

  const csvData = filteredClasses.map(cls => ({
    No: cls.classid,
    Date: new Date(cls.date).toLocaleDateString(),
    ClassCode: cls.class_code,
    ClassID: cls.classid,
    Subject: cls.subject_name,
    Teacher: `${cls.nickname} (${cls.teacher_name})`,
    Time: formatTime(cls.schedule_time),
    ScheduleHour: cls.schedule_hour,
    Status: cls.status === 'approved' ? t('confirmed') : t('confirmpending'),
    AdminApprove: cls.adminapprove === 'adminconfirm' ? t('Admin Confirmed') : t('Pending')
  }));

  const formatDate = (dateString) => {
    return moment(dateString).format('DD MMM YYYY');
  };


  const columns = [
    {
      title: t('No'),
      dataIndex: 'classid',
      key: 'no',
      width: 70,
      render: (_, __, index) => (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      width: 120,
      render: (date) => (
        <Space>
          <CalendarOutlined />
          <Text>{formatDate(date)}</Text>
        </Space>
      ),
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
    },
    {
      title: t('classcode'),
      dataIndex: 'class_code',
      key: 'classcode',
      width: 120,
      render: (code) => <Tag color="blue">{code}</Tag>,
    },
    {
      title: t('classid'),
      dataIndex: 'classid',
      key: 'classid',
      width: 80,
    },
    {
      title: t('subject'),
      dataIndex: 'subject_name',
      key: 'subject',
      width: 150,
      render: (subject) => (
        <Tooltip title={subject}>
          <Space>
            <BookOutlined />
            <Text ellipsis style={{ maxWidth: 120 }}>{subject}</Text>
          </Space>
        </Tooltip>
      ),
    },
    {
      title: t('teacher'),
      dataIndex: 'nickname',
      key: 'teacher',
      width: 120,
      render: (nickname) => (
        <Space>
          <UserOutlined />
          <Text>{nickname}</Text>
        </Space>
      ),
      filters: getUniqueTutors().map(tutor => ({ text: tutor, value: tutor })),
      onFilter: (value, record) => record.nickname === value,
    },
    {
      title: t('time'),
      dataIndex: 'schedule_time',
      key: 'time',
      width: 100,
      render: (time) => (
        <Space>
          <ClockCircleOutlined />
          <Text>{formatTime(time)}</Text>
        </Space>
      ),
    },
    {
      title: t('Hour'),
      dataIndex: 'schedule_hour',
      key: 'hour',
      width: 80,
      render: (hours) => <Tag color="purple">{hours}</Tag>,
    },
    {
      title: t('confirmteach'),
      dataIndex: 'status',
      key: 'status',
      width: 140,
      render: (status) => {
        return status === 'approved' ? (
          <Tag icon={<CheckCircleOutlined />} color="success">
            {t('HOURSAPPROVED')}
          </Tag>
        ) : (
          <Tag icon={<ClockCircleOutlined />} color="warning">
            {t('confirmpending')}
          </Tag>
        );
      },
      filters: [
        { text: t('confirmed'), value: 'approved' },
        { text: t('confirmpending'), value: 'pending' },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: t('adminapprove'),
      dataIndex: 'adminapprove',
      key: 'adminapprove',
      width: 160,
      render: (status, record) => {
        return status === 'adminconfirm' ? (
          <Tag icon={<CheckCircleOutlined />} color="success">
            {t('Admin Confirmed')}
          </Tag>
        ) : (
          <Popconfirm
            title={t('Confirm Admin Approval')}
            description={t('Are you sure you want to confirm this class?')}
            onConfirm={() => handleAdminApprove(record.classid, 'adminconfirm')}
            okText={t('Yes')}
            cancelText={t('No')}
          >
            <Button 
              type="primary" 
              size="small" 
              icon={<CheckOutlined />}
            >
              {t('Admin Confirm')}
            </Button>
          </Popconfirm>
        );
      },
      filters: [
        { text: t('Admin Confirmed'), value: 'adminconfirm' },
        { text: t('Pending'), value: 'pending' },
      ],
      onFilter: (value, record) => record.adminapprove === value,
    },
  ];

  return (
    <div style={{ padding: '24px' }}>
      {/* Summary Cards */}
      <Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
        <Col xs={24} sm={12} md={6}>
          <Card>
            <Statistic
              title={t('Total Classes')}
              value={summaryStats.totalClasses}
              valueStyle={{ color: '#1890ff' }}
              prefix={<CalendarOutlined />}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Card>
            <Statistic
              title={t('Confirmed Classes')}
              value={summaryStats.confirmedClasses}
              valueStyle={{ color: '#52c41a' }}
              prefix={<CheckCircleOutlined />}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Card>
            <Statistic
              title={t('Pending Classes')}
              value={summaryStats.pendingClasses}
              valueStyle={{ color: '#faad14' }}
              prefix={<ClockCircleOutlined />}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Card>
            <Statistic
              title={t('Admin Confirmed')}
              value={summaryStats.adminConfirmed}
              valueStyle={{ color: '#722ed1' }}
              prefix={<CheckOutlined />}
            />
          </Card>
        </Col>
      </Row>

      {/* Filters and Export */}
      <Card style={{ marginBottom: 16 }}>
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Row gutter={[16, 16]} align="middle">
            <Col xs={24} sm={24} md={18}>
              <Space wrap size="middle">
                <Space>
                  <Text strong><CalendarOutlined /> {t('Month/Year')}:</Text>
                  <Select
                    style={{ width: 150 }}
                    value={selectedMonthYear}
                    onChange={setSelectedMonthYear}
                    placeholder={t('Select Month/Year')}
                    allowClear
                  >
                    <Option value="">{t('All Months/Years')}</Option>
                    {generateMonthYearOptions().map(option => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </Space>

                <Space>
                  <Text strong><UserOutlined /> {t('Tutor')}:</Text>
                  <Select
                    style={{ width: 150 }}
                    value={tutorFilter}
                    onChange={setTutorFilter}
                    placeholder={t('Select Tutor')}
                    allowClear
                    showSearch
                  >
                    <Option value="">{t('All Tutors')}</Option>
                    {getUniqueTutors().map(tutor => (
                      <Option key={tutor} value={tutor}>
                        {tutor}
                      </Option>
                    ))}
                  </Select>
                </Space>

                <Space>
                  <Text strong><CheckCircleOutlined /> {t('Status')}:</Text>
                  <Select
                    style={{ width: 150 }}
                    value={statusFilter}
                    onChange={setStatusFilter}
                    placeholder={t('Select Status')}
                    allowClear
                  >
                    <Option value="">{t('All Statuses')}</Option>
                    <Option value="approved">{t('confirmed')}</Option>
                    <Option value="pending">{t('confirmpending')}</Option>
                  </Select>
                </Space>

                <Space>
                  <Text strong><CheckOutlined /> {t('Admin Status')}:</Text>
                  <Select
                    style={{ width: 180 }}
                    value={adminapproveFilter}
                    onChange={setAdminapproveFilter}
                    placeholder={t('Select Admin Status')}
                    allowClear
                  >
                    <Option value="">{t('All Statuses')}</Option>
                    <Option value="adminconfirm">{t('Admin Confirmed')}</Option>
                    <Option value="pending">{t('Pending')}</Option>
                  </Select>
                </Space>
              </Space>
            </Col>
            
            <Col xs={24} sm={24} md={6} style={{ textAlign: 'right' }}>
              <CSVLink 
                data={csvData} 
                filename={`tutor_class_log_${selectedMonthYear || 'all'}.csv`}
                style={{ textDecoration: 'none' }}
              >
                <Button 
                  type="primary" 
                  icon={<FileExcelOutlined />}
                >
                  {t('exportToCSV')}
                </Button>
              </CSVLink>
            </Col>
          </Row>
        </Space>
      </Card>

      {/* Table */}
      <Card>
        <Table
          columns={columns}
          dataSource={filteredClasses}
          rowKey="classid"
          pagination={pagination}
          onChange={handleTableChange}
          loading={loading}
          scroll={{ x: 'max-content' }}
          size="middle"
          bordered
        />
      </Card>
    </div>
  );
};

export default withRoleAccess(TutorClassLog, ['superadmin', 'admin']);