import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import {
  Layout,
  Card,
  Typography,
  Select,
  Button,
  Table,
  Space,
  Input,
  Tag,
  Modal,
  Checkbox,
  Descriptions,
  Tooltip,
} from 'antd';
import {
  SearchOutlined,
  FileExcelOutlined,
  UserOutlined,
  TagOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment-timezone';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import withRoleAccess from '../../hoc/withRoleAccess';

const { Content } = Layout;
const { Title, Text } = Typography;

const formatDate = (dateString) => moment(dateString).format('DD MMM YYYY');

const generatePeriodOptions = () => {
  const options = [];
  const startYear = 2025; // Starting year
  const startMonth = 1;   // Starting month
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // 1-based month

  // Generate options spanning multiple years
  for (let year = startYear; year <= currentYear + 1; year++) {
    for (let month = 1; month <= 12; month++) {
      // Skip months before the start date
      if (year === startYear && month < startMonth) continue;
      
      // Calculate previous month for period start (26th)
      const prevMonth = month === 1 ? 12 : month - 1;
      const prevYear = month === 1 ? year - 1 : year;
      
      // Format for display: "26 Feb - 25 Mar 2025"
      const startMonthName = new Date(prevYear, prevMonth - 1).toLocaleString('default', { month: 'short' });
      const endMonthName = new Date(year, month - 1).toLocaleString('default', { month: 'short' });
      const label = `26 ${startMonthName} - 25 ${endMonthName} ${year}`;
      
      // Store month/year information as the value
      // Format: "year-month" for end date (we calculate start date when needed)
      const value = `${year}-${month}`;
      
      options.push({ value, label });
      
      // Don't add periods too far in the future
      if (year === currentYear + 1 && month >= currentMonth) break;
    }
  }

  return options;
};

const TeacherDetailsPage = () => {
  const { teacherId } = useParams();
  const { t } = useTranslation(); // useTranslation hook
  const [teacher, setTeacher] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [hourlyRates, setHourlyRates] = useState({});
  const [isApproved, setIsApproved] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [withholdingTax, setWithholdingTax] = useState(false); 
  const [showConfirmModal, setShowConfirmModal] = useState(false); 
  const [confirmData, setConfirmData] = useState({}); 
  const [classChangeLogs, setClassChangeLogs] = useState([]);
  const [registrationCounts, setRegistrationCounts] = useState({});
  const [remarks, setRemarks] = useState({});
  const [hourlyRateDetails, setHourlyRateDetails] = useState({});
  const [selectedPeriod, setSelectedPeriod] = useState(`${new Date().getFullYear()}-${new Date().getMonth() + 1}`);
  const [tempPeriod, setTempPeriod] = useState(`${new Date().getFullYear()}-${new Date().getMonth() + 1}`);
  
  const API_URL = process.env.REACT_APP_API_URL;

  const handleSelect = () => {
    setSelectedPeriod(tempPeriod);
    
    // Extract year and month from period value
    const [year, month] = tempPeriod.split('-').map(Number);
    
    // Set these values for compatibility with existing code
    setSelectedMonth(month);
    setSelectedYear(year);
    
    // Filter classes with the extracted month and year
    filterClasses(classes, month, year);
  };
  
  const calculateTimeRange = (scheduleTime, scheduleHour) => {
    if (!scheduleTime || !scheduleHour) {
      return "Invalid Time";
    }
  
    const startTime = moment(scheduleTime, 'HH:mm');
    const [hours, minutes] = (scheduleHour || "0 0").split(' ').map(value => parseInt(value) || 0);
    const endTime = startTime.clone().add(hours, 'hours').add(minutes, 'minutes');
  
    return `${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}`;
  };
  
  const formatScheduleHour = (scheduleHourString) => {
    if (!scheduleHourString) {
      return "00:00";
    }
  
    const [hours, minutes] = (scheduleHourString || "0 0").split(' ').map(value => parseInt(value) || 0);
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  };
  

  const fetchTeacher = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/teachers/${teacherId}`, {
        headers: {
          Authorization: `Bearer ${token}` 
        }
      });
      setTeacher(response.data);
    } catch (error) {
      console.error(t('errorFetchingTeacher'), error);
    }
  }, [teacherId, API_URL, t]);

  const fetchClasses = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/classes/teacher/${teacherId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setClasses(response.data);
      filterClasses(response.data, selectedMonth, selectedYear);
    } catch (error) {
      console.error(t('errorFetchingClasses'), error);
    }
  }, [teacherId, selectedMonth, selectedYear, API_URL, t]);

  const fetchApprovedClasses = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const periodString = getPeriodString(selectedMonth, selectedYear);
      
      const response = await axios.get(`${API_URL}/teacher-hours/${teacherId}/approved`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          period: periodString
        }
      });
      
      // Rest of the function remains the same
      if (response.data.length > 0) {
        setIsApproved(true);
        setFilteredClasses(response.data);
        const rates = {};
        response.data.forEach(({ class_id, hourly_rate }) => {
          rates[class_id] = hourly_rate;
        });
        setHourlyRates(rates);
      } else {
        setIsApproved(false);
      }
    } catch (error) {
      console.error(t('errorFetchingApprovedClasses'), error);
    }
  }, [teacherId, selectedMonth, selectedYear, API_URL, t]);

  const fetchClassChangeLogs = useCallback(async () => {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/class-change/class-change-logs`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setClassChangeLogs(response.data);
    } catch (error) {
        console.error('Error fetching class change logs:', error);
    }
}, [API_URL]);

const fetchRegistrationCounts = useCallback(async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/registrations/registrations-count`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    // Convert array to an object with groupid as keys for easier lookup
    const countsObject = {};
    if (Array.isArray(response.data)) {
      response.data.forEach(item => {
        countsObject[item.groupid] = item.registered_students;
      });
    }
    
    setRegistrationCounts(countsObject);
  } catch (error) {
    console.error('Error fetching registration counts:', error);
  }
}, [API_URL]);


  useEffect(() => {
    fetchTeacher();
    fetchClasses();
    fetchApprovedClasses();
    fetchClassChangeLogs(); 
    fetchRegistrationCounts(); 
  }, [fetchTeacher, fetchClasses, fetchApprovedClasses, fetchClassChangeLogs, fetchRegistrationCounts]);


  const fetchHourlyRates = useCallback(async (classList) => {
    try {
      const token = localStorage.getItem('token');
      const rates = {};
      const rateDetails = {};
  
      // Loop through all class records to fetch their respective rates
      for (const cls of classList) {
        try {
          // Extract the necessary parameters
          const { classid, groupid, schedule_hour } = cls;
          
          // Skip if no groupid is available
          if (!groupid) {
            rates[classid] = 0;
            continue;
          }
          
          // Get the student count for this class group
          const studentCount = registrationCounts[groupid] || 0;
  
          // Simplify the call to only use groupId - the API will handle the rest
          const response = await axios.get(`${API_URL}/subject-group/hourly-rate`, {
            params: { 
              groupId: groupid,
              scheduleHour: schedule_hour,
              studentCount
            },
            headers: { Authorization: `Bearer ${token}` },
          });

          if (response.data && response.data.hourlyRate) {
            rates[classid] = parseFloat(response.data.hourlyRate);

            rateDetails[classid] = {
              flatRate: response.data.flatRate,
              additionalPayout: response.data.additionalPayout,
              studentCount: response.data.studentCount
            };
          } else {
            rates[classid] = 0;
          }
        } catch (error) {
          console.error(`Error fetching rate for class ${cls.classid}:`, error);
          rates[cls.classid] = 0;
        }
      }
  
      setHourlyRates(rates);
      setHourlyRateDetails(rateDetails);
      
    } catch (error) {
      console.error('Error in fetchHourlyRates:', error);
    }
  }, [registrationCounts, API_URL]);

// Call this function inside useEffect after fetching class data
useEffect(() => {
  if (filteredClasses.length > 0 && Object.keys(registrationCounts).length > 0) {
    fetchHourlyRates(filteredClasses);
  }
}, [filteredClasses, registrationCounts, fetchHourlyRates]);



const filterClasses = (classes, month, year) => {
  // For period 26th of previous month to 25th of selected month
  const startDate = new Date(year, month - 2, 26); // 26th of previous month
  const endDate = new Date(year, month - 1, 25);   // 25th of current month

  const filtered = classes.filter(cls => {
    const classDate = new Date(cls.date);
    return classDate >= startDate && classDate <= endDate;
  });
  
  setFilteredClasses(filtered);
};

const getPeriodString = (month, year) => {
  // Previous month for start date
  const prevMonth = month === 1 ? 12 : month - 1;
  const prevYear = month === 1 ? year - 1 : year;
  
  // Format the period as "YYYY-MM-26_YYYY-MM-25"
  const startPeriod = `${prevYear}-${prevMonth < 10 ? '0' + prevMonth : prevMonth}-26`;
  const endPeriod = `${year}-${month < 10 ? '0' + month : month}-25`;
  
  return `${startPeriod}_${endPeriod}`;
};
  const convertScheduleHourToDecimal = (scheduleHour) => {
    if (!scheduleHour) return 0;
    const [hours, minutes] = scheduleHour.split('h').map(part => part.trim().replace('m', ''));
    const hoursDecimal = parseInt(hours, 10) || 0;
    const minutesDecimal = parseInt(minutes, 10) / 60 || 0;
    return hoursDecimal + minutesDecimal;
  };

  const formatDecimalToHoursMinutes = (decimalHours) => {
    const totalMinutes = Math.round(decimalHours * 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes}m`;
  };

  const handleApprove = () => {
    // Prepare data for approved classes
    const approvedClassesData = filteredClasses.map(cls => {
      const scheduleHourDecimal = convertScheduleHourToDecimal(cls.schedule_hour);
      const hourlyRate = hourlyRates[cls.classid] || (teacher ? teacher.hoursrate : 0);
      const rateDetails = hourlyRateDetails[cls.classid] || {};
      
      return {
        classId: cls.classid,
        groupId: cls.groupid,
        hourlyRate: hourlyRate,
        hoursWorked: scheduleHourDecimal,
        flatRate: rateDetails.flatRate || 0,
        additionalPayout: rateDetails.additionalPayout || 0,
        studentCount: registrationCounts[cls.groupid] || 0,
        totalAmount: scheduleHourDecimal * hourlyRate,
        remark: remarks[cls.classid] || '',
      };
    });
  
    const totalAmountWithTax = withholdingTax ? totalAmount * 0.97 : totalAmount;
    
    setConfirmData({
      teacherName: `${teacher.firstname} ${teacher.lastname}`,
      period: getDisplayPeriod(selectedMonth, selectedYear),
      totalAmount: formatAmount(totalAmountWithTax),
      approvedClasses: approvedClassesData,
    });
  
    setShowConfirmModal(true);
  };
  
  const confirmApproval = async () => {
    try {
      const token = localStorage.getItem('token');
      const periodString = getPeriodString(selectedMonth, selectedYear);
      
      await axios.post(`${API_URL}/teacher-hours/${teacherId}/approve`, {
        period: periodString,
        approvedClasses: confirmData.approvedClasses.map(cls => ({
          classId: cls.classId,
          hourlyRate: cls.hourlyRate,
          hoursWorked: cls.hoursWorked.toFixed(2),
          flatRate: cls.flatRate,
          additionalPayout: cls.additionalPayout,
          studentCount: cls.studentCount,
          totalAmount: cls.totalAmount,
          remark: cls.remark
        })),
        totalAmount: parseFloat(confirmData.totalAmount.replace(/,/g, '')),
        withholdingTaxApplied: withholdingTax 
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      setShowConfirmation(true);
      fetchApprovedClasses();
      setShowConfirmModal(false);
    } catch (error) {
      console.error(t('errorApprovingHours'), error);
    }
  };
  
  const getDisplayPeriod = (month, year) => {
    // For display purposes
    const prevMonth = month === 1 ? 12 : month - 1;
    const prevYear = month === 1 ? year - 1 : year;
    
    return `26/${prevMonth < 10 ? '0' + prevMonth : prevMonth}/${prevYear} - 25/${month < 10 ? '0' + month : month}/${year}`;
  };

  const handleRemarkChange = (classId, value) => {
    setRemarks((prevRemarks) => ({
      ...prevRemarks,
      [classId]: value,
    }));
  };

const getChangeReasonDisplay = (reason) => {
  switch (reason) {
      case 'admin_adjust':
          return { text: 'Admin', color: '#95101e' };
      case 'cancelled_by_student':
          return { text: 'CL by S.', color: 'red' };
      case 'cancelled_by_teacher':
          return { text: 'CL by T.', color: 'orange' };
      case 'class_on_hold_by_student':
          return { text: 'On hold by S.', color: 'blue' };
      case 'class_on_hold_by_school':
          return { text: 'On hold by P.', color: 'green' };
      case 'cancel_same_day':
          return { text: 'Cancel Same Day', color: '#FF4500' }; // Added Cancel Same Day
      case 'make_up':
          return { text: 'Make Up', color: '#8A2BE2' }; // Added Make Up
      case 'others':
          return { text: 'Others', color: 'darkgrey' };
      default:
          return { text: reason, color: 'black' };
  }
};


const handleExportCSV = () => {
  const periodString = getDisplayPeriod(selectedMonth, selectedYear).replace(/\//g, '-');
  
  const csvData = filteredClasses.map(cls => ({
    'Teacher ID': teacher ? teacher.userid : '',
    'Teacher Name': teacher ? `${teacher.firstname} ${teacher.lastname}` : '',
    'Class Code': cls.class_code,
    'Class ID': cls.classid,
    'Subject Name': cls.subject_name,
    'Date': formatDate(cls.date),
    'Schedule Hour': cls.schedule_hour,
    'Hourly Rate': hourlyRates[cls.classid] || (teacher ? teacher.hoursrate : 0),
    'Total Hours': convertScheduleHourToDecimal(cls.schedule_hour).toFixed(2),
    'Total Amount': formatAmount(convertScheduleHourToDecimal(cls.schedule_hour) * (hourlyRates[cls.classid] || (teacher ? teacher.hoursrate : 0))),
    'Status': cls.status,
    'Payout': cls.payout,
    'Period': periodString // Use periodString
  }));
  
  return csvData;
};

  const handleRateChange = (classId, value) => {
    setHourlyRates({
      ...hourlyRates,
      [classId]: parseFloat(value) || 0
    });
  };

  const totalScheduleHours = filteredClasses.reduce((acc, cls) => acc + convertScheduleHourToDecimal(cls.schedule_hour), 0);
  const totalAmount = filteredClasses.reduce((acc, cls) => {
    const scheduleHourDecimal = convertScheduleHourToDecimal(cls.schedule_hour);
    const hourlyRate = hourlyRates[cls.classid] || (teacher ? teacher.hoursrate : 0);
    return acc + (scheduleHourDecimal * hourlyRate);
  }, 0);

  const formatAmount = (amount) => {
    return amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const allClassesApproved = filteredClasses.every(cls => cls.status === 'approved');


      


  const columns = [
    {
      title: t('classCode'),
      dataIndex: 'class_code',
      key: 'class_code',
      width: 120,
    },
    {
      title: t('id'),
      dataIndex: 'classid',
      key: 'classid',
      width: 80,
    },
   
    {
      title: t('subjectGroup'),
      key: 'subject_group',
      width: 120,
      render: (_, record) => (
        record.subject_group_name ? (
          <Tag color="#95101e">
            {record.subject_group_name}
          </Tag>
        ) : (
          <Tag color="default">
            {record.subjectgroup || t('noGroup')}
          </Tag>
        )
      ),
    },
    {
      title: t('subject'),
      dataIndex: 'subject_name',
      key: 'subject_name',
      ellipsis: false,  
      render: subject_name => (
        <div style={{ 
          whiteSpace: 'normal',
          wordBreak: 'break-word'
        }}>
          {subject_name}
        </div>
      ),
    },
    { title: t('date'), dataIndex: 'date', key: 'date', render: formatDate },
    {
      title: t('time'),
      key: 'time_range',
      render: (_, record) => calculateTimeRange(record.schedule_time, record.schedule_hour),
    },
    { title: t('hours'), dataIndex: 'schedule_hour', key: 'schedule_hour', render: formatScheduleHour },
    {
      title: t('subjectGroup'),
      dataIndex: 'group_name',
      key: 'group_name',
      render: (groupName, record) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {groupName ? (
            <Tag color="cyan" icon={<TagOutlined />}>
              {groupName}
            </Tag>
          ) : (
            <span style={{ color: '#bfbfbf', fontStyle: 'italic' }}>
              {t('noSubjectGroup')}
            </span>
          )}
        </div>
      ),
    },
    {
      title: t('type'),
      key: 'type',
      width: 100,
      render: (_, record) => (
        <Tag color={record.isprivate ? 'blue' : 'green'}>
          {record.isprivate ? t('private') : t('group')}
        </Tag>
      ),
    },
    {
      title: t('registeredStudents'),
      key: 'registered_students',
      width: 120,
      render: (_, record) => {
        const count = registrationCounts[record.groupid] || 0;

        let color = 'default';
        if (count > 0) color = 'blue';
        if (count >= 5) color = 'green';
        if (count >= 10) color = 'purple';
        
        return (
          <Tag color={color}>
            {count}
          </Tag>
        );
      },
    },
    {
      title: t('rate'),
      key: 'rate',
      width: 120,
      render: (_, record) => {
        const hourlyRate = hourlyRates[record.classid] !== undefined 
          ? hourlyRates[record.classid] 
          : 0;
        
        const studentCount = registrationCounts[record.groupid] || 0;
        const details = hourlyRateDetails[record.classid] || {};
        
        // Create a compact tooltip content
        const tooltipContent = (
          <div style={{ padding: '8px', maxWidth: '200px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '4px' }}>
              <span style={{ fontWeight: 'bold' }}>{t('baseRate')}:</span>
              <span>{details.flatRate || 0}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '4px' }}>
              <span style={{ fontWeight: 'bold' }}>{t('additionalPayout')}:</span>
              <span>{details.additionalPayout || 0}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ fontWeight: 'bold' }}>{t('students')}:</span>
              <span>{studentCount}</span>
            </div>
          </div>
        );
        
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              type="number"
              size="small"
              value={hourlyRate}
              onChange={(e) => handleRateChange(record.classid, e.target.value)}
              disabled={isApproved}
              style={{ width: 80 }}
            />
            <Tooltip 
              title={tooltipContent}
              placement="right"
              overlayStyle={{ maxWidth: '300px' }}
              overlayInnerStyle={{ padding: 0 }}
            >
              <Button 
                type="text" 
                size="small"
                icon={<InfoCircleOutlined />} 
                style={{ marginLeft: '4px', padding: '0 8px' }}
              />
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: t('change'),
      key: 'change',
      width: 120,
      render: (_, record) => {
        const classChangeLog = classChangeLogs.find(log => log.classid === record.classid);
        const changeReason = getChangeReasonDisplay(classChangeLog?.changereason);
        return (
          <Tag color={changeReason.color}>
            {changeReason.text}
          </Tag>
        );
      },
    },
    {
      title: t('note'),
      key: 'note',
      render: (_, record) => {
        const classChangeLog = classChangeLogs.find(log => log.classid === record.classid);
        return classChangeLog?.note || '-';
      },
    },
    {
      title: t('total'),
      key: 'total',
      width: 100,
      render: (_, record) => {
        const scheduleHourDecimal = convertScheduleHourToDecimal(record.schedule_hour);
        const hourlyRate = hourlyRates[record.classid] || (teacher ? teacher.hoursrate : 0);
        const totalAmount = scheduleHourDecimal * hourlyRate;
        return formatAmount(totalAmount);
      },
    },
    {
      title: t('confirmteach'),
      key: 'status',
      width: 120,
      render: (_, record) => (
        <Tag color={record.status === 'approved' ? 'success' : 'processing'}>
          {record.status === 'approved' ? t('HOURSAPPROVED') : t('confirmpending')}
        </Tag>
      ),
    },
    {
      title: t('admin'),
      key: 'admin',
      width: 100,
      render: (_, record) => (
        <Tag color={record.adminapprove === 'adminconfirm' ? 'success' : 'processing'}>
          {record.adminapprove === 'adminconfirm' ? t('adminconfirmed') : t('confirmpending')}
        </Tag>
      ),
    },
    {
      title: t('status'),
      key: 'payout',
      width: 100,
      render: (_, record) => (
        <Tag color={record.payout === 'pending' ? 'warning' : 'success'}>
          {record.payout === 'pending' ? t('Pending') : t('Paid')}
        </Tag>
      ),
    },
    {
      title: t('remark'),
      key: 'remark',
      width: 200,
      render: (_, record) => (
        <Input
          placeholder={t('enterRemark')}
          value={remarks[record.classid] || ''}
          onChange={(e) => handleRemarkChange(record.classid, e.target.value)}
        />
      ),
    },
  ];

  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Header />
        <Content style={{ padding: '24px', marginLeft: '70px', marginTop: '60px' }}>
          <Card>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
              <Title level={2}>{t('teacherDetails')}</Title>
              
              {teacher && (
                <Descriptions bordered>
                  <Descriptions.Item label={t('teacherName')}>
                    <Space>
                      <UserOutlined />
                      {`(${teacher.nickname}) ${teacher.firstname} ${teacher.lastname}`}
                    </Space>
                  </Descriptions.Item>
                  <Descriptions.Item label={t('teacherID')}>
                    {teacher.userid}
                  </Descriptions.Item>
                </Descriptions>
              )}

              <Space>      
                <Select
                  value={tempPeriod}
                  onChange={setTempPeriod}
                  style={{ width: 250 }}
                  options={generatePeriodOptions()}
                />
                <Button 
                  type="primary" 
                  icon={<SearchOutlined />}
                  onClick={handleSelect}
                >
                  {t('search')}
                </Button>

                <Button 
                  icon={<FileExcelOutlined />}
                >
                  <CSVLink
                    data={handleExportCSV()}
                    filename={`approved_hours_${teacherId}_${selectedMonth}_${selectedYear}.csv`}
                  >
                    {t('exportToCSV')}
                  </CSVLink>
                </Button>
              </Space>

              <Table
                columns={columns}
                dataSource={filteredClasses.map((cls, index) => ({ ...cls, key: cls.classid || `temp-${index}` }))}
                rowKey="key"
                scroll={{ x: 1500 }}
                pagination={false}
                summary={() => (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={5}>
                        <Text strong>{t('total')}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5}>
                        <Text strong>{formatDecimalToHoursMinutes(totalScheduleHours)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6} colSpan={3} />
                      <Table.Summary.Cell index={9}>
                        <Text strong>{formatAmount(totalAmount)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={10} colSpan={4} />
                    </Table.Summary.Row>
                  </Table.Summary>
                )}
              />

              {allClassesApproved && !isApproved && (
                <Button 
                  type="primary"
                  onClick={handleApprove}
                  size="large"
                >
                  {t('approveHoursAndPayment')}
                </Button>
              )}
            </Space>
          </Card>

          <Modal
  title={t('confirm_payment')}
  open={showConfirmModal}
  onCancel={() => setShowConfirmModal(false)}
  footer={[
    <Button key="cancel" onClick={() => setShowConfirmModal(false)}>
      {t('cancel')}
    </Button>,
    <Button 
      key="confirm" 
      type="primary" 
      onClick={confirmApproval}
    >
      {t('confirm')}
    </Button>
  ]}
>
  <Space direction="vertical" style={{ width: '100%' }}>
    <Descriptions column={1}>
      <Descriptions.Item label={t('teacherName')}>
        {confirmData.teacherName}
      </Descriptions.Item>
      <Descriptions.Item label={t('period')}>
        {confirmData.period}
      </Descriptions.Item>
      <Descriptions.Item label={t('nettotal')}>
        {confirmData.totalAmount}
      </Descriptions.Item>
    </Descriptions>

    <Checkbox
      checked={withholdingTax}
      onChange={(e) => setWithholdingTax(e.target.checked)}
    >
      {t('sumWithholdingTax')}
    </Checkbox>
  </Space>
</Modal>

          <Modal
            title={t('success')}
            open={showConfirmation}
            onOk={() => setShowConfirmation(false)}
            onCancel={() => setShowConfirmation(false)}
            footer={[
              <Button key="ok" type="primary" onClick={() => setShowConfirmation(false)}>
                {t('close')}
              </Button>
            ]}
          >
            <p>{t('teacherHoursApprovalSaved')}</p>
          </Modal>
        </Content>
      </Layout>
    </Layout>
  );
};

export default withRoleAccess(TeacherDetailsPage, ['superadmin']);