import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
      .then(() => {
        updateBodyClass(lng);
      })
      .catch((err) => {
        console.error('Error changing language:', err);
      });
  };

  const updateBodyClass = (lng) => {
    document.body.classList.remove('lang-en', 'lang-th');
    if (lng === 'en') {
      document.body.classList.add('lang-en');
    } else if (lng === 'th') {
      document.body.classList.add('lang-th');
    }
  };

  useEffect(() => {
    // Set initial language class on load
    updateBodyClass(i18n.language);
  }, [i18n.language]);

  return (
    <div className="language-switcher">
      <select
        onChange={(e) => changeLanguage(e.target.value)}
        className="select-language"
        defaultValue={i18n.language}
      >
        <option value="en">EN</option>
        <option value="th">TH</option>
      </select>
    </div>
  );
};

export default LanguageSwitcher;
