import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Select,
  Button,
  message,
  Tabs,
  Table,
  Space,
  Typography,
  Divider,
  Empty,
  List,
  Tag,
  Spin,
  Popconfirm,
  Row,
  Col
} from 'antd';
import {
  DeleteOutlined,
  LinkOutlined,
  UserOutlined,
  BookOutlined,
  GlobalOutlined,
  PlusOutlined,
  FilterOutlined
} from '@ant-design/icons';

const { Option } = Select;
const { Text } = Typography;
const { TabPane } = Tabs;

const MatchTeacherSubject = () => {
  const { t } = useTranslation();
  const [teachers, setTeachers] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState(undefined);
  const [selectedSubject, setSelectedSubject] = useState(undefined);
  const [selectedLanguage, setSelectedLanguage] = useState(undefined);
  const [matches, setMatches] = useState([]);
  const [filteredTeachers, setFilteredTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('1');
  const [subjectFilterTab, setSubjectFilterTab] = useState('Thai');
  
  // Filter states for matched teachers
  const [matchedSelectedSubject, setMatchedSelectedSubject] = useState(undefined);
  const [matchedSelectedLanguage, setMatchedSelectedLanguage] = useState(undefined);
  
  const API_URL = process.env.REACT_APP_API_URL;

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        message.error(t('No token found. Please log in.'));
        return;
      }

      const [teachersResponse, subjectsResponse, matchesResponse] = await Promise.all([
        axios.get(`${API_URL}/teachers`, { headers: { Authorization: `Bearer ${token}` } }),
        axios.get(`${API_URL}/subjects`, { headers: { Authorization: `Bearer ${token}` } }),
        axios.get(`${API_URL}/match/match-teacher-to-subject`, { headers: { Authorization: `Bearer ${token}` } }),
      ]);

      setTeachers(teachersResponse.data);
      setSubjects(subjectsResponse.data);
      setMatches(matchesResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error(t('An error occurred while fetching data.'));
    } finally {
      setLoading(false);
    }
  }, [API_URL, t]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleMatch = async () => {
    if (!selectedTeacher || !selectedSubject || !selectedLanguage) {
      message.warning(t('Please select a teacher, subject, and language'));
      return;
    }

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        message.error(t('No token found. Please log in.'));
        return;
      }

      await axios.post(`${API_URL}/match/match-teacher-to-subject`, {
        teacherId: selectedTeacher,
        subjectId: selectedSubject,
        language: selectedLanguage
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      message.success(t('Match created successfully!'));
      fetchData();
      
      // Reset form
      setSelectedTeacher(undefined);
      setSelectedSubject(undefined);
      setSelectedLanguage(undefined);
      
    } catch (error) {
      console.error('Error creating match:', error);
      message.error(t('Error creating match: ') + (error.response ? error.response.data.message : t('Server error')));
    }
  };

  const handleDeleteMatch = async (matchId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${API_URL}/match/match-teacher-to-subject/${matchId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      message.success(t('Match deleted successfully!'));
      fetchData();
    } catch (error) {
      console.error('Error deleting match:', error);
      message.error(t('Error deleting match: ') + (error.response ? error.response.data.message : t('Server error')));
    }
  };

  useEffect(() => {
    const filterTeachers = () => {
      if (matchedSelectedSubject && matchedSelectedLanguage) {
        const filtered = matches.filter(match =>
          match.subject_id === parseInt(matchedSelectedSubject) &&
          match.language === matchedSelectedLanguage
        ).map(match => ({
          key: `${match.teacher_nickname}-${match.id}`,
          firstname: match.teacher_firstname,
          lastname: match.teacher_lastname,
          nickname: match.teacher_nickname
        }));
        setFilteredTeachers(filtered);
      } else {
        setFilteredTeachers([]);
      }
    };

    filterTeachers();
  }, [matchedSelectedSubject, matchedSelectedLanguage, matches]);

  const sortedSubjects = useMemo(() => 
    [...subjects].sort((a, b) => 
      a.title.localeCompare(b.title, undefined, { sensitivity: 'base' })
    ),
    [subjects]
  );

  // Group subjects by category for better organization
  const groupedSubjectsByLanguage = useMemo(() => {
    const thaiSubjects = {};
    const englishSubjects = {};

    matches.forEach(match => {
      const subjectId = match.subject_id.toString();
      const language = match.language;
      const teacherInfo = {
        id: match.id,
        nickname: match.teacher_nickname,
        firstname: match.teacher_firstname,
        lastname: match.teacher_lastname
      };

      if (language.toLowerCase() === 'thai') {
        if (!thaiSubjects[subjectId]) {
          const subjectTitle = match.subject_title;
          thaiSubjects[subjectId] = { title: subjectTitle, teachers: [] };
        }
        thaiSubjects[subjectId].teachers.push(teacherInfo);
      } else if (language.toLowerCase() === 'english') {
        if (!englishSubjects[subjectId]) {
          const subjectTitle = match.subject_title;
          englishSubjects[subjectId] = { title: subjectTitle, teachers: [] };
        }
        englishSubjects[subjectId].teachers.push(teacherInfo);
      }
    });

    return {
      thai: thaiSubjects,
      english: englishSubjects
    };
  }, [matches]);

  // Table columns for matches management
  const columns = [
    {
      title: t('teacher'),
      dataIndex: 'teacher',
      key: 'teacher',
      render: (_, record) => (
        <span>
          <UserOutlined style={{ marginRight: 8 }} />
          {record.teacher_nickname} {record.teacher_firstname} {record.teacher_lastname}
        </span>
      ),
    },
    {
      title: t('subject'),
      dataIndex: 'subject_title',
      key: 'subject',
      render: (text) => (
        <span>
          <BookOutlined style={{ marginRight: 8 }} />
          {text}
        </span>
      ),
    },
    {
      title: t('language'),
      dataIndex: 'language',
      key: 'language',
      render: (language) => {
        const color = language.toLowerCase() === 'thai' ? 'blue' : 'green';
        return (
          <Tag color={color} icon={<GlobalOutlined />}>
            {language}
          </Tag>
        );
      },
      filters: [
        { text: 'Thai', value: 'Thai' },
        { text: 'English', value: 'English' },
      ],
      onFilter: (value, record) => record.language === value,
    },
    {
      title: t('actions'),
      key: 'actions',
      render: (_, record) => (
        <Popconfirm
          title={t('Are you sure you want to delete this match?')}
          onConfirm={() => handleDeleteMatch(record.id)}
          okText={t('Yes')}
          cancelText={t('No')}
        >
          <Button 
            type="text" 
            danger 
            icon={<DeleteOutlined />}
          />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div style={{ padding: '24px' }}>      
      <Tabs 
        activeKey={activeTab} 
        onChange={setActiveTab} 
        type="card"
        style={{ marginBottom: 24 }}
      >
        {/* Tab 1: Create and Find Matches */}
        <TabPane 
          tab={
            <span>
              <PlusOutlined />
              {t('Create & Find Matches')}
            </span>
          } 
          key="1"
        >
          <Spin spinning={loading}>
            <Row gutter={[24, 24]}>
              {/* Create Match Card */}
              <Col xs={24} md={12}>
                <Card
                  title={
                    <Space>
                      <LinkOutlined />
                      {t('matchingTeachers')}
                    </Space>
                  }
                  bordered
                >
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <div>
                      <Text strong>{t('selectTeacher')}:</Text>
                      <Select
                        placeholder={t('selectATeacher')}
                        style={{ width: '100%', marginTop: 8 }}
                        value={selectedTeacher}
                        onChange={setSelectedTeacher}
                        showSearch
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {teachers.map(teacher => (
                          <Option key={teacher.userid} value={teacher.userid}>
                            {teacher.nickname} {teacher.firstname} {teacher.lastname}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    
                    <div>
                      <Text strong>{t('selectSubject')}:</Text>
                      <Select
                        placeholder={t('selectASubject')}
                        style={{ width: '100%', marginTop: 8 }}
                        value={selectedSubject}
                        onChange={setSelectedSubject}
                        showSearch
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {sortedSubjects.map(subject => (
                          <Option key={subject.subjectid} value={subject.subjectid}>
                            {subject.title}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    
                    <div>
                      <Text strong>{t('selectLanguage')}:</Text>
                      <Select
                        placeholder={t('selectALanguage')}
                        style={{ width: '100%', marginTop: 8 }}
                        value={selectedLanguage}
                        onChange={setSelectedLanguage}
                      >
                        <Option value="Thai">{t('thai')}</Option>
                        <Option value="English">{t('english')}</Option>
                      </Select>
                    </div>
                    
                    <Button 
                      type="primary" 
                      onClick={handleMatch} 
                      icon={<PlusOutlined />}
                      style={{ marginTop: 16 }}
                    >
                      {t('createMatch')}
                    </Button>
                  </Space>
                </Card>
              </Col>
              
              {/* Find Matched Teachers Card */}
              <Col xs={24} md={12}>
                <Card
                  title={
                    <Space>
                      <FilterOutlined />
                      {t('matchedTeachers')}
                    </Space>
                  }
                  bordered
                >
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <div>
                      <Text strong>{t('selectSubject')}:</Text>
                      <Select
                        placeholder={t('selectASubject')}
                        style={{ width: '100%', marginTop: 8 }}
                        value={matchedSelectedSubject}
                        onChange={setMatchedSelectedSubject}
                        showSearch
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {sortedSubjects.map(subject => (
                          <Option key={subject.subjectid} value={subject.subjectid}>
                            {subject.title}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    
                    <div>
                      <Text strong>{t('selectLanguage')}:</Text>
                      <Select
                        placeholder={t('selectALanguage')}
                        style={{ width: '100%', marginTop: 8 }}
                        value={matchedSelectedLanguage}
                        onChange={setMatchedSelectedLanguage}
                      >
                        <Option value="Thai">{t('thai')}</Option>
                        <Option value="English">{t('english')}</Option>
                      </Select>
                    </div>
                    
                    <Divider />
                    
                    <div style={{ minHeight: 200 }}>
                      <Text strong>{t('Results')}</Text>
                      {filteredTeachers.length > 0 ? (
                        <List
                          itemLayout="horizontal"
                          dataSource={filteredTeachers}
                          renderItem={teacher => (
                            <List.Item>
                              <List.Item.Meta
                                avatar={<UserOutlined style={{ fontSize: 18 }} />}
                                title={`${teacher.nickname} ${teacher.firstname} ${teacher.lastname}`}
                              />
                            </List.Item>
                          )}
                          style={{ marginTop: 16 }}
                        />
                      ) : (
                        <Empty
                          description={t('noTeachersFound')}
                          style={{ marginTop: 32 }}
                        />
                      )}
                    </div>
                  </Space>
                </Card>
              </Col>
            </Row>
          </Spin>
        </TabPane>
        
        {/* Tab 2: Subject Overview */}
        <TabPane 
          tab={
            <span>
              <BookOutlined />
              {t('Subject Overview')}
            </span>
          } 
          key="2"
        >
          <Spin spinning={loading}>
            <Card bordered>
              <Tabs 
                activeKey={subjectFilterTab} 
                onChange={setSubjectFilterTab}
                type="card"
                tabPosition="top"
              >
                <TabPane 
                  tab={<Tag color="blue" icon={<GlobalOutlined />}>{t('thai')}</Tag>} 
                  key="Thai"
                >
                  <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
                    {Object.entries(groupedSubjectsByLanguage.thai).map(([subjectId, data]) => (
                      <Col xs={24} sm={12} md={8} lg={6} key={subjectId}>
                        <Card 
                          title={data.title}
                          size="small"
                          bordered
                          style={{ height: '100%' }}
                        >
                          <List
                            dataSource={data.teachers}
                            renderItem={teacher => (
                              <List.Item>
                                <Text>{teacher.nickname} {teacher.firstname}</Text>
                              </List.Item>
                            )}
                            locale={{ emptyText: t('No teachers assigned') }}
                          />
                        </Card>
                      </Col>
                    ))}
                    {Object.keys(groupedSubjectsByLanguage.thai).length === 0 && (
                      <Col span={24}>
                        <Empty description={t('No Thai subject matches found')} />
                      </Col>
                    )}
                  </Row>
                </TabPane>
                
                <TabPane 
                  tab={<Tag color="green" icon={<GlobalOutlined />}>{t('english')}</Tag>} 
                  key="English"
                >
                  <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
                    {Object.entries(groupedSubjectsByLanguage.english).map(([subjectId, data]) => (
                      <Col xs={24} sm={12} md={8} lg={6} key={subjectId}>
                        <Card 
                          title={data.title}
                          size="small"
                          bordered
                          style={{ height: '100%' }}
                        >
                          <List
                            dataSource={data.teachers}
                            renderItem={teacher => (
                              <List.Item>
                                <Text>{teacher.nickname} {teacher.firstname}</Text>
                              </List.Item>
                            )}
                            locale={{ emptyText: t('No teachers assigned') }}
                          />
                        </Card>
                      </Col>
                    ))}
                    {Object.keys(groupedSubjectsByLanguage.english).length === 0 && (
                      <Col span={24}>
                        <Empty description={t('No English subject matches found')} />
                      </Col>
                    )}
                  </Row>
                </TabPane>
              </Tabs>
            </Card>
          </Spin>
        </TabPane>
        
        {/* Tab 3: Manage Matches */}
        <TabPane 
          tab={
            <span>
              <DeleteOutlined />
              {t('Manage Matches')}
            </span>
          } 
          key="3"
        >
          <Spin spinning={loading}>
            <Card bordered>
              <Table
                columns={columns}
                dataSource={matches}
                rowKey="id"
                pagination={{
                  pageSize: 10,
                  showSizeChanger: false,
                  showTotal: (total) => `${t('Total')}: ${total} ${t('matches')}`
                }}
              />
            </Card>
          </Spin>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default withRoleAccess(MatchTeacherSubject, ['superadmin', 'admin']);