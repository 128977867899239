import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import withRoleAccess from '../../hoc/withRoleAccess';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Table, Tabs, Input, Button, Card, Typography, Space } from 'antd';
import { SearchOutlined, LeftOutlined } from '@ant-design/icons';
import TeacherPaymentReport from './TeacherPaymentReport';
import TutorClassLogPayment from './TutorClassLogPayment';
import CreateScheme from '../Subject/CreateScheme';

const { Title } = Typography;

const TeacherHoursAdmin = () => {
  const { t } = useTranslation();
  const [teachers, setTeachers] = useState([]);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/teachers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTeachers(response.data);
      } catch (error) {
        console.error(t('errorFetchingTeachers'), error);
        setError(t('failedToFetchTeachers'));
      }
    };

    fetchTeachers();
  }, [API_URL, t]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const filteredTeachers = teachers.filter(
    (teacher) =>
      teacher.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      teacher.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      teacher.userid.toString().includes(searchTerm)
  );


  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentTeachers = filteredTeachers.slice(startIndex, endIndex);

  const columns = [
    {
      title: t('teacherID'),
      dataIndex: 'userid',
      key: 'userid',
      sorter: (a, b) => a.userid - b.userid,
    },
    {
      title: t('teacherName'),
      dataIndex: 'name',
      key: 'name',
      render: (_, teacher) => (
        <Link to={`/teacher-details/${teacher.userid}`}>
          ({teacher.nickname}) {teacher.firstname} {teacher.lastname}
        </Link>
      ),
    },
  ];

  return (
    <div>
      <Sidebar />
      <Header />
      <div style={{ padding: '16px', marginLeft: '70px' }}>

        <Button type="default" icon={<LeftOutlined />} onClick={() => window.history.back()}>
          {t('back')}
        </Button>

        <Card style={{ marginTop: 16 }}>
          <Title level={3}>{t('tutorHourlyRates')}</Title>

          {error && <p style={{ color: 'red' }}>{error}</p>}

          <Tabs
              defaultActiveKey="hours"
              items={[
                {
                  label: t('teacherHours'),
                  key: 'hours',
                  children: (
                    <>
                      <Space style={{ marginBottom: 16 }}>
                        <Input
                          placeholder={t('searchByNameOrID')}
                          prefix={<SearchOutlined />}
                          value={searchTerm}
                          onChange={handleSearchChange}
                          style={{ width: 300 }}
                        />
                      </Space>

                      <Table
                        dataSource={currentTeachers}
                        columns={columns}
                        pagination={{
                          current: currentPage,
                          pageSize: itemsPerPage,
                          total: filteredTeachers.length,
                          onChange: (page) => setCurrentPage(page),
                        }}
                        rowKey="userid"
                      />
                    </>
                  ),
                },
                {
                  label: t('paymentReport'),
                  key: 'payment',
                  children: <TeacherPaymentReport />,
                },
                {
                  label: t('tutorclasslogs'),
                  key: 'logs',
                  children: <TutorClassLogPayment />,
                },
                {
                  label: t('Create Payment Scheme'),
                  key: 'createpayment',
                  children: <CreateScheme />,
                },
              ]}
            />

        </Card>
      </div>
    </div>
  );
};

export default withRoleAccess(TeacherHoursAdmin, ['superadmin']);
