import React, { useState, useEffect } from 'react';
import axios from 'axios';
import withRoleAccess from '../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { 
  Row, 
  Col, 
  Card, 
  Statistic, 
  Typography, 
  Space, 
  List, 
  Avatar,
  Tag,
  Divider,
  Empty
} from 'antd';
import {
  UserOutlined,
  TeamOutlined,
  BookOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import './Dashboard.css';


const { Text } = Typography;

function AdminDashboard() {
  const { t, i18n } = useTranslation();
  const [stats, setStats] = useState([]);
  const [todayClasses, setTodayClasses] = useState([]);
  const [classGroups, setClassGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL;

  const formatMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, '0')} ${t('hr')}`;
  };


  useEffect(() => {
    const fetchStats = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        const [studentsResponse, teachersResponse, classesResponse] = await Promise.all([
          axios.get(`${API_URL}/students/count`, { headers }),
          axios.get(`${API_URL}/teachers/count`, { headers }),
          axios.get(`${API_URL}/class-groups/count`, { headers })
        ]);

        setStats({
          totalStudents: studentsResponse.data.total_students,
          totalTeachers: teachersResponse.data.total_teachers,
          totalClasses: classesResponse.data.total_classes,
          totalSales: 140000, // Replace with real sales data if available
        });
      } catch (error) {
        console.error('Error fetching stats:', error);
        setError(t('fetchStatsError'));
      }
    };

    const fetchTodayClasses = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
  
        
        const response = await axios.get(`${API_URL}/classes`, { headers });
    
    
        const today = new Date();
        const todayString = today.toISOString().split('T')[0]; 

    
        // Filter classes for today
        const todayClasses = response.data.filter(cls => {
          const classDate = new Date(cls.date).toISOString().split('T')[0]; 
          return classDate === todayString;
        });
    
        const sortedTodayClasses = todayClasses.sort((a, b) => {
          const [hoursA, minutesA] = a.schedule_time.split(':');
          const [hoursB, minutesB] = b.schedule_time.split(':');
          const dateA = new Date(a.date); 
          const dateB = new Date(b.date); 
          dateA.setHours(hoursA);
          dateA.setMinutes(minutesA);
          dateB.setHours(hoursB);
          dateB.setMinutes(minutesB);
          return dateA - dateB;
        });
        
    
        const formattedClasses = sortedTodayClasses.map(cls => ({
          classid: cls.classid,
          class_code: cls.class_code,
          subject_name: cls.subject_name,
          teacher_nickname: cls.nickname,
          schedule_time: cls.schedule_time,
          schedule_hour: cls.schedule_hour,
          date: cls.date
        }));
    
        setTodayClasses(formattedClasses);
      } catch (error) {
        console.error('Error fetching today\'s classes:', error);
        setError(t('fetchTodayClassesError'));
      }
    };
    
    const fetchNewCourses = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        const [classGroupsResponse, classesResponse] = await Promise.all([
          axios.get(`${API_URL}/class-groups?type=group`, { headers }),
          axios.get(`${API_URL}/classes`, { headers })
        ]);
    
        const classGroups = classGroupsResponse.data;
        const classes = classesResponse.data;
    
        const aggregatedClassGroups = classGroups.map(group => {
          const groupClasses = classes.filter(c => c.groupid === group.groupid);
    
          const start_date = groupClasses.length > 0 
            ? new Date(Math.min(...groupClasses.map(c => new Date(c.date)))) 
            : null;
          const end_date = groupClasses.length > 0 
            ? new Date(Math.max(...groupClasses.map(c => new Date(c.date)))) 
            : null;
    
          const subjectNames = [...new Set(groupClasses.map(c => c.subject_name))].join(', ');
          const teacherNames = [...new Set(groupClasses.map(c => c.nickname))].join(', ');
    
          return { 
            ...group, 
            start_date: start_date?.toISOString().split('T')[0], 
            end_date: end_date?.toISOString().split('T')[0], 
            subject_name: subjectNames, 
            nickname: teacherNames 
          };
        });
    
        const now = new Date();
    
        // Filter out groups with passed start_date and non-private classes
        const upcomingCourses = aggregatedClassGroups.filter(group => {
          return (
            group.start_date && 
            new Date(group.start_date) >= now && 
            !group.isprivate
          );
        });
    
        // Sort by start_date, closest date first
        const sortedCourses = upcomingCourses
          .sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
          .slice(0, 15); // Take only the first 15 courses
    
        setClassGroups(sortedCourses);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching class groups:', error);
        setError(t('fetchClassGroupsError'));
        setLoading(false);
      }
    };
    
    fetchStats();
    fetchTodayClasses();
    fetchNewCourses();
    
  }, [API_URL, t]);

  if (error) return <p>{error}</p>;

  const formatTimeToBangkok = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes);
    return date.toLocaleTimeString('en-GB', { timeZone: 'Asia/Bangkok', hour: '2-digit', minute: '2-digit' });
  };
  

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric'};
    return date.toLocaleDateString(i18n.language, options);
  };

  const formatScheduleHour = (scheduleHourString) => {
    const [hours, minutes] = scheduleHourString.split(' ').map((value) => parseInt(value));
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  };

  const getTodayClasses = () => {
    const today = new Date();
    const todayString = today.toISOString().split('T')[0]; 

  
    return todayClasses.filter(cls => {
      const classDate = new Date(cls.date).toISOString().split('T')[0]; 
      return classDate === todayString;
    });
  };

  const calculateEndTime = (scheduleTime, scheduleHour) => {
    const [hours, minutes] = scheduleTime.split(':').map(Number);
    let duration = 0;
  
    if (typeof scheduleHour === 'string') {
      if (scheduleHour.includes(':')) {
        const [durationHours, durationMinutes] = scheduleHour.split(':').map(Number);
        duration = durationHours * 60 + durationMinutes;
      } else {
        duration = parseFloat(scheduleHour) * 60;
      }
    } else if (typeof scheduleHour === 'number') {
      duration = scheduleHour * 60;
    }
  
    if (isNaN(duration)) {
      console.error('Invalid scheduleHour:', scheduleHour);
      return 'N/A';
    }
  
    const endDate = new Date();
    endDate.setHours(hours);
    endDate.setMinutes(minutes + duration);
  
    // Adjust to Bangkok timezone
    return endDate.toLocaleTimeString('en-GB', { timeZone: 'Asia/Bangkok', hour: '2-digit', minute: '2-digit' });
  };
  
  

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div style={{ padding: '24px' }}>
      {/* Statistics Section */}
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={8}>
          <Card hoverable>
            <Statistic
              title={t('totalStudents')}
              value={stats.totalStudents}
              prefix={<UserOutlined style={{ color: '#1890ff' }} />}

            />
          </Card>
        </Col>
        <Col xs={24} sm={8}>
          <Card hoverable>
            <Statistic
              title={t('totalTeachers')}
              value={stats.totalTeachers}
              prefix={<TeamOutlined style={{ color: '#52c41a' }} />}

            />
          </Card>
        </Col>
        <Col xs={24} sm={8}>
          <Card hoverable>
            <Statistic
              title={t('Total Courses')}
              value={stats.totalClasses}
              prefix={<BookOutlined style={{ color: '#faad14' }} />}

            />
          </Card>
        </Col>
      </Row>

      {/* Today's Classes Section */}
      <Row gutter={[16, 16]} style={{ marginTop: '24px' }}>
        <Col xs={24} lg={12}>
          <Card 
            title={
              <Space>
                <ClockCircleOutlined />
                <Text strong>{t('Today Classes')}</Text>
              </Space>
            }
          >
            {getTodayClasses().length === 0 ? (
              <Empty description={t('No Classes Today')} />
            ) : (
              <List
                itemLayout="horizontal"
                dataSource={getTodayClasses()}
                renderItem={classItem => {
                  const endTime = calculateEndTime(classItem.schedule_time, classItem.schedule_hour);
                  return (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Avatar style={{ backgroundColor: '#1890ff' }}>
                            {classItem.subject_name[0]}
                          </Avatar>
                        }
                        title={
                          <Space>
                            <Text strong>{classItem.subject_name}</Text>
                            <Tag color="blue">
                              {formatTimeToBangkok(classItem.schedule_time)} - {endTime}
                            </Tag>
                          </Space>
                        }
                        description={
                          <Space direction="vertical" size="small">
                            <Text type="secondary">{t('course')}: {classItem.class_code}</Text>
                            <Text type="secondary">{t('teacher')}: {classItem.teacher_nickname}</Text>
                            <Text type="secondary">{t('hours')}: {formatScheduleHour(classItem.schedule_hour)}</Text>
                          </Space>
                        }
                      />
                    </List.Item>
                  );
                }}
              />
            )}
          </Card>
        </Col>

        {/* New Class Groups Section */}
        <Col xs={24} lg={12}>
          <Card 
            title={
              <Space>
                <CalendarOutlined />
                <Text strong>
                  {t('Next 15 Class Groups')} ({classGroups.length}/10)
                </Text>
              </Space>
            }
          >
            
            <List
              itemLayout="vertical"
              dataSource={classGroups.filter(cls => !cls.isprivate)}
              locale={{ emptyText: <Empty description={t('No classes available')} /> }}
              renderItem={cls => (
                <List.Item
                  extra={
                    <Tag color="processing">
                      {formatMinutesToHours(cls.total_hours)}
                    </Tag>
                  }
                >
                  <List.Item.Meta
                    title={
                      <Space>
                        <Text strong>{cls.class_code}</Text>
                        <Divider type="vertical" />
                        <Text type="secondary">
                          {formatDate(cls.start_date)} - {formatDate(cls.end_date)}
                        </Text>
                      </Space>
                    }
                    description={
                      <Space direction="vertical" size="small">
                        <Space>
                          <InfoCircleOutlined />
                          <Text>{cls.subject_name}</Text>
                        </Space>
                        <Space>
                          <TeamOutlined />
                          <Text>{cls.nickname}</Text>
                        </Space>
                        <Space>
                          <UserOutlined />
                          <Text>{t('registerCapacity')}: {cls.register_capacity}</Text>
                        </Space>
                      </Space>
                    }
                  />
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default withRoleAccess(AdminDashboard, ['admin', 'superadmin']);