import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Input, Button, Row, Col, message, Upload } from 'antd';
import { 
  UserOutlined, 
  IdcardOutlined, 
  PhoneOutlined, 
  HomeOutlined, 
  BankOutlined, 
  TeamOutlined,
  LoadingOutlined,
  PlusOutlined
} from '@ant-design/icons';
import withRoleAccess from '../../hoc/withRoleAccess';

const EditStudentModal = ({ isOpen, onClose, onSave, studentId }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [fileList, setFileList] = useState([]);
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [uploadLoading, setUploadLoading] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        // When the modal is closed, reset the form and state
        if (!isOpen) {
            form.resetFields();
            setImageUrl('');
            setDateOfBirth('');
            setFileList([]);
            setLoading(true);
        }
    }, [isOpen, form]);

    useEffect(() => {
        // Only fetch if the modal is open and we have a student ID
        if (isOpen && studentId) {
            const fetchStudent = async () => {
                // Reset image URL at the start of fetch to prevent showing old image
                setImageUrl('');
                
                try {
                    const token = localStorage.getItem('token');
                    const response = await axios.get(`${API_URL}/students/${studentId}`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
    
                    const studentData = response.data;
    
                    // Format date of birth properly for the input field
                    let formattedDateOfBirth = '';
                    if (studentData.date_of_birth) {
                        // Convert to YYYY-MM-DD format
                        const date = new Date(studentData.date_of_birth);
                        formattedDateOfBirth = date.toISOString().split('T')[0];
                        setDateOfBirth(formattedDateOfBirth);
                    }
                    
                    // Set all form fields except date of birth which is handled separately
                    form.setFieldsValue({
                        ...studentData,
                        date_of_birth: undefined // Clear this to avoid conflict with our custom handler
                    });
    
                    if (studentData.nationalIdPdf) {
                        setFileList([
                            {
                                uid: '-1',
                                name: 'Uploaded File',
                                status: 'done',
                                url: studentData.nationalIdPdf,
                            }
                        ]);
                    }
    
                    // Set profile photo if available
                   // Set profile photo if available
                if (studentData.profile_photo) {
                    // Extract base URL (remove the API path part)
                    const baseUrl = API_URL.replace(/\/api.*$/, '');
                    
                    // Handle the case where profile_photo contains /var/www/uploads
                    if (studentData.profile_photo.includes('/var/www/uploads')) {
                        // Replace /var/www with nothing to get the correct URL path
                        const fixedPath = studentData.profile_photo.replace('/var/www', '');
                        setImageUrl(`${baseUrl}${fixedPath}`);
                    } else if (studentData.profile_photo.startsWith('/uploads')) {
                        // Already a relative path
                        setImageUrl(`${baseUrl}${studentData.profile_photo}`);
                    } else if (studentData.profile_photo.startsWith('http')) {
                        // If it's already a full URL, use it directly
                        setImageUrl(studentData.profile_photo);
                    } else {
                        // For any other format, append to base URL
                        setImageUrl(`${baseUrl}/${studentData.profile_photo}`);
                    }
                    
                    // Add cache busting parameter to force image refresh
                    console.log("Setting image URL with cache buster", Date.now());
                }
                } catch (error) {
                    console.error('Error fetching student:', error);
                    message.error(t('fetchStudentError'));
                } finally {
                    setLoading(false);
                }
            };
    
            fetchStudent();
        }
    }, [studentId, API_URL, form, t, isOpen]);
    
    // Function to handle profile photo upload
    const handleProfilePhotoChange = (info) => {
        if (info.file.status === 'uploading') {
            setUploadLoading(true);
            return;
        }
        
        if (info.file.status === 'done') {
            setUploadLoading(false);
        }
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must be smaller than 2MB!');
        }
        
        return false; // Return false to stop automatic upload and handle it manually
    };

    const handleSubmit = async (values) => {
        try {
            const token = localStorage.getItem('token');
    
            // Create FormData object to handle file uploads
            const formData = new FormData();
            
            // Add all text fields
            Object.keys(values).forEach(key => {
                // Skip undefined values and handled separately
                if (values[key] !== undefined && key !== 'profilePhoto') {
                    formData.append(key, values[key]);
                }
            });
            
            // Add date of birth
            formData.append('date_of_birth', dateOfBirth);
            
            // Add schoolid, ensuring it's a number or null
            formData.append('schoolid', values.schoolid ? parseInt(values.schoolid) : '');
            
            // Add profile photo if selected
            if (values.profilePhoto && values.profilePhoto.fileList && values.profilePhoto.fileList.length > 0) {
                formData.append('profilePhoto', values.profilePhoto.fileList[0].originFileObj);
            }
    
            // Make API request
            const response = await axios.put(`${API_URL}/students/${studentId}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            
            message.success(t('studentUpdatedSuccessfully'));
            
            // Call the onSave callback with the updated data
            if (onSave && typeof onSave === 'function') {
                onSave(response.data);
            }
            
            onClose();
        } catch (error) {
            console.error('Error updating student:', error);
            message.error(t('errorUpdatingStudent'));
        }
    };
    
    // Custom upload button for profile photo
    const uploadButton = (
        <div>
            {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>{t('upload')}</div>
        </div>
    );
    
    return (
        <Modal
            title={t('editStudent')}
            open={isOpen}
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose}>{t('cancel')}</Button>,
                <Button key="submit" type="primary" onClick={() => form.submit()}>{t('save')}</Button>,
            ]}
            width={800} // Wider modal to accommodate the photo
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{}}
            >
                <Row gutter={24}>
                    {/* Left side - Form fields */}
                    <Col span={16}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="firstname"
                                    label={t('firstName')}
                                    rules={[{ required: true, message: t('form.required_field') }]}
                                >
                                    <Input prefix={<UserOutlined />} placeholder={t('firstName')} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="lastname"
                                    label={t('lastName')}
                                    rules={[{ required: true, message: t('form.required_field') }]}
                                >
                                    <Input prefix={<UserOutlined />} placeholder={t('lastName')} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="nickname"
                                    label={t('nickname')}
                                >
                                    <Input placeholder={t('nickname')} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="email"
                                    label={t('email')}
                                    rules={[{ required: true, type: 'email', message: t('form.invalid_email') }]}
                                >
                                    <Input placeholder={t('email')} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label={t('dateOfBirth')}
                                    required
                                >
                                    <input
                                        type="date"
                                        value={dateOfBirth}  
                                        onChange={(e) => setDateOfBirth(e.target.value)}
                                        style={{
                                            width: "100%",
                                            padding: "8px",
                                            border: "1px solid #d9d9d9",
                                            borderRadius: "4px",
                                            fontSize: "14px",
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="nationalid"
                                    label={t('nationalID')}
                                >
                                    <Input prefix={<IdcardOutlined />} placeholder={t('nationalID')} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="phone"
                                    label={t('phone')}
                                >
                                    <Input prefix={<PhoneOutlined />} placeholder={t('phone')} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="address"
                                    label={t('address')}
                                >
                                    <Input prefix={<HomeOutlined />} placeholder={t('address')} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="currenteducationlevel"
                                    label={t('currentEducationLevel')}
                                >
                                    <Input placeholder={t('currentEducationLevel')} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="schoolname"
                                    label={t('schoolName')}
                                >
                                    <Input prefix={<BankOutlined />} placeholder={t('schoolName')} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="guardianname"
                                    label={t('guardianName')}
                                >
                                    <Input prefix={<TeamOutlined />} placeholder={t('guardianName')} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="guardiancontact"
                                    label={t('guardianContact')}
                                >
                                    <Input prefix={<PhoneOutlined />} placeholder={t('guardianContact')} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    
                    {/* Right side - Profile photo */}
                    <Col span={8}>
                        <div style={{ 
                            textAlign: 'center', 
                            padding: '20px', 
                            background: '#f9f9f9', 
                            borderRadius: '8px',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <h3 style={{ marginBottom: '20px' }}>{t('Profile Photo')}</h3>
                            
                            <Form.Item 
                                name="profilePhoto" 
                                valuePropName="file"
                                noStyle
                            >
                                <Upload
                                    name="profilePhoto"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={beforeUpload}
                                    onChange={handleProfilePhotoChange}
                                    maxCount={1}
                                    accept=".jpg,.jpeg,.png"
                                    style={{ width: '100%' }}
                                >
                                    {imageUrl ? (
                                        <img 
                                            src={imageUrl} 
                                            alt="Profile" 
                                            style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                                        />
                                    ) : uploadButton}
                                </Upload>
                            </Form.Item>
                            
                            <p style={{ marginTop: '10px', fontSize: '12px', color: '#888' }}>
                                {t('Click to upload a new photo')}
                            </p>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default withRoleAccess(EditStudentModal, ['admin', 'superadmin']);