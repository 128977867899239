import React, { useEffect, useState } from 'react';
import { Table, Select, Button, Space, Tag, Card } from 'antd';
import { CSVLink } from 'react-csv';
import { FileExcelOutlined } from '@ant-design/icons';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const generateMonthYearOptions = () => {
  const options = [];
  const startYear = 2024;
  const startMonth = 7; 
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  for (let year = startYear; year <= currentYear + 5; year++) {
    for (let month = 0; month < 12; month++) {
      if (year === startYear && month < startMonth) continue;
      const monthYear = new Date(year, month).toLocaleString('default', {
        month: 'short',
        year: 'numeric',
      }).toUpperCase();
      options.push({ value: `${year}-${month + 1}`, label: monthYear });
      if (year === currentYear && month >= currentMonth) break;
    }
  }
  return options;
};

const TutorClassLog = () => {
  const { t } = useTranslation();
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [selectedMonthYear, setSelectedMonthYear] = useState(`${new Date().getFullYear()}-${new Date().getMonth() + 1}`);
  const [statusFilter, setStatusFilter] = useState('');
  const [adminapproveFilter, setAdminapproveFilter] = useState('');
  const [payoutFilter, setPayoutFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/classes`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setClasses(response.data);
        setFilteredClasses(response.data);
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
    };
    fetchClasses();
  }, []);

  useEffect(() => {
    const filtered = classes.filter(cls => {
      const classDate = new Date(cls.date);
      const classMonthYear = `${classDate.getFullYear()}-${classDate.getMonth() + 1}`;
      return (
        (selectedMonthYear ? classMonthYear === selectedMonthYear : true) &&
        (statusFilter ? cls.status === statusFilter : true) &&
        (adminapproveFilter ? cls.adminapprove === adminapproveFilter : true) &&
        (payoutFilter ? cls.payout === payoutFilter : true)
      );
    });

    setFilteredClasses(filtered);
    setCurrentPage(1);
  }, [selectedMonthYear, statusFilter, adminapproveFilter, payoutFilter, classes]);

  const csvData = filteredClasses.map(cls => ({
    No: cls.classid,
    Date: new Date(cls.date).toLocaleDateString(),
    ClassCode: cls.class_code,
    ClassID: cls.classid,
    Subject: cls.subject_name,
    Teacher: cls.teacher_name,
    Time: cls.schedule_time,
    ScheduleHour: cls.schedule_hour,
    Status: cls.status,
    AdminApprove: cls.adminapprove,
    Payout: cls.payout,
  }));

  const columns = [
    { title: t('No'), dataIndex: 'classid', key: 'classid' },
    { 
      title: t('date'), 
      dataIndex: 'date', 
      key: 'date',
      render: (date) => new Date(date).toLocaleDateString(),
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
    },
    { title: t('classcode'), dataIndex: 'class_code', key: 'class_code' },
    { title: t('classid'), dataIndex: 'classid', key: 'classid' },
    { title: t('subject'), dataIndex: 'subject_name', key: 'subject_name' },
    { 
      title: t('teacher'), 
      dataIndex: 'teacher_name', 
      key: 'teacher_name',
      render: (_, cls) => `(${cls.nickname}) ${cls.teacher_name}`
    },
    { title: t('time'), dataIndex: 'schedule_time', key: 'schedule_time' },
    { title: t('scheduleHour'), dataIndex: 'schedule_hour', key: 'schedule_hour' },
    { 
      title: t('Tutor Confirm'), 
      dataIndex: 'status', 
      key: 'status',
      render: (status) => {
        const isApproved = status === 'approved';
        return (
          <Tag 
            color={isApproved ? '#f6ffed' : '#fffbe6'} 
            style={{ 
              color: isApproved ? '#52c41a' : '#faad14',
              borderColor: isApproved ? '#b7eb8f' : '#ffe58f'
            }}
          >
            {isApproved ? t('HOURSAPPROVED') : t('confirmpending')}
          </Tag>
        );
      }
    },
    { 
      title: t('Admin Confirm'), 
      dataIndex: 'adminapprove', 
      key: 'adminapprove',
      render: (adminapprove) => {
        const isConfirmed = adminapprove === 'adminconfirm';
        return (
          <Tag 
            color={isConfirmed ? '#f6ffed' : '#fffbe6'} 
            style={{ 
              color: isConfirmed ? '#52c41a' : '#faad14',
              borderColor: isConfirmed ? '#b7eb8f' : '#ffe58f'
            }}
          >
            {isConfirmed ? t('confirmed') : t('confirmpending')}
          </Tag>
        );
      }
    },
    { 
      title: t('payout'), 
      dataIndex: 'payout', 
      key: 'payout',
      render: (payout) => {
        const isPending = payout === 'pending';
        return (
          <Tag 
            color={isPending ? '#fff1f0' : '#f6ffed'} 
            style={{ 
              color: isPending ? '#f5222d' : '#52c41a',
              borderColor: isPending ? '#ffa39e' : '#b7eb8f'
            }}
          >
            {isPending ? t('Pending') : t('Paid')}
          </Tag>
        );
      }
    }
  ];

  return (
    <div style={{ padding: '16px' }}>
      
      <Card title={t('tutorclasslogs')}>
      <Space style={{ marginBottom: 16 }}>
        <Select value={selectedMonthYear} onChange={setSelectedMonthYear} style={{ width: 160 }}>
          <Option value="">{t('All Months/Years')}</Option>
          {generateMonthYearOptions().map(option => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>

        <Select value={statusFilter} onChange={setStatusFilter} style={{ width: 160 }}>
          <Option value="">{t('classstatuses')}</Option>
          <Option value="approved">{t('confirmed')}</Option>
          <Option value="pending">{t('confirmpending')}</Option>
        </Select>

        <Select value={adminapproveFilter} onChange={setAdminapproveFilter} style={{ width: 160 }}>
          <Option value="">{t('Admin Approve')}</Option>
          <Option value="adminconfirm">{t('Admin Confirmed')}</Option> 
          <Option value="pending">{t('Pending')}</Option>
        </Select>

        <Select value={payoutFilter} onChange={setPayoutFilter} style={{ width: 160 }}>
          <Option value="">{t('payout')}</Option>
          <Option value="pending">{t('Pending')}</Option>
          <Option value="paid">{t('Paid')}</Option>
        </Select>

        <CSVLink data={csvData} filename={`tutor_class_log_${selectedMonthYear || 'all'}.csv`}>
          <Button icon={<FileExcelOutlined />} type="primary">{t('exportToCSV')}</Button>
        </CSVLink>
      </Space>

      <Table 
        columns={columns} 
        dataSource={filteredClasses} 
        rowKey="classid"
        pagination={{ 
          current: currentPage, 
          pageSize: itemsPerPage, 
          onChange: setCurrentPage 
        }}
        bordered
      />
      </Card>
    </div>
  );
};

export default withRoleAccess(TutorClassLog, ['superadmin']);
