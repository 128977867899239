import React, { useState, useEffect } from 'react';
import { Modal, Select, Button, Space, message, Tag } from 'antd';
import { TagOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Option } = Select;

const SubjectGroupModal = ({ 
  visible, 
  onClose, 
  classGroupData, 
  subjectGroups, 
  onSuccess,
  t, 
  API_URL 
}) => {
  const [selectedSubjectGroup, setSelectedSubjectGroup] = useState(null);
  const [loading, setLoading] = useState(false);

  // Reset selected value when modal opens
  useEffect(() => {
    if (visible && classGroupData) {
      setSelectedSubjectGroup(classGroupData.subject_group_id || null);
    }
  }, [visible, classGroupData]);

  const handleCancel = () => {
    onClose();
    setSelectedSubjectGroup(null);
  };

  const saveSubjectGroup = async () => {
    if (!classGroupData || !classGroupData.groupid) {
      message.error(t('invalidClassGroupData'));
      return;
    }

    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      
      // Call a dedicated API endpoint for updating the subject group
      await axios.put(
        `${API_URL}/class-groups/${classGroupData.groupid}/subject-group`, 
        { subject_group_id: selectedSubjectGroup },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      message.success(t('subjectGroupUpdated'));
      onSuccess();
      onClose();
    } catch (error) {
      console.error('Error updating subject group:', error);
      message.error(t('updateSubjectGroupError'));
    } finally {
      setLoading(false);
    }
  };

  // Get the group name for the currently selected subject group
  const getCurrentGroupName = (groupId) => {
    if (!groupId) return null;
    const group = subjectGroups.find(g => g.id === groupId);
    return group ? group.group_name : null;
  };

  // Check if the selected subject group is different from the current one and valid
  const hasChanged = selectedSubjectGroup !== null && 
    selectedSubjectGroup !== undefined && 
    selectedSubjectGroup !== classGroupData?.subject_group_id;

  // Prepare footer buttons - only show Save when a new, different subject group is selected
  const footerButtons = [
    <Button key="cancel" onClick={handleCancel}>
      {t('cancel')}
    </Button>,
    <Button 
      key="submit" 
      type="primary" 
      onClick={saveSubjectGroup}
      loading={loading}
      disabled={!hasChanged} // Disable Save button until a new subject group is selected
    >
      {t('save')}
    </Button>
  ];

  return (
    <Modal
      title={<Space><TagOutlined />{t('updateSubjectGroup')}</Space>}
      visible={visible}
      onCancel={handleCancel}
      footer={footerButtons}
      destroyOnClose
      maskClosable={false}
      width={400}
      centered
    >
      <div style={{ marginBottom: '16px' }}>
        <p>{t('currentSubjectGroup')}:</p>
        {classGroupData?.group_name ? (
          <Tag color="cyan">{classGroupData.group_name}</Tag>
        ) : (
          <span style={{ color: '#bfbfbf', fontStyle: 'italic' }}>
            {t('noSubjectGroup')}
          </span>
        )}
      </div>
      
      <div>
        <p>{t('selectNewSubjectGroup')}:</p>
        <Select 
            style={{ width: '100%' }} 
            placeholder={t('selectSubjectGroup')} 
            allowClear
            value={selectedSubjectGroup}
            onChange={(value) => setSelectedSubjectGroup(value)}
            mode="default" // Keeps it as a single-select dropdown
            showSearch // Enables search functionality
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } // Filters options based on input
            optionFilterProp="children" // Specifies which property to filter on (option text)
            >
            {subjectGroups.map(group => (
                <Option key={group.id} value={group.id}>
                {group.group_name}
                </Option>
            ))}
            </Select>
        
        {hasChanged ? (
          <div style={{ marginTop: '12px' }}>
            <p>{t('newSelection')}:</p>
            <Tag color="green">{getCurrentGroupName(selectedSubjectGroup) || t('noSubjectGroup')}</Tag>
          </div>
        ) : (
          <div style={{ marginTop: '12px', color: '#ff4d4f' }}>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SubjectGroupModal;