import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Select } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import './Classes.css';
import CustomModal from "./CustomModal";

const { Option } = Select;

const DetailsModal = ({
  showModal,
  closeModal,
  modalData,
  classChangeLogs,
  handleEdit,
  handleSave,
  handleUpdateSubmit,
  editMode,
  editValues,
  setEditValues,
  setEditMode,
  teachers,
  subjects,
  classrooms,
  timeIntervals,
  isModalOpen,
  setIsModalOpen,
  changeReason,
  setChangeReason,
  changeNote,
  setChangeNote,
  formatTime,
  formatDate,
  getDayOfWeek,
}) => {
  const { t } = useTranslation();

  const timeOptions = Array.from({ length: 12 }, (_, index) => {
    const hour = index + 8;
    return Array.from({ length: 4 }, (_, quarter) => {
      const minutes = quarter * 15;
      const time = `${hour.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
      return { label: time, value: time };
    });
  }).flat();

  const getChangeReasonDisplay = (reason) => {
    switch (reason) {
        case 'admin_adjust':
            return { text: 'Admin', color: '#95101e' };
        case 'cancelled_by_student':
            return { text: 'CL by S.', color: 'red' };
        case 'cancelled_by_teacher':
            return { text: 'CL by T.', color: 'orange' };
        case 'class_on_hold_by_student':
            return { text: 'On hold by S.', color: 'blue' };
        case 'class_on_hold_by_school':
            return { text: 'On hold by P.', color: 'green' };
        case 'cancel_same_day':
            return { text: 'Cancel Same Day', color: '#FF4500' }; // Added Cancel Same Day
        case 'make_up':
            return { text: 'Make Up', color: '#8A2BE2' }; // Added Make Up
        case 'others':
            return { text: 'Others', color: 'darkgrey' };
        default:
            return { text: reason, color: 'black' };
    }
};

  return (
    <Modal
      visible={showModal}
      onCancel={closeModal}
      footer={null}
      width={600}
    >
      {modalData && modalData.type === "class" && (
        <div className="class-details">
            <span style={{ fontSize: "20px", color: "#888" }}>
            {modalData.data.class_code || t("N/A")} ({modalData.data.classid || t("N/A")})
          </span>
          <table className="details-table">
        
            <tbody>
              {/* Date Row */}
              <tr>
      <td className="label-cell">{t("Date")}:</td>
      <td className="content-cell">
        {editMode.date ? (
          <input
            type="date"
            value={editValues.date || modalData.data.date}
            onChange={(e) =>
              setEditValues({ ...editValues, date: e.target.value })
            }
            className="date-input"
          />
        ) : (
          <>
            {getDayOfWeek(modalData.data.date)} {formatDate(modalData.data.date)}
          </>
        )}
      </td>
      <td className="edit-cell">
        {editMode.date ? (
          <div className="edit-actions">
            <Button
              type="primary"
              size="small"
              onClick={() => handleSave("date")}
              style={{ marginRight: "8px" }}
            >
              {t("Save")}
            </Button>
            <Button
              size="small"
              onClick={() => setEditMode({ ...editMode, date: false })}
            >
              {t("Cancel")}
            </Button>
          </div>
        ) : (
          <Button
            type="text"
            className="edit-button"
            onClick={() => handleEdit("date")}
          >
            {t("Edit")}
          </Button>
        )}
      </td>
    </tr>


              {/* Time Row */}
              <tr>
      <td className="label-cell">{t("Time")}:</td>
      <td className="content-cell">
        {editMode.time ? (
          <div className="time-selector">
            <Select
              value={editValues.scheduleTime}
              onChange={(value) =>
                setEditValues({ ...editValues, scheduleTime: value })
              }
              style={{ width: 120 }}
            >
              {timeOptions.map(({ label, value }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
            <span className="time-label">for</span>
            <Select
              value={editValues.scheduleHour}
              onChange={(value) =>
                setEditValues({ ...editValues, scheduleHour: Number(value) })
              }
              style={{ width: 150 }}
            >
              {timeIntervals.map(({ value, label }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </div>
        ) : (
          <>
            {formatTime(modalData.data.schedule_time)} -{" "}
            {modalData.data.end_time}
          </>
        )}
      </td>
      <td className="edit-cell">
        {editMode.time ? (
          <div className="edit-actions">
            <Button
              type="primary"
              size="small"
              onClick={() => handleSave("time")}
              style={{ marginRight: "8px" }}
            >
              {t("Save")}
            </Button>
            <Button
              size="small"
              onClick={() => setEditMode({ ...editMode, time: false })}
            >
              {t("Cancel")}
            </Button>
          </div>
        ) : (
          <Button
            type="text"
            className="edit-button"
            onClick={() => handleEdit("time")}
          >
            {t("Edit")}
          </Button>
        )}
      </td>
    </tr>
    <tr>
  <td className="label-cell">{t("Teacher")}:</td>
  <td className="content-cell">
    {editMode.teacher ? (
      <Select
      showSearch
      value={editValues.teacherId || undefined}
      onChange={(value) => setEditValues({ ...editValues, teacherId: value })}
      style={{ width: 200 }}
      placeholder={t("Search or Select Teacher")}
      optionFilterProp="children" // Filter based on displayed text
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
    >
      {teachers.map((teacher) => (
        <Option key={`teacher-${teacher.userid}`} value={teacher.userid}>
          {teacher.nickname}
        </Option>
      ))}
    </Select>    
    ) : (
      modalData.data.nickname
    )}
  </td>
  <td className="edit-cell">
    {editMode.teacher ? (
      <div className="edit-actions">
        <Button
          type="primary"
          size="small"
          onClick={() => handleSave("teacher")}
          style={{ marginRight: "8px" }}
        >
          {t("Save")}
        </Button>
        <Button
          size="small"
          onClick={() => setEditMode({ ...editMode, teacher: false })}
        >
          {t("Cancel")}
        </Button>
      </div>
    ) : (
      <Button
        type="text"
        className="edit-button"
        onClick={() => handleEdit("teacher")}
      >
        {t("Edit")}
      </Button>
    )}
  </td>
</tr>

<tr>
  <td className="label-cell">{t("Subject")}:</td>
  <td className="content-cell">
    {editMode.subject ? (
      <Select
      showSearch
      value={editValues.subjectId || undefined}
      onChange={(value) => setEditValues({ ...editValues, subjectId: value })}
      style={{ width: 200 }}
      placeholder={t("Search or Select Subject")}
      optionFilterProp="children" // Filter based on displayed text
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
    >
      {subjects.map((subject) =>
        subject?.subjectid ? (
          <Option key={subject.subjectid} value={subject.subjectid}>
            {subject.title || t("No Title")}
          </Option>
        ) : null
      )}
    </Select>
    
    ) : (
      modalData.data.subject_name
    )}
  </td>
  <td className="edit-cell">
    {editMode.subject ? (
      <div className="edit-actions">
        <Button
          type="primary"
          size="small"
          onClick={() => handleSave("subject")}
          style={{ marginRight: "8px" }}
        >
          {t("Save")}
        </Button>
        <Button
          size="small"
          onClick={() => setEditMode({ ...editMode, subject: false })}
        >
          {t("Cancel")}
        </Button>
      </div>
    ) : (
      <Button
        type="text"
        className="edit-button"
        onClick={() => handleEdit("subject")}
      >
        {t("Edit")}
      </Button>
    )}
  </td>
</tr>

<tr>
  <td className="label-cell">{t("Classroom")}:</td>
  <td className="content-cell">
    {editMode.room ? (
      <Select
        value={editValues.roomId || undefined}
        onChange={(value) => setEditValues({ ...editValues, roomId: value })}
        style={{ width: 120 }}
        placeholder={t("Select Room")}
      >
        {classrooms.map((classroom) =>
          classroom?.classroomid ? (
            <Option key={`room-${classroom.classroomid}`} value={classroom.classroomid}>
              {classroom.number}
            </Option>
          ) : null
        )}
      </Select>
    ) : (
      modalData.data.classroom_number
    )}
  </td>
  <td className="edit-cell">
    {editMode.room ? (
      <div className="edit-actions">
        <Button
          type="primary"
          size="small"
          onClick={() => handleSave("room")}
          style={{ marginRight: "8px" }}
        >
          {t("Save")}
        </Button>
        <Button
          size="small"
          onClick={() => setEditMode({ ...editMode, room: false })}
        >
          {t("Cancel")}
        </Button>
      </div>
    ) : (
      <Button
        type="text"
        className="edit-button"
        onClick={() => handleEdit("room")}
      >
        {t("Edit")}
      </Button>
    )}
  </td>

</tr>



            </tbody>
          </table>
          <table className="change-logs-table">
  <thead>
    <tr>
      <th style={{ width: "10%", textAlign: "left" }}>{t("From")}</th>
      <th style={{ width: "10%", textAlign: "left" }}>{t("To")}</th>
      <th style={{ width: "13%", textAlign: "left" }}>{t("Change Reason")}</th>
      <th style={{ width: "14%", textAlign: "left" }}>{t("Note")}</th>
    </tr>
  </thead>
  <tbody>
    {classChangeLogs
      .filter((ccl) => ccl.classid === modalData.data.classid)
      .map((ccl, index) => (
        <tr key={index} style={{ backgroundColor: "#f9f9f9" }}>
          {/* Column 1: Original Date */}
          <td className="change-content">
            {formatDate(ccl.originaldate)}
          </td>

          <td className="change-content">
            {formatDate(ccl.newdate)}
          </td>

          {/* Column 2: Change Reason */}
          <td
            className="change-content"
            style={{
              color: getChangeReasonDisplay(ccl.changereason).color,
              width: "13%",
            }}
          >
            {getChangeReasonDisplay(ccl.changereason).text}
          </td>

          {/* Column 3: Note */}
          <td
            className="change-content"
            style={{
              width: "14%",
              wordWrap: "break-word",
              whiteSpace: "normal",
            }}
          >
            {ccl.note || t("N/A")}
          </td>
        </tr>
      ))}
  </tbody>
</table>


        </div>
      )}
    {modalData && modalData.type === "event" && (
        <div className="event-details">
          <p>
            <strong>{t("Event Title")}: </strong>
            {modalData.data.title}
          </p>
          <p>
            <strong>{t("Time")}: </strong>
            {moment(modalData.data.start).format("HH:mm")} -{" "}
            {moment(modalData.data.end).format("HH:mm")}
          </p>
        </div>
      )}

      {/* Add the CustomModal Here */}
      <CustomModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setChangeReason("");
          setChangeNote("");
        }}
        onSubmit={(reason, note) => {
          handleUpdateSubmit(reason, note);
          setIsModalOpen(false);
        }}
        reason={changeReason}
        setReason={setChangeReason}
        note={changeNote}
        setNote={setChangeNote}
      />
    </Modal>
  );
};

DetailsModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalData: PropTypes.object,
  handleEdit: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleUpdateSubmit: PropTypes.func.isRequired,
  editMode: PropTypes.object.isRequired,
  editValues: PropTypes.object.isRequired,
  setEditValues: PropTypes.func.isRequired,
  setEditMode: PropTypes.func.isRequired,
  teachers: PropTypes.array,
  subjects: PropTypes.array,
  classrooms: PropTypes.array,
  timeIntervals: PropTypes.array,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  changeReason: PropTypes.string.isRequired,
  setChangeReason: PropTypes.func.isRequired,
  changeNote: PropTypes.string.isRequired,
  setChangeNote: PropTypes.func.isRequired,
  formatTime: PropTypes.func.isRequired,
  formatDate: PropTypes.func.isRequired,
  getDayOfWeek: PropTypes.func.isRequired,
};

DetailsModal.defaultProps = {
  teachers: [],
  subjects: [],
  classrooms: [],
  timeIntervals: [],
};

export default DetailsModal;
