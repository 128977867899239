import React from 'react';
import { Modal, Button, Descriptions, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const { Title } = Typography;

function SuccessModal({ details, onClose, onConfirm }) {
  return (
    <Modal
      title={<Title level={4}>Confirm User Details</Title>}
      visible={true}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="confirm" type="primary" onClick={onConfirm}>
          Confirm
        </Button>,
      ]}
      closeIcon={<CloseOutlined />}
    >
      <Descriptions bordered column={1} size="middle">
        <Descriptions.Item label="First Name">{details.firstname}</Descriptions.Item>
        <Descriptions.Item label="Last Name">{details.lastname}</Descriptions.Item>
        <Descriptions.Item label="Email">{details.email}</Descriptions.Item>
        <Descriptions.Item label="Role">{details.role}</Descriptions.Item>
        <Descriptions.Item label="Phone">{details.phone}</Descriptions.Item>
        <Descriptions.Item label="Address">{details.address}</Descriptions.Item>
        <Descriptions.Item label="Nickname">{details.nickname}</Descriptions.Item>
        <Descriptions.Item label="National ID">{details.nationalid}</Descriptions.Item>
        <Descriptions.Item label="Date of Birth">{details.date_of_birth}</Descriptions.Item>
        <Descriptions.Item label="Temporary Password">{details.password}</Descriptions.Item>

        {details.role === 'teacher' && (
          <>
            <Descriptions.Item label="Bachelor's Degree">{details.bachelorDegree}</Descriptions.Item>
            <Descriptions.Item label="Master's Degree">{details.masterDegree}</Descriptions.Item>
            <Descriptions.Item label="Doctoral Degree">{details.doctoralDegree}</Descriptions.Item>
          </>
        )}

        {details.role === 'student' && (
          <>
            <Descriptions.Item label="Current Education Level">{details.currentEducationLevel}</Descriptions.Item>
            <Descriptions.Item label="Guardian Name">{details.guardianName}</Descriptions.Item>
            <Descriptions.Item label="Guardian Phone">{details.guardianContact}</Descriptions.Item>
          </>
        )}
      </Descriptions>
    </Modal>
  );
}

export default SuccessModal;
