import React, { useState, useEffect } from 'react';
import { 
  Form, Input, Button, Select, Upload, DatePicker, message, 
  ConfigProvider, Modal, Card, Typography, Row, Col, InputNumber,
  Tag, Divider, Space
} from 'antd';
import { 
  UploadOutlined, PlusOutlined, CalendarOutlined, 
  DollarOutlined, FileTextOutlined, TagOutlined,
  SaveOutlined, CloseOutlined
} from '@ant-design/icons';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import th_TH from 'antd/lib/locale/th_TH';

const { Option } = Select;
const { Title, Text } = Typography;

// Define category colors for consistent tag display
const CATEGORY_COLORS = {
  postoffice: 'blue',
  transport: 'green',
  gas: 'orange',
  laundry: 'purple',
  utilities: 'cyan',
  return: 'red',
  facebook: '#3b5998',
  tiktok: '#000000',
  others: 'default',
  commission: 'gold',
  maintenance: 'lime',
  renovation: 'volcano',
  'blanket return': 'red',
  'key return': 'red',
  refund: 'red',
  'travel expense': 'purple',
  'staff meals and drinks': 'geekblue',
  'stationery supplies': 'cyan',
  'stationery equipment': 'cyan',
  'teaching materials and equipment': 'blue',
  'it equipment': 'magenta',
  'learning materials and equipment': 'blue'
};

const AddExpense = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [addingCategory, setAddingCategory] = useState(false);
  const [categoryModalVisible, setCategoryModalVisible] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  const handleUpload = (file) => {
    setFile(file);
    setFileList([{
      uid: `file-${Date.now()}`,
      name: file.name,
      status: 'done',
      url: URL.createObjectURL(file),
    }]);
    return false;
  };

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/expense/expense-categories`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching expense categories:', error);
      message.error(t('Error fetching categories'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleAddCategory = async () => {
    if (!newCategory) {
      message.warning(t('category_required'));
      return;
    }
    try {
      setAddingCategory(true);
      const token = localStorage.getItem('token');

      await axios.post(`${API_URL}/expense/categories`, { category_name: newCategory }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      message.success(t('category_added_successfully'));
      setNewCategory('');
      fetchCategories(); // Refresh category list
      setCategoryModalVisible(false); // Close the modal after successful addition
    } catch (error) {
      console.error('Error adding category:', error);
      message.error(t('category_add_failed'));
    } finally {
      setAddingCategory(false);
    }
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();
      
      // Prepare form data
      const amount = typeof values.amount === 'number' ? values.amount : 
                    parseFloat(values.amount?.replace(/[^0-9.-]/g, '') || 0);
                    
      const selectedDate = dayjs(values.date);
      
      // Extract month_year from the date automatically
      const month_year = selectedDate.format('YYYY-MM');
      
      formData.append('expense_type', values.expense_type);
      formData.append('category_id', values.category_id);
      formData.append('amount', amount);
      formData.append('description', values.description || '');
      formData.append('date', selectedDate.format('YYYY-MM-DD'));
      formData.append('month_year', month_year);
      
      if (file) {
        formData.append('receipt', file);
      }

      const token = localStorage.getItem('token');
      
      await axios.post(`${API_URL}/expense`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      message.success(t('expense_added_successfully'));
      
      // Reset form
      form.resetFields();
      setFile(null);
      setFileList([]);
      
    } catch (error) {
      console.error('Error submitting expense:', error);
      message.error(t('add_expense_failed'));
    } finally {
      setLoading(false);
    }
  };

  // Get the color for a category tag
  const getCategoryColor = (categoryName) => {
    const category = categories.find(c => c.category_id === categoryName);
    const categoryLower = category?.category_name?.toLowerCase();
    return CATEGORY_COLORS[categoryLower] || 'default';
  };

  // Clean up object URLs on unmount
  useEffect(() => {
    return () => {
      fileList.forEach(file => {
        if (file.url && file.url.startsWith('blob:')) {
          URL.revokeObjectURL(file.url);
        }
      });
    };
  }, [fileList]);

  return (
    <ConfigProvider locale={th_TH}>
      <Card 
        title={
          <Space>
            <FileTextOutlined />
            <Title level={4} style={{ margin: 0 }}>{t('Add New Expense')}</Title>
          </Space>
        }
        bordered={false}
        style={{ maxWidth: 800, margin: '0 auto' }}
      >
        <Form 
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          className="expense-form"
          requiredMark="optional"
          initialValues={{
            date: dayjs(),
          }}
        >
          <Row gutter={24}>
            <Col xs={24} md={12}>
              <Form.Item
                name="date"
                label={
                  <Space>
                    <CalendarOutlined />
                    {t('date_expense')}
                  </Space>
                }
                rules={[{ required: true, message: t('date_required') }]}
              >
                <DatePicker 
                  format="DD/MM/YYYY"
                  style={{ width: '100%' }}
                  placeholder={t('Select date')}
                />
              </Form.Item>
            </Col>
            
            <Col xs={24} md={12}>
              <Form.Item
                name="amount"
                label={
                  <Space>
                    <DollarOutlined />
                    {t('amount_label')}
                  </Space>
                }
                rules={[{ required: true, message: t('amount_required') }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  precision={2}
                  placeholder="0.00"
                  formatter={value => `฿ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/฿\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="category_id"
            label={
              <Space>
                <TagOutlined />
                {t('category_label')}
              </Space>
            }
            rules={[{ required: true, message: t('category_required') }]}
            extra={
              <Button 
                type="link" 
                icon={<PlusOutlined />} 
                onClick={() => setCategoryModalVisible(true)}
                style={{ padding: '4px 0' }}
              >
                {t('add_new_category')}
              </Button>
            }
          >
            <Select 
              placeholder={t('select_category')}
              showSearch
              optionFilterProp="children"
              loading={loading}
              style={{ width: '100%' }}
            >
              {categories.map(category => (
                <Option key={category.category_id} value={category.category_id}>
                  <Tag color={CATEGORY_COLORS[category.category_name?.toLowerCase()] || 'default'}>
                    {category.category_name}
                  </Tag>
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="expense_type"
            label={t('details')}
            rules={[{ required: true, message: t('type_required') }]}
          >
            <Input placeholder={t('Enter expense details')} />
          </Form.Item>

          <Form.Item 
            name="description" 
            label={t('memo')}
          >
            <Input.TextArea 
              rows={4} 
              placeholder={t('Enter additional notes (optional)')}
            />
          </Form.Item>

          <Form.Item 
            label={
              <Space>
                <UploadOutlined />
                {t('upload_receipt_label')}
              </Space>
            }
          >
            <Upload
              beforeUpload={handleUpload}
              maxCount={1}
              accept=".pdf,.jpg,.jpeg,.png"
              fileList={fileList}
              onRemove={() => {
                setFile(null);
                setFileList([]);
              }}
              onChange={({ fileList }) => setFileList(fileList)}
            >
              <Button icon={<UploadOutlined />}>{t('upload_button')}</Button>
            </Upload>
            <Text type="secondary" style={{ display: 'block', marginTop: 8 }}>
              {t('Supported formats: PDF, JPG, PNG')}
            </Text>
          </Form.Item>

          <Divider />

          <Form.Item style={{ marginBottom: 0, textAlign: 'right' }}>
            <Space>
              <Button 
                onClick={() => form.resetFields()}
                icon={<CloseOutlined />}
              >
                {t('reset')}
              </Button>
              <Button 
                type="primary" 
                htmlType="submit" 
                loading={loading}
                icon={<SaveOutlined />}
              >
                {t('submit_button')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>

      {/* Add Category Modal */}
      <Modal
        title={
          <Space>
            <PlusOutlined />
            {t('add_new_category')}
          </Space>
        }
        visible={categoryModalVisible}
        onCancel={() => setCategoryModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setCategoryModalVisible(false)}>
            {t('cancel')}
          </Button>,
          <Button 
            key="submit" 
            type="primary" 
            onClick={handleAddCategory} 
            loading={addingCategory}
            icon={<SaveOutlined />}
          >
            {t('add')}
          </Button>
        ]}
      >
        <Form layout="vertical">
          <Form.Item 
            label={t('Category Name')}
            required
          >
            <Input
              placeholder={t('new_category_placeholder')}
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              onPressEnter={handleAddCategory}
              prefix={<TagOutlined style={{ color: '#bfbfbf' }} />}
            />
          </Form.Item>
          <Text type="secondary">
            {t('Enter a descriptive name for the new expense category')}
          </Text>
        </Form>
      </Modal>
    </ConfigProvider>
  );
};

export default withRoleAccess(AddExpense, ['superadmin']);