import React, { useEffect } from 'react';
import { Modal, Form, Input, Checkbox, message, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import withRoleAccess from '../../hoc/withRoleAccess';

const { Option } = Select;

const SubjectModal = ({ visible, onClose, onSave, subjectData, subjectGroups = [] }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      if (subjectData) {
        form.setFieldsValue({
          ...subjectData,
          language: Array.isArray(subjectData.language) 
            ? subjectData.language 
            : (typeof subjectData.language === 'string' ? JSON.parse(subjectData.language || '[]') : [])
        });
      } else {
        form.resetFields();
      }
    }
  }, [visible, subjectData, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      
      if (values.subjectgroupid) {
        const selectedGroup = subjectGroups.find(group => group.id === values.subjectgroupid);
        if (selectedGroup) {
          values.subjectgroup = selectedGroup.name;
        }
      }
      
      await onSave(values);
      message.success(t('changesSaved'));
      onClose();
    } catch (error) {
      console.error('Validation failed:', error);
      message.error(t('saveSubjectError'));
    }
  };

  return (
    <Modal
      visible={visible}
      title={subjectData ? t('editSubject') : t('createSubject')}
      onCancel={onClose}
      onOk={handleSubmit}
      okText={t('save')}
      width={700}
    >
      <Form form={form} layout="vertical">
        {/* Subject Group Dropdown */}
        <Form.Item
          name="subjectgroupid"
          label={t('subjectGroup')}
          rules={[{ required: true, message: t('fieldRequired') }]}
        >
          <Select 
            placeholder={t('selectSubjectGroup')}
            showSearch
            optionFilterProp="children"
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {subjectGroups.map((group) => (
              <Option key={group.id} value={group.id}>
                {group.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        
        {/* Legacy Subject Group Input (hidden or can be removed) */}
        <Form.Item name="subjectgroup" hidden>
          <Input />
        </Form.Item>
        
        <Form.Item
          name="code"
          label={t('code')}
          rules={[{ required: true, message: t('fieldRequired') }]}
        >
          <Input />
        </Form.Item>
        
        {/* Changed "year" to "number of hours" */}
        <Form.Item
          name="year"
          label={t('Number of Hours')}
          rules={[{ required: true, message: t('fieldRequired') }]}
        >
          <Input type="number" min={1} />
        </Form.Item>
        
        <Form.Item
          name="title"
          label={t('subjecttitle')}
          rules={[{ required: true, message: t('fieldRequired') }]}
        >
          <Input />
        </Form.Item>
        
        <Form.Item
          name="level"
          label={t('level')}
          rules={[{ required: true, message: t('fieldRequired') }]}
        >
          <Input />
        </Form.Item>
        
        {/* Removed Specification Field */}

        <Form.Item
          name="description"
          label={t('description')}
          rules={[{ required: true, message: t('fieldRequired') }]}
        >
          <Input.TextArea rows={3} />
        </Form.Item>
        
        <Form.Item
          name="image_url"
          label={t('imageUrl')}
          rules={[{ required: true, message: t('fieldRequired') }]}
        >
          <Input />
        </Form.Item>
        
        <Form.Item name="language" label={t('languages')}>
          <Checkbox.Group>
            <Checkbox value="Thai">Thai</Checkbox>
            <Checkbox value="English">English</Checkbox>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default withRoleAccess(SubjectModal, ['admin', 'superadmin']);
