import React, { useState, useEffect } from 'react';
import { Layout, Table, Card, Row, Col, Statistic, Progress, Tabs, Spin, Empty, Alert, Typography, Button } from 'antd';
import { FieldTimeOutlined, CalendarOutlined, LineChartOutlined, DollarOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import getUserIdFromToken from '../../Utils/authUtils';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import withRoleAccess from '../../hoc/withRoleAccess';

const { Content } = Layout;
const { Text } = Typography;
const { TabPane } = Tabs;

const MonthlyHoursSummary = () => {
  const { t } = useTranslation();
  const userId = getUserIdFromToken();
  const [hoursData, setHoursData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('summary');
  const [stats, setStats] = useState({
    totalHours: 0,
    averageMonthly: 0,
    highestMonth: { period: '', hours: 0 },
    currentMonth: { period: '', hours: 0 },
    yearlyTarget: 1000, // Example target - you could fetch this from settings
    progress: 0,
  });
  const API_URL = process.env.REACT_APP_API_URL;

  // Helper function to format a period string for display
  const formatPeriodDisplay = (monthYearStr) => {
    // Expected format from API: "MMM YYYY"
    const date = moment(monthYearStr, 'MMM YYYY');
    const endMonth = date.format('MMM');
    const endYear = date.format('YYYY');
    
    // Calculate the previous month for the starting date (26th)
    const startDate = date.clone().subtract(1, 'month');
    const startMonth = startDate.format('MMM');

    
    // Format as "26 Feb - 25 Mar 2025"
    return `26 ${startMonth} - 25 ${endMonth} ${endYear}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // Fetch monthly hours data
        await fetchPeriodHours();
        
        // Fetch additional class data for detailed analysis
        await fetchClassDetails();
        
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, API_URL, t]);

  const fetchPeriodHours = async () => {
    try {
      const token = localStorage.getItem('token');
      
      const response = await axios.get(`${API_URL}/classes/teacher/periodhours/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      const data = response.data.map((item) => ({
        ...item,
        // Use the period fields directly
        periodDisplay: item.period_display,
        display_hours: item.total_hours / 60, // Convert minutes to hours
        original_minutes: item.total_hours, // Keep original minutes for tooltip
        // Add a color based on hours (you can adjust thresholds)
        color: item.total_hours / 60 > 100 ? '#52c41a' : 
               item.total_hours / 60 > 50 ? '#1890ff' : '#faad14'
      }));
  
      setHoursData(data);
      calculateStats(data);
    } catch (error) {
      console.error('Error fetching period hours:', error);
    }
  };

  const fetchClassDetails = async () => {
    try {
      const token = localStorage.getItem('token');
      
      // This would be a new API endpoint you'd need to create
      const response = await axios.get(`${API_URL}/classes/teacher/details/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setClassData(response.data || []);
    } catch (error) {
      console.error('Error fetching class details:', error);
      // If the API doesn't exist yet, we'll just use empty data
      setClassData([]);
    }
  };

  const calculateStats = (data) => {
    if (!data || data.length === 0) {
      return;
    }

    // Calculate total hours
    const totalHours = data.reduce((sum, item) => sum + item.display_hours, 0);
    
    // Calculate average monthly hours
    const averageMonthly = totalHours / data.length;
    
    // Find highest month
    const highestMonth = [...data].sort((a, b) => b.display_hours - a.display_hours)[0];
    
    // Get current month
    const currentMonth = data.find(item => 
      moment(item.originalMonthYear, 'MMM YYYY').format('MMM YYYY') === 
      moment().format('MMM YYYY')
    ) || { monthYear: 'Current Period', display_hours: 0 };
    
    // Calculate progress towards yearly target
    const progress = Math.min(100, Math.round((totalHours / stats.yearlyTarget) * 100));
    
    setStats({
      ...stats,
      totalHours: totalHours.toFixed(1),
      averageMonthly: averageMonthly.toFixed(1),
      highestMonth: { 
        period: highestMonth?.monthYear || '', 
        hours: highestMonth?.display_hours.toFixed(1) || 0 
      },
      currentMonth: { 
        period: currentMonth?.monthYear || 'Current Period', 
        hours: currentMonth?.display_hours.toFixed(1) || 0 
      },
      progress
    });
  };

  const columns = [
    {
      title: t('Period'),
      dataIndex: 'periodDisplay',
      key: 'periodDisplay',
      width: '50%',
    },
    {
      title: t('Hours'),
      key: 'total_hours',
      width: '20%',
      render: (_, record) => (
        <span title={`${record.original_minutes} minutes`}>
          {record.display_hours.toFixed(1)}
        </span>
      ),
      sorter: (a, b) => a.display_hours - b.display_hours,
    },
    {
      title: t('Visualization'),
      key: 'visualization',
      width: '30%',
      render: (_, record) => (
        <Progress 
          percent={Math.min(100, (record.display_hours / 120) * 100)} 
          size="small" 
          status="active"
          strokeColor={record.color}
          showInfo={false}
        />
      ),
    },
  ];

  const isMobile = window.innerWidth < 768;

  // Render line chart using Progress components
  const renderLineChart = () => {
    if (hoursData.length === 0) {
      return <Empty description={t('No data available')} />;
    }

    // Sort data chronologically
    const sortedData = [...hoursData].sort((a, b) => 
      moment(a.originalMonthYear, 'MMM YYYY').diff(moment(b.originalMonthYear, 'MMM YYYY'))
    );

    // Find the maximum value for scaling
    const maxHours = Math.max(...sortedData.map(item => item.display_hours));
    
    return (
      <div style={{ padding: '20px' }}>
        <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
          <Text strong>{t('Monthly Hours Trend')}</Text>
          <Text>{t('Max')}: {maxHours.toFixed(1)}h</Text>
        </div>
        {sortedData.map(item => (
          <div key={item.originalMonthYear} style={{ marginBottom: '16px' }}>
            <Row align="middle">
              <Col span={6} style={{ paddingRight: '12px' }}>
                <Text>{item.originalMonthYear}</Text>
              </Col>
              <Col span={14}>
                <Progress 
                  percent={Math.min(100, (item.display_hours / (maxHours * 1.1)) * 100)} 
                  strokeColor={item.color}
                  showInfo={false}
                />
              </Col>
              <Col span={4} style={{ textAlign: 'right' }}>
                <Text strong>{item.display_hours.toFixed(1)}h</Text>
              </Col>
            </Row>
          </div>
        ))}
      </div>
    );
  };


  return (
    <Layout>
      <Sidebar />
      <Layout style={{ padding: '10px' }}>
        <Header />
        <Content>
          
          {loading ? (
            <div style={{ textAlign: 'center', padding: '40px' }}>
              <Spin size="large" />
            </div>
          ) : (
            <>
              {/* Statistics Cards */}
              <Row gutter={[16, 16]} style={{ marginBottom: '24px' }}>
                <Col xs={24} sm={12} md={6}>
                  <Card>
                    <Statistic
                      title={t('Total Hours')}
                      value={stats.totalHours}
                      suffix="h"
                      prefix={<FieldTimeOutlined />}
                      valueStyle={{ color: '#1890ff' }}
                    />
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Card>
                    <Statistic
                      title={t('Monthly Average')}
                      value={stats.averageMonthly}
                      suffix="h"
                      prefix={<CalendarOutlined />}
                      valueStyle={{ color: '#52c41a' }}
                    />
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Card>
                    <Statistic
                      title={t('Highest Month')}
                      value={stats.highestMonth.hours}
                      suffix="h"
                      prefix={<LineChartOutlined />}
                      valueStyle={{ color: '#722ed1' }}
                    />
                    <Text type="secondary">{stats.highestMonth.period}</Text>
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Card>
                    <Statistic
                      title={t('Current Month')}
                      value={stats.currentMonth.hours}
                      suffix="h"
                      prefix={<DollarOutlined />}
                      valueStyle={{ color: '#fa8c16' }}
                    />
                    <Text type="secondary">{stats.currentMonth.period}</Text>
                  </Card>
                </Col>
              </Row>

              {/* Target Progress */}
              <Card title={t('Yearly Hours Target')} style={{ marginBottom: '24px' }}>
                <Row gutter={16} align="middle">
                  <Col span={18}>
                    <Progress 
                      percent={stats.progress} 
                      status={stats.progress >= 100 ? 'success' : 'active'}
                      strokeColor={{
                        '0%': '#108ee9',
                        '100%': '#87d068',
                      }}
                    />
                  </Col>
                  <Col span={6} style={{ textAlign: 'center' }}>
                    <Statistic 
                      value={`${stats.totalHours}/${stats.yearlyTarget}`} 
                      suffix="h"
                      valueStyle={{ fontSize: '18px' }}
                    />
                  </Col>
                </Row>
              </Card>

              {/* Tabs for different views */}
              <Tabs 
                activeKey={activeTab} 
                onChange={setActiveTab}
                tabBarExtraContent={
                  <Button 
                    icon={<DownloadOutlined />}
                    onClick={() => {
                      // Handle export functionality
                      console.log('Export data');
                    }}
                  >
                    {t('Export')}
                  </Button>
                }
              >
                <TabPane tab={t('Summary')} key="summary">
                  <Card>
                    <Table
                      columns={columns}
                      dataSource={hoursData}
                      rowKey="originalMonthYear"
                      pagination={false}
                      scroll={{ x: 'max-content' }}
                    />
                  </Card>
                </TabPane>
                
                <TabPane tab={t('Line Chart')} key="lineChart">
                  <Card>
                    <div style={{ height: isMobile ? 'auto' : 400, minHeight: 300 }}>
                      {renderLineChart()}
                    </div>
                  </Card>
                </TabPane>
              
              </Tabs>
            </>
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default withRoleAccess(MonthlyHoursSummary, ['teacher']);