import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { sarabunFont } from '../../sarabunFont';
import {
  Layout,
  Card,
  Typography,
  Row,
  Col,
  Select,
  Input,
  Button,
  Checkbox,
  Divider,
  Descriptions,
  Tag,
  Spin,
  List,
  Statistic,
  Modal,
  message,
  Form,
  Result
} from 'antd';
import {
  FileOutlined,
  UserOutlined,
  BookOutlined,
  DollarOutlined,
  CalendarOutlined
} from '@ant-design/icons';
import './Registrations.css';

const { Content } = Layout;
const { Title, Text } = Typography;

// Function to load the Sarabun font into jsPDF
const loadSarabunFont = (doc) => {
  doc.addFileToVFS('Sarabun.ttf', sarabunFont.src.split('base64,')[1]);
  doc.addFont('Sarabun.ttf', 'Sarabun', 'normal');
  doc.setFont('Sarabun');
};

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return 'N/A';
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return new Intl.DateTimeFormat('en-GB', options).format(date).toUpperCase();
};

const formatTime = (timeString) => {
  if (!timeString) return 'N/A';
  const [hours, minutes] = timeString.split(':');
  return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
};

const formatNumber = (number) => {
  const parsedNumber = parseFloat(number);
  if (isNaN(parsedNumber)) {
    return '0.00';
  }
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(parsedNumber);
};

const Registrations = () => {
  const { t } = useTranslation();
  const [students, setStudents] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState('');
  const [studentDetails, setStudentDetails] = useState({});
  const [selectedClass, setSelectedClass] = useState('');
  const [schedule, setSchedule] = useState([]);
  const [promotion, setPromotion] = useState('');
  const [price, setPrice] = useState(0);
  const [inventoryPrice, setInventoryPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [inventoryItems, setInventoryItems] = useState([]);
  const [selectedInventoryItems, setSelectedInventoryItems] = useState([]);
  const [baseClassPrice, setBaseClassPrice] = useState(0);
  const [priceBeforeVat, setPriceBeforeVat] = useState(0);
  const [vat, setVat] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  const fetchStudents = useCallback(async (search = '') => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/students/reg-students-select`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          search
        }
      });
  
  
      if (response.data.success) {
        setStudents(response.data.students);
      }
    } catch (error) {
      console.error('Error fetching students:', error);
      message.error('Failed to fetch students');
    } finally {
      setIsLoading(false);
    }
  }, [API_URL]);

  const fetchClasses = useCallback(async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/class-groups`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const classesData = Array.isArray(response.data) ? response.data : [];

      // Filter out classes where the end_date has passed
      const filteredClasses = classesData.filter((classGroup) => {
        const endDate = new Date(classGroup.end_date);
        const today = new Date();
        return endDate >= today;
      });

      setClasses(filteredClasses);
    } catch (error) {
      console.error('Error fetching classes:', error);
      message.error('Failed to fetch classes');
    } finally {
      setIsLoading(false);
    }
  }, [API_URL]);

  const fetchInventoryItems = useCallback(async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/inventory`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const inventoryData = response.data || [];

      // Filter inventory items with status 'active'
      const activeInventoryItems = inventoryData.filter(item => item.status === 'active');

      setInventoryItems(activeInventoryItems);
    } catch (error) {
      console.error('Error fetching inventory items:', error);
      message.error('Failed to fetch inventory items');
    } finally {
      setIsLoading(false);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchStudents();
    fetchClasses();
    fetchInventoryItems();
  }, [fetchStudents, fetchClasses, fetchInventoryItems]);


  const classOptions = classes.map(cls => ({
    value: cls.groupid,
    label: `${cls.class_code} ${t('start_date')} ${formatDate(cls.start_date)}`
  }));

  const inventoryOptions = inventoryItems.map(item => ({
    value: item.inventory_id,
    label: `${item.item_name} (${formatNumber(item.price_per_unit)})`,
    price: parseFloat(item.price_per_unit) || 0,
    name: item.item_name,
    inventory_id: item.inventory_id
  }));

  const handleStudentSelect = (value) => {
    setSelectedStudent(value);
    const selected = students.find(student => student.value === value);
    
    setStudentDetails(selected || {});
  };

  const handleClassSelect = (value) => {
    setSelectedClass(value);
    const selectedClass = classes.find(cls => cls.groupid === value);
    const classPrice = selectedClass ? parseFloat(selectedClass.price) || 0 : 0;
    setSchedule(selectedClass ? selectedClass.classes : []);
    setBaseClassPrice(classPrice);
    setPrice(classPrice);
    setPromotion('');
    setInventoryPrice(0);
    const initialPriceBeforeVat = classPrice;
    setPriceBeforeVat(initialPriceBeforeVat);
    calculateTotalPrice(initialPriceBeforeVat, promotion, vat);
  };

  const handleInventorySelect = (values) => {
    const selectedItems = values.map(value => {
      const item = inventoryOptions.find(option => option.value === value);
      return item || { value, label: value, price: 0 };
    });

    setSelectedInventoryItems(selectedItems);

    const inventoryCost = selectedItems.reduce((total, item) => {
      return total + (item.price || 0);
    }, 0);

    setInventoryPrice(inventoryCost);

    const newPriceBeforeVat = baseClassPrice + inventoryCost - (parseFloat(promotion) || 0);
    setPriceBeforeVat(newPriceBeforeVat);

    calculateTotalPrice(newPriceBeforeVat, promotion, vat);
  };

  const handlePromotionChange = (e) => {
    const promoValue = e.target.value;
    setPromotion(promoValue);

    const parsedPromotion = parseFloat(promoValue) || 0;
    const newPriceBeforeVat = baseClassPrice + inventoryPrice - parsedPromotion;
    setPriceBeforeVat(newPriceBeforeVat);

    calculateTotalPrice(newPriceBeforeVat, promoValue, vat);
  };

  const handleVatChange = (checked) => {
    setVat(checked);
    calculateTotalPrice(priceBeforeVat, promotion, checked);
  };

  const calculateTotalPrice = (priceBeforeVat, promotion, vat) => {
    let finalPrice = priceBeforeVat || 0;

    if (vat) {
      finalPrice = finalPrice * 1.07; // Apply 7% VAT
    }

    setTotalPrice(finalPrice);
  };

  const showConfirmModal = () => {
    setIsConfirmationModalOpen(true);
  };

  const handleConfirmRegistration = async () => {
    try {
      setIsLoading(true);

      const validPromotion = promotion ? parseFloat(promotion) : 0;
      if (isNaN(validPromotion)) {
        message.error("Invalid promotion value");
        return;
      }

      const validPrice = price ? parseFloat(price) : 0;
      if (isNaN(validPrice) || validPrice <= 0) {
        message.error("Invalid price value");
        return;
      }

      const validTotalPrice = totalPrice ? parseFloat(totalPrice) : 0;
      if (isNaN(validTotalPrice) || validTotalPrice <= 0) {
        message.error("Invalid total price value");
        return;
      }

      const userData = JSON.parse(localStorage.getItem('userData'));
      const userId = userData ? userData.userId : null;

      // Preparing registration data - simplified version
      const registrationData = {
        studentId: selectedStudent,
        classId: selectedClass,
        promotion: validPromotion,
        finalPrice: validTotalPrice,
        paymentMethod: 'system', // Default payment method
        paymentConfirmed: true, // Automatically confirmed
        createdBy: userId,
        price: validPrice,
        discount: validPromotion,
        inventoryItems: selectedInventoryItems.map(item => ({
          inventory_id: item.inventory_id,
          price: item.price
        })),
        inventoryPrice: inventoryPrice,
        vat: vat ? 0.07 : 0
      };

      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}/registrations`, registrationData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // Close confirmation modal and show success modal
      setIsConfirmationModalOpen(false);
      setSuccessModalVisible(true);

      // Reset form
      resetForm();
    } catch (error) {
      console.error('Error saving registration:', error);
      message.error('Failed to complete registration');
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setSelectedStudent('');
    setStudentDetails({});
    setSelectedClass('');
    setSchedule([]);
    setPromotion('');
    setPrice(0);
    setInventoryPrice(0);
    setTotalPrice(0);
    setVat(false);
    setSelectedInventoryItems([]);
    setPriceBeforeVat(0);
    setBaseClassPrice(0);
  };

  const generateQuotationPDF = () => {
    const doc = new jsPDF();
    loadSarabunFont(doc);
    doc.setFont('Sarabun', 'normal');
  
    // Get selected class details first to avoid reference errors
    const selectedClassDetails = classes.find(cls => cls.groupid === selectedClass) || {};
    const classCode = selectedClassDetails.class_code || 'N/A';
    const classPrice = baseClassPrice || 0;
  
    // Set page properties
    const pageWidth = doc.internal.pageSize.width;
    const margin = 15;
    
    const headerImage = new Image();
headerImage.src = '/images/logosquare.png';

headerImage.onload = () => {
  // Add logo on the left
  const logoWidth = 30;
  const logoHeight = 30;
  doc.addImage(headerImage, 'PNG', margin, margin, logoWidth, logoHeight);
  
  // Company info moved to the left, next to the logo
  const textStartX = margin + logoWidth + 10; // 10 points of spacing after the logo
  
  doc.setFontSize(12);
  doc.setFont('helvetica', 'bold');
  doc.text('Y Learning (Thailand) Co., Ltd.', textStartX, margin + 8);
  
  doc.setFontSize(8);
  doc.setFont('Sarabun', 'normal');
  doc.text('บริษัท วาย เลิร์นนิ่ง (ไทยแลนด์) จำกัด', textStartX, margin + 15);
  doc.text('เลขที่ 98/38 ซอยลาดพร้าว 35 แขวงจันทรเกษม', textStartX, margin + 20);
  doc.text('เขตจตุจักร กรุงเทพมหานคร 10900', textStartX, margin + 25);
  doc.text('เลขภาษี 0105564038397', textStartX, margin + 30);
  doc.text('Tel: 082-656-2287', textStartX, margin + 35);
  doc.text('Email: theadvisor.service@gmail.com', textStartX, margin + 40);
  
 // Quotation box on the right - smaller dimensions
const boxWidth = 55; // Reduced width
const boxHeight = 45; // Reduced height
const boxX = pageWidth - margin - boxWidth;
const boxY = margin;

// Draw box with light blue background
doc.setFillColor(240, 245, 255);
doc.rect(boxX, boxY, boxWidth, boxHeight, 'F');

// Add "QUOTATION" title to the box - smaller font
doc.setFont('helvetica', 'bold');
doc.setFontSize(20); 
doc.setTextColor(70, 70, 255);
doc.text('QUOTATION', boxX + boxWidth / 2, boxY + 10, { align: 'center' });

doc.setFont('Sarabun', 'normal');
doc.setFontSize(9); 
doc.setTextColor(0, 0, 0);

const timestamp = moment().format('YYYYMMDDHHmm');
const quotationNo = `QO-${timestamp}`;

doc.text('เลขที่:', boxX + 8, boxY + 20);
doc.text(quotationNo, boxX + boxWidth - 8, boxY + 20, { align: 'right' });

doc.text('วันที่:', boxX + 8, boxY + 28);
doc.text(moment().format('DD/MM/YYYY'), boxX + boxWidth - 8, boxY + 28, { align: 'right' });

doc.text('หมดอายุ:', boxX + 8, boxY + 36);
doc.text(moment().add(15, 'days').format('DD/MM/YYYY'), boxX + boxWidth - 8, boxY + 36, { align: 'right' });


doc.setDrawColor(200, 200, 200);
doc.line(margin, margin + 45, pageWidth - margin, margin + 45);
      
      // Customer information section
      const startY = margin + 55;
      
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(11);
      doc.text('Customer Information', margin, startY);
      
      doc.setFont('Sarabun', 'normal');
      doc.setFontSize(10);
      doc.text(`Name: ${studentDetails.firstname || studentDetails.contactInfo?.firstname || studentDetails.label || ''} ${studentDetails.lastname || studentDetails.contactInfo?.lastname || ''}`, margin, startY + 10);
      doc.text(`Address: ${studentDetails.address || studentDetails.contactInfo?.address || '-'}`, margin, startY + 17);
      doc.text(`Phone: ${studentDetails.phone || studentDetails.contactInfo?.phone || '-'}`, margin, startY + 24);
      doc.text(`Email: ${studentDetails.email || studentDetails.contactInfo?.email || '-'}`, margin, startY + 31);
      
      const courseY = startY + 45;

    doc.setDrawColor(220, 220, 240); 
    doc.line(margin, startY + 40, pageWidth - margin, startY + 40);
    doc.setLineDashPattern([], 0); 
      
      // Calculate totals
        const totalBeforeDiscount = classPrice + selectedInventoryItems.reduce((sum, item) => 
          sum + (item.price || item.price_per_unit || 0), 0);
        const discountAmount = parseFloat(promotion) || 0;
        const totalAfterDiscount = totalBeforeDiscount - discountAmount;
        const vatAmount = vat ? totalAfterDiscount * 0.07 : 0;
        const grandTotal = totalAfterDiscount + vatAmount;

        // Create the main table without summary rows
        const bodyContent = [
          [
            '1',
            `${classCode}`,
            '1',
            'Course',
            formatNumber(classPrice),
            formatNumber(classPrice)
          ],
          ...selectedInventoryItems.map((item, index) => [
            (index + 2).toString(),
            item.label || item.name,
            '1',
            'Book',
            formatNumber(item.price || item.price_per_unit),
            formatNumber(item.price || item.price_per_unit)
          ])
        ];

        // Create the table without summary rows
        doc.autoTable({
          startY: courseY + 10,
          head: [['#', 'Description', 'Quantity', 'Unit', 'Unit Price', 'Total']],
          body: bodyContent,
          theme: 'grid',
          styles: { font: 'Sarabun', fontSize: 9 },
          headStyles: { fillColor: [240, 240, 250], textColor: [50, 50, 50], fontStyle: 'bold' },
          columnStyles: {
            0: { cellWidth: 10 },
            1: { cellWidth: 80 },
            2: { cellWidth: 20, halign: 'center' },
            3: { cellWidth: 20, halign: 'center' },
            4: { cellWidth: 25, halign: 'right' },
            5: { cellWidth: 25, halign: 'right' }
          },
          alternateRowStyles: { fillColor: [248, 248, 255] }
        });

        // Add the summary information outside the table
        const summaryX = doc.internal.pageSize.width - 80; // Right-aligned
        let summaryY = doc.autoTable.previous.finalY + 10;
        const lineHeight = 6;

        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);

        // Summary rows
        doc.text('Total:', summaryX, summaryY);
        doc.text(formatNumber(totalBeforeDiscount), summaryX + 55, summaryY, { align: 'right' });
        summaryY += lineHeight;

        doc.text('Discount:', summaryX, summaryY);
        doc.text(formatNumber(discountAmount), summaryX + 55, summaryY, { align: 'right' });
        summaryY += lineHeight;

        doc.text('Total after discount:', summaryX, summaryY);
        doc.text(formatNumber(totalAfterDiscount), summaryX + 55, summaryY, { align: 'right' });
        summaryY += lineHeight;

        doc.text('VAT 7%:', summaryX, summaryY);
        doc.text(formatNumber(vatAmount), summaryX + 55, summaryY, { align: 'right' });
        summaryY += lineHeight;

        // Grand total with bold font
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(12);
        doc.text('Grand Total:', summaryX, summaryY);
        doc.text(formatNumber(grandTotal), summaryX + 55, summaryY, { align: 'right' });
      
      const paymentY = doc.autoTable.previous.finalY + 50;

        doc.setFont('helvetica', 'bold');
        doc.setFontSize(11);
        doc.text('Payment Information', margin, paymentY);

        // Add Kasikorn Bank logo
        // The parameters are: image, format, x, y, width, height
        doc.addImage('/images/kbank.png', 'PNG', margin, paymentY + 5, 10, 10);

        doc.setFont('Sarabun', 'normal');
        doc.setFontSize(10);
        // Adjust text positions to place all text below the image
        doc.text('ธนาคารกสิกรไทย [Kasikorn Bank]', margin, paymentY + 20);
        doc.text('บจก. วาย เลิร์นนิ่ง (ไทยแลนด์) [Y Learning (Thailand) Co., Ltd.]', margin, paymentY + 25);
        doc.text('เลขที่บัญชีออมทรัพย์ 110-1-19906-8', margin, paymentY + 30);
      
      // Add signature section
      const signatureY = paymentY + 55;
      
      doc.text('Authorized Signature', margin + 50, signatureY, { align: 'center' });
      doc.line(margin + 20, signatureY - 5, margin + 80, signatureY - 5);
      
      doc.text('Company Stamp', pageWidth - margin - 50, signatureY, { align: 'center' });
      doc.line(pageWidth - margin - 80, signatureY - 5, pageWidth - margin - 20, signatureY - 5);
      
      // Add note at the bottom
      const noteY = signatureY + 20;
      doc.setFontSize(8);
      doc.text('Note: This is a computer-generated document. No signature is required.', pageWidth / 2, noteY, { align: 'center' });
      
      // Save the PDF
      doc.save(`quotation-${timestamp}.pdf`);
    };
  };

  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Header />
        <Content style={{ padding: '24px', marginLeft: '75px', marginTop: '64px' }}>
          <Card>
            <Title level={2}>{t('registration.register_for_course')}</Title>
            <Divider />

            <Spin spinning={isLoading}>
              <Row gutter={[24, 24]}>
                {/* Left Column */}
                <Col xs={24} md={12}>
                  <Card title={t('student')} bordered={false}>
                    <Form.Item label={t('registration.select_student')} required>
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder={t('registration.search_or_select_student')}
                      optionFilterProp="label"
                      onChange={handleStudentSelect}
                      value={selectedStudent}
                      options={students} // Use students array directly
                      filterOption={(input, option) =>
                        option?.label?.toLowerCase().includes(input.toLowerCase())
                      }
                      onSearch={(value) => fetchStudents(value)}
                      notFoundContent={isLoading ? <Spin size="small" /> : "No students found"}
                    />
                    </Form.Item>

                    {selectedStudent && (
                    <Descriptions bordered size="small">
                      <Descriptions.Item label={t('name')} span={3}>
                        <UserOutlined /> {studentDetails.firstname || studentDetails.label || 'N/A'}
                      </Descriptions.Item>
                      <Descriptions.Item label={t('address')} span={3}>
                        {(studentDetails.contactInfo?.address || studentDetails.address || 'N/A')}
                      </Descriptions.Item>
                      <Descriptions.Item label={t('phone')} span={3}>
                        {(studentDetails.contactInfo?.phone || studentDetails.phone || 'N/A')}
                      </Descriptions.Item>
                      <Descriptions.Item label={t('email')} span={3}>
                        {(studentDetails.contactInfo?.email || studentDetails.email || 'N/A')}
                      </Descriptions.Item>
                    </Descriptions>
                  )}

                    <Divider />

                    <Form.Item label={t('registration.select_class')} required>
                      <Select
                        style={{ width: '100%' }}
                        placeholder={t('registration.select_class')}
                        onChange={handleClassSelect}
                        value={selectedClass}
                        options={classOptions}
                      />
                    </Form.Item>

                    <Form.Item label={t('registration.select_inventory_items')}>
                      <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder={t('registration.select_inventory')}
                        onChange={handleInventorySelect}
                        value={selectedInventoryItems.map(item => item.value)}
                        options={inventoryOptions}
                        optionFilterProp="label"
                        filterOption={(input, option) =>
                          option.label.toLowerCase().includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>

                    {selectedClass && schedule.length > 0 && (
                      <>
                        <Divider orientation="left">{t('registration.class_schedule')}</Divider>
                        <List
                          size="small"
                          bordered
                          dataSource={schedule}
                          renderItem={(slot, index) => (
                            <List.Item>
                              <CalendarOutlined style={{ marginRight: 8 }} />
                              <Text>{formatDate(slot.date)}</Text>
                              <Text type="secondary" style={{ margin: '0 8px' }}>{slot.schedule_day}</Text>
                              <Text>{formatTime(slot.schedule_time)}</Text>
                              <Text type="secondary" style={{ margin: '0 8px' }}>({slot.schedule_hour})</Text>
                              <Text strong>{slot.subject_name}</Text>
                            </List.Item>
                          )}
                        />
                      </>
                    )}
                  </Card>
                </Col>

                {/* Right Column */}
                <Col xs={24} md={12}>
                  <Card title={t('price')} bordered={false}>
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Statistic 
                          title={t('registration.class_price')}
                          value={price}
                          precision={2}
                        />
                      </Col>
                      <Col span={12}>
                        <Statistic 
                          title={t('registration.inventory_price')}
                          value={inventoryPrice}
                          precision={2}
                          prefix={<BookOutlined />}
                        />
                      </Col>
                      <Col span={24}>
                        <Form.Item label={t('registration.promotion_discount')}>
                          <Input
                            prefix={<DollarOutlined />}
                            placeholder="0.00"
                            value={promotion}
                            onChange={handlePromotionChange}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Statistic 
                          title={t('registration.price_before_vat')}
                          value={priceBeforeVat}
                          precision={2}
                        />
                      </Col>
                      <Col span={24}>
                        <Form.Item>
                          <Checkbox checked={vat} onChange={(e) => handleVatChange(e.target.checked)}>
                            {t('registration.vat')}
                          </Checkbox>
                          {vat && (
                            <Tag color="blue" style={{ marginLeft: 8 }}>
                              +{formatNumber(priceBeforeVat * 0.07)}
                            </Tag>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Statistic 
                          title={t('registration.total_price')}
                          value={totalPrice}
                          precision={2}
                          valueStyle={{ color: '#3f8600', fontWeight: 'bold', fontSize: '24px' }}
                        />
                      </Col>
                    </Row>

                    <Divider />

                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Button 
                          type="default" 
                          icon={<FileOutlined />} 
                          onClick={generateQuotationPDF}
                          disabled={!selectedStudent || !selectedClass}
                          block
                        >
                          {t('registration.quotation')}
                        </Button>
                      </Col>
                      <Col span={12}>
                        <Button 
                          type="primary" 
                          onClick={showConfirmModal}
                          disabled={!selectedStudent || !selectedClass || totalPrice <= 0}
                          block
                        >
                          {t('registration.confirm_registration')}
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Spin>
          </Card>
        </Content>
      </Layout>

      {/* Confirmation Modal */}
      <Modal
  title={t('registration.confirm_registration_details')}
  open={isConfirmationModalOpen}
  onOk={handleConfirmRegistration}
  onCancel={() => setIsConfirmationModalOpen(false)}
  confirmLoading={isLoading}
>
  <Descriptions bordered column={1} size="small">
    <Descriptions.Item label={t('registration.student')}>
      {studentDetails.firstname || studentDetails.label?.split(' ')[0] || ''} {studentDetails.lastname || (studentDetails.label?.split(' ').slice(1).join(' ')) || ''}
    </Descriptions.Item>
    
    <Descriptions.Item label={t('course')}>
      {classes.find(cls => cls.groupid === selectedClass)?.class_code} - 
      {classes.find(cls => cls.groupid === selectedClass)?.subject_name}
    </Descriptions.Item>
    {selectedInventoryItems.length > 0 && (
      <Descriptions.Item label={t('materials')}>
        <ul style={{ paddingLeft: 20, margin: 0 }}>
          {selectedInventoryItems.map((item, index) => (
            <li key={index}>{item.name || item.label} - {formatNumber(item.price)}</li>
          ))}
        </ul>
      </Descriptions.Item>
    )}
    <Descriptions.Item label={t('registration.price')}>
      {formatNumber(price)}
    </Descriptions.Item>
    {promotion && (
      <Descriptions.Item label={t('registration.promotion')}>
        {formatNumber(promotion)}
      </Descriptions.Item>
    )}
    {vat && (
      <Descriptions.Item label={t('registration.vat')}>
        {formatNumber(priceBeforeVat * 0.07)}
      </Descriptions.Item>
    )}
    <Descriptions.Item label={t('registration.total_price')}>
      <Text strong>{formatNumber(totalPrice)}</Text>
    </Descriptions.Item>
  </Descriptions>
</Modal>

      {/* Success Modal */}
      <Modal

        open={successModalVisible}
        onOk={() => setSuccessModalVisible(false)}
        onCancel={() => setSuccessModalVisible(false)}
        footer={[
          <Button key="ok" type="primary" onClick={() => setSuccessModalVisible(false)}>
            {t('ok')}
          </Button>
        ]}
      >
        <Result
          status="success"
          title={t('registration.successful_registration')}
          
        />
      </Modal>
    </Layout>
  );
};

export default withRoleAccess(Registrations, ['superadmin', 'admin']);