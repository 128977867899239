import React, { useState, useEffect } from 'react';
import { Layout, Tabs } from 'antd';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import EditDeleteTeacher from './EditDeleteTeacher';
import EditDeleteStudent from './EditDeleteStudent';
import EditDeleteSubject from './EditDeleteSubject';
import FilterTaskTable from '../Class/FilterTaskTable';
import ClassChange from '../Class/ClassChange';
import Classes from '../Class/Classes';
import EditDeleteClassroom from './EditDeleteClassroom';
import AnnouncementAdmin from '../Announcement/AnnouncementAdmin';
import MatchTeacherSubject from '../SubjectMatch/MatchTeacherSubject';
import CreateTeacherForm from '../CreateUserform/CreateTeacherForm';
import CreateStudentForm from '../CreateUserform/CreateStudentForm';
import TutorClassLog from '../Teacher/TutorClassLog';
import CreateCourse from '../Class/CreateCourse';

const { Content } = Layout;

function AdminPanel() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('teachers');
  const [showCreateTeacherForm, setShowCreateTeacherForm] = useState(false);
  const [showCreateStudentForm, setShowCreateStudentForm] = useState(false);
  const [tabChangeKey, setTabChangeKey] = useState(null); // Used to signal data refetch

  const toggleCreateTeacherForm = () => {
    setShowCreateTeacherForm(!showCreateTeacherForm);
  };

  const toggleCreateStudentForm = () => {
    setShowCreateStudentForm(!showCreateStudentForm);
  };

  useEffect(() => {
    // Update tabChangeKey whenever the tab changes to trigger a data fetch in the selected component
    setTabChangeKey(activeTab);
  }, [activeTab]);

  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Header />
        <Content style={{ marginTop: '60px', marginLeft: '70px', padding: '24px' }}>
        <Tabs 
  activeKey={activeTab} 
  onChange={setActiveTab} 
  className="tabs-container"
  items={[
    {
      key: "teachers",
      label: t("tutors"),
      children: showCreateTeacherForm ? (
        <CreateTeacherForm onClose={toggleCreateTeacherForm} />
      ) : (
        <EditDeleteTeacher onCreateNewTeacher={toggleCreateTeacherForm} shouldFetch={tabChangeKey === "teachers"} />
      )
    },
    {
      key: "students",
      label: t("students"),
      children: showCreateStudentForm ? (
        <CreateStudentForm onClose={toggleCreateStudentForm} />
      ) : (
        <EditDeleteStudent onCreateNewStudent={toggleCreateStudentForm} shouldFetch={tabChangeKey === "students"} />
      )
    },
    {
      key: "subjects",
      label: t("subjects"),
      children: <EditDeleteSubject shouldFetch={tabChangeKey === "subjects"} />
    },
    {
      key: "classrooms",
      label: t("classrooms"),
      children: <EditDeleteClassroom shouldFetch={tabChangeKey === "classrooms"} />
    },
    {
      key: "class",
      label: t("createCourse1"),
      children: <Classes shouldFetch={tabChangeKey === "class"} />
    },
    {
      key: "createcourse",
      label: t("createCourse2"),
      children: <CreateCourse shouldFetch={tabChangeKey === "createcourse"} />
    },
    {
      key: "courses",
      label: t("manageCourses"),
      children: <FilterTaskTable shouldFetch={tabChangeKey === "courses"} />
    },
    {
      key: "classchange",
      label: t("classchange"),
      children: <ClassChange shouldFetch={tabChangeKey === "classchange"} />
    },
    {
      key: "announcements",
      label: t("announcements"),
      children: <AnnouncementAdmin shouldFetch={tabChangeKey === "announcements"} />
    },
    {
      key: "matchteachersubject",
      label: t("matchteachersubject"),
      children: <MatchTeacherSubject shouldFetch={tabChangeKey === "matchteachersubject"} />
    },
    {
      key: "teacherhourlog",
      label: t("teacherhourlog"),
      children: <TutorClassLog shouldFetch={tabChangeKey === "teacherhourlog"} />
    }
  ]}
/>

        </Content>
      </Layout>
    </Layout>
  );
}

export default withRoleAccess(AdminPanel, ['admin', 'superadmin']);
