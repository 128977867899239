import React, { useEffect, useState } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import './Classroom.css';
import { useTranslation } from 'react-i18next';
import { Button, Card, Typography, Row, Col, List, message, Spin } from 'antd';

const { Text } = Typography;

function Classroom() {
  const { t, i18n } = useTranslation();
  const [classrooms, setClassrooms] = useState([]);
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('this');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const [classroomResponse, classResponse] = await Promise.all([
          axios.get(`${API_URL}/classrooms`, { headers: { Authorization: `Bearer ${token}` } }),
          axios.get(`${API_URL}/classes`, { headers: { Authorization: `Bearer ${token}` } })
        ]);
        setClassrooms(classroomResponse.data);
        setClasses(classResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        message.error(t('classroom.fetch_error'));
        setError(t('classroom.fetch_error'));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [API_URL, t]);

  const formatDate = (date) => {
    const options = { day: '2-digit', month: 'short', year: '2-digit' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  };

  const getClassEventsForWeek = (centerDate) => {
    // Reset time components to midnight
    const startDate = new Date(centerDate);
    startDate.setHours(0, 0, 0, 0);
    startDate.setDate(centerDate.getDate() - centerDate.getDay());
    
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);
    endDate.setHours(23, 59, 59, 999);  // End of day
  
    return classes.filter(cls => {
      // Create date with consistent timezone handling
      const clsDate = new Date(cls.date);
      clsDate.setHours(0, 0, 0, 0);  // Reset time component
      
      return clsDate >= startDate && clsDate <= endDate;
    });
};

  const renderWeekView = () => {
    const classEvents = getClassEventsForWeek(selectedDate);
    const today = new Date().setHours(0, 0, 0, 0); // Today's date without time
    const days = [
      t('days.sunday'),
      t('days.monday'),
      t('days.tuesday'),
      t('days.wednesday'),
      t('days.thursday'),
      t('days.friday'),
      t('days.saturday')
    ];
    
    const weekDates = days.map((_, index) => {
      const date = new Date(selectedDate);
      date.setDate(selectedDate.getDate() - selectedDate.getDay() + index);
      return date;
    });
  
    const sortedClassrooms = [...classrooms].sort((a, b) => {
      const numA = a.number.match(/\d+/g);
      const numB = b.number.match(/\d+/g);
      return numA && numB ? parseInt(numA[0], 10) - parseInt(numB[0], 10) : a.number.localeCompare(b.number);
    });

   return (
    <Row gutter={[16, 16]}>
      {days.map((day, index) => (
        <Col key={day} span={24} md={8}>
          <Card 
            title={`${day} (${formatDate(weekDates[index])})`} 
            bordered 
            style={{
              backgroundColor: weekDates[index].setHours(0, 0, 0, 0) === today ? '#e6f7ff' : '#fff', 
              borderColor: weekDates[index].setHours(0, 0, 0, 0) === today ? '#91d5ff' : undefined
            }}
          >
            {sortedClassrooms.map(classroom => {
              const events = classEvents
                .filter(cls => {
                  const eventDate = new Date(cls.date);
                  return eventDate.getDay() === weekDates[index].getDay() && cls.classroom_number === classroom.number;
                })
                .sort((a, b) => a.schedule_time.localeCompare(b.schedule_time));

              return (
                <Card key={classroom.classroomid} type="inner" title={`${t('classroom.room')} ${classroom.number}`} bordered={false}>
                    {events.length > 0 ? (
                      <List
                        dataSource={events}
                        renderItem={(event, index) => (
                          <List.Item
                            style={{
                              backgroundColor: index % 2 === 0 ? '#f0f5ff' : '#e6f7ff',
                              padding: '8px 12px',
                              borderRadius: '4px',
                              marginBottom: '4px'
                            }}
                          >
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                              <Text strong>{event.subject_name}</Text>
                              <div>
                                <Text type="secondary">
                                  {`(${event.schedule_time.slice(0, 5)}-${event.end_time.slice(0, 5)})`}
                                </Text>
                                <Text type="secondary" style={{ marginLeft: '8px' }}>
                                  - {event.nickname}
                                </Text>
                              </div>
                            </div>
                          </List.Item>
                        )}
                      />
                    ) : (
                      <Text type="secondary">{t('classroom.available')}</Text>
                    )}
                  </Card>
              );
            })}
          </Card>
        </Col>
      ))}
    </Row>
  );
};

  const handlePreviousWeek = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() - 7);
    setSelectedDate(newDate);
    setActiveTab('previous');
  };
  
  const handleNextWeek = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + 7);
    setSelectedDate(newDate);
    setActiveTab('next');
  };

  const handleThisWeek = () => {
    setSelectedDate(new Date());
    setActiveTab('this');
  };

  if (loading) return <Spin tip={t('loading')} />;
  if (error) return <Text type="danger">{error}</Text>;

  return (
    <div>
      <Sidebar />
      <Header />
      <div className="classroom-page-container">
      <div style={{ display: 'flex', justifyContent: 'center', gap: '16px', marginBottom: '24px' }}>
  <Button
    onClick={handlePreviousWeek}
    type={activeTab === 'previous' ? 'primary' : 'default'}
    style={{ padding: '8px 24px', fontSize: '16px' }}
  >
    {t('navigation.previous_week')}
  </Button>
  <Button
    onClick={handleThisWeek}
    type={activeTab === 'this' ? 'primary' : 'default'}
    style={{ padding: '8px 24px', fontSize: '16px' }}
  >
    {t('navigation.this_week')}
  </Button>
  <Button
    onClick={handleNextWeek}
    type={activeTab === 'next' ? 'primary' : 'default'}
    style={{ padding: '8px 24px', fontSize: '16px' }}
  >
    {t('navigation.next_week')}
  </Button>
</div>

        {renderWeekView()}
      </div>
    </div>
  );
}

export default withRoleAccess(Classroom, ['admin', 'superadmin', 'teacher', 'student']);
